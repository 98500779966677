import React, { useState, useEffect, useRef } from 'react';
import OtpInput from 'react-otp-input';

export default function OtpInputBox({ onChange, value }) {
  const firstInputRef = useRef(null);

  const inputStyle = {
    width: "27px",
    height: "27px",
    border: "1px solid black",
    borderRadius: "1px",
    textAlign: "center",
  };

  
  const handleOtpChange = (otp) => {
    onChange(otp); 
    const nextIndex = otp.length;

    if (nextIndex < 6 && nextIndex > 0) {
      const nextInput = document.querySelector(`input[otp-index="${nextIndex}"]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus(); 
    }
  }, []);

  return (
    <OtpInput
      value={value}
      onChange={handleOtpChange} 
      numInputs={6}
      renderSeparator={<span>{"\u00A0\u00A0\u00A0"}</span>}
      inputStyle={inputStyle}
      renderInput={(props, index) => (
        <input
          {...props}
          otp-index={index} 
          ref={index === 0 ? firstInputRef : null} 
        />
      )}
    />
  );
}
