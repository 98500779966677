import React, { useEffect, useState } from "react";

const ImageCard = ({divclassName,bgColor,altImg, imgSrc, cardName, number, height, width, padding,subAdmin}) => {
    console.log(subAdmin,"subAdmindddd",window.innerWidth);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
 
    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div
            className={divclassName}
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: bgColor,
                borderRadius: "8px",
                boxShadow: "2px 2px 5px -2px #000000",
                height: height,
                width: width || "100%",
                justifyContent: "space-between",
            }}
        >
            <div style={{width: "38%"}}>
            <img className=" m-2"
                style={{ display: "block", margin: "0 auto",padding: windowWidth < 324 ? "0px" : "0.5rem" }}
                alt={altImg}
                src={imgSrc}
            />
            </div>
            <div style={{ marginLeft: "10px",textAlign: "center",paddingRight: "4px",width: "62%"}}>
            <p
                style={{
                    color: "#ffffff",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    // fontSize: subAdmin && window.innerWidth>768 ? "1.3rem" : "1rem" ,
                    fontSize: subAdmin 
                                ? (windowWidth < 324 ? "0.8rem" : (windowWidth > 768 ? "1.3rem" : "1rem")) 
                                : "1rem",
                    display: "block",
                    marginTop:"20px"
                }}
            >{cardName}</p>
            <p
                style={{
                    color: "#ffffff",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "600",
                    fontSize: subAdmin && windowWidth < 324 ? "0.8rem" : "1rem",
                    alignContent: "center",
                    marginBottom:"20px"
                }}
            >{number}</p>
            </div>
        </div>
    )
}

export default ImageCard