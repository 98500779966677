// import React, { useEffect, useState } from 'react';
// import './FeatureModal.css';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import { getRoles, updateRoles } from '../../services/appfeaturesrole'; // Import saveSelectedFeatures function
// import { toast } from 'react-toastify';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import { Button } from '@mui/material';

// const FeatureModal = ({ rowData, onClose, roleName }) => {
//   const [accordions, setAccordions] = useState([]);
//   const [selectedFeatures, setSelectedFeatures] = useState({});

//   const toggleAccordion = (index) => {
//     const updatedAccordions = accordions.map((accordion, i) =>
//       index === i ? { ...accordion, isOpen: !accordion.isOpen } : accordion
//     );
//     setAccordions(updatedAccordions);
//   };

//   const toggleFeature = (screenName, featureId) => {
//     setSelectedFeatures((prevSelectedFeatures) => {
//       const updatedSelectedFeatures = { ...prevSelectedFeatures };
//       const accordionIndex = accordions.findIndex((accordion) => accordion.name === screenName);
//       const featureIndex = accordions[accordionIndex].features.findIndex((feature) => feature._id === featureId);
//       if (featureIndex !== -1) {
//         updatedSelectedFeatures[screenName] = updatedSelectedFeatures[screenName] || {};
//         updatedSelectedFeatures[screenName][featureId] = !updatedSelectedFeatures[screenName][featureId];
//       }
//       return updatedSelectedFeatures;
//     });
//   };

//   const saveSelection = async () => {
//     // Send selected features to backend for saving
//     try {
//       console.log(selectedFeatures,"selectedFeatures")
//       // const data = rowData._id;
//       const data={
//         roleid:rowData._id,
//         screens:selectedFeatures
//       }
//       console.log(data,"dataToPassed")
//       const response = await updateRoles(data);
//       console.log(response,"updatedResponse");
//       if (response.success) {
//         toast.success("Selected features saved successfully.");
//         onClose();
//       } else {
//         toast.error("Error while saving selected features.");
//       }
//     } catch (error) {
//       console.log(error);
//       toast.error("An error occurred while saving selected features.");
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = rowData._id;
//         const response = await getRoles({ data });
//         if (response.success) {
//           const mappedData = response.data.featuresAccess.map((item) => ({
//             name: item.ScreenName,
//             features: item.features,
//             isOpen: false,
//           }));
//           setAccordions(mappedData);

//           const initialSelectedFeatures = {};
//           mappedData.forEach((accordion) => {
//             initialSelectedFeatures[accordion.name] = {};
//             accordion.features.forEach((feature) => {
//               if (feature.access) {
//                 initialSelectedFeatures[accordion.name][feature._id] = true;
//               } else {
//                 initialSelectedFeatures[accordion.name][feature._id] = false;
//               }
//             });
//           });
//           setSelectedFeatures(initialSelectedFeatures);
//         } else {
//           toast.error("Error while fetching roles.");
//         }
//       } catch (error) {
//         console.log(error);
//         toast.error("An error occurred while fetching roles.");
//       }
//     };
//     fetchData();
//   }, [rowData]);

//   return (
//     <div className="feature-modal">
//       <div className="dialog-title">
//         <div className="dialogRolename">{roleName}</div>
//         <div className="dialog-close-icon">
//           <HighlightOffIcon onClick={onClose} />
//         </div>
//       </div>
//       {accordions.map((accordion, index) => (
//         <div key={index} className="accordion">
//           <div
//         className={accordion.isOpen ? 'accordion-open-header' : 'accordion-header'}
//         onClick={() => toggleAccordion(index)}
//       >
//             {accordion.name}
//             <span className="arrow">{accordion.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
//           </div>
//           {accordion.isOpen && (
//             <div className="accordion-content">
//               <div className="feature-container">
//                 {accordion.features.map((feature, featureIndex) => (
//                 <div key={featureIndex} className="feature-item">
//                 <label className={`feature-label ${selectedFeatures[accordion.name]?.[feature._id] ? 'checked' : ''}`}>
//                   <input
//                     type="checkbox"
//                     checked={selectedFeatures[accordion.name]?.[feature._id] || false}
//                     onChange={() => toggleFeature(accordion.name, feature._id)}
//                     className="feature-checkbox"
//                   />{' '}
//                   {feature.name}
//                 </label>
//               </div>
//                 ))}
//               </div>
//             </div>
//           )}
//         </div>
//       ))}
//        <div className="buttonRow">
//                 <Button variant="outlined" color="error" onClick={onClose}>
//                   Cancel
//                 </Button>
//                 <Button variant="contained" color="primary" onClick={saveSelection}>
//                   Save
//                 </Button>
//               </div>
   
//     </div>
//   );
// };

// export default FeatureModal;



import React, { useEffect, useState } from 'react';
import './FeatureModal.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getRoles, updateRoles } from '../../services/appfeaturesrole'; // Import saveSelectedFeatures function
import { toast } from 'react-toastify';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button } from '@mui/material';
import { showToast } from '../../messages';

const FeatureModal = ({ rowData, onClose, roleName }) => {
  const [accordions, setAccordions] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState({});

  let screenName = "Features Management";
  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  console.log(features, "features")
  const featureArray = features?.featuresAccess;

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(feature => feature.ScreenName == screenName);

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features
      // setAccessTofeature(accessFeatures)
      // console.log("accessFeatures", accessFeatures);
    } else {
      console.log("No feature found with screen name:", screenName);
    }
  } else {
    console.log("No features found in the storage.");
  }

  const toggleAccordion = (index) => {
    const updatedAccordions = accordions.map((accordion, i) =>
      index === i ? { ...accordion, isOpen: !accordion.isOpen } : accordion
    );
    setAccordions(updatedAccordions);
  };

  const toggleFeature = (screenName, featureId) => {
    setSelectedFeatures((prevSelectedFeatures) => {
      const updatedSelectedFeatures = { ...prevSelectedFeatures };
      updatedSelectedFeatures[screenName] = updatedSelectedFeatures[screenName] || {};
      updatedSelectedFeatures[screenName][featureId] = !updatedSelectedFeatures[screenName][featureId];
      return updatedSelectedFeatures;
    });
  };

  const toggleSelectAll = (screenName, isChecked) => {
    setSelectedFeatures((prevSelectedFeatures) => {
      const updatedSelectedFeatures = { ...prevSelectedFeatures };
      updatedSelectedFeatures[screenName] = updatedSelectedFeatures[screenName] || {};
      const features = accordions.find((accordion) => accordion.name === screenName).features;
      features.forEach((feature) => {
        updatedSelectedFeatures[screenName][feature._id] = isChecked;
      });
      return updatedSelectedFeatures;
    });
  };

  const saveSelection = async () => {
    try {
      let featurename = "Add Feature"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {
      showToast('Restriction.editFeature')
    } else {
      console.log(selectedFeatures, "selectedFeatures");
      const data = {
        roleid: rowData._id,
        screens: selectedFeatures,
      };
      console.log(data, "dataToPassed");
      const response = await updateRoles(data);
      console.log(response, "updatedResponse");
      if (response.success) {
        showToast('Organization.featureSave')
        onClose();
      } else {
        showToast('Organization.errFeatureSave')
      }
    }
    } catch (error) {
      console.log(error);
      showToast('Error.errorOccured')
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = rowData._id;
        const response = await getRoles({ data });
        if (response.success) {
          const mappedData = response.data.featuresAccess.map((item) => ({
            name: item.ScreenName,
            features: item.features,
            isOpen: false,
          }));
          setAccordions(mappedData);

          const initialSelectedFeatures = {};
          mappedData.forEach((accordion) => {
            initialSelectedFeatures[accordion.name] = {};
            accordion.features.forEach((feature) => {
              initialSelectedFeatures[accordion.name][feature._id] = feature.access;
            });
          });
          setSelectedFeatures(initialSelectedFeatures);
        } else {
          showToast('Organization.errFetchRoles')
        }
      } catch (error) {
        console.log(error);
        showToast('Error.errorOccured')
      }
    };
    fetchData();
  }, [rowData]);

  return (
    <div className="feature-modal">
      <div className="dialog-title">
        <div className="dialogRolename">{roleName}</div>
        <div className="dialog-close-icon">
          <HighlightOffIcon onClick={onClose} />
        </div>
      </div>
      <div className="dialog-content">
      {accordions.map((accordion, index) => (
        <div key={index} className="accordion">
          <div
            className={accordion.isOpen ? 'accordion-open-header' : 'accordion-header'}
            onClick={() => toggleAccordion(index)}
          >
            {accordion.name}
            <span className="arrow">{accordion.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
          </div>
          {accordion.isOpen && (
            <div className="accordion-content">
              <div className="feature-container">
                <div className="feature-item">
                  <label className="feature-label">
                    <input
                      type="checkbox"
                      checked={accordions[index].features.every(
                        (feature) => selectedFeatures[accordion.name]?.[feature._id]
                      )}
                      onChange={(e) => toggleSelectAll(accordion.name, e.target.checked)}
                      className="feature-checkbox"
                    />{' '}
                    Select All
                  </label>
                </div>
                {accordion.features.map((feature, featureIndex) => (
                  <div key={featureIndex} className="feature-item">
                    <label className={`feature-label ${selectedFeatures[accordion.name]?.[feature._id] ? 'checked' : ''}`}>
                      <input
                        type="checkbox"
                        checked={selectedFeatures[accordion.name]?.[feature._id] || false}
                        onChange={() => toggleFeature(accordion.name, feature._id)}
                        className="feature-checkbox"
                      />{' '}
                      {feature.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
      </div>
      <div className="buttonRow">
        <Button variant="outlined" color="error" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={saveSelection}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default FeatureModal;

