import {
    apiUrl,
    herokuUrl
  } from "./config.json";
   
   
  import httpService from "./httpService.js";
   
  let apiEndpoint = "";
   
  if (process.env.NODE_ENV !== "production") {
    apiEndpoint = `${apiUrl}/assets`;
   
  } else {
    apiEndpoint = `${apiUrl}/assets`;
   
  }
 
  export async function addasset(data){
    const response=await httpService.post(`${apiEndpoint}/addasset`,{data});
    return response;
  }
 
  export async function getassetsbyid(data){
    console.log(data,"data")
    const response=await httpService.post(`${apiEndpoint}/getassetsbyid`,{data});
    return response;
  }

  export async function getactiveassets(data){
    console.log(data,"data")
    const response=await httpService.post(`${apiEndpoint}/getactiveassets`,{data});
    return response;
  }

  export async function getrecentactivities(data) {
    const response = await httpService.post(`${apiEndpoint}/getRecentActivities`, data);
    return response;
   }
   export async function getActivitiesforNotifications(data) {
    const response = await httpService.post(`${apiEndpoint}/getActivitiesforNotifications`, data);
    return response;
   }
  export async function updateAssetOpportunity(data) {
    const response = await httpService.post(`${apiEndpoint}/updateAssetOpportunity`, data);
    return response;
   }

   export async function getSpecificAsset(data){
    const response=await httpService.post(`${apiEndpoint}/getSpecificeOpportunity`,{data});
    return response;
  }

  export async function bidAsset(data){
    const response=await httpService.post(`${apiEndpoint}/bidAsset`,{data});
    return response;
  }

  export async function nobidAsset(data){
    const response=await httpService.post(`${apiEndpoint}/nobidAsset`,{data});
    return response;
  }

  export async function requestBid(data){
    const response=await httpService.post(`${apiEndpoint}/requestBid`,{data});
    return response;
  }

  export async function submitToNextTeam(data){
    const response=await httpService.post(`${apiEndpoint}/submitToNextTeam`,{data});
    return response;
  }

  export async function sendBack(data){
    const response=await httpService.post(`${apiEndpoint}/sendBack`,{data});
    return response;
  }

  export async function getTeamTask(data){
    const response=await httpService.post(`${apiEndpoint}/getTeamtask`,{data});
    return response;
  }

  export async function terminateProject(data){
    const response=await httpService.post(`${apiEndpoint}/terminateProject`,{data});
    return response;
  }

  export async function updateTeamTask(data){
    const response=await httpService.post(`${apiEndpoint}/updateTeamtask`,{data});
    return response;
  }

  export async function sendBackTeams(data){
    const response=await httpService.post(`${apiEndpoint}/sendBackTeams`,{data});
    return response;
  }

  export async function addAuditHistory(data){
    const response=await httpService.post(`${apiEndpoint}/addAuditHistory`,{data});
    return response;
  }

  export async function getAuditHistory(data){
    const response=await httpService.post(`${apiEndpoint}/getAuditHistory`,{data});
    return response;
  }

  export async function getSubmissionHistory(data){
    const response=await httpService.post(`${apiEndpoint}/getSubmissionHistory`,{data});
    return response;
  }


  // export async function getAllassetsharing(){ 
  //   const response=await httpService.get(`${apiEndpoint}/getAllassetsharing`); 
  //   return response; 
  
  // } 

  // export async function getAllTaskAssignments() { 
  //   const response = await httpService.get(`${apiEndpoint}/getAllTaskAssignments`); 
  //   return response; 
  
  // } 

  export async function getCompletedProjects(data){
    const response=await httpService.post(`${apiEndpoint}/getCompletedProjects`,{data});
    return response;
  }
  export async function getInprogressProjects(data){
    const response=await httpService.post(`${apiEndpoint}/getInprogressProjects`,{data});
    return response;
  }
  export async function getCancelledProjects(data){
    const response=await httpService.post(`${apiEndpoint}/getCancelledProjects`,{data});
    return response;
  }
  export async function getProjectProgress(data){
    const response=await httpService.post(`${apiEndpoint}/getProjectProgress`,{data});
    return response;
  }   export async function getAllassetsharing(){
    const response=await httpService.get(`${apiEndpoint}/getAllassetsharing`);
    return response;
  }

  export async function getAllTaskAssignments() {

    const response = await httpService.get(`${apiEndpoint}/getAllTaskAssignments`);
    return response;
  }
  export async function getClausesByAssetId(data){
    const response=await httpService.post(`${apiEndpoint}/getClausesByAssetId`,{data});
    return response;
  }

  export async function getAssetsByOrg(data){
    const response=await httpService.post(`${apiEndpoint}/getAssetsByOrg`,{data});
    return response;
  }
  
  export async function addTeam(data){
    const response=await httpService.post(`${apiEndpoint}/addTeam`,{data});
    return response;
  }

  export async function removeFromTeam(data){
    const response=await httpService.post(`${apiEndpoint}/removeFromTeam`,{data});
    return response;
  }

  export async function getRolesOfSelectedWorkflow(data){
    const response=await httpService.post(`${apiEndpoint}/getRolesOfSelectedWorkflow`,{data});
    return response;
  }
  export async function workFlowList(data){
    const response=await httpService.post(`${apiEndpoint}/getWorkflowListForSelection`,{data});
    return response;
  }
  export async function getAssetsOfSelectedWorkflow(data){
    const response=await httpService.post(`${apiEndpoint}/getAssetsOfSelectedWorkflow`,{data});
    return response;
  }
  export async function getWorkflowListByOrg(data){
    const response=await httpService.post(`${apiEndpoint}/getWorkflowListByOrg`,{data});
    return response;
  }


  export async function checkMember(data){
    const response=await httpService.post(`${apiEndpoint}/checkMembers`,{data});
    return response;
  }
  export async function validateAccess(data){
    const response=await httpService.post(`${apiEndpoint}/validateAccess`,{data});
    return response;
  }

  export async function validateFunctionalityAccess(data){
    const response=await httpService.post(`${apiEndpoint}/validateFunctionalityAccess`,{data});
    return response;
  }


  export async function submitProposal(data){
    const response=await httpService.post(`${apiEndpoint}/submitProposal`,{data});
    return response;
  }

  export async function sentNotitfication(data){
    const response=await httpService.post(`${apiEndpoint}/sendNotification`,{data});
    return response;
  }

  export async function isNotificationSent(data){
    const response=await httpService.post(`${apiEndpoint}/isNotificationSent`,{data});
    return response;
  }
  export async function validateTerminateBtn(data){
    const response=await httpService.post(`${apiEndpoint}/validateTerminateBtn`,{data});
    return response;
  }


  export async function isFirstTeamUser(data){
    const response=await httpService.post(`${apiEndpoint}/isFirstTeamUser`,{data});
    return response;
  }

  export async function getAllocatedProjects(data){
    const response=await httpService.post(`${apiEndpoint}/getAllocatedProjects`,{data});
    return response;
  }

  export async function getasset(assetId){
    const response=await httpService.post(`${apiEndpoint}/getasset`,{assetId});
    return response;
  }

    
  export async function unArchiveProject(data){
    const response=await httpService.post(`${apiEndpoint}/unArchiveProject`,{data});
    return response;
  }


  export async function getVideoUrl(fileName) {
    console.log(fileName,"loplpl")
    const response = await fetch(`${apiEndpoint}/getVideoUrl`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileName }), 
    });
  
    if (!response.ok) {
      throw new Error('Error fetching video preview');
    }
  
    const blob = await response.blob();
    const contentType = response.headers.get('Content-Type'); 
    return { blob, contentType };
  }

  export async function addAisections(data){
    const response=await httpService.post(`${apiEndpoint}/addAisections`,{data});
    return response;
  }

   export async function addTemplateTheme(data){
    const response=await httpService.post(`${apiEndpoint}/addTemplateStyle`,{data});
    return response;
  }


  export async function removeTemplate(data){
    const response=await httpService.post(`${apiEndpoint}/removeTemplate`,{data});
    return response;
  }

  export default {
    addasset,
    getassetsbyid,
    getactiveassets,
    getrecentactivities,
    updateAssetOpportunity,
    getSpecificAsset,
    getAllassetsharing,
    getAllTaskAssignments,
    getCancelledProjects,
    getInprogressProjects,
    getCompletedProjects,
    getProjectProgress,
    getActivitiesforNotifications,
    getClausesByAssetId,
    validateAccess ,
    validateFunctionalityAccess,
    submitProposal,
    validateTerminateBtn,
    isFirstTeamUser,
    getAllocatedProjects,
    getasset,
    workFlowList,
    addAisections
  }