import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardContent,
  Checkbox,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Modal,  
  Dialog,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import DatePicker from 'react-datepicker';
// import '.../'
import Select from 'react-select';
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  ShareOptionModal,
  DeleteOptionModal,
  DownloadOptionModal,
  RestoreOptionModal,
  PermanentDeleteOptionModal,
} from "./MyDocumentsCardsModal"; // Make sure the path is correct
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DescriptionIcon from "@mui/icons-material/Description";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import BorderColorIcon from "@mui/icons-material/BorderColor";
import httpService from "../services/httpService";
import opportunityMatchService from "../services/opportunityMatchService";
import { toast } from "react-toastify";
import { showToast } from "../messages";
import { getSelectedStage, addOrgInDefaultWorkflow, checkOrgInDefaultWorkflow,checkIsworkflowAdded, callUseForWorkflow } from "../services/workflowServices";
import assetService, { updateAssetOpportunity,workFlowList } from "../services/assetService";
import mxgraph from "mxgraph";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Loader from "../utils/loader";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { replace } from "formik";
import opportunityService, {
  getDescription, updateOpportunity,getUserInfo
} from "../services/opportunityService";
import ArchiveIcon from '@mui/icons-material/Archive';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Tooltip from "@mui/material/Tooltip";
import id from "date-fns/locale/id";
import { Col, Form, Row } from "react-bootstrap";
import InputTextField from "./ReusableComponents/InputTextField";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import CustomDatePicker from "./ReusableComponents/DatePicker";
import { Stack } from "@mui/material";
import locationcountryService from "../services/locationcountryService";
import locationstateService from "../services/locationstateService";
import noticetypesService from "../services/noticetypesService";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SingleSelectDropDown from "./ReusableComponents/SigleSelectDropDown";
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { render } from "react-dom";
import AddIcon from "@mui/icons-material/Add";
import "../css/card/mydocument_Card.css"
import ReplyIcon from '@mui/icons-material/Reply';
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import UnarchiveIcon from '@mui/icons-material/Unarchive';

const { mxGraph, mxRubberband, mxEvent } = mxgraph({
  mxImageBasePath: "mxgraph/images",
  mxBasePath: "mxgraph",
});

const statusPendingStyle = {
  fontSize: "11px",
  color: "#00e673",
  borderRadius: "50%",
  WebkitAnimation: "sonarEffect 1.3s ease-out infinite",
  MozAnimation: "sonarEffect 1.3s ease-out infinite",
  animation: "sonarEffect 1.3s ease-out infinite",
  zIndex: 999
};
const acceptRequestStyle = {
  fontSize: "11px",
  color: "red",
  borderRadius: "50%",
  WebkitAnimation: "sonarEffect 1.3s ease-out infinite",
  MozAnimation: "sonarEffect 1.3s ease-out infinite",
  animation: "sonarEffect 1.3s ease-out infinite"
};
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: 'black',
            border: 'none',
            // boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
            // transition: 'box-shadow 0.3s',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: '700',
            // fontSize: '14px',
            fontSize: '0.9rem',
            height: { screen: "ManualOpportunity" } && "40px",

            '&:focus': {
              color: 'black',
              outline: 'none',
              boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
            },
          },
          '& .MuiInputLabel-root': {
            color: 'black',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: "700",

            // fontSize: '14px',
            fontSize: '0.9rem',
            "&.Mui-focused": {
              color: "black",
            },
            // '&:focus': {
            //     color: '#646464',
            // },
          },
          '& .MuiInputBase-multiline': {
            // boxShadow: "none"
            // minHeight: '10px', // Set a minimum height for multiline
          },

        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          // borderColor: '#646464', // Change the outline border color
          // borderWidth: '1px', // Add a border width
          '&:focus': {
            // borderColor: '#646464',
          },
        },
        input: {
          '&:focus': {
            boxShadow: "none !important"
          }
        },
        inputSingle: {
          // border: 'none', // Remove the inner border for single-line input
          // borderRadius: '8px', // Add border radius
          // padding: '10px', // Add padding
        },
        inputMultiline: {
          border: 'none', // Remove the inner border for multiline input
          // borderRadius: '8px', // Add border radius
          // padding: '10px', // Add padding
          // padding: "none"
          boxShadow: "none"
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s',
        }
      }
    }
  },
});


const customStyles1 = {
  multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#1976d2',
      color: 'white',
  }),
  multiValueLabel: (provided) => ({
      ...provided,
      color: 'white',
  }),
  multiValueRemove: (provided) => ({
      ...provided,
      color: 'white',
      ':hover': {
          backgroundColor: '#145a86',
          color: 'white',
      },
  }),
};

const InputTextFields = ({ id, name, value, onChange, label, placeholder, disabled, type }) => {
  console.log(type, "tytytytyty")
  return (
    <ThemeProvider theme={theme}>
      <input
        id={id}
        name={name}
        label={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        type={type}
        style={{
          color: 'black',
          border: '1px solid gray',
          fontFamily: 'Montserrat, sans-serif',
          fontWeight: '900',
          fontSize: '0.9rem',
          height: '40px',
          width: '100%',
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s',
          '&:focus': {
            outline: 'none',
            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
          },
        }}
      />

    </ThemeProvider>
  );
};



export const DocumentListCard = ({
  openMetrics,
  viewType1,
  reminderAssetId,
  resourceLinksData,
  clauseExtraction,
  requestAccess,
  getRequestedlistMember,
  requestListData,
  assetRequestStatusdata,
  commentModal,
  createdBy,
  redirectToPropsal,
  renderIcons,
  assetTypes,
  redirectToRfp,
  assetid,
  agancy,
  handleNewPage,
  HandleProposalWriting,
  descriptionData,
  title = "",
  description = "",
  noticeId = "",
  department = "",
  subtier = "",
  office = {},
  contact = "",
  place = "",
  date = "",
  date1 = "",
  status = "Yes",
  orgId = "",
  viewType = "",
  percentage = "",
  resourceLinksPath='',
  id = "",
  count = "",
  cardChecked = false,
  onCardCheckboxChange,
  documentId = "",
  getAllDocuments,
  type,
  assetNoteComment,
  RFP,
  proposalWriting,
  hierarchy,
  opportunityUpdate,
  highlightClor,
  oppList,
  // enableLoading,
  // disableLoading
  showPastOpportunities,
  viewType3,
  unArchiveOpen,
}) => {
  console.log(
    title,
    noticeId,
    department,
    subtier, "____opportunityid");
  const [dueDate,setDueDate] =useState("")

  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const userRole=localStorage.getItem("Role")

    const formattedDate = `${months[monthIndex]
      } ${day}, ${year} at ${formatTime(hours, minutes)}`;

    return formattedDate;
  };

  const formatTime = (hours, minutes) => {
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm} GMT+5:30`;
  };

  const handleDueDate = (date)=>{
    setDueDate(date)
    setFormData({ ...formData, responseDate: date });
}

  const today = new Date();
  let statusColor;
  let tooltipContent;
  if (date1) {
    const responseDate = new Date(date1);
    const timeRemaining = responseDate - today;
    const totalDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const percentageRemaining = (totalDays / 30) * 100;

    if (totalDays <= 0) {
      // Response date has passed
      statusColor = "black"; // Updated color to 'purple'
      tooltipContent = `Delayed (${Math.abs(totalDays)} days late)`;
    } else if (percentageRemaining <= 33.33) {
      statusColor = "red";
      tooltipContent = `Delayed (${totalDays} days remaining)`;
    } else if (percentageRemaining <= 66.33) {
      statusColor = "orange";
      tooltipContent = `Moderately delayed (${totalDays} days remaining)`;
    } else if (percentageRemaining > 66.33) {
      statusColor = "green";
      tooltipContent = `On time (${totalDays} days remaining)`;
    }else {
      statusColor = "grey";
      tooltipContent = `Response date not available`;
    }
  } else {
    statusColor = "grey";
    tooltipContent = `Response date not available`;
  }

  console.log(statusColor, "statusColor");
  console.log(noticeId, "titletitles");

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [isPermanentDeleteModalOpen, setIsPermanentDeleteModalOpen] =
    useState(false);
  const [descriptionContent, setDescriptionContent] = useState(null);
  const [statesData, setstatesData] = useState([]);
  const [countriesData, setcountriesData] = useState([]);
  const [countryState, setCountryState] = useState([]);
  const [noticetypesData, setNoticetypesData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [statusValidation,setStatusValidation]=useState(false)
  const [isRequestedList,setIsRequestedList]=useState(false)
  const [selectedWorkflowList,setSelectedWorkflowList]=useState()
  const [noticeIdforEdit, setNoticeIdforEdit] = useState('');
  const [titleforEdit, setTitleIdforEdit] = useState('');
  const [departmentforEdit, setDepartmentforEdit] = useState('');
  const [descriptionforEdit, setDescriptionforEdit] = useState('');
  const [subtierforEdit, setSubtierforEdit] = useState('');
  const [officeforEdit, setOfficeforEdit] = useState('');
  const [nameforEdit, setNameforEdit] = useState('');
  const [phoneNumberEdit, setPhoneNumberEdit] = useState('');
  const [emailEdit, setEmailEdit] = useState('');
  const [phoneEdit, setPhoneEdit] = useState('');
  const [noticeTypeEdit, setnoticeTypeEdit] = useState('');
  const [cityEdit, setCityEdit] = useState('');
  const [countryEdit, setCountryEdit] = useState('');
  const [stateEdit, setStateEdit] = useState('');
  const [dateEdit, setDateEdit] = useState('');
  const [validateIsOwner ,setvalidateIsOwner]=useState()
  const [showModal, setShowModal] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const [workflowList,setWorkFlowList]=useState([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const name=contact.fullName
  let screenName = "Opportunity Finder";


  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const featureArray = features?.featuresAccess;
  const hierarchyuser=features?.hierarchy

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
      const matchingFeature = featureArray.filter(feature => feature.ScreenName ==screenName);
     
      if (matchingFeature) {
         accessFeatures = matchingFeature[0].features
        // setAccessTofeature(accessFeatures)
          // console.log("accessFeatures", accessFeatures);
      } else {
          console.log("No feature found with screen name:", screenName);
      }
  } else {
      console.log("No features found in the storage.");
  }
  const [formData, setFormData] = useState({
    solicitationNumber: noticeId,
    title: title,
    description: description,
    name: name,
    noticeType:subtier,
    country:place?.country?.name ?  place?.country?.name :"",
    state:place?.state?.name ? place?.state?.name :"",
    phoneNumber:contact.phone || "",
    orgId:orgId,
    city:place?.city?.name ? place?.state?.name : "",
    responseDate:formatDate(date1),
    email:contact.email,
    department: '',
    subtier:'',
    office:''
  });

  const noticeoptionsList = noticetypesData.map((item) => ({
    value: item.value,
    name: "noticeType",
  }));
  const countryoptionsList = countriesData.map((item) => ({
    value: item.country,
    name: "country",
  }));
  const stateoptionsList = countryState.map((item) => ({
    value: item.state,
    name: "state",
  }));
  console.log(place, "states");
  // const [formData, setFormData] = useState({
  //   title: "",
  //   solicitationNumber: "",
  //   description: "",
  //   name: "",
  //   phoneNumber: "",
  //   email: "",
  //   noticeType: "",
  //   uploadFiles: "",
  //   responseDate: null,
  //   country: "",
  //   state: "",
  //   city: "",
  // });

  const email = sessionStorage.getItem("currentUserEmail");

  const [Department, Subtier] = department.split(".");
  const departmentArray = department.split(".");
  const officename = departmentArray[departmentArray.length - 1];
  const historys = useHistory();
  const handleUpdateOpportunity=async(assetid,noticeId)=>{
    // enableLoading()
    try {
      const data=assetid;
      const response=await updateAssetOpportunity({data});
      console.log(response,"updateResponse")
      if(response.status==200){
        toast.success(response.message);
        window.location.reload();
      }else{
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error)
    }
    // disableLoading()
  }

  const handleMenuClick = (event) => {
    let featurename = "Add Asset"
    const checkfeatureName = accessFeatures?.filter(k=>k.name == featurename)
    console.log(checkfeatureName[0].access,"checkfeatureName")
    if(!checkfeatureName[0].access){
      
      showToast('Error.addProject')
    }else{
      setAnchorEl(event.currentTarget);
    }
    
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    if (action === "Share") {
      setIsShareModalOpen(true);
    }
    if (action === "Delete") {
      setIsDeleteModalOpen(true);
    }
    if (action === "Download") {
      setIsDownloadModalOpen(true);
    }
    if (action === "Restore") {
      setIsRestoreModalOpen(true);
    }
    if (action === "PermanentDelete") {
      setIsPermanentDeleteModalOpen(true);
    }

    handleMenuClose();
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  const closeRestoreModal = () => {
    setIsRestoreModalOpen(false);
  };

  const closePermanentDeleteModal = () => {
    setIsPermanentDeleteModalOpen(false);
  };

  
  // const handleclicks =  (documentId) => {
  //   // alert("documentId")
  //   try {
  //     //    const data =  documentId;
  //     historys.push(`/singleNoticePage/${documentId}`);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleclicks = (documentId) => {
  console.log('Document ID:', documentId);
// alert("futurelist")
// alert(showPastOpportunities)
    try {
      historys.push({
        pathname: "/singleNoticePage",
        state: { documentId,showPastOpportunities } // Pass the documentId as state
      });
    } catch (error) {
      console.log(error);
    }
  };
  

  const getCuurentUser = async (Username) => {
    // enableLoading()
    try {
      const response = await getUserInfo(Username);
      // console.log("userinfo", response);
      setUserInfo(response.data);
      return true;
    } catch (error) {
      console.log(error);
    }
    // disableLoading()
  };
  useEffect(() => {
    const Username = sessionStorage.getItem("currentUserEmail");
    getCuurentUser(Username);
  }, [])



  const getWorkflowList = async ()=>{
    setLoading(true)
    try{
      setLoading(true)
      let data = {
        orgId:sessionStorage.getItem("organizationId")
      }
      const response = await workFlowList(data)
      console.log("callled",response)
      if(response.status == 200){
        console.log("workflow list",response)
        setWorkFlowList(response?.workflowList)
        setLoading(false)
      }


    }catch(error){
      setLoading(false)
      console.log(error,"error in geting workflow list ")

    }finally{
      setLoading(false)
    }

  }


  const handleSelectWorkflowList =async (option )=>{
        console.log("workflow details",option)
        handleUseForWorkflow(option.value)
        setSelectedWorkflowList(option);
   
    }
   
    const handleUseForWorkflow = async(documentId)=>{
      // enableLoading()
      const email=sessionStorage.getItem("currentUserEmail")
      const orgid = sessionStorage.getItem("organizationId")
      const response = await callUseForWorkflow(documentId,email,orgid)
      if(response.status==200){
        console.log(response.message,"useForWorkflow");
      }else{
        console.log(response.message,"useForWorkflow")
      }
      // disableLoading()
    }
 

  const addWorkflowAsset = async (type)=>{
      try {
        console.log(type,"___+projectType")
        if(!selectedWorkflowList && type !== "Archive"){
          showToast('Error.selectWorkflow')
          return
        }
        
      // enableLoading()
        const data = { id, email, type, noticeId, subtier ,workflowId:selectedWorkflowList?.value };
        const response = await assetService.addasset(data);
        console.log(response, "matchresponse");
       if(response.status == 200){
          toast.success(response.message)
        }
        else {
          toast.error(response.message)
       
        }
        handleCloseModal()
      } catch (e) {
        console.log(e);
      } finally {
// disableLoading()
      }
    };
  

const handleCloseModal = () => {
    setShowModal(false);
    setSelectedWorkflowList([])
  };
  const redirectToWorkflow = () => {
    setShowModal(false);
    historys.push('/workflow'); 
  };

  const addOrgopportunity = async (id, type) => {
    // enableLoading()
    try {
      console.log(type,"++type")
      if(type == "Archive"){
        await addWorkflowAsset(type)
        return
      }
       console.log("PPBPBPB")
       getWorkflowList()
       setShowModal(true);
    } catch (e) {
      console.log(e);
    } 
    // finally {
    //   // disableLoading()
    //         setShowModal(true);
    //       }
  };
  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  const truncatedDescription = description
    ? stripHtmlTags(description).split(" ").slice(0, 20).join(" ")
    : "";
  const truncatedDescriptioncontent = descriptionContent
    ? stripHtmlTags(descriptionContent).split(" ").slice(0, 20).join(" ")
    : "";
  console.log(truncatedDescriptioncontent, "description---");
  const handleInfoIconClick = async (documentId) => {
    try {
      // enableLoading()
      setLoading(true)
      const data = documentId;
      // alert(documentId);
      const response = await getDescription(data);
      setDescriptionContent(response?.data);
      console.log(response?.data, "Redirectresponse");
    } catch (error) {
      console.log("Error fetching document data:", error);
    } finally {
// disableLoading()
setLoading(false)
    }
  };
  useEffect(() => {
    // handleInfoIconClick()
  }, [documentId]);

  useEffect(() => {
    // handleInfoIconClick()
    console.log("assetNoteComment", assetNoteComment);
  }, [assetNoteComment]);
  let responseDate = formatDate(date1)
  const handleEditClick = () => {
    console.log("noticeid:", noticeId," title:",title,",description:",description,",fullname:",contact.fullName,",phoneno:",contact.phone,",email:",contact.email,",phoneedit:",contact.phoneNumber,",noticetype:",subtier,",city",place.city.name,",country:",place.country.name,",place and date",place.state.name,date1,",department:  ",Department,", Subtier:  ",Subtier,", Officename:",officename)
    setShowEditPopup(true);
    setNoticeIdforEdit(noticeId)
    setTitleIdforEdit(title)
    setDescriptionforEdit(description)
    setDepartmentforEdit(Department)
    setSubtierforEdit(Subtier)
    setOfficeforEdit(officename)
    setNameforEdit(contact.fullName)
    setPhoneNumberEdit(contact.phone)
    setEmailEdit(contact.email)
    setPhoneEdit(contact.phoneNumber)
    setnoticeTypeEdit({subtier})
    setCityEdit(place.city.name || "")
    setCountryEdit({value: place.country.name , name: 'country'})
    setStateEdit({value: place.state.name , name: 'state'})
    setDateEdit(date1)
    setDueDate(new Date(date1))
  };
  console.log()
  console.log(setStateEdit, "noticeIdforEdit")
  const handleEditPopupClose = () => {
    setShowEditPopup(false);
  };



  const handleChangeDropdown = (event) => {
    // const { name, value } = event;
    console.log(event, "eventt");
    if (event?.name === "country") {
      const selectedCountryCode = event?.value;
      const selectedCountry = countriesData.find(
        (country) => country.countrycode === selectedCountryCode
      );
      const selectedLabel = selectedCountry ? selectedCountry.country : "";
      setSelectedValue(selectedLabel);
      setFormData({ ...formData, [event?.name]: event?.value });
      const filteredData = statesData.filter(
        (state) => state.country.country === event?.value
      );
      console.log(event?.value, "filtervalue");
      console.log(filteredData, "filteredData");
      setCountryState(filteredData);
    } else if (event?.name === "noticeType") {
      const selectedNoticeType = noticetypesData.find(
        (type) => type.code === event?.value
      );
      const selectedLabel = selectedNoticeType ? selectedNoticeType.event?.value : "";
      setSelectedValue(selectedLabel);
      setFormData({ ...formData, [event?.name]: event?.value });
    } else if (event?.name === "state") {
      const selectedState = statesData.find(
        (state) => state.stateCode === event?.value
      );
      const selectedLabel = selectedState ? selectedState.state : "";
      setSelectedValue(selectedLabel);
      setFormData({ ...formData, [event?.name]: event?.value });
    } else {
      console.log("working", event, event?.name);
      setFormData({ ...formData, [event?.name]: event?.value });
    }
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'solicitationNumber') {
      setNoticeIdforEdit(value);
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (name === 'title') {
      setTitleIdforEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (name === 'description') {
      setDescriptionforEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }else if (name === 'department') {
      setDepartmentforEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }else if (name === 'subtier') {
      setSubtierforEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }else if (name === 'office') {
      setOfficeforEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (name === 'name') {
      setNameforEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (name === 'phoneNumber') {
      setPhoneNumberEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (name === 'email') {
      setEmailEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else if (name === 'phoneNumber') {
      setPhoneEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    else if (name === 'noticeType') {
      setnoticeTypeEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    else if (name === 'city') {
      setCityEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    else if (name === 'responseDate') {
      setDateEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    else if (name === 'country') {
      setCityEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    else if (name === 'date') {
      setDateEdit(value)
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
    else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // enableLoading()
      console.log(formData, "formdata1");
      const response = await updateOpportunity(formData);
      console.log(response, "updatedresponse")
      toast.success(response.message)
      if (response.status == 200) {
        window.location.reload();
      }
      // setNoticeIdforEdit('');
      // setTitleIdforEdit('')
      // setDescriptionforEdit('')
      // descriptionforEdit('')
      // setNameforEdit('')
      // setPhoneNumberEdit('')
      // setEmailEdit('')
      // setPhoneEdit('')
      // setnoticeTypeEdit('')
    } catch (error) {
      showToast('Error.opportunityUpdate')
      console.log(error)
    } finally {
      // disableLoading()
    }
  };


  console.log(formData, "foemDataaaaaaaa");


  const getCountries = async () => {
    // enableLoading()
    try {
      const response = await locationcountryService.getcountry();
      let countryResponse = response.checkcountry;
      setcountriesData(countryResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
    }
    // disableLoading()
  };

  const getStates = async () => {
    // enableLoading()

    try {
      const response = await locationstateService.getlocationStates();
      let stateResponse = response.data;
      setstatesData(stateResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
    }
    // disableLoading()
  };
  const getNoticetypes = async () => {
    // enableLoading()
    try {
      const response = await noticetypesService.getnoticetypes();
      let noticetypesResponse = response.data;
      setNoticetypesData(noticetypesResponse);
    } catch (error) {
      console.error("Error fetching Noticetype data:", error.message);
    }
    // disableLoading()
  };

  useEffect(() => {
    getCountries();
    getStates();
    getNoticetypes();
  }, []);

  useEffect(()=>{
    if(createdBy){
      if(createdBy == email || parseInt(hierarchy) <= parseInt(hierarchyuser)){
        setvalidateIsOwner(false)
      }else{
        setvalidateIsOwner(true)
      }
    }
    console.log(validateIsOwner,"checking ",createdBy,email)
    handleStatusValidation()
    console.log("assetRequestdata",assetRequestStatusdata)
    handleGetRequestListData()
    console.log("requestListData",requestListData)
  },[createdBy,requestListData,assetRequestStatusdata])

  const handleStatusValidation = () => {
   if(assetRequestStatusdata){
    const found = assetRequestStatusdata?.some((data) => {
      console.log("Found Check",data.assetId ,assetid,data.requestedStatus)
      return data.assetId == assetid && data.requestedStatus == "pending";
      
    });
    console.log("Found",found,assetid)
    setStatusValidation(found);
   }else{
    setStatusValidation(false)
   }
  };
  

  const handleGetRequestListData = () => {
    const check = requestListData?.some(data => data.assetId === assetid && data.requestedStatus == "pending");
    setIsRequestedList(check || false); // Ensure setIsRequestedList receives a boolean value
    console.log("check", check, requestListData);
  };
  
  return (
    <>
      <Loader loading={loading} />

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            minWidth: windowWidth > 767 ? "400px" : '300px',
            minHeight: '40vh',
            maxWidth: '40vw',
            maxHeight: '90vh',
            padding: "15px"
          },
        }}
      >
        <div className="dialogHeader">
          <div className="dialogClose" style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
            <CloseIcon onClick={handleCloseModal} />
          </div>
        </div>
        <DialogContent >
          <p>Choose the workflow that best suits your needs! We offer a default option to get you started, or you can create a custom dynamic workflow. Selecting a workflow is mandatory to proceed.</p>
          <Button
            onClick={redirectToWorkflow}
            style={{ cursor: "pointer" }}
            variant="contained"
            color="primary"
          >Add Workflow</Button>

        <div style={{display:"flex",justifyContent:"space-between",textAlign:"center",marginTop:"10px"}}>
        <div
        style={{width:"75%"}} 
        >
          <Select
          // isMulti
          options={workflowList.map(workflow => ({
            value: workflow._id,
            label: workflow.workflowName
          }))}
          value={selectedWorkflowList || ""}
          onChange={(selectedOptions) => handleSelectWorkflowList(selectedOptions)}
          styles={customStyles1}
        />
          </div>

          <div style={{width:"23%"}} >
              <Button
           onClick={() => addWorkflowAsset( (type = "Project") )}
            style={{ cursor: "pointer",width:"100%",height:"38px" }}
            variant="contained"
            color="primary"
          >Add Project</Button>
          </div>
        </div>
        </DialogContent>
      </Dialog>
      <div
        style={{
          // width:"100%",
          border: "1px solid #ddd",
          borderBottom: "1px solid #ddd",
          // backgroundColor: "red",
          width: "100%",
          borderRadius:
            viewType == "filterScreen" || "searchScreen" ? "20px" : "0px",
          marginBottom:
            viewType == "filterScreen" || "searchScreen" ? "7px" : "0px",
        }}
      >
         
        <CardContent
          id="listCard"
          style={{
            border:"1px solid #ccc",
            borderLeft:`10px solid ${statusColor}`,
            position: "relative",
            borderRadius:"15px",
            // padding: "10px",
            // marginBottom:"5px",
            backgroundColor:
            status === "Yes" 
            ? (reminderAssetId === assetid && highlightClor === "highlight" ? "#A5FA4A" : "white") 
            : "lightgray"
          }}
          
        >
        
          {/* {type === "MyDocuments" && (
                <Checkbox
                    style={{ position: 'absolute', top: '10px', left: '10px' }}
                    checked={cardChecked}
                    onChange={onCardCheckboxChange}
                />
                 )} */}

          {/* Document content */}
          <div style={{ display: "flex",flexDirection:"column", width: "100%" }}>
            {/* First Column */}
            <div style={{ width: "100%",display:"flex",marginBottom:"10px"}}>
            <div style={{ width: windowWidth<768?"50%": "70%",borderBottom:"1px solid #ccc",paddingBottom:"10px"}}>
           
               {opportunityUpdate==true ?<>
                <Typography
                onClick={() => handleclicks(documentId,showPastOpportunities)}
                  variant="body1"
                  style={{
                    fontWeight: "bold",
                    // marginLeft: "15px",
                    fontSize: "14px",
                    overflow: "hidden",
                    color: "blue",
                  }}
                >
                  {title}
                </Typography>
                <Tooltip  title="New update is available for this asset. click here to get updated version.">
                  <i
                    className="fa fa-asterisk blurb-ripple-out point"
                    style={statusPendingStyle}
                    onClick={()=>handleUpdateOpportunity(assetid,noticeId)}
                    // onClick={() => handleTeamCollab(data)}
                    aria-hidden="true"
                  ></i>
                </Tooltip >
               </>:<>
               <Typography
                  variant="body1"
                  onClick={() => handleclicks(documentId)}
                  style={{
                    fontWeight: "bold",
                    // marginLeft: "15px",
                    fontSize: "14px",
                    overflow: "hidden",
                    color: "blue",
                    cursor:"pointer"
                  }}
                >
                 {title}
                </Typography>
               
               </>}
           </div>
           <div style={{width:windowWidth < 768 ? "50%" : "30%",borderBottom:"1px solid #ccc",textAlign:"right",paddingBottom:"10px",paddingRight:"15px" }}>
           {/* iconss */}
            {/* IconButton in the first row */}
            {/* <Typography style={{ fontSize: "14px" }}>
                {viewType === "filterScreen" && (
                  <span style={{ color: "green" }}>Match {percentage}%</span>
                )}
              </Typography> */}
            {subtier !== "Award Notice" &&
                statusColor !== "black" &&
                viewType !== "Oppoupload" &&
                viewType !== "completed_project_screen" &&
                viewType !== "Shared Project" && 
                viewType !== "Archived" && 
                viewType !== "cancelled_project" &&(
                  <IconButton
                    style={{ marginTop: "-15px", }}
                    onClick={handleMenuClick}
                  >
                    <PlaylistAddIcon />
                  </IconButton>
                )}
           </div>
         </div>
           {/* {second column} */}
            <div style={{ width: "100%",borderBottom:"1px solid #ccc" ,display: "flex",flexDirection:windowWidth < 768 ? "column" : "row" ,marginBottom:"10px",paddingBottom:"10px"}}>
            <div style={{ width:windowWidth < 768 ? "100%" : "58%" , paddingRight: "20px"}}>
             
              <Typography style={{ marginTop:title ? "3px" : "30px", fontSize: "14px" }}>
                <b>Notice ID :</b> {noticeId}
              </Typography>
              <Typography style={{ marginTop: "3px", fontSize: "14px" }}>
                <b>Description :</b>
                {truncatedDescription ? (
                  <>
                    {truncatedDescription}
                    {stripHtmlTags(description).split(" ").length > 20 && "..."}
                  </>
                ) : (
                  <>
                    {descriptionContent == null ? (
                      <>
                        <IconButton
                          style={{ outline: "none" }}
                          title={"Click here to see description"}
                        >
                          {" "}
                          <InfoIcon onClick={()=>handleInfoIconClick(documentId)} />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {truncatedDescriptioncontent}
                        {stripHtmlTags(descriptionContent).split(" ").length >
                          20 && "..."}
                      </>
                    )}
                  </>
                )}
              </Typography>
             
            </div>
            
            <div style={{ width: windowWidth < 768 ? "100%" : "42%", paddingRight: "20px"}}>

             

              {/* Other fields */}
              <Typography style={{ fontSize: "14px" }}>
                {viewType === "filterScreen" && (
                  <span style={{ color: "green" }}>Match {(percentage === '0') ? '100' : percentage}%</span>
                )}
              </Typography>
              <Typography>
                <b>Updated Date :</b> {formatDate(date)}
                {count > 1 && (
                  <span style={{ color: "red" }}> ({count - 1})</span>
                )}
              </Typography>
              <Typography>
                <b>Published Date :</b> {formatDate(date)}
              </Typography>
              <Typography style={{ color: statusColor }}>
                <b>Response Date :</b> {formatDate(date1)}
              </Typography>
              {/* <Typography>
                <b>Notice Type :</b> {subtier}
              </Typography> */}
            </div>
            </div>
          
        <div style={{ width: "100%", display: "flex",flexDirection:windowWidth < 768 ? "column" : "row",justifyContent:"space-between"}}>
{/* third row */}
              <div
                style={{
                  width:windowWidth < 768 ? "100%" : "23%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <Typography>
                  <b>Department</b>
                </Typography>
                <Typography style={{color:"#1976D2"}}>{Department}</Typography>
              </div>
              <div
                style={{
                  width:windowWidth < 768 ? "100%" : "23%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <Typography>
                  <b>Subtier</b>
                </Typography>
                <Typography style={{color:"#1976D2"}}>{Subtier}</Typography>
              </div>
              <div
                style={{
                  width:windowWidth < 768 ? "100%" : "23%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <Typography>
                  <b>Office</b>
                </Typography>
                <Typography style={{color:"#1976D2"}}>{officename}</Typography>
              </div>
              <div
                style={{
                  width:windowWidth < 768 ? "100%" : "23%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <Typography>
                  <b>Notice Type</b>
                </Typography>
                <Typography style={{color:"#1976D2"}}>{subtier}</Typography>
              </div>
            {/* </div> */}
        </div>

           
          </div>

          {/* Menu Icon */}

          {/* <div
            style={{
              position: "absolute",
              top: "7px",
              left: "5px",
              backgroundColor: statusColor,
              width: "15px",
              borderRadius: "50%",
              height: "15px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            title={tooltipContent} // Tooltip content on hover
          ></div> */}

          {viewType1 == "completed_project" && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={"Add Project Completion Metrics"}>
  
                    <IconButton
                      onClick={()=>{openMetrics(id,title)}}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                </>

            )}
            {viewType3 == "Archived" && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={"UnArchive"}>
  
                    <IconButton
                      onClick={()=>{unArchiveOpen(id)}}
                    >
                      <UnarchiveIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                </>

            )}


         
           <>
            {viewType == "Oppoupload" && (
              <>


             
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "70px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={"Clause Extraction"}>
  
                    <IconButton
                      onClick={()=>{clauseExtraction(resourceLinksData,assetid,title)}}
                    >
                      <ArchiveIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "40px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={RFP}>
  
                    <IconButton
                      style={
                        assetNoteComment == "true"
                          ? { color: "green" }
                          : { color: "grey" }
                      }
                      onClick={() => {redirectToRfp( title,
                        noticeId,
                        descriptionData,
                        id,
                        type,
                        date1,
                        agancy,
                        assetid,
                        resourceLinksPath,
                        viewType,
                        oppList
                      )}}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </div>
  
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
                  <Tooltip title={proposalWriting}>
                    <IconButton
                      onClick={() => {
                        redirectToPropsal(
                          title,
                          noticeId,
                          descriptionData,
                          id,
                          type,
                          date1,
                          agancy,
                          assetid,
                          oppList
                        );
                      }}
                    >
                      <AccountTreeIcon />
                    </IconButton>
                  </Tooltip>
                </div>
  
                {orgId && (
                  <div
                    style={{
                      position: "absolute",
                      top: "1px",
                      right: "75px",
                      cursor: "pointer",
                      marginRight:"20px"
                    }}
                    onClick={handleEditClick}
                  >
                    <Tooltip title="Edit Manual Opportunity">
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
               
              </>
            )}
           </>
          

          {/* {validateIsOwner === true && statusValidation === false ?
          (<>
           {viewType == "Oppoupload" && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={"Request Access"}>
                
                    <IconButton
                      style={
                       { color: "green" }
                         
                      }
                      onClick={() => {
                        commentModal(
                        createdBy,
                        assetid,
                        email
                        
                      )}}
                    >
                      <CallReceivedIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                </>
           )}
          </>)
          :validateIsOwner === true && statusValidation === true ?( 
          <>
         
               <Tooltip  title="Collaboration">
                  <i
                    className="fa fa-asterisk blurb-ripple-out point"
                    style={statusPendingStyle}
                    // data-toggle="tooltip"
                    // title="Collaboration"
                    // onClick={() => handleTeamCollab(data)}
                    aria-hidden="true"
                  ></i>
                </Tooltip >
          </>):null
          
          
          } 

 */}

        {validateIsOwner === false && isRequestedList === true && requestListData.length > 0 &&
                  <>
                
                      <Tooltip  title="Collaboration">
                          <i
                            className="fa fa-asterisk blurb-ripple-out point"
                            style={acceptRequestStyle}
                            // data-toggle="tooltip"
                            // title="Collaboration"
                            onClick={() => getRequestedlistMember(assetid)}
                            aria-hidden="true"
                          ></i>
                        </Tooltip >
                  </>
        }

        




          {/* {viewType == "Shared Project" && 
            <>
             
               
                  {renderIcons == 2  &&
                   <>
                    <div
                      style={{
                        position: "absolute",
                        top: "1px",
                        right: "40px",
                        cursor: "pointer",
                      }}
                    >

                      <Tooltip title={RFP}>

                        <IconButton
                          style={
                            assetNoteComment == "true"
                              ? { color: "green" }
                              : { color: "grey" }
                          }
                          onClick={() => {
                            redirectToRfp(title,
                              noticeId,
                              descriptionData,
                              id,
                              type,
                              date1,
                              agancy,
                              assetid,viewType)
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                     <div
                     style={{
                       position: "absolute",
                       top: "1px",
                       right: "10px",
                       cursor: "pointer",
                     }}
                   >
                     <Tooltip title={proposalWriting}>
                       <IconButton
                         onClick={() => {
                           redirectToPropsal(
                             title,
                             noticeId,
                             descriptionData,
                             id,
                             type,
                             date1,
                             agancy,
                             assetid,
                             viewType
                           );
                         }}
                       >
                         <AccountTreeIcon />
                       </IconButton>
                     </Tooltip>
                   </div>
</>
                  }

                 
               
                   {renderIcons === 1 && assetTypes==="rfp" &&  renderIcons !==2 &&
                 <>
                   <div
                      style={{
                        position: "absolute",
                        top: "1px",
                        right: "40px",
                        cursor: "pointer",
                      }}
                    >

                      <Tooltip title={RFP}>

                        <IconButton
                          style={
                            assetNoteComment == "true"
                              ? { color: "green" }
                              : { color: "grey" }
                          }

                          onClick={() => {redirectToRfp( title,
                            noticeId,
                            descriptionData,
                            id,
                            type,
                            date1,
                            agancy,
                            assetid,
                            resourceLinksPath,
                            viewType
                          )}}
                        >
                          <AddCircleOutlineIcon /> 
                        </IconButton>
                      </Tooltip>
                    </div>


                    <div
                    style={{
                      position: "absolute",
                      top: "1px",
                      right: "10px",
                      cursor: "pointer",
                      color:"red"
                    }}
                    >
                    <Tooltip title={"Request Access"}>
                      <IconButton
                      style={{color:"red"}}
                        onClick={() => {
                          requestAccess(
                            assetid,
                            createdBy,
                            email,
                            "proposal"
                          );
                        }}
                      >
                        <AccountTreeIcon />
                      </IconButton>
                    </Tooltip>
                    </div>

             </>
                
                   }

                   {renderIcons === 1 && assetTypes==="proposal" && renderIcons !==2 &&
                    <>
                   <div
                   style={{
                     position: "absolute",
                     top: "1px",
                     right: "10px",
                     cursor: "pointer",
                   }}
                 >
                   <Tooltip title={proposalWriting}>
                     <IconButton
                       onClick={() => {
                         redirectToPropsal(
                           title,
                           noticeId,
                           descriptionData,
                           id,
                           type,
                           date1,
                           agancy,
                           assetid,viewType
                         );
                       }}
                     >
                       <AccountTreeIcon />
                     </IconButton>
                   </Tooltip>
                 </div>


                 <div
                    style={{
                      position: "absolute",
                      top: "1px",
                      right: "40px",
                      cursor: "pointer",
                      color:"red"
                    }}
                    >
                    <Tooltip title={"Request Access"}>
                      <IconButton
                      style={{color:"red"}}
                        onClick={() => {
                          requestAccess(
                            assetid,
                            createdBy,
                            email,
                            "rfp"
                          );
                        }}
                      >
                        < AddCircleOutlineIcon /> 
                      </IconButton>
                    </Tooltip>
                    </div>

</>


                  }

                  
                
               
                  </>} */}
             



          {viewType == "completed_project_screen" && (
            <IconButton
              style={{
                position: "absolute",
                outline: "none",
                top: "5px",
                right: "-5px",
              }}
              title="Check Response"
            >
              <RemoveRedEyeIcon />
            </IconButton>
          )}
          {/* Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {type === "MyDocuments" && (
              <>
                <MenuItem
                  onClick={() => addOrgopportunity(id, (type = "Project"))}
                >
                  Add To My Project
                </MenuItem>
                <MenuItem
                  onClick={() => addOrgopportunity(id, (type = "Archive"))}
                >
                  Add To Archive
                </MenuItem>
                {/* <MenuItem onClick={() => handleMenuItemClick('Download')}>Download</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Delete')}>Delete</MenuItem> */}
              </>
            )}
            {type === "SharedWithMe" && (
              <>
                {/* <MenuItem onClick={() => handleMenuItemClick('Download')}>Download</MenuItem> */}
                <MenuItem onClick={() => handleMenuItemClick("Delete")}>
                  Delete
                </MenuItem>
              </>
            )}
            {type === "DeletedDocuments" && (
              <>
                <MenuItem onClick={() => handleMenuItemClick("Restore")}>
                  Restore
                </MenuItem>
                <MenuItem
                  onClick={() => handleMenuItemClick("PermanentDelete")}
                >
                  Delete Permanently
                </MenuItem>
              </>
            )}
            {/* Add other menu items here */}
          </Menu>

          {/* <ShareOptionModal
            show={isShareModalOpen}
            onHide={closeShareModal}
            id={documentId}
          /> */}
          <DeleteOptionModal
            show={isDeleteModalOpen}
            onHide={closeDeleteModal}
            id={documentId}
            getAllDocuments={getAllDocuments}
          />
          {/* <DownloadOptionModal
            show={isDownloadModalOpen}
            onHide={closeDownloadModal}
            id={documentId}
          /> */}
          {/* <RestoreOptionModal
            show={isRestoreModalOpen}
            onHide={closeRestoreModal}
            id={documentId}
            getAllDocuments={getAllDocuments}
          />
          <PermanentDeleteOptionModal
            show={isPermanentDeleteModalOpen}
            onHide={closePermanentDeleteModal}
            id={documentId}
            getAllDocuments={getAllDocuments}
          /> */}
        </CardContent>
        
      
      </div>
    
      {showEditPopup && (


        <Modal style={{ marginTop: "-45px" }} open={showEditPopup}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              outline: "none",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              width: "80%",
              height: "600px",
              marginTop: "25px",
              scrollbarWidth: "none",
              overflowY: "auto", // Make modal content scrollable
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h5"
                id="summary-modal-title"
                style={{ color: "#007bff", fontWeight: 700 }}
              >
                Opportunity Details
              </Typography>
              <IconButton
                style={{ color: "red" }}
                onClick={handleEditPopupClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{
              height: "400px", overflowY: "auto",
              scrollbarWidth: "none", // For Firefox
              msOverflowStyle: "none", // For IE and Edge
              "&::-webkit-scrollbar": {
                width: "6px", // Width of the scrollbar
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent", // Color of the thumb
              },
            }}>
              {/* First Row: Title, Solicitation Number, and Notice Type */}
              <Row className="form-row">
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <InputTextField
                      customStyles={{ height: '55px' }}
                      id="input-id"
                      name="title"
                      value={titleforEdit}
                      onChange={handleChange}
                      label="Title"
                      type="text"
                      screen="ManualOpportunity"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Title"
                      autoComplete="off"
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <InputTextField
                      id="input-id"
                      name="solicitationNumber"
                      value={noticeIdforEdit}
                      onChange={handleChange}
                      label="Solicitation Number"
                      type="text"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Solicitation Number"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <SingleSelectDropDown
                      customStyles={{ height: '55px' }}
                      name="Notice Type"
                      optionList={noticeoptionsList}
                      onChange={handleChangeDropdown}
                      labelKey="value"
                      label="Notice Type"
                      value={noticeTypeEdit.subtier}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="form-row">
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <InputTextField
                      customStyles={{ height: '55px' }}
                      id="input-id"
                      name="department"
                      value={departmentforEdit}
                      onChange={handleChange}
                      label="Department"
                      type="text"
                      screen="ManualOpportunity"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Department"
                      autoComplete="off"
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <InputTextField
                      id="input-id"
                      name="subtier"
                      value={subtierforEdit}
                      onChange={handleChange}
                      label="Subtier"
                      type="text"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Subtier"
                      autoComplete="off"
                      
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-container">
                  <InputTextField
                      id="input-id"
                      name="office"
                      value={officeforEdit}
                      onChange={handleChange}
                      label="Office"
                      type="text"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Office"
                      autoComplete="off"
                      
                    />
                  </div>
                </Col>
              </Row>

              {/* Point of Contact */}
              <h3 style={{ color: "#4682B4", marginTop: "-6px", marginLeft: "10px" }}>Point of Contact</h3>
              {/* Second Row: Name, Phone Number, and Email */}
              <Row style={{ marginTop: "-6px" }} className="form-row">
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <InputTextField
                      style={{ height: "100%" }}
                      id="input-id"
                      name="name"
                      value={nameforEdit}
                      onChange={handleChange}
                      label="Name"
                      type="text"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Your Name"
                      autoComplete="off"
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <InputTextField
                      id="input-id"
                      name="phoneNumber"
                      value={phoneNumberEdit}
                      onChange={handleChange}
                      label="Phone Number"
                      type="text"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Your Number"
                      autoComplete="off"
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <InputTextField
                      id="input-id"
                      name="email"
                      value={emailEdit}
                      onChange={handleChange}
                      label="Email"
                      type="text"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Your Email"
                      autoComplete="off"
                    />
                  </div>
                </Col>
              </Row>

              {/* Place of Performance */}
              <h3 style={{ color: "#4682B4", marginTop: "-6px", marginLeft: "10px" }}>Place of Performance</h3>
              {/* Third Row: Country, State, and City */}
              <Row style={{ marginTop: "-6px" }} className="form-row">
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <SingleSelectDropDown
                      name="country"
                      value={countryEdit}
                      onChange={handleChangeDropdown}
                      optionList={countryoptionsList}
                      labelKey="value"
                      label="Country"
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <SingleSelectDropDown
                      name="state"
                      value={stateEdit}
                      onChange={handleChangeDropdown}
                      optionList={stateoptionsList}
                      labelKey="value"
                      label="State"
                      type="state"
                    />
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="form-container">
                    <InputTextField
                      customStyles={{ height: '55px' }}
                      id="input-id"
                      name="city"
                      value={cityEdit}
                      onChange={handleChange}
                      label="City"
                      type="text"
                      screen="ManualOpportunity"
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter City"
                      autoComplete="off"
                    />
                  </div>
                </Col>
              </Row>

              {/* Fourth Row: Response Date and File */}
              <Row className="form-row">
                <Col xs={12} md={6}>
                  <div className="form-container">
                    {/* <InputTextField
                      id="input-id"
                      name="responseDate"
                      label="Response Date"
                      onChange={handleChange}
                      type="date"
                      value={dateEdit}
                      multiline={false}
                      rows={1}
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Response Date"
                      autoComplete="off"
                      InputLabelProps={{ shrink: true, style: { display: 'none' } }}
                    /> */}
                     <DatePicker
      selected={dueDate}
      onChange={(e) => handleDueDate(e)}
      dateFormat="yyyy-MM-dd"
      placeholderText="yyyy-mm-dd"
      customInput={
        <TextField
          label="Select Date"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: window.innerWidth <768 ? 200 : 580,}}
        /> }
        />
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="form-container">
                    <InputTextField
                      id="input-id"
                      name="attachments"
                      onChange={handleChange}
                      type="file"
                      label="Attachments"
                      // multiline={false}
                      // rows={1}
                      // helperText="Helper text"
                      fullWidth={true}
                      // placeholder="Upload File"
                      autoComplete="off"
                    />
                  </div>
                </Col>
              </Row>

              {/* Description */}
              <Row className="form-row">
                <Col>
                  <div className="form-container">
                    <InputTextField
                      id="input-id"
                      name="description"
                      value={descriptionforEdit}
                      onChange={handleChange}
                      label="Description"
                      type="text"
                      multiline
                      // rows={1} // Set rows to 1 initially
                      helperText="Helper text"
                      fullWidth={true}
                      placeholder="Enter Description"
                      autoComplete="off"
                      inputProps={{
                        style: {
                          minHeight: '100px',
                          height: 'auto', // Set height to auto to allow dynamic resizing
                        },
                      }}
                      // Adjust height dynamically as content changes
                      onInput={(e) => {
                        e.target.style.height = 'auto';
                        e.target.style.height = e.target.scrollHeight + 'px';
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {/* Buttons */}
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "35px",
              }}
            >
              <Stack spacing={1} direction="row">
                <Button
                  onClick={()=>{
                    setTitleIdforEdit("")
                    setDescriptionforEdit("")
                    setDepartmentforEdit('')
                    setSubtierforEdit('')
                    setOfficeforEdit('')
                    setNameforEdit("")
                    setPhoneNumberEdit("")
                    setEmailEdit("")
                    setPhoneEdit("")
                    setnoticeTypeEdit({})
                    setCityEdit("")
                    setCountryEdit("")
                    setStateEdit("")
                    setDateEdit()
                  }}
                  variant="outlined"
                  style={{
                    width: window.innerWidth <768 ? "80px" : "150px",
                    fontSize: "15px",
                    backgroundColor: "white",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  style={{
                    width: window.innerWidth <768 ? "80px" : "150px",
                    fontsize: "17px",
                    fontWeight: "bold",
                  }}
                >
                  Update
                </Button>
              </Stack>
            </Row>
          </div>
        </Modal>


      )}
    </>
  );
};

export const DocumentGridCard = ({
  // redirectToPropsal,
  // assetid,
  // agancy,
  // type,
  // descriptionData,
  // handleNewPage,
  // HandleProposalWriting,
  // setisHandleNewPage,
  // setShowList,
  // title = "",
  // noticeId = "",
  // department = "",
  // subtier = "",
  // office = {},
  // date = "",
  // date1 = "",
  // status = "Yes",
  // viewType = "",
  // percentage = "",
  // id = "",
  // cardChecked = false,
  // onCardCheckboxChange,
  // documentId = "",
  // getAllDocuments,
  // resourceLinksPath,
  // RFP,
  // proposalWriting,
  // redirectToRfp,
  // description = "",
  openMetrics,
  viewType1,
  reminderAssetId,
  clauseExtraction,
  requestAccess,
  getRequestedlistMember,
  requestListData,
  assetRequestStatusdata,
  commentModal,
  createdBy,
  redirectToPropsal,
  renderIcons,
  assetTypes,
  redirectToRfp,
  assetid,
  agancy,
  handleNewPage,
  HandleProposalWriting,
  descriptionData,
  title = "",
  description = "",
  noticeId = "",
  department = "",
  subtier = "",
  office = {},
  contact = "",
  place = "",
  date = "",
  date1 = "",
  status = "Yes",
  orgId = "",
  viewType = "",
  percentage = "",
  resourceLinksPath='',
  id = "",
  count = "",
  cardChecked = false,
  onCardCheckboxChange,
  documentId,
  resourceLinksData,
  getAllDocuments,
  type,
  assetNoteComment,
  RFP,
  proposalWriting,
  hierarchy,
  opportunityUpdate,
  highlightClor,
  oppList,
  showPastOpportunities,
  viewType3,
  unArchiveOpen,
}) => {
  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const userRole=localStorage.getItem("Role")
    const formattedDate = `${months[monthIndex]
      } ${day}, ${year} at ${formatTime(hours, minutes)}`;

    return formattedDate;
  };
  const formatTime = (hours, minutes) => {
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm} GMT+5:30`;
  };
  const today = new Date();
  let statusColor;
  let tooltipContent;
  if (date1) {
    const responseDate = new Date(date1);
    console.log(today, responseDate, "responseDate");
    const timeRemaining = responseDate - today;
    const totalDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const percentageRemaining = (totalDays / 30) * 100;
    if (percentageRemaining <= 33.33) {
      statusColor = "red";
      tooltipContent = `Delayed (${totalDays} days remaining)`;
    } else if (percentageRemaining <= 66.33) {
      statusColor = "orange";
      tooltipContent = `Moderately delayed (${totalDays} days remaining)`;
    } else if(percentageRemaining > 66.33) {
      statusColor = "green";
      tooltipContent = `On time (${totalDays} days remaining)`;
    }else {
      statusColor = "grey";
      tooltipContent = `Response date not available`;
    }
  } else {
    statusColor = "grey";
    tooltipContent = `Response date not available`;
  }
  console.log(statusColor, "statusColor");

  let screenName = "Opportunity Finder";

  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const featureArray = features?.featuresAccess;
  const hierarchyuser=features?.hierarchy

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
      const matchingFeature = featureArray.filter(feature => feature.ScreenName ==screenName);
     
      if (matchingFeature) {
         accessFeatures = matchingFeature[0].features
        // setAccessTofeature(accessFeatures)
          // console.log("accessFeatures", accessFeatures);
      } else {
          console.log("No feature found with screen name:", screenName);
      }
  } else {
      console.log("No features found in the storage.");
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPermanentDeleteModalOpen, setIsPermanentDeleteModalOpen] =
    useState(false);
  const email = sessionStorage.getItem("currentUserEmail");

  
  const [selectedWorkflowList,setSelectedWorkflowList]=useState()
  const [Department, Subtier] = department.split(".");
  const departmentArray = department.split(".");
  const officename = departmentArray[departmentArray.length - 1];
  const [showModal, setShowModal] = useState(false);
  const [userInfo, setUserInfo] = useState("");
  const history = useHistory();
  const [descriptionContent, setDescriptionContent] = useState(null);
  const [workflowList,setWorkFlowList]=useState([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  // const history = useHistory();
  const handleclick = async (documentId) => {
    // alert(documentId)

    try {
      //    const data =  documentId;
      history.push(`/singleNoticePage/${documentId}`);
    } catch (error) {
      console.log(error);
    }
  };

  const getCuurentUser = async (Username) => {
    try {
      const response = await getUserInfo(Username);
      // console.log("userinfo", response);
      setUserInfo(response.data);
      return true;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const Username = sessionStorage.getItem("currentUserEmail");
    getCuurentUser(Username);
  }, [])

const handleCloseModal = () => {
    setShowModal(false);
  };
  const redirectToWorkflow = () => {
    setShowModal(false);
    history.push('/workflow'); 
  };



  console.log("Department:", Department);
  console.log("Subtier:", Subtier);

  const handleMenuClick = (event) => {
    let featurename = "Add Asset"
    const checkfeatureName = accessFeatures?.filter(k=>k.name == featurename)
    console.log(checkfeatureName[0].access,"checkfeatureName")
    if(!checkfeatureName[0].access){
      
      showToast('Error.addProject')
    }else{
      setAnchorEl(event.currentTarget);
    }
    
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    if (action === "Share") {
      setIsShareModalOpen(true);
    }
    if (action === "Delete") {
      setIsDeleteModalOpen(true);
    }
    if (action === "Download") {
      setIsDownloadModalOpen(true);
    }
    if (action === "Restore") {
      setIsRestoreModalOpen(true);
    }
    if (action === "PermanentDelete") {
      setIsPermanentDeleteModalOpen(true);
    }
    handleMenuClose();
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  const closeRestoreModal = () => {
    setIsRestoreModalOpen(false);
  };

  const closePermanentDeleteModal = () => {
    setIsPermanentDeleteModalOpen(false);
  };
  // const addOrgopportunity = async (id, type) => {
  //   try {
  //     setLoading(true);
    
  //     const data = { id, email, type, noticeId, subtier };
  //     const response = await assetService.addasset(data);
  //     console.log(response, "matchresponse");
  //     toast.success(response.message);
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setLoading(false);
  //     setShowModal(true);
  //   }
  // };

  const addOrgopportunity = async (id, type) => {
    // enableLoading()
    try {
      console.log(type,"++type")
      if(type == "Archive"){
        await addWorkflowAsset(type)
        return
      }
       getWorkflowList()
       setShowModal(true);
    } catch (e) {
      console.log(e);
    }
    // finally {
    //   // disableLoading()
    //         setShowModal(true);
    //       }
  };

  const getWorkflowList = async ()=>{
    try{
      setLoading(true)
      let data = {
        orgId:sessionStorage.getItem("organizationId")
      }
      const response = await workFlowList(data)
      console.log("callled",response)
      if(response.status == 200){
        console.log("workflow list",response)
        setWorkFlowList(response.workflowList)
        setLoading(false)
      }


    }catch(error){
      console.log(error,"error in geting workflow list ")
      setLoading(false)
    }

  }

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }
  const truncatedDescription = description
    ? stripHtmlTags(description).split(" ").slice(0, 20).join(" ")
    : "";
  const truncatedDescriptioncontent = descriptionContent
    ? stripHtmlTags(descriptionContent).split(" ").slice(0, 20).join(" ")
    : "";
  console.log(truncatedDescriptioncontent, "description---");
  const handleInfoIconClick = async (documentId) => {
    try {
      setLoading(true);
      const data = documentId;
      // alert(documentId);
      const response = await getDescription(data);
      setDescriptionContent(response?.data);
      console.log(response?.data, "Redirectresponse");
    } catch (error) {
      console.log("Error fetching document data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // handleInfoIconClick()
  }, [documentId]);

  const addWorkflowAsset = async (type)=>{
    try {
      console.log(type,"++typw")
      if(!selectedWorkflowList && type !== "Archive"){
        showToast('Error.selectWorkflow')
        return
      }

    // enableLoading()
      const data = { id, email, type, noticeId, subtier ,workflowId:selectedWorkflowList?.value };
      const response = await assetService.addasset(data);
      console.log(response, "matchresponse");
     if(response.status == 200){
        toast.success(response.message)
      }
      else {
        toast.error(response.message)
     
      }
      handleCloseModal()
    } catch (e) {
      console.log(e);
    } finally {
// disableLoading()
    }
  };


  const handleSelectWorkflowList =async (option )=>{
    console.log("workflow details",option)
    handleUseForWorkflow(option.value)
    setSelectedWorkflowList(option);

}


const handleUseForWorkflow = async(documentId)=>{
  // enableLoading()
  const email=sessionStorage.getItem("currentUserEmail")
  const orgid = sessionStorage.getItem("organizationId")
  const response = await callUseForWorkflow(documentId,email,orgid)
  if(response.status==200){
    console.log(response.message,"useForWorkflow");
  }else{
    console.log(response.message,"useForWorkflow")
  }
  // disableLoading()
}
console.log(subtier,statusColor,viewType,"safdufyusadyuasg")

// const handleclicks =  (documentId) => {
//   // alert("documentId")
//   try {
//     //    const data =  documentId;
//     history.push(`/singleNoticePage/${documentId}`);
//   } catch (error) {
//     console.log(error);
//   }
// };

const handleclicks = (documentId) => {
  console.log('Document ID:', documentId);
// alert(showPastOpportunities)
  try {
    history.push({
      pathname: "/singleNoticePage",
      state: { documentId, showPastOpportunities } // Pass the documentId as state
    });
  } catch (error) {
    console.log(error);
  }
};
  return (
    <>
      <Loader loading={loading} />
      

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            minWidth: windowWidth > 767 ? "400px" : '300px',
            minHeight: '40vh',
            maxWidth: '40vw',
            maxHeight: '40vh',
            padding: "15px",
           
          },
        }}
      >
        <div className="dialogHeader">
          <div className="dialogClose" style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
            <CloseIcon onClick={handleCloseModal} />
          </div>
        </div>
        <DialogContent >
          <p>Choose the workflow that best suits your needs! We offer a default option to get you started, or you can create a custom dynamic workflow. Selecting a workflow is mandatory to proceed.</p>
          <Button
            onClick={redirectToWorkflow}
            style={{ cursor: "pointer" }}
            variant="contained"
            color="primary"
          >Add Workflow</Button>

        <div style={{display:"flex",justifyContent:"space-between",textAlign:"center",marginTop:"10px"}}>
        <div
        style={{width:"75%"}} 
        >
          <Select
          // isMulti
          options={workflowList.map(workflow => ({
            value: workflow._id,
            label: workflow.workflowName
          }))}
          value={selectedWorkflowList || ""}
          onChange={(selectedOptions) => handleSelectWorkflowList(selectedOptions)}
          styles={customStyles1}
        />
          </div>

          <div style={{width:"23%"}} >
              <Button
           onClick={() => addWorkflowAsset( (type = "Project") )}
            style={{ cursor: "pointer",width:"100%",height:"38px" }}
            variant="contained"
            color="primary"
          >Add Project</Button>
          </div>
        </div>
        </DialogContent>
      </Dialog>
     <Card
  style={{
    width: "100%",
    marginBottom: "3%",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    border: "2px solid red",
  }}
>
  <div
    style={{
      height: "320px", // Adjust the height as needed
      overflowY: "auto",
    }}
  >
    <CardContent
      style={{
        border: "1px solid #ccc",
        borderLeft: `10px solid ${statusColor}`,
        position: "relative",
        padding: "5px",
        backgroundColor: status === "Yes" ? "white" : "lightgray",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "100%", padding: "7px" }}>
        <div style={{ borderBottom: "1px solid #ccc", display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "80%", display: "flex", marginBottom: "10px" }}>
            <Typography
              variant="body1"
              onClick={() => handleclicks(documentId)}
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                overflow: "hidden",
                color: "blue",
                cursor:"pointer"
                // border:"1px solid red",
                // width:"300px"
              }}
            >
              {title}{" "}
              {viewType === "filterScreen" && (
                <span style={{ color: "green" }}>Match {(percentage === '0') ? '100' : percentage}%</span>
              )}
            </Typography>
                      </div>
                      <div style={{width:"15%"}}>
                        
                        {subtier !== "Award Notice" &&
                statusColor !== "black" &&
                viewType !== "Oppoupload" &&
                viewType !== "completed_project_screen" &&
                viewType !== "Shared Project" && 
                viewType !== "Archived" && 
                viewType !== "cancelled_project" &&(
                  <IconButton
                  
                    onClick={handleMenuClick}
                  >
                    <PlaylistAddIcon />
                  </IconButton>
                )}         
                {viewType1 == "completed_project" && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={"Add Project Completion Metrics"}>
  
                    <IconButton
                      onClick={()=>{openMetrics(id,title)}}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                </>

            )}
            {viewType3 == "Archived" && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={"UnArchive"}>
  
                    <IconButton
                      onClick={()=>{unArchiveOpen(id)}}
                    >
                      <UnarchiveIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                </>

            )}
          
                      </div>
                      </div>
                      <div style={{ width: "100%", display: "flex", flexDirection:"column",borderBottom:"1px solid #ccc",marginBottom:"10px",paddingBottom:"10px"}}>
 <Typography style={{ marginTop:title ? "3px" : "30px", fontSize: "14px" }}>
              <b>Notice ID : {noticeId}</b>
            </Typography>
            <Typography style={{ marginTop: "3px", fontSize: "14px" }}>
                <b>Description :</b>
                {truncatedDescription ? (
                  <>
                    {truncatedDescription}
                    {stripHtmlTags(description).split(" ").length > 20 && "..."}
                  </>
                ) : (
                  <>
                    {descriptionContent == null ? (
                      <>
                        <IconButton
                          style={{ outline: "none" }}
                          title={"Click here to see description"}
                        >
                          {" "}
                          <InfoIcon onClick={()=>handleInfoIconClick(documentId)} />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        {truncatedDescriptioncontent}
                        {stripHtmlTags(descriptionContent).split(" ").length >
                          20 && "..."}
                      </>
                    )}
                  </>
                )}
              </Typography>
              <Typography>
                <b>Updated Date :</b> {formatDate(date)}
                {count > 1 && (
                  <span style={{ color: "red" }}> ({count - 1})</span>
                )}
              </Typography>
              <Typography>
                <b>Published Date :</b> {formatDate(date)}
              </Typography>
              <Typography style={{ color: statusColor }}>
                <b>Response Date :</b> {formatDate(date1)}
              </Typography>
                        </div>
                        <div style={{ width: "100%", display: "flex",justifyContent:"space-between"}}>
{/* third row */}
<div
                style={{
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <Typography>
                  <b>Department</b>
                </Typography>
                <Typography style={{color:"#1976D2"}}>{Department}</Typography>
              </div>
              <div
                style={{
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <Typography>
                  <b>Subtier</b>
                </Typography>
                <Typography style={{color:"#1976D2"}}>{Subtier}</Typography>
              </div>
              <div
                style={{
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <Typography>
                  <b>Office</b>
                </Typography>
                <Typography style={{color:"#1976D2"}}>{officename}</Typography>
              </div>
              <div
                style={{
                  width: "23%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                }}
              >
                <Typography>
                  <b>Notice Type</b>
                </Typography>
                <Typography style={{color:"#1976D2"}}>{subtier}</Typography>
              </div>
            {/* </div> */}
        </div>
      </div>

                    
         

          {viewType == "Oppoupload" && (
            <>



              {/* <div
                style={{
                  position: "absolute",
                  top: "1px",
                  right: "70px",
                  cursor: "pointer",
                }}
              >
                <Tooltip >
                  <IconButton
                    onClick={() => {redirectToRfp( title,
                      noticeId,
                      descriptionData,
                      id,
                      type,
                      date1,
                      agancy,
                      assetid)}}
                  >
                   <p>click</p>
                  </IconButton>
                </Tooltip>
              </div> */}

                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "70px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={"Clause Extraction"}>
  
                    <IconButton
                      onClick={()=>{clauseExtraction(resourceLinksData,assetid,title)}}
                    >
                      <ArchiveIcon />
                    </IconButton>
                  </Tooltip>
                </div>

                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "40px",
                    cursor: "pointer",
                  }}
                >
  
                  <Tooltip title={RFP}>
  
                    <IconButton
                      style={
                        assetNoteComment == "true"
                          ? { color: "green" }
                          : { color: "grey" }
                      }
                      onClick={() => {redirectToRfp( title,
                        noticeId,
                        descriptionData,
                        id,
                        type,
                        date1,
                        agancy,
                        assetid,
                        resourceLinksPath,
                        viewType,
                        oppList
                      )}}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                </div>
  
                <div
                  style={{
                    position: "absolute",
                    top: "1px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
                  <Tooltip title={proposalWriting}>
                    <IconButton
                      onClick={() => {
                        redirectToPropsal(
                          title,
                          noticeId,
                          descriptionData,
                          id,
                          type,
                          date1,
                          agancy,
                          assetid,
                          oppList
                        );
                      }}
                    >
                      <AccountTreeIcon />
                    </IconButton>
                  </Tooltip>
                </div>
            </>
          )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {type === "MyDocuments" && (
          <>
            <MenuItem onClick={() => addOrgopportunity(id, (type = "Project"))}>Add To My Project</MenuItem>
            <MenuItem onClick={() => addOrgopportunity(id, (type = "Archive"))}>Add To Archive</MenuItem>
          </>
        )}
        {type === "SharedWithMe" && (
          <>
            <MenuItem onClick={() => handleMenuItemClick("Delete")}>Delete</MenuItem>
          </>
        )}
        {type === "DeletedDocuments" && (
          <>
            <MenuItem onClick={() => handleMenuItemClick("Restore")}>Restore</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("PermanentDelete")}>Delete Permanently</MenuItem>
          </>
        )}
      </Menu>
    </CardContent>
  </div>
</Card>


      {/* <ShareOptionModal
        show={isShareModalOpen}
        onHide={closeShareModal}
        id={documentId}
      /> */}
      <DeleteOptionModal
        show={isDeleteModalOpen}
        onHide={closeDeleteModal}
        id={documentId}
        getAllDocuments={getAllDocuments}
      />
      {/* <DownloadOptionModal
        show={isDownloadModalOpen}
        onHide={closeDownloadModal}
        id={documentId}
      /> */}
      {/* <RestoreOptionModal
        show={isRestoreModalOpen}
        onHide={closeRestoreModal}
        id={documentId}
        getAllDocuments={getAllDocuments}
      />
      <PermanentDeleteOptionModal
        show={isPermanentDeleteModalOpen}
        onHide={closePermanentDeleteModal}
        id={documentId}
        getAllDocuments={getAllDocuments}
      /> */}
    </>
  );
};

export const WorkFlowGridCard = ({
  title = "",
  backgroundColorCard,
  orgIdFound,
  workFlowId,
  closeModal,
  callUseForWorkflow,
  description,
  deleteWorkflow,
  openeditWorkflowModal,
  createDuplicate,
  createdby = "",
  createddate = "",
  appname = "",
  noticeId = "",
  department = "",
  subtier = "",
  office = {},
  date = "",
  date1 = "",
  status = "Yes",
  cardChecked = false,
  onCardCheckboxChange,
  documentId = "",
  getAllDocuments,
  type,
  copy="",
  isActive="",
  isAdmin="",
  windowWidth,
  isDefault

}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
  const [defaultWorkflowActive, setDefaultWorkflowActive] = useState(false)
  const [isPermanentDeleteModalOpen, setIsPermanentDeleteModalOpen] =
    useState(false);
  const [originalDate, setOriginalDate] = useState(createddate);
  const [formattedDate, setFormattedDate] = useState("");
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [Department, Subtier] = department.split(".");
  let screenName = "Opportunity Finder";
  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const userRole=localStorage.getItem("Role")
  const featureArray = features?.featuresAccess;
  console.log(isActive,isAdmin,typeof(isActive),"checkisasctive")
  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
      const matchingFeature = featureArray.filter(feature => feature.ScreenName ==screenName);
     
      if (matchingFeature) {
         accessFeatures = matchingFeature[0].features
        // setAccessTofeature(accessFeatures)
          // console.log("accessFeatures", accessFeatures);
      } else {
          console.log("No feature found with screen name:", screenName);
      }
  } else {
      console.log("No features found in the storage.");
  }

  const handleMenuClick = (event) => {
    let featurename = "Add Asset"
    const checkfeatureName = accessFeatures?.filter(k=>k.name == featurename)
    console.log(checkfeatureName[0].access,"checkfeatureName")
    if(!checkfeatureName[0].access){
      
      showToast('Error.addProject')
    }else{
      setAnchorEl(event.currentTarget);
    }
    
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    if (action === "Share") {
      setIsShareModalOpen(true);
    }
    if (action === "Delete") {
      setIsDeleteModalOpen(true);
    }
    if (action === "Download") {
      setIsDownloadModalOpen(true);
    }
    if (action === "Restore") {
      setIsRestoreModalOpen(true);
    }
    if (action === "PermanentDelete") {
      setIsPermanentDeleteModalOpen(true);
    }
    handleMenuClose();
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  const closeRestoreModal = () => {
    setIsRestoreModalOpen(false);
  };

  const closePermanentDeleteModal = () => {
    setIsPermanentDeleteModalOpen(false);
  };

  useEffect(()=> {
    setOriginalDate(createddate)
  }, [createddate])

  useEffect(() => {
    closeModal();
    const date = new Date(originalDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    const orgId = sessionStorage.getItem("orgToken")
    checkDefaultWorkflowActive(orgId)

    setFormattedDate(formattedDate);
  }, [originalDate]);



  const handleCardClick = (documentId, value) => {
    setSelectedCardId(documentId);
    console.log("documentIddocumentIddocumentId", documentId);
  };

  useEffect(() => {
    setSelectedCardId(backgroundColorCard);
  }, [backgroundColorCard]);


  const handleActivateWorkflow = async () => {
    const orgId = sessionStorage.getItem("orgToken")
    try {
      let data = {
        orgId: orgId
      };
      const response = await addOrgInDefaultWorkflow(data);
      if (response.status == 200) {
        setDefaultWorkflowActive(true)
        showToast('Organization.workflowActive')
      }else {
        showToast('Organization.workflowFailed')
      }
    } catch (error) {
     
      showToast('Error.errorOccured')
    }
  }

  const checkDefaultWorkflowActive = async (id) => {
    
    try {
      let data = {
        orgId: id
      };
      const response = await checkOrgInDefaultWorkflow(data);
      if (response.status == 200) {
        setDefaultWorkflowActive(response.exists)
        
      }else {
        setDefaultWorkflowActive(false)
      }
    } catch (error) {
      showToast('Error.errorOccured')
    }
  }

  return (
    <>
 {/* <Card
  style={{
    height: "100%",
    minWidth: "300px",
    marginRight: "3%",
    marginBottom: "3%",
    borderRadius: "10px",
    border: "1px solid gray",
    display: "flex",
    flexDirection: "column",
  }}
> */}
<Card
  style={{
    backgroundColor:"#fff",
    height: "100%",
    width: "100%",
    marginRight: "3%",
    marginBottom: "3%",
    borderRadius: "10px",
    border: "1px solid gray",
    display: "flex",
    flexDirection: "column",
    flex: windowWidth < 400 ? '1 1 auto' : (windowWidth < 770 ? '1 1 100%' : '0 0 auto')  }}
>
  <CardContent
    style={{
      position: "relative",
      padding: "5px",
      flex: 1,
      backgroundColor: defaultWorkflowActive ? "#d5eeff" : "",
      borderRadius: "10px",
    }}
  >
    <div style={{ padding: "7px", height: "100%", }}>
      <div style={{display:'flex', justifyContent:'space-between'}}>
      <Typography
        variant="body1"
        style={{
          fontWeight: "bold",
          fontSize: "14px",
          overflow: "hidden",
          color: "black",
          marginBottom: "10px",
          whiteSpace: "nowrap", // Prevents the text from wrapping to the next line
          textOverflow: "ellipsis", // Adds "..." if the text overflows
          width: "100%", 
        }}
      >
        <button
          style={{
            fontSize: "14px",
            color: "black",
            fontWeight: 'bold',
            padding: "0px",
            border: "none",
            cursor: "pointer",
            backgroundColor: "transparent",
            outline: "none",
          }}
          onClick={() => {
            workFlowId(documentId, false);
          }}
        >
          {title}
        </button>
      </Typography>
      {!defaultWorkflowActive &&
      <IconButton onClick={()=> handleActivateWorkflow()}>
        <AddIcon/>
      </IconButton>
}
      </div>
      <Typography style={{ marginBottom: "10px", color: '#1976d2' }}>
        <b>Created by: </b>{createdby}
      </Typography>
      <Typography style={{ marginBottom: "10px", color: '#1976d2' }}>
        <b>Created Date: </b>{formattedDate}
      </Typography>
      <hr style={{ margin: "10px 0"}} />
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center", 
        position: "absolute", 
        bottom: "10",        
        width: "90%",
           }}>
        {orgIdFound ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {userRole=="Admin" && <>
              {title=="Review and Approval Workflow" &&
               <Tooltip title="Create copy this default workflow">
              <IconButton style={{ color: "#1976D2", padding: 0 }}>
                <FileCopyIcon onClick={() => {
                  createDuplicate(documentId);
                }} />
              </IconButton>
              </Tooltip>}
            </>}
        
          
            {!isDefault && 
            <IconButton
            onClick={() => {
              openeditWorkflowModal(documentId, title, createdby, description);
            }}
            style={{ color: "#1976D2", padding: 0 }}
          >
            <BorderColorIcon />
          </IconButton>}
           {!isDefault && 
            <IconButton
              onClick={() => {
                deleteWorkflow(documentId, title,isAdmin);
              }}
              style={{ color: "red", padding: 0 }}
            >
              <DeleteIcon />
            </IconButton>
           }
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <IconButton
              disabled
              style={{ color: "rgba(0, 0, 0, 0.26)", padding: 0 }}
            >
              <DescriptionIcon />
            </IconButton>
            <IconButton
              disabled
              style={{ color: "rgba(0, 0, 0, 0.26)", padding: 0 }}
            >
              <BorderColorIcon />
            </IconButton>
            <IconButton
              disabled
              style={{ color: "rgba(0, 0, 0, 0.26)", padding: 0 }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  </CardContent>
</Card>


      <ShareOptionModal
        show={isShareModalOpen}
        onHide={closeShareModal}
        id={documentId}
      />
      <DeleteOptionModal
        show={isDeleteModalOpen}
        onHide={closeDeleteModal}
        id={documentId}
        getAllDocuments={getAllDocuments}
      />
      {/* <DownloadOptionModal
        show={isDownloadModalOpen}
        onHide={closeDownloadModal}
        id={documentId}
      /> */}
      <RestoreOptionModal
        show={isRestoreModalOpen}
        onHide={closeRestoreModal}
        id={documentId}
        getAllDocuments={getAllDocuments}
      />
      <PermanentDeleteOptionModal
        show={isPermanentDeleteModalOpen}
        onHide={closePermanentDeleteModal}
        id={documentId}
        getAllDocuments={getAllDocuments}
      />
    </>
  );
};


export const StatusCard = ({ statusName, statusDescription }) => {
  return (
    <div
      style={{
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        padding: "20px",
        backgroundColor: "white",
        borderRadius: "8px",
        width: "220px",
        margin: "10px",
      }}
    >
      <p style={{ color: "#1976d2", fontSize: "14px", marginLeft: "" }}>
        Status:{statusName}
      </p>
      <p style={{ color: "#1976d2", fontSize: "14px" }}>
        Description:{statusDescription}
      </p>
    </div>
  );
};

export const ReusableCard = (props) => {
  const {
    viewType,
    Id,
    deleteNote,
    text,
    updateNote,
    deleteComment,
    updateComment,
    createdby,
    onClickCard,
    handleRevertNotification,
    trackRecord,
    sectionCreatedBy,
    userId,
    date,
    addedContent,
    removedContent,
    s3Version,
    mouseEnter,
    mouseLeave,
    documentBody,
    replyToComment,
    replyView,
    replyArray,
    deleteCommentReply,
    deleteNotesReply,
    updateCommentReply,
    updateNotesReply
  } = props;



  const [expanded, setExpanded] = useState(false);
  const [timeDifference, setTimeDifference] = useState();
  const [revertRequest,setRevertRequest] = useState(false)



  useEffect(() => {
    const cuurentTime = Date.now();
    const postTime = new Date(date).getTime();
    // console.log("cuurentTime",cuurentTime)
    // console.log("Date",date)
    const postedTime = formatTimeAgo(postTime, cuurentTime);
    setTimeDifference(postedTime);
    // console.log("postedTime",postedTime)
  }, [date]);


  useEffect(()=>{

    if (trackRecord?.revertedBy?.some(item => item.revertStatus === false)) {
      setRevertRequest(true);
    }else{
      setRevertRequest(false)
    }

  },[trackRecord])



  const handleCardClick = () => {
    onClickCard(text, viewType, Id, createdby);
  };

  const handleRevertClick = () => {
    onClickCard(s3Version, documentBody,trackRecord)
  }


  const handleDeleteButtonClick = (event) => {
    event.stopPropagation();
    if (viewType === "noteCard") {
      deleteNote(Id);
    } else if (viewType === "commentCard") {
      deleteComment(Id);
    }
  };

  const handleUpdateButtonClick = (event) => {
    event.stopPropagation();
    if (viewType === "noteCard") {
      updateNote(Id, text);
    } else if (viewType === "commentCard") {
      updateComment(Id, text);
    }
  };

  function formatTimeAgo(postTime, currentTime) {
    const timeDifference = currentTime - postTime;

    const seconds = Math.floor(timeDifference / 1000);

    if (seconds < 60) {
      return seconds + " sec ago";
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return minutes + " min ago";
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return hours + " hr ago";
    } else {
      const date = new Date(postTime);
      return date.toLocaleDateString();
    }
  }

  // replyToComment
  const handleReplyButtonClick = (event) => {
    event.stopPropagation();
    if (viewType === "noteCard") {
      replyToComment(Id);
    } else if (viewType === "commentCard") {
      replyToComment(Id);
    }
  };
  // console.log(replyView, "showReply..")
  // console.log(replyArray, "replyArray..")
  const calculateTimeDifference = (createdDate) => {
    const now = new Date();
    const created = new Date(createdDate);
    const diff = Math.abs(now - created);
    const seconds = Math.floor(diff / 1000);
  
    if (seconds < 60) {
      return `${seconds} sec ago`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} min ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hr ago`;
    } else {
      return created.toLocaleDateString();
    }
  };
  const handleDeleteReply = (Id) => {
    // event.stopPropagation();
    if (viewType === "noteCard") {
      deleteNotesReply(Id)
    } else if (viewType === "commentCard") {
      deleteCommentReply(Id)
    }
  };

  const handleUpdateReply = (Id,replyMsg, isUpdateTrue) => {
    console.log("id+++",Id,replyMsg)
    // event.stopPropagation();
    if (viewType === "noteCard") {
      updateCommentReply(Id,replyMsg,isUpdateTrue)
    } else if (viewType === "commentCard") {
      updateCommentReply(Id,replyMsg,isUpdateTrue)
    }
  };


  return (
    <>
      <div
      
        // onClick={viewType === "trackedChangesCard" ? "" : handleCardClick}
        style={{
          display: "flex",
          cursor: "pointer",
          borderRadius: "8px",
          border: "1px solid white",
          marginBottom: "14px",
          alignItems: "stretch",
        }}
      >
        <div
          style={{
            width: "8px",
            backgroundColor: "#1976d2",
            borderRadius: "8px 0 0 8px",
          }}
        ></div>
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "0 8px 8px 0",
            padding: "10px 15px",
            // marginBottom: "10px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            position: "relative",
            flex: 1,
          }}
        >
          <>
            <div className="row rowtest" style={{display:'flex', flexWrap:'nowrap'}} >
              <div className= {viewType=== "trackedChangesCard" ? "col-md-12" : "col-md-11"} style={{ padding: "5px" }} onClick={viewType === "trackedChangesCard" ? "" : handleCardClick}>
                <div
                  style={{
                    display: "flex",
                    margin: "0px",
                    alignItems: "center",
                  }}
                >
                  <p style={{ fontWeight: "bolder", margin: "0px" }}>
                    {createdby}
                  </p>
                  <div
                    style={{
                      margin: "0 5px",
                      borderLeft: "1px solid black",
                      height: "12px",
                    }}
                  ></div>
                  <p style={{ fontWeight: "bolder" }}>{timeDifference}</p>
                </div>

                <p
                  style={{
                    fontSize: "14px",
                    overflow: "hidden",
                    marginTop: "5px",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {text}
                </p>
                {viewType==="trackedChangesCard" &&

<div style={{ width: '100%' }}
//  onMouseEnter={() => mouseEnter(addedContent, removedContent)} onMouseLeave={() => mouseLeave()}
 >
{addedContent.length > 0 &&
  // <>
  //   <div><strong>Added Content:</strong></div>
  //   <ul style={{marginLeft:'15px'}}>
  //       {addedContent.map((item, idx) => {
  //           const displayText = item.length > 100 ? `${item.substring(0, 100)}...` : item;
  //           return (
  //               <li key={idx} style={{fontSize:'12px'}}>{displayText}</li>
  //           );
  //       })}
  //   </ul>
  //   </>
  <>
    <div><strong>Added Content:</strong></div>
    <ul style={{ marginLeft: '15px' }}>
      {addedContent.map((item, idx) => {
        const containsImage = /<img\s+src=['"]([^'"]+)['"]/i.test(item); // Regex to check if item contains an img tag
        if (containsImage) {
          const imageSrc = item.match(/<img\s+src=['"]([^'"]+)['"]/i)[1]; // Extracting the image source
          return (
            <li key={idx} style={{ fontSize: '12px' }}>
              <img src={imageSrc} alt={`Content Image ${idx}`} style={{ maxWidth: '100px', height: 'auto' }} />
            </li>
          );
        } else {
          const displayText = item.length > 100 ? `${item.substring(0, 100)}...` : item;
          return (
            <li key={idx} style={{ fontSize: '12px' }}>{displayText}</li>
          );
        }
      })}
    </ul>
  </>
}
{removedContent.length > 0 &&
  <>
    <div><strong>Removed Content:</strong></div>
    <ul style={{ marginLeft: '15px' }}>
      {removedContent.map((item, idx) => {
        const containsImage = /<img\s+src=['"]([^'"]+)['"]/i.test(item); // Check for img tag
        if (containsImage) {
          const imageSrc = item.match(/<img\s+src=['"]([^'"]+)['"]/i)[1]; // Extract image source
          return (
            <li key={idx} style={{ fontSize: '12px' }}>
              <img src={imageSrc} alt={`Removed Content Image ${idx}`} style={{ maxWidth: '100px', height: 'auto' }} />
            </li>
          );
        } else {
          const displayText = item.length > 100 ? `${item.substring(0, 100)}...` : item;
          return (
            <li key={idx} style={{ fontSize: '12px' }}>{displayText}</li>
          );
        }
      })}
    </ul>
  </>
}
<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
  { revertRequest && userId === sectionCreatedBy &&
  <button onClick={()=>handleRevertNotification(trackRecord)}>
  <i
      className="fa fa-asterisk blurb-ripple-out point"
      style={{ color: "orange" }}
      aria-hidden="true"
    ></i>
  </button>
}
  <Button color="primary" variant="contained" onClick={handleRevertClick}>
    Revert
  </Button>

</div>
</div>
            }
              </div>

              { viewType === "trackedChangesCard" ||

              <div
                className="col-md-1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  padding: "5px",
                  alignItems:'flex-end'
                }}
              >
                {viewType === "noteCard" && (
                  <>
                    <button
                      style={{
                        color: "red",
                        margin: "0px",
                        backgroundColor: "transparent",
                        padding: "4px",
                      }}
                      onClick={handleDeleteButtonClick}
                    >
                      <DeleteIcon />
                    </button>

                    <button
                      style={{
                        marginLeft: "1px",
                        color: "#1976d2",
                        margin: "0px",
                        padding: "4px",
                        backgroundColor: "transparent",
                      }}
                      onClick={handleUpdateButtonClick}
                    >
                      <BorderColorIcon />
                    </button>
                    <button
                      style={{
                        marginLeft: "1px",
                        color: "#1976d2",
                        margin: "0px",
                        padding: "4px",
                        backgroundColor: "transparent",
                      }}
                      onClick={handleReplyButtonClick}
                    >
                      <ReplyIcon />
                    </button>
                  </>
                )}

                {viewType === "commentCard" && (
                  <>
                    <button
                      style={{
                        color: "red",
                        margin: "0px",
                        backgroundColor: "transparent",
                        padding: "4px",
                      }}
                      onClick={handleDeleteButtonClick}
                    >
                      <DeleteIcon />
                    </button>

                    <button
                      style={{
                        marginLeft: "1px",
                        color: "#1976d2",
                        margin: "0px",
                        padding: "4px",
                        backgroundColor: "transparent",
                      }}
                      onClick={handleUpdateButtonClick}
                    >
                      <BorderColorIcon />
                    </button>
                    <button
                      style={{
                        marginLeft: "1px",
                        color: "#1976d2",
                        margin: "0px",
                        padding: "4px",
                        backgroundColor: "transparent",
                      }}
                      onClick={handleReplyButtonClick}
                    >
                      <ReplyIcon />
                    </button>
                  </>
                )}
              </div>

}

           
            </div>

            {replyView == "showReply" &&
              <>
                <div style={{ border: '1px solid #1976D2', borderRadius: '5px' }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <div className="comment_Flex_Button">
                        <div className="note_count_flex">
                          <Typography style={{ fontWeight: "600", fontSize: "14px", color: "#1976D2", marginRight: "4px" }}>Replies</Typography>
                          <div>
                            <p style={{ fontWeight: "600", fontSize: "14px", color: "#1976D2" }}>({replyArray?.length})</p>
                          </div>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography component="div">
                        {replyArray?.map((reply, index) => (
                          <div key={index} className="row" style={{ width: "100%", borderRadius: "8px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", marginBottom: "10px",marginLeft: "5px" }}>
                            <div className={viewType === "trackedChangesCard" ? "col-md-12" : "col-md-10"} >
                              <div style={{ display: "flex", margin: "0px", alignItems: "center"}}>
                                <samll style={{ fontWeight: "bolder", margin: "0px"  }}>{reply.createdby}</samll>
                                <div style={{ margin: "0 5px", borderLeft: "1px solid black", height: "12px"}}></div>
                                <small style={{ fontWeight: "bolder" }}>{calculateTimeDifference(reply.createdDate)}</small>
                              </div>
                              <small style={{ fontSize: "14px", overflow: "hidden", marginTop: "5px", textOverflow: "ellipsis", display: "-webkit-box", WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}>
                                {reply.replyMsg}
                              </small>
                            </div>

                            {viewType !== "trackedChangesCard" && (
                              <div className="col-md-2" style={{ display: "flex", flexDirection: "column", justifyContent: "start", padding: "5px" }}>
                                {viewType === "noteCard" && (
                                  <>
                                    <button
                                      style={{ color: "red", margin: "0px", backgroundColor: "transparent", padding: "4px" }}
                                      onClick={() => handleDeleteReply(reply._id)}
                                    >
                                      <DeleteIcon />
                                    </button>
                                    <button
                                      style={{ marginLeft: "1px", color: "#1976d2", margin: "0px", padding: "4px", backgroundColor: "transparent" }}
                                      onClick={() => handleUpdateReply(reply._id,reply.replyMsg,true)}
                                    >
                                      <BorderColorIcon />
                                    </button>
                                  </>
                                )}
                                {viewType === "commentCard" && (
                                  <>
                                    <button
                                      style={{ color: "red", margin: "0px", backgroundColor: "transparent", padding: "4px",fontsize: "5px" }}
                                      onClick={() => handleDeleteReply(reply._id)}
                                    >
                                      <DeleteIcon style={{fontsize: "10px"}} />
                                    </button>
                                    <button
                                      style={{ marginLeft: "1px", color: "#1976d2", margin: "0px", padding: "4px", backgroundColor: "transparent" }}
                                      onClick={() => handleUpdateReply(reply._id,reply.replyMsg,true)}
                                    >
                                      <BorderColorIcon  />
                                    </button>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>



              </>
            }

          </>
        </div>
      </div>
    </>
  );
};


