import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/assetsSection";
} else {
  apiEndpoint = herokuUrl + "/assetsSection";
}


  
export async function getAssetsection(opportunityId,assetType,assetId) {
    let data = await httpService.post(`${apiEndpoint}/getAssetsection`,{"opportunityId":opportunityId,assetType,assetId});
    console.log(data);
    return data;
  }
  
  export async function getSubsections(sectionId) {
    let data = await httpService.post(`${apiEndpoint}/getSubsections`, { sectionId });
    console.log(data);
    return data;
}


export async function addAssetsection(assetId, description, title, assetType,sectionType, orgId,userId,userName) {
  let data = await httpService.post(`${apiEndpoint}/addassetSection`,
    {
      "assetId": assetId,
      "description": description,
      "title": title,
      assetType,
      sectionType,
      orgId,
      userId,
      userName
    });
    console.log(data);
    return data;
  }
  export async function addAssetsubsection(assetId,description,title,assetType,orgId,sectionId) {
    let data = await httpService.post(`${apiEndpoint}/addAssetSubSection`,
    {
        "assetId":assetId,
        "description":description,
        "title":title,
        assetType,
        orgId,
        sectionId
    });
    console.log(data);
    return data;
  }

export async function updateAssetsection(id, title) {
  let data = await httpService.post(`${apiEndpoint}/updateassetSection`, {
    id: id,
    title: title
  });
  return data;
}

export async function addElectronicSignatureSection(data){
  const response=await httpService.post(`${apiEndpoint}/addElectronicSignatureSection`,{data});
  return response;
}

export async function getorderedsection(data) {
  console.log(data, "data")
  const response = await httpService.post(`${apiEndpoint}/getorderedsection`, { data });
  return response;
}

 export async function decisionByReceiver(assetType, assetsectionId, assetId, taskassigid, orgId, cuurentUser, assignedBy, status,link,assignedto,rawUpdatedContent,taskAsignmentcomment) {

    // alert(rawUpdatedContent)
 
 
    console.log(assetType, assetsectionId, assetId, taskassigid, orgId, cuurentUser, assignedBy, status,link,assignedto,rawUpdatedContent,"comment:  ",taskAsignmentcomment, "chetanananan")
    
 
    if (status == "accepted") {
 
      let data = await httpService.post(`${apiEndpoint}/decisionByReceiver`,
 
        {
 
          assetId: assetId,
 
          workflowName: "Task Assignment",
 
          fromStageName: "Open",
 
          toStageName: "Open",
 
          FromStatusName: "Accept",
 
          ToStatusName: "Accepted",
 
          orgId: orgId,
 
          assetId: assetId,
 
          assetSectionId: assetsectionId,
 
          assetType: assetType,
 
          taskassignmentid: taskassigid,
 
          assignedTo: cuurentUser,
 
          assignedBy: assignedBy,
 
          status : "accepted"
 
   
   
 
        });
 
      console.log(data);
 
      return data;
 
    }
 
    else if (status == "submit") {
 
      let data = await httpService.post(`${apiEndpoint}/decisionByReceiver`, {
 
        assetId: assetId,
 
        workflowName: `Task Assignment`,
 
        fromStageName: `Open`,
 
        toStageName: `Open`,
 
        FromStatusName: `Accepted`,
 
        ToStatusName: `Task Submitted`,
 
        orgId: orgId,
 
        assetSectionId: assetsectionId,
 
        assetType: assetType,
 
        taskassignmentid: taskassigid,
 
        assignedTo: cuurentUser,
 
        assignedBy: assignedBy,
 
        link:link,
 
        status:status
 
      })
 
   
   
 
      console.log(data,"dataservice")
 
      return data;
 
    }
 
    else if (status == "approved") {
 
      let data = await httpService.post(`${apiEndpoint}/decisionByReceiver`, {
 
        assetId: assetId,
 
        workflowName: `Task Assignment`,
 
        fromStageName: `Open`,
 
        toStageName: `Close`,
 
        FromStatusName: `Approved Review`,
 
        ToStatusName: `Complete`,
 
        orgId: orgId,
 
        assetSectionId: assetsectionId,
 
        assetType: assetType,
 
        taskassignmentid: taskassigid,
 
        assignedTo: assignedto,
 
        assignedBy: assignedBy,
 
        link:link,
 
        status:status,
 
        htmlContent:rawUpdatedContent,
        message:taskAsignmentcomment
 
      })
 
   
   
 
      console.log(data,"dataservice")
 
      return data;
 
    }
 
    else if (status == "acceptboth") {
 
      let data = await httpService.post(`${apiEndpoint}/decisionByReceiver`, {
 
        assetId: assetId,
 
        workflowName: `Task Assignment`,
 
        fromStageName: `Open`,
 
        toStageName: `Close`,
 
        FromStatusName: `Approved Review`,
 
        ToStatusName: `Complete`,
 
        orgId: orgId,
 
        assetSectionId: assetsectionId,
 
        assetType: assetType,
 
        taskassignmentid: taskassigid,
 
        assignedTo: assignedto,
 
        assignedBy: assignedBy,
 
        link:link,
 
        status:status,
 
        htmlContent:rawUpdatedContent,
        message:taskAsignmentcomment
      })
 
   
   
 
      console.log(data,"dataservice")
 
      return data;
 
    }
 
   
   
 
    else if (status == "rejected") {
//  alert("triggered")
 console.log(rawUpdatedContent,"rawUpdatedContentrawUpdatedContent")
      let data = await httpService.post(`${apiEndpoint}/decisionByReceiver`, {
 
        assetId: assetId,
 
        workflowName: `Task Assignment`,
 
        fromStageName: `Open`,
 
        toStageName: `Close`,
 
        FromStatusName: `Reject Review`,
 
        ToStatusName: `Complete`,
 
        orgId: orgId,
 
        assetSectionId: assetsectionId,
 
        assetType: assetType,
 
        taskassignmentid: taskassigid,
 
        assignedTo: assignedto,
 
        assignedBy: assignedBy,
 
        link:link,
 
        status:status,
 
        htmlContent:rawUpdatedContent,
        message:taskAsignmentcomment
 
      })
 
   
   
 
      console.log(data,"dataservice")
 
      return data;
 
    }else if (status == "reject") {
      console.log(orgId,assetId,assetsectionId,assetType,taskassigid,cuurentUser,assignedBy,"sericereject")
      let data = await httpService.post(`${apiEndpoint}/decisionByReceiver`,
 
        {
 
          assetId: assetId,
 
          workflowName: "Task Assignment",
 
          fromStageName: "Open",
 
          toStageName: "Open",
 
          FromStatusName: "Reject",
 
          ToStatusName: "Open",
 
          orgId: orgId,
 
          assetId: assetId,
 
          assetSectionId: assetsectionId,
 
          assetType: assetType,
 
          taskassignmentid: taskassigid,
 
          assignedTo: cuurentUser,
 
          assignedBy: assignedBy,
 
          status : "reject"
 
   
   
 
        });
 
      console.log(data);
 
      return data;
 
    }
 
  }


  export async function decisionByReceiverforSubsection(data,status,htmlContent,message){
    const totaldata = {data,status,htmlContent, message};
    console.log(totaldata,"totaldatatotaldata")
    // alert("totaldata")
    if (status == "approvedSubsection" || status == "acceptbothSubsection" || status == "rejectedSubsection") {
 
      let datas = await httpService.post(`${apiEndpoint}/decisionByReceiverforSubsection`,
 
        {
 
          totaldata
 
        });
 
      console.log(datas,"datasdatas");
 
      return datas;
 
    }

  }



  export async function getAllSectionVersions(data){
    const response = await httpService.post(`${apiEndpoint}/getAllSectionVersions`, { data });
    return response;
  }