import React from "react";
import Box from '@mui/material/Box';
import { DataGrid } from "@mui/x-data-grid";

const DataGridMui = ({ height, getRowId, rows, columns, checkboxSelection,width, }) => {

    return (
        <Box sx={{
            // height: height || "47vh", 
            width: width || "80vw", 
            overflowY: "auto",
            overflowX: "auto", // Prevent horizontal overflow
            // border: "10px solid red",
            // '@media (max-width: 600px)': {
            //     border: "5px solid red",
            // }
            marginLeft: "5px",
            
        }}>
            <DataGrid
                sx={{
                    borderColor: "secondary",
                    "& .MuiDataGrid-cell:hover": {
                        color: "primary.main",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#1976D2",
                        fontSize: "12px",
                        color: "white",
                        padding: "10px",
                    },
                    "& .MuiDataGrid-cell": {
                        fontSize: "10px",
                        padding: "8px",
                        borderRight: "1px solid #e0e0e0",
                        // '@media (max-width: 600px)': {
                        //     fontSize: "8px", // Smaller font size for mobile
                        //     padding: "6px",  // Smaller padding for mobile
                        // },
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            backgroundColor: '#FF0000',
                        },
                    },
                }}
                getRowId={getRowId}
                rows={rows}
                columns={columns.map(column => ({
                    ...column,
                    flex: 1,  // Columns will flex to fill available space
                    minWidth: column.field === "selectRole" ? 460 : 100,// Minimum width to prevent columns from shrinking too much
                }))}
                disableRowSelectionOnClick
                checkboxSelection={checkboxSelection}
                rowHeight={30}
                hideFooter
                disableMultipleColumnsSorting
                // sortModel={[
                //     { field: "action1", sort: "asc" },
                //     { field: "action", sort: "asc" },
                // ]}
            />
        </Box>
    );
}

export default DataGridMui;
