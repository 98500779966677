import React, { useState, useRef, useEffect, useMemo } from "react";
import JoditEditor, { Jodit } from "jodit-react";
import './joditStyle.css';
import mammoth from "mammoth";
import * as XLSX from "xlsx";
// import imgage from '../../static/Group 745.png';
import { toast } from "react-toastify";
// const pdfjs = require('pdfjs-dist/legacy/build/pdf');

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function JoditEditorComp({ editorHeight, editorContent, editor, className, onChange, isEdit = false, isMain = true, setEditorContentUpload}) {
    const [content, setContent] = useState(editorContent);
    const [selectedFile, setSelectedFile] = useState(null);
    console.log(editorHeight,"editorHeight")
    const config = { 
        readonly: !isEdit ? true : false,
        branding: false,
        statusbar: false, // Set to false to hide the status bar
        height: editorHeight,
        toolbar: isEdit ? true : false,
        askBeforePasteHTML: false,
        toolbarButtonSize: "small",
        buttons: [
            
            'undo', 'redo', 'bold', 'italic', 'underline', 'strikethrough',
            '|',
            'superscript', 'subscript',
            '|',
            'ul', 'ol',
            '|',
            'indent', 'outdent',
            '|',
            'font', 'fontsize', 'brush', 'paragraph',
            '|',
            'image', 'table', 'link',
            '|',
            'align', 'lineHeight', 'hr', 'eraser', 'find', 'spellcheck','print',
            '|',
            'cut', 'selectall', 'copy', 'paste',
            '|',
            'fullsize',
        ],
        toolbarAdaptive: false,
        uploader: {
                        insertImageAsBase64URI: true, 
                        url: '', 
                        isSuccess: () => true,
                        process: () => {},
                    },
                    filebrowser: {
                        ajax: {
                            url: '',
                        },
                    },
        events: {
            afterInit(instance) {
                editor.current = instance;
                console.log('jodit instance', instance.selection, editor.current);
            },
            // drop: async (e) => {
            //     e.preventDefault();
            //     const files = e.dataTransfer.files;

            //     if (files.length > 0) {
            //         const file = files[0];
            //         const reader = new FileReader();

            //         // Handle different file types
            //         if (file.type.startsWith('text/')) {
            //             reader.onload = (event) => {
            //                 const result = event.target.result;
            //                 editor.current.selection.insertHTML(result);
            //             };
            //             reader.readAsText(file);
            //         } else if (file.type.startsWith('image/')) {
            //             reader.onload = (event) => {
            //                 const result = event.target.result;
            //                 const imgTag = `<img src="${result}" alt="${file.name}" />`;
            //                 editor.current.selection.insertHTML(imgTag);
            //             };
            //             reader.readAsDataURL(file);
            //         } else if (file.type === 'application/pdf') {
            //             await handlePDF(file);
            //         } else if (file.name.endsWith('.docx')) {
            //             await handleDocx(file);
            //         }else if (file.name.endsWith('.doc')) {
            //             // await handleDocFile(file);
            //         }else if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
            //             await handleExcel(file);
            //         } else {
            //             alert('Unsupported file type.');
            //         }
            //     }
            // },
        },
        controls: {
            paragraph: {
                list: Jodit.atom({
                    p: 'Paragraph',
                    h1: 'Heading 1',
                    h2: 'Heading 2',
                    h3: 'Heading 3',
                    h4: 'Heading 4',
                    blockquote: 'Quote',
                })
            },
            'spellcheck': {
                exec: (editor1) => {
                    const editorContent = editor.current.editor;
                    const allElements = Array.from(editorContent.children);
                    const fragment = document.createDocumentFragment();
                    allElements.forEach((element) => {
                    if (!element.id.startsWith('jodit-selection_marker')) {
                        fragment.appendChild(element.cloneNode(true)); // Clone to avoid modifying the original DOM
                    }
                    });

                    editorContent.innerHTML = ""; 
                    editorContent.appendChild(fragment); 

                    setEditorContentUpload(editorContent.innerHTML);

                },
                tooltip: 'Clear Search Highlights'
            },
            print: {
                exec: () => {
                    if (editor.current && editor.current.editor) {
                        const editorContent = editor.current.editor.innerHTML;
            
                        // Create a new window for printing
                        const printWindow = window.open('', '_blank', 'width=800,height=600');
                        printWindow.document.open();
            
                        // Add HTML structure and styles for printable content
                        printWindow.document.write(`
                            <html>
                                <head>
                                    <title>Print Document</title>
                                    <style>
                                        body { font-family: Arial, sans-serif; margin: 20px; }
                                        img { max-width: 100%; height: auto; }
                                        p { margin: 10px 0; }
                                    </style>
                                </head>
                                <body>${editorContent}</body>
                            </html>
                        `);
                        printWindow.document.close();
            
                        // Trigger print after the content loads
                        printWindow.onload = () => {
                            printWindow.print();
                            printWindow.close();
                        };
                    } else {
                        console.error("Editor instance not found.");
                    }
                },
                tooltip: 'Print Document'
            }
            
        }
    }

    const handleFontChange = (editor, font) => {
        console.log("font_", editor, font);
        editor.selection.applyCSS({ 'font-family': font });
    };

    useEffect(() => {
        console.log("content___", editorContent);
    }, [editorContent])

    
    // const handlePDF = async (file) => {
    //     const reader = new FileReader();
    //     reader.onload = async (event) => {
    //         const arrayBuffer = event.target.result;
    
    //         try {
    //             const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
    //             let htmlContent = '';
    
    //             for (let i = 1; i <= pdf.numPages; i++) {
    //                 const page = await pdf.getPage(i);
    //                 const content = await page.getTextContent();
    
    //                 // Create a div to wrap the content of each page
    //                 let pageHTML = `<div style="margin: 20px 0;">`;
    
    //                 // Group text items into paragraphs based on their y-coordinate proximity
    //                 let paragraphs = [];
    //                 let currentParagraph = [];
    //                 let lastY = null;
    
    //                 content.items.forEach((item) => {
    //                     const text = item.str;
    //                     const y = item.transform[5];
    
    //                     // If there's a significant difference in y-coordinates, consider it a new paragraph
    //                     if (lastY !== null && Math.abs(lastY - y) > 10) {
    //                         paragraphs.push(currentParagraph);
    //                         currentParagraph = [];
    //                     }
    
    //                     currentParagraph.push(text);
    //                     lastY = y;
    //                 });
    
    //                 // Add the last paragraph
    //                 if (currentParagraph.length > 0) {
    //                     paragraphs.push(currentParagraph);
    //                 }
    
    //                 // Convert paragraphs into HTML
    //                 paragraphs.forEach((paragraph) => {
    //                     pageHTML += `<p>${paragraph.join(' ')}</p>`;
    //                 });
    
    //                 pageHTML += `</div>`;
    //                 htmlContent += pageHTML;
    //             }
    
    //             console.log(htmlContent, "pdffiletext");
    //             if(htmlContent){
    //             editor.current.selection.insertHTML(htmlContent); // Insert formatted HTML into the editor
    //         }else{
    //             toast.error("There was an error processing the PDF file.")
    //         }
    //         } catch (error) {
    //             console.error("Error parsing PDF: ", error);
    //             toast.error("There was an error processing the PDF file.");
    //         }
    //     };
    //     reader.readAsArrayBuffer(file);
    // };
    
    

    // const handleDocx = async (file) => {
    //     const reader = new FileReader();
    //     reader.onload = async (event) => {
    //         const arrayBuffer = event.target.result;
    //         console.log(arrayBuffer,"arrayBuffer")
    //         const { value } = await mammoth.convertToHtml({ arrayBuffer });
    //         editor.current.selection.insertHTML(value);
    //         console.log(value,"docxvalue")
    //     };
    //     reader.readAsArrayBuffer(file);
    // };

    // const handleDocx = async (file) => {
    //     try {
    //         const reader = new FileReader();
            
    //         reader.onload = async (event) => {
    //             try {
    //                 const arrayBuffer = event.target.result;
    //                 console.log(arrayBuffer, "arrayBuffer");
                    
    //                 // Convert the array buffer to HTML
    //                 const { value } = await mammoth.convertToHtml({ arrayBuffer });
                    
    //                 // Insert the converted HTML into the editor
    //                 if (editor.current && editor.current.selection && value) {
    //                     editor.current.selection.insertHTML(value);
    //                 }else{
    //                     toast.error("There was an error processing this file")
    //                 }
                    
    //                 console.log(value, "docxvalue");
    //             } catch (conversionError) {
    //                 console.log("Error during the DOCX to HTML conversion:", conversionError);
    //             }
    //         };
            
    //         reader.onerror = (readError) => {
    //             console.log("Error reading the DOCX file:", readError);
    //         };
            
    //         reader.readAsArrayBuffer(file);
    //     } catch (error) {
    //         console.log("Error handling the DOCX file upload:", error);
    //     }
    // };
    



    // const handleExcel = async (file) => {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //         const data = new Uint8Array(event.target.result);
    //         const workbook = XLSX.read(data, { type: 'array' });
    //         const htmlContent = XLSX.utils.sheet_to_html(workbook.Sheets[workbook.SheetNames[0]]);
    //         editor.current.selection.insertHTML(htmlContent);
    //     };
    //     reader.readAsArrayBuffer(file);
    // };

    // const handleExcel = async (file) => {
    //     try {
    //         const reader = new FileReader();
            
    //         reader.onload = (event) => {
    //             try {
    //                 const data = new Uint8Array(event.target.result);
                    
    //                 // Read the workbook from the Excel file
    //                 const workbook = XLSX.read(data, { type: 'array' });
                    
    //                 // Convert the first sheet to HTML
    //                 const htmlContent = XLSX.utils.sheet_to_html(workbook.Sheets[workbook.SheetNames[0]]);
                    
    //                 // Insert the HTML content into the editor
    //                 if (editor.current && editor.current.selection) {
    //                     editor.current.selection.insertHTML(htmlContent);
    //                 }
                    
    //                 console.log(htmlContent, "Excel HTML content");
    //             } catch (conversionError) {
    //                 console.log("Error during the Excel to HTML conversion:", conversionError);
    //             }
    //         };
            
    //         reader.onerror = (readError) => {
    //             console.log("Error reading the Excel file:", readError);
    //         };
            
    //         reader.readAsArrayBuffer(file);
    //     } catch (error) {
    //         console.error("Error handling the Excel file upload:", error);
    //     }
    // };
    
    const jodit = useMemo(() => {
        return (
            <div>
                <JoditEditor
                    ref={editor}
                    value={editorContent}
                    config={config}
                    className={"custom-editor"}
                    onChange={onChange}
                />
            </div>
        )
    }, [isEdit,editorHeight])

    return (
        <div>
            {isMain ? jodit :
                <div>
                    <JoditEditor
                        ref={editor}
                        value={editorContent}
                        config={config}
                        className={"custom-editor"}
                        onBlur={onChange}
                    />
                </div>

            }
        </div>
    )
}