import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Documents from "@material-ui/icons/Description";
import PostAddIcon from "@material-ui/icons/PostAdd";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupsIcon from "@mui/icons-material/Groups";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Dashboard from "@material-ui/icons/Equalizer";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import avatar from "../static/img_avatar.png";
import NotificationCard from "./Cards/NotificationCard";
import { useHistory } from "react-router-dom";
import authService from "../services/authService";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import { getProfile, getProfile2 } from "../services/userService";
import "./Navbar.css";
import "./AdminSideNavBar.css";
import GenericThemeSwitch from "./GenericThemeSwitch";
import isEmptyObject from "../utils/isEmptyObject";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { deleteAllReadNotifications, getNotifications, getReadNotifications, markAllAsRead } from "../services/notficationService";
import { getHomeCMS } from "../services/adminServices";
// import PetLogo from "../static/signup_login/PetLogo.png"
import ContactProLogo from "../static/signup_login/contactPrologo.PNG";
import { subadminMenuItems, superAdminMenuItems, userMenuItems } from "../components/sidebarItems";
import SearchIcon from '@mui/icons-material/Search';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from "react-redux";
import { sideBarClose, sideBaropen } from "../redux/sidenavbar/sidenavbar.action";
import feedback from ".././static/admin_dashboard/feedback.png"
import deadline from ".././static/admin_dashboard/deadline.png"
import SettingsIcon from '@mui/icons-material/Settings';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ArticleIcon from '@mui/icons-material/Article';
import { getUserInfo } from "../services/opportunityService";
import { getActivitiesforNotifications, getrecentactivities } from "../services/assetService";
import { markallasread } from "../services/activityTransaction";
import dashboardServices from "../services/dashboardServices";
import { getassetsbyid } from "../services/assetService";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import MenuIcon from '@mui/icons-material/Menu';
import ReceiptIcon from "@material-ui/icons/Receipt";
import Review from "@material-ui/icons/Chat";
import BookIcon from "@material-ui/icons/Book";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ContactProLogoMobile from "../static/signup_login/contactProLogo_Mobile.png";
import QuizIcon from '@mui/icons-material/Quiz';

const drawerWidth = 250;
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "blue",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  drawer: {
    // border: "2px solid black",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative", // Default positioning
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer, // Default zIndex
    [theme.breakpoints.down("sm")]: {
      position: "fixed", // Overlay the content
      height: "100%", // Full height
      top: 0,
      left: 0,
      zIndex: theme.zIndex.drawer + 1, // Higher zIndex for small screens
      transform: "translateX(-100%)", // Initially hidden on small screens
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      transform: "translateX(0)", // Show when open on small screens
    },
  },

  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.down("sm")]: {
      transform: "translateX(-100%)", // Hide when closed on small screens
    },
  },
  
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbarB: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  popoverContent: {
    width: 400,
    maxHeight: "90vh",
  },
}));

function AdminHeader(props, documentId) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [profilePic, setProfilePic] = useState("");
  const [emailId, setemailId] = useState("");
  const [user, setUser] = useState("");
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [isAdmin, setAdmin] = React.useState(false);
  const [notifications, setnotifications] = useState([]);
  const [recentActivities, setRecentActivities] = useState([])
  const isMenuOpen = Boolean(anchorEl);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cms, setCms] = useState({});
  const dispatch = useDispatch();
  const [viewAll, setViewAll] = useState(false);
  const [bottomText, setBottomText] = useState("");
  const [username, setusername] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("ContractPRO");
  const sideBarData = useSelector(state => state.sideNavbar)
  const [userInfo, setUserInfo] = useState('')
  console.log(user, "username");
  const [nearDeadlineData, setNearDeadlineData] = useState([]);
  const location = useLocation();
  const [prevPath, setPrevPath] = useState(null);
  const [userRoleforDashboard, setUserRoleforDashboard] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filter, setFilter] = useState('unread'); 
  const [readNotifications, setReadNotifications] = useState([]);
  const [readActivities, setReadActivities] = useState([]); 


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log(windowWidth, "windowWidth")
  const mobileView = windowWidth <= 600;

  const userRole = sessionStorage.getItem("Role")
  useEffect(() => {
    if (userRole) {
      setUserRoleforDashboard(userRole)
    }
  }, [userRole])

  console.log(userRole, "userroles")
  console.log(userRoleforDashboard, "userRoleforDashboard")

  useEffect(() => {
    setnotifications(notifications);
  }, [notifications]);

  useEffect(() => {
    // Retrieve the previous path from local storage
    const previousPath = sessionStorage.getItem('prevPath');
    setPrevPath(previousPath);

    // Save the current path as the previous path in local storage
    sessionStorage.setItem('prevPath', location.pathname);
    sessionStorage.setItem('prevPath', location.pathname);

    // Update previous path on location change
  }, [location.pathname]);


  console.log(prevPath, "previousPath")
  console.log(location, "locationPath")

  const getCuurentUser = async () => {
    try {
      const Username = sessionStorage.getItem("currentUserEmail")
      const response = await getUserInfo(Username)
      console.log("userinfo", response)
      setUserInfo(response.data)

    } catch (error) {
      console.log(error)
    }
  }

  const formatDate = () => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const date = new Date();
    const month = date.toLocaleDateString("en-US", { month: "short" });
    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const year = date.toLocaleDateString("en-US", { year: "numeric" });

    return `${month} ${day} | ${year}`;
  };


  useEffect(() => {
    const currentPath = window.location.pathname;

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    if (window.location.pathname?.startsWith('/OppourtunityManagementSubadminRedirect')) {
      setSelectedMenu('Oppourtunity Management');
  } else 
  {switch (currentPath) {
      case "/subAdminDashboard":
        const capitalizedUsername = capitalizeFirstLetter(user);
        setSelectedMenu(`Welcome ${capitalizedUsername}`);
        // setBottomText(`${formatDate()}`);
        break;
      case "/dashboard":
        const capitalizeddUsername = capitalizeFirstLetter(user);
        setSelectedMenu(`Welcome ${capitalizeddUsername}`);
        // setBottomText(`${formatDate()}`);
        break;
      case "/subAdminManageUser":
        setSelectedMenu(" Manage Users");
        break;
      case "/organization":
        setSelectedMenu(" Organization ");
        break;
      case "/filterOpportunityinOrg":
        setSelectedMenu("Matching Opportunities ");
        break;
      // case "/subAdminUserRequest":
      //   setSelectedMenu("User Request");
      //   break;
      case "/singleNoticePage/:documentId":
        setSelectedMenu("Opportunity View");
        break;
      case "/OppourtunityManagementSubadmin":
        setSelectedMenu("Opportunity Management");
        break;
      case "/manualOpportunity":
        setSelectedMenu("Manual Opportunity");
        break;
      case "/communicationHubSubadmin":
        setSelectedMenu("Communication Hub");
        break;
      case "/userroleSubadmin":
        setSelectedMenu("User Role");
        break;
      case "/helpAndSupportSubadmin":
        setSelectedMenu("Help and support");
        break;
      case "/workflow":
        setSelectedMenu("Workflow Administration");
        break;
      case '/searchOpportunity':
        setSelectedMenu('Opportunity Finder');
        break;

      case '/ProposalCreationPage':
        setSelectedMenu('Proposal Creation');
        break;
      case '/appfeatureRolelist':
        setSelectedMenu("Role Management");
        break;


      case '/FARclause':
        setSelectedMenu("Clause Generation");
        break;

      case '/far':
        setSelectedMenu("Clause Generation");
        break;

      case '/accountsettings':
        setSelectedMenu("Account Settings");
        break;

      case '/contactUs':
        setSelectedMenu("Contact Us");
        break;

      // case '/pricing':
      //   setSelectedMenu("Subscriptions");
      //   break;

      case '/invoices':
        setSelectedMenu("Invoices");
        break;

      case '/TemplateManagement':
        setSelectedMenu('Template Management');
        break;
      case '/ProposalCreationPage':
        setSelectedMenu('Proposal Creation');
        break;

      case '/rfpManagement':
        setSelectedMenu('RFP Management');
        break;

      case '/proposalWriting':
        setSelectedMenu('Proposal Writing');
        break;

      case '/TemplateGallery':
        setSelectedMenu('Template Gallery');
        break;

      case '/singleNoticePage/66a02177d2c5d94f439bc3e3':
        setSelectedMenu('Single Notice Page');
        break;
   

        case '/accountsettings':
          setSelectedMenu('Account Settings');
          break;
        case '/admindashboard':
          const capitalizedddUsername = capitalizeFirstLetter(user);
          setSelectedMenu(`Welcome ${capitalizedddUsername}`);
          setBottomText(`${formatDate()}`);
          break;
        case '/manageuser':
          setSelectedMenu('Manage Users');
          break;
          case '/manageorganization':
            setSelectedMenu('Manage Organization');
            break;
          case '/appfeatureRolelist':
            setSelectedMenu('Manage Feature');
            break;
        case '/pricing':
          setSelectedMenu('Manage Subscription');
          break;
        case '/manageinvoices':
          setSelectedMenu('Manage Invoices');
          break;
        case '/adminBlogs':
          setSelectedMenu('Manage Blogs');
          break;
        case '/costumHome':
          setSelectedMenu('Customise Landing');
          break;
        case '/managereviews':
          setSelectedMenu("Manage Reviews");
          break;
        case '/promptsGenerator':
          setSelectedMenu('Prompts Generator')
          break;
        case '/TemplateGallery':
          setSelectedMenu('Template Gallery')
          break;
        case '/faq':
          setSelectedMenu('FAQ')
          break;
         
        
            
      default:
        if ('/singleNoticePage') {
          setSelectedMenu('Single Notice Page');
        } else {
          // Handle other paths or set a default value
          setSelectedMenu('');
        }
        break;



    }
  }
    
  }, [user,location.pathname]);
  console.log(location.pathname,"location.pathname")

  useEffect(() => {
    console.log("open or close _____", open, sideBarData.isOpen.stateValue)
    if (sideBarData?.isOpen?.stateValue == true) {
      setOpen(true)
      console.log("sideBarData______--", sideBarData)
    }


  }, [open])

  const fetchNearDeadlineData = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await getassetsbyid(data);
      const currentDate = new Date();
      const nearDeadlineData = response.data
        .filter((item) => {
          const deadline = item.opportunity.responseDeadLine;
          if (deadline !== null) {
            const deadlineDate = new Date(deadline);
            const differenceInDays = Math.ceil((deadlineDate - currentDate) / (1000 * 60 * 60 * 24));
            return differenceInDays <= 7 && differenceInDays >= 0;
          }
          return false;
        })
        .map((item) => ({
          projectname: item.opportunity.title,
          _id: item._id,
          date: formatDateForEvent(item.opportunity.responseDeadLine),
          color: getColorBasedOnDeadline(formatDateForEvent(item.opportunity.responseDeadLine)),
        }));
      console.log(nearDeadlineData, "nearDeadlineData in");
      const filteredNearDeadlineData = nearDeadlineData.filter(item => item.color === "red");
      console.log(filteredNearDeadlineData, "filteredNearDeadlineData");

      // setNearDeadlineData(filteredNearDeadlineData);

      return filteredNearDeadlineData
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatDateForEvent = (dateString) => {
    if (!dateString) {
      return "Date not available";
    }
    const datePart = dateString.split(" at ")[0];
    const date = new Date(datePart);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return dateString;
    }
  };

  const getColorBasedOnDeadline = (deadlineDate) => {
    const currentDate = new Date();
    const differenceInDays = Math.ceil(
      (new Date(deadlineDate) - currentDate) / (1000 * 60 * 60 * 24)
    );

    const totalDays = 30;
    const percentageRemaining = (differenceInDays / totalDays) * 100;

    if (differenceInDays < 0) {
      return "black";
    } else if (percentageRemaining <= 33.33) {
      return "red";
    } else if (percentageRemaining <= 66.33) {
      return "orange";
    } else {
      return "green";
    }
  };


  const fetchAndAddEventsToCalendar = async () => {
    try {
      const nearDeadlineData = await fetchNearDeadlineData();
      console.log(nearDeadlineData, "nearDeadlineData");

      const events = nearDeadlineData.map((item) => {
        const deadlineDate = new Date(item.date);
        const currentDate = new Date();
        const reminderDate = new Date(deadlineDate.getTime() - (24 * 60 * 60 * 1000));
        const endDate = new Date(deadlineDate.getTime() + 60 * 60 * 1000); // Add 1 hour
        return {
          // id: uuidv4(),
          subject: item.projectname,
          start: {
            dateTime: deadlineDate.toISOString(),
            timeZone: "UTC"
          },
          end: {
            dateTime: endDate.toISOString(),
            timeZone: "UTC"
          },
          body: {
            contentType: "HTML",
            content: `Deadline for ${item.projectname}`
          },
          reminderMinutesBeforeStart: 15,
          iCalUId: item._id
        };
      });

      console.log(events, "events");
      try {
        const response = await dashboardServices.addEventToOutlook(events);
        console.log('Events added successfully:', response);
      } catch (error) {
        console.error('Error adding events:', error);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    console.log("_____Called");
    fetchAndAddEventsToCalendar();
    const interval = setInterval(fetchAndAddEventsToCalendar, 2 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);



  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getCms = async () => {
    const { currentUser, setCurrentUser } = props;
    let cms = await getHomeCMS();
    setCms(cms.data);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(sideBaropen({ stateValue: true }))
  };
  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(sideBarClose({ stateValue: false }))
  };
  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };
  function handleNavigation(route) {
    history.push(`/${route}`);
  }
  const handleMenuSelection = (menuName) => {
    setSelectedMenu(menuName);
  };

  useEffect(() => {
    const user = authService.getCurrentUser();
    //console.log(user);
    if (user) {
      if (user.isAdmin === true) {
        setAdmin(true);
      }
    }
    console.log(isAdmin);
  });

  useEffect(() => {
    displyPic();
    // getNotifications();
    getCms();
    getCuurentUser()
  }, []);

  // const getNotifications = async () => {
  //   let notifications = await NotificationServices.getnotifications();
  //   setnotifications(notifications);
  // };

  const displyPic = async () => {
    let user;
    // const { currentUser, setCurrentUser } = props;
    // if (currentUser && !isEmptyObject(currentUser)) {
    //   user = { ...currentUser };
    // } else {
      const id = sessionStorage.getItem("userId")
      user = await getProfile2(id);
      console.log("currentUser", user);
      user = user.data;
      // setCurrentUser(user);
    // }
    console.log("users", user);
    const { display_picture } = user;
    setProfilePic(display_picture);
    // setemailId(user.data.email);
    // setUser(user.data.username)
    setemailId(user.email);
    setUser(user.username);
    setBottomText(user.organizationName)
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const dummyNotifications = [
    {
      image: feedback,
      heading: "Reviewer Feedback",
      text: "Feedback received for 'Project B - Mobile App Development.",
      time: "10 mins",
    },
    {
      image: feedback,
      heading: "Reviewer Feedback",
      text: "Feedback received for 'Project B - Mobile App Development.",
      time: "12 mins",
    },
    {
      image: feedback,
      heading: "Reviewer Feedback",
      text: "Feedback received for 'Project B - Mobile App Development.",
      time: "13 mins",
    },
    {
      image: deadline,
      heading: "Approaching Deadline!",
      text: "24 hours left until the deadline.",
      time: "15 mins",
    },
    {
      image: deadline,
      heading: "Approaching Deadline!",
      text: "24 hours left until the deadline.",
      time: "30 mins",
    },

  ];
  const userEmail = sessionStorage.getItem("currentUserEmail")

  const fetchNotifications = async () => {
    try {
      const response = await getNotifications(userEmail);
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const recentact = await getActivitiesforNotifications(data);
      const userInfoDateCreated = new Date(userInfo.date_created);
  
      // Fetch read notifications and activities
      const readResponse = await getReadNotifications(userEmail);
      const readNotifications = readResponse.data.notifications;
      const readActivities = readResponse.data.activities;
  
      // Filter unread notifications created after userInfo.date_created
      const filteredNotifications = response.notifications.filter(notification => {
        const notificationCreatedOn = new Date(notification.createdOn);
        return notificationCreatedOn > userInfoDateCreated;
      });
  
      // Filter unread activities created after userInfo.date_created
      const filteredRecentActivities = recentact.simplifiedTransactions.filter(activity => {
        const activityCreatedOn = new Date(activity.createdOn);
        return activityCreatedOn > userInfoDateCreated;
      });
  
      setnotifications(filteredNotifications); 
      setRecentActivities(filteredRecentActivities); 
      setReadNotifications(readNotifications); 
      setReadActivities(readActivities); 
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };
  useEffect(() => {
    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 30000);
    return () => clearInterval(intervalId);
  }, [userInfo]);

const deleteReadNotifications = async () =>{
  try {
    const deleteResponse = await deleteAllReadNotifications(userEmail);

    if (deleteResponse.status === 200) {
      fetchNotifications();
      console.log("All read notifications deleted successfully.");
    } else {
      console.error("Failed to delete notifications and activities:", deleteResponse.message);
    }
  } catch (error) {
    console.error("Error during deleting read notifications:", error);
  }
}
  const markAllNotificationsAsRead = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const response = await markAllAsRead(email);
      const recentActivityResponse = await markallasread(email);
      console.log("recentActivityResponse ", recentActivityResponse)
      if (response.status === 200) {
        fetchNotifications()
      }
    } catch (error) {
      console.error(error);
    }
  };

  const notfication_popover = (
    <Popover
      id="popover-basic"
      style={{ zIndex: "8888", maxWidth: "600px", border: "1px solid #1976D2" }}
    >
      <Popover.Content className={classes.popoverContent}>
        <Typography variant="h6" style={{ fontWeight: "bold" }}>Notifications</Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
          <select value={filter} onChange={(e) => setFilter(e.target.value)} style={{color:'#1976d2', border:'none', fontSize:'15px'}}>
            <option value="unread">Unread</option>
            <option value="read">Read</option>
          </select>
          {filter === 'unread' ? (
            <button className="ReadBtn" onClick={markAllNotificationsAsRead}>
              Mark all as Read
            </button>
          ) : (
            <button className="DeleteAllBtn" style={{ color: 'red' }} onClick={deleteReadNotifications}>
              Delete All
            </button>
          )}
        </div>
        <div style={{ overflowY: 'auto', maxHeight: 'calc(85vh - 55px)' }}>
          {filter === 'unread' ? (
            <NotificationCard notifications={notifications} recentActivities={recentActivities} filter={filter} />
          ) : (
            <NotificationCard notifications={readNotifications} recentActivities={readActivities} filter={filter} />
          )}
        </div>
      </Popover.Content>
    </Popover>
  );
  

  const dropdown_popover = (
    <Popover id="popover-basic" style={{ zIndex: "8888" }}>
      <Popover.Content>
        <NavLink
          to={{ pathname: "/accountsettings" }}
          style={{ color: "black" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/accountsettings") && "background-grey"}
          >
            <Typography>Account Settings</Typography>
          </MenuItem>
        </NavLink>
        <NavLink
          to={{ pathname: "/pricing" }}
          style={{ color: "black", paddingTop: "2px" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/pricing") && "background-grey"}
          >
            Subscriptions
          </MenuItem>
        </NavLink>
        <NavLink to={{ pathname: "/invoices" }} style={{ color: "black" }}>
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/invoices") && "background-grey"}
          >
            Invoices
          </MenuItem>
        </NavLink>
        <NavLink
          to={{ pathname: "/contactUs" }}
          style={{ color: "black", paddingTop: "2px" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/contactUs") && "background-grey"}
          >
            Support
          </MenuItem>
        </NavLink>
        <NavLink to={{ pathname: "/logout" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
        </NavLink>
      </Popover.Content>
    </Popover>
  );
  const getThemeSwitch = () => {
    if (window.location.pathname.match("/accountsettings")) {
      return null;
    }
    return <GenericThemeSwitch />;
  };

  // function isUrlMatch(url) {
  //   if (window.location.pathname.match(url)) {
  //     return true;
  //   }
  //   return false;
  // }

  // function isUrlMatch(path) {
  //   if (
  //     location.pathname === "/rfpManagement" &&
  //     path === "/OppourtunityManagementSubadmin"
  //   ) {
  //     return true; // Highlight the Opportunity Management if on /rfpManagement
  //   }
  //   if (
  //     location.pathname === "/proposalWriting" &&
  //     path === "/OppourtunityManagementSubadmin"
  //   ) {
  //     return true; // Highlight the Opportunity Management if on /rfpManagement
  //   }
  //   if (
  //     location.pathname.startsWith("/singleNoticePage") &&
  //     path === "/searchOpportunity"
  //   ) {
  //     return true; // Highlight the Opportunity Management if on /rfpManagement
  //   }
  //   // if (
  //   //   location.pathname.startsWith("/singleNoticePage") &&
  //   //   path === "/filterOpportunityinOrg"
  //   // ) {
  //   //   return true; // Highlight the Opportunity Management if on /rfpManagement
  //   // }
  //   return location.pathname === path;
  // }


  function isUrlMatch(path) {
    if (
      location.pathname === "/rfpManagement" &&
      path === "/OppourtunityManagementSubadmin"
    ) {
      return true; // Highlight the Opportunity Management if on /rfpManagement
    }
    if (
      location.pathname === "/proposalWriting" &&
      path === "/OppourtunityManagementSubadmin"
    ) {
      return true; // Highlight the Opportunity Management if on /rfpManagement
    }
    if (
      location.pathname.startsWith("/singleNoticePage") &&
      prevPath === "/searchOpportunity" &&
      path === "/searchOpportunity"
    ) {
      return true; // Highlight Search Opportunity if came from Search Opportunity
    }
    if (
      location.pathname.startsWith("/singleNoticePage") &&
      prevPath === "/filterOpportunityinOrg" &&
      path === "/filterOpportunityinOrg"
    ) {
      return true; // Highlight Filter Opportunity if came from Filter Opportunity
    }
    if (
      location.pathname.startsWith("/singleNoticePage") &&
      prevPath === "/OppourtunityManagementSubadmin" &&
      path === "/OppourtunityManagementSubadmin"
    ) {
      return true; // Highlight Filter Opportunity if came from Filter Opportunity
    }
    return location.pathname === path;
  }
  console.log(open, "___HHH")

  const totalNotifications = notifications.length + recentActivities.length;

  return (
    <div className={classes.root} style={{ marginLeft: windowWidth < 960 ? "0px" : "-25px", }}>
      <CssBaseline />
      {getThemeSwitch()}
      <AppBar
        id="appBar"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {mobileView ? (
            <>
              {!open &&
                <div
                  style={{
                    color: "#007bff",
                    display: "flex" ,
                    justifyContent: "space-between" ,
                    alignItems: "center" ,
                    // border: "1px solid black"
                  }}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                  id="normalsidenav"
                >
                  {mobileView ? (<MenuIcon style={{ fontSize: "25px", color: "#007bff" }} />) : (<ChevronRightIcon style={{ color: "#007bff" }} />)}

                  <img
                    alt="logo"
                    // className="img-fluid lcss"
                    src={mobileView ? ContactProLogoMobile : ContactProLogo}
                    style={{
                      width: mobileView ? "50px" : "100px",
                      height: "35px",
                      objectFit: "contain",
                      // marginLeft: "5px",
                      // border: "1px solid black",
                      marginRight: "5px"
                    }}
                  // src={logo}
                  ></img>
                  <p>
                    {mobileView && selectedMenu !== "ContractPRO" && (
                      <>
                      <text
                        style={{
                          fontFamily: "ProximaNova-Semibold",
                          lineHeight: "1.18",
                          fontWeight: 600,
                          fontSize: "1rem",
                          color: "#3d83fa",
                          letterSpacing: "none",
                        }}
                      >
                        {selectedMenu}
                      </text>
                      <br />
                      {bottomText && (
                        <small
                          style={{
                            fontFamily: "ProximaNova-Light",
                            lineHeight: "1.18",
                            // fontSize: "0.6rem",
                            fontWeight: "bold",
                            color: "#0630C7",
                            letterSpacing: "none",
                          }}
                        >
                          {bottomText}/
                          <span style={{color: "#033AFF", fontFamily: "ProximaNova-Light", fontWeight:'normal'}}>{userRoleforDashboard}</span>
                        </small>
                      )}
                      </>

                    )}
                  </p>
                </div>
              }
            </>) : (
            <div
              style={{
                color: "#007bff",
              }}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
              id="normalsidenav"
            >
              <ChevronRightIcon style={{ color: "#007bff" }} />

              <img
                alt="logo"
                // className="img-fluid lcss"
                src={ContactProLogo}
                // src={
                //   cms && cms.Navbar_logo
                //     ? cms.Navbar_logo.Image
                //     : `${imgUrl}/pet-website-static-images/Group+570.png`
                // }
                style={{
                  width: "100px",
                  height: "40px",
                  objectFit: "contain",
                  marginLeft: "20px",
                  // border: "1px solid black"
                }}
              // src={logo}
              ></img>
            </div>
          )
          }

          {/* <Typography variant="h6" noWrap style={{ color: "#4d4f5c" }}>
            <p>
              PROFESSIONAL <small>EDITING TOOL</small>
            </p>
          </Typography> */}
          {/* <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
            <p>
              <text
                style={{
                  fontFamily: "ProximaNova-Semibold",
                  lineHeight: "1.18",
                  fontWeight: 600,
                  fontSize: "22px",
                  color: "#3d83fa",
                  // backgroundColor: "#99ccff",
                }}
              >
                PROFESSIONAL
              </text>{" "}
              <small
                style={{
                  fontFamily: "ProximaNova-Light",
                  lineHeight: "1.18",
                  fontSize: "22px",
                  fontWeight: 300,
                  color: "#3d83fa",
                }}
              >
                EDITING TOOL
              </small>
            </p>
          </Typography> */}

          <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
            <p>
              {selectedMenu !== "ContractPRO" ? (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "22px",
                      color: "#3d83fa",
                      marginLeft: "15px"
                    }}
                  >
                    {selectedMenu}
                  </text>
                  <br />
                  {bottomText && (
                    <small
                      style={{
                        fontFamily: "ProximaNova-Light",
                        lineHeight: "1.18",
                        fontSize: "17px",
                        fontWeight:'bold',
                        color: "#0630C7",
                        marginLeft: "15px"
                      }}
                    >
                      {bottomText}/
                      <span style={{color: "#033AFF", fontFamily: "ProximaNova-Light", fontSize: "15px", fontWeight:'normal'}}>{userRoleforDashboard}</span>
                    </small>
                  )}
                </>
              ) : (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "22px",
                      color: "#3d83fa",
                      marginRight: "5px",
                    }}
                  >
                    ContractPRO
                  </text>
                  <small
                    style={{
                      fontFamily: "ProximaNova-Light",
                      lineHeight: "1.18",
                      fontSize: "22px",
                      fontWeight: 300,
                      color: "#3d83fa",
                    }}
                  >
                  </small>
                </>
              )}
            </p>
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div style={{ marginTop: "6%" }}>
              {/* <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={openContactUs}
              >
                <ContactMailIcon />
              </IconButton> */}
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={notfication_popover}
                rootClose={true}
              >
                <IconButton
                  style={{ color: "#4d4f5c" }}
                  aria-label={`show ${totalNotifications} new notifications`}
                  color="inherit"
                  disabled={notifications.length === 0 && recentActivities.length === 0}

                >
                  <Badge
                    badgeContent={totalNotifications}
                    color="secondary"
                  >
                    <NotificationsIcon style={{ color: "#1976D2" }} />
                  </Badge>
                </IconButton>
              </OverlayTrigger>
            </div>
            <div>
              <Grid container justify="center" alignItems="center">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={dropdown_popover}
                  rootClose={true}
                >
                  <Avatar
                    aria-label="account of current user"
                    aria-controls={menuId}
                    onClick={handleProfileMenuOpen}
                    aria-haspopup="true"
                    alt={user}
                    src={profilePic ? profilePic : avatar}
                    id="profilePic"
                    className={`${classes.avatar} profilePic point`}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={user}
                    color="inherit"
                  />
                </OverlayTrigger>
              </Grid>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={notfication_popover}
              rootClose={true}
            >
              <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label={`show ${totalNotifications} new notifications`}
                color="inherit"
              >
                <Badge
                  badgeContent={totalNotifications}
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </OverlayTrigger>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={dropdown_popover}
              rootClose={true}
            >
              {/* <MoreIcon /> */}
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                aria-haspopup="true"
                alt={user}
                src={profilePic ? profilePic : avatar}
                id="profilePic"
                className={`${classes.avatar} point`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={emailId}
                color="inherit"
              />
            </OverlayTrigger>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        // open={open}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        id="normalsidenav"
      // style={{border: "2px solid red"}}
      >
        <div
          className={classes.toolbarB}
          style={{
            backgroundColor: "white",
            position: "sticky",
            top: 0,
            left: 0
          }}>
          <img
            alt="logo"
            className="img-fluid lcss"
            src={ContactProLogo}
            // src={
            //   cms && cms.Navbar_logo
            //     ? cms.Navbar_logo.Image
            //     : "https://petblogbucket.s3.us-east-2.amazonaws.com/pet-website-static-images/Group+570.png"
            // }
            style={{
              width: "70%",
              height: "70%",
              objectFit: "contain",
            }}
          // src={logo}
          ></img>

          <IconButton className="p-1" onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              // <ChevronRightIcon
              //   style={{ color: "#007bff", border: "1px solid blue" }}
              // />
              mobileView ? (
                <MenuIcon style={{ color: "#007bff", border: "1px solid blue" }} />
              ) : (
                <ChevronRightIcon style={{ color: "#007bff", border: "1px solid blue" }} />
              )
            ) : (
              // <ChevronLeftIcon style={{ color: "#007bff" }} />
              mobileView ? (
                <CloseIcon style={{ color: "#007bff", }} />
              ) : (
                <ChevronLeftIcon style={{ color: "#007bff" }} />
              )
            )}
          </IconButton>
        </div>
        <Divider />
        {/* list starts */}
        {/* <List
          className="drawerMenu pointer"
          id="menuList"
          style={{ height: "100vh" }}
        > */}
        {/* Link 1 */}
        <List
          className="drawerMenu pointer"
          id="menuList"
          style={{ overflowY: "auto", height: "100vh" }}
        >
          {userInfo?.Role === 'User' && (
            userMenuItems.map((item, index) => (
              <NavLink
                className="sidenavbar-textlink"
                key={index}
                to={{ pathname: item.path }}
              >
                <ListItem
                  className={
                    isUrlMatch(item.path) || isUrlMatch(`/blogpreview/:id`)
                      ? "background-red hvr-pulse"
                      : "hvr-pulse"
                  }
                  onClick={() => {
                    handleMenuSelection(item.label);
                  }}
                >
                  <ListItemIcon
                    className="drawerMenu"
                    style={{ color: "#007bff", marginLeft: "-8px" }}
                  >
                    <LightTooltip title={item?.label ? "" : item?.label}>
                      <p
                        style={{ margin: "auto" }}
                        className={
                          isUrlMatch(item.path) ? "iconCol" : "hvr-pulse"
                        }
                      >
                        {" "}
                        {getIconComponent(item.icon)}
                      </p>
                    </LightTooltip>
                  </ListItemIcon>
                  <Typography style={{ marginLeft: "14px" }}>
                    {item.label}
                  </Typography>
                </ListItem>
              </NavLink>
            ))
          ) }
           {userInfo?.Role === 'Admin' &&(
            subadminMenuItems.map((item, index) => (
              <NavLink
                className="sidenavbar-textlink"
                key={index}
                to={{ pathname: item.path }}
              >
                <ListItem
                  className={
                    isUrlMatch(item.path) || isUrlMatch(`/blogpreview/:id`)
                      ? "background-red hvr-pulse"
                      : "hvr-pulse"
                  }
                  onClick={() => {
                    handleMenuSelection(item.label);
                  }}
                >
                  <ListItemIcon
                    className="drawerMenu"
                    style={{ color: "#007bff", marginLeft: "-8px" }}
                  >
                    <LightTooltip title={open ? "" : item.label}>
                      <p
                        style={{ margin: "auto" }}
                        className={
                          isUrlMatch(item.path) ? "iconCol" : "hvr-pulse"
                        }
                      >
                        {" "}
                        {getIconComponent(item.icon)}
                      </p>
                    </LightTooltip>
                  </ListItemIcon>
                  <Typography style={{ marginLeft: "14px" }}>
                    {item.label}
                  </Typography>
                </ListItem>
              </NavLink>
            ))
          )}

{userInfo?.Role === 'SuperAdmin' && (
            superAdminMenuItems?.map((item, index) => (
              <NavLink
                className="sidenavbar-textlink"
                key={index}
                to={{ pathname: item.path }}
              >
                <ListItem
                  className={
                    isUrlMatch(item.path) || isUrlMatch(`/blogpreview/:id`)
                      ? "background-red hvr-pulse"
                      : "hvr-pulse"
                  }
                  onClick={() => {
                    handleMenuSelection(item.label);
                  }}
                >
                  <ListItemIcon
                    className="drawerMenu"
                    style={{ color: "#007bff", marginLeft: "-8px" }}
                  >
                    <LightTooltip title={item?.label ? "" : item?.label}>
                      <p
                        style={{ margin: "auto" }}
                        className={
                          isUrlMatch(item.path) ? "iconCol" : "hvr-pulse"
                        }
                      >
                        {" "}
                        {getIconComponent(item.icon)}
                      </p>
                    </LightTooltip>
                  </ListItemIcon>
                  <Typography style={{ marginLeft: "14px" }}>
                    {item.label}
                  </Typography>
                </ListItem>
              </NavLink>
            ))
          ) }

        </List>

      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const getIconComponent = (icon, isActive) => {
  const color = isActive ? "red" : "hvr-pulse"; // Set the color to blue when isActive is true
  switch (icon) {
    case "Dashboard":
      return <Dashboard style={{ color }} />;
    case "LocalLibraryIcon":
      return <LocalLibraryIcon style={{ color }} />;
    case "ScreenSearchDesktopIcon":
      return <ScreenSearchDesktopIcon style={{ color }} />;
    case 'SearchIcon':
      return <SearchIcon style={{ color }} />;
    // case "PeopleAltIcon":
    //   return <PeopleAltIcon style={{ color }} />;
    // case "ManageAccountsRoundedIcon":
    //   return <ManageAccountsRoundedIcon style={{ color }} />;
    case "PostAddIcon":
      return <PostAddIcon style={{ color }} />;
    case "Documents":
      return <Documents style={{ color }} />;
    case "GroupsIcon":
      return <GroupsIcon style={{ color }} />;
    case "ManageAccountsIcon":
      return <ManageAccountsIcon style={{ color }} />;
    case "LiveHelpIcon":
      return <LiveHelpIcon style={{ color }} />;
    case "AccountTreeIcon":
      return <AccountTreeIcon style={{ color }} />;
    case "PeopleAltIcon":
      return <PeopleAltIcon style={{ color }} />;
    case "SettingsIcon":
      return <SettingsIcon style={{ color }} />;
    case "AddToPhotosIcon":
      return <AddToPhotosIcon style={{ color }} />;
    case "ArticleIcon":
      return <ArticleIcon style={{ color }} />;
    case "QuizIcon":
      return <QuizIcon style={{ color }} />;


    case "CorporateFareIcon":
      return <CorporateFareIcon style={{ color }} />;
    case "ReceiptIcon":
      return <ReceiptIcon style={{ color }} />;
    case "AttachMoneyIcon":
      return <AttachMoneyIcon style={{ color }} />;
    case "Review":
      return <Review style={{ color }} />;
    case "BookIcon":
      return <BookIcon style={{ color }} />;
    case "AccountBalanceIcon":
      return <AccountBalanceIcon style={{ color }} />;
    case "ReceiptLongIcon":
      return <ReceiptLongIcon style={{ color }} />;
    case "HistoryEduIcon":
      return <HistoryEduIcon style={{ color }} />;

    // Add more cases as needed
    default:
      return null;
  }
};
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
