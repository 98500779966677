import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider} from "@mui/material/styles";
import { GlobalStyles} from '@mui/material';
import Paper from '@mui/material/Paper';

const theme = createTheme();

function LanguageDropdown({
  selectLabel, id, name, value, onChange, optionsList,
  margin, disabled, customColor,isDarkMode
}) {

  
  console.log("optionslist", optionsList)


  return (
    <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            '.MuiBox-root.css-jfggi0': {
              border: isDarkMode?'1px solid transparent':"", // Make the border transparent
            },
          }}
        />
  
      <Box className="MuiBox-root css-jfggi0" sx={{ m: 1, background: isDarkMode ? "#555" : "white", margin: { margin } }}>

        <FormControl sx={{ m: 1, margin: "unset" }} fullWidth size="small">
            <InputLabel
            id="demo-select-small-label"
            sx={{
              color: isDarkMode ? 'white' : '#646464', // White color for label in dark mode
              "&.Mui-focused": {
                color: isDarkMode ? 'white' : '#646464', // Keep white color when focused in dark mode
              },
            }}
          >
            {selectLabel}
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            name={name}
            id={id}
            value={value}
            label={selectLabel}
            onChange={(e) => onChange(e)}
            disabled={disabled}
            sx={{
              border: '1px solid transparent', 
              backgroundColor: isDarkMode ? '#555' : 'white', 
            }}
            renderValue={(selected) => {
              const selectedOption = optionsList.find(option => option.value === selected);
              return selectedOption ? (
                <>
                  {selectedOption.name}
                  &nbsp;{selectedOption.nativeWord}
                </>
              ) : "";
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  color: '#42a5f5',
                },
              },
            }}
          >
            {optionsList.length > 0 &&
              optionsList.map((obj) => {
                let { value, name } = obj;
                return (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}

export default LanguageDropdown;
