const initialState = {
    selectedCoauthorContent: null,
};

const manageCoauthorSelectedContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SELECT_COAUTHOR_CONTENT':
            return {
                ...state,
                selectedCoauthorContent: action.payload, 
            };
        case 'CLEAR_COAUTHOR_CONTENT':
            return {
                ...state,
                selectedCoauthorContent: null, 
            };
        default:
            return state;
    }
};

export default manageCoauthorSelectedContentReducer;
