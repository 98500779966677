import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import moment from 'moment';
import Select from 'react-select';
const BarChartComp = ({
  chartHeading,
  barChartData,
  boxWidth,
  boxHeight,
  sectionColors,
  legendStyles,
  rows,
  adjustTitle
}) => {
  const [selectedYear, setSelectedYear] = useState(moment().year()); // Default to current year
 
  const filteredData = rows.filter(row => moment(row.submissionDate).year() === selectedYear);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
 
    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
 
  const transformData = () => {
    const months = moment.monthsShort();
    const groupedData = months.map(month => {
      const monthData = filteredData.filter(row => moment(row.submissionDate).format('MMM') === month);
      const totalWinningPercentage = monthData.reduce((acc, row) => acc + row.winningPercentage, 0);
      const totalLostPercentage = monthData.reduce((acc, row) => acc + row.lostPercentage, 0);
      const count = monthData.length;
     
      return {
        month,
        winningPercentage: count > 0 ? (totalWinningPercentage / count) : 0,
        lostPercentage: count > 0 ? (totalLostPercentage / count) : 0,
      };
    });
 
    return groupedData;
  };
 
  const handleChangeYear = (e) => {
    console.log(e,"+++vvv")
    setSelectedYear(e);
  };
 
  useEffect(() => {
    transformData();
  }, [selectedYear, rows]);
 
 
  const getAllYears = () => {
    const currentYear = moment().year();
    const years = [];
    for (let year = 2000; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };
  const options = getAllYears().map((year) => ({ value: year, label: year }));

  const tooltipFormatter = (value) => {
    return `${value}%`;
  };
 
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        border: '1px solid #ccc',
        boxShadow: "2px 2px 5px -2px #000000",
        padding: "2px",
        width: "100%" || boxWidth,
        height: "auto" || boxHeight,
        marginBottom:window.innerWidth<768?0:"25px"
      }}
    >
      <div className="row p-2" style={{margin: windowWidth < 601 && adjustTitle ? "2px" : ""}}>
        <div className="col-md-7 col-8" style={{padding: windowWidth < 330 ? "0px" : ""}}>
            <h6
            className="p-2"
            style={{
              color: "#1976d2",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            {chartHeading}
            </h6>
        </div>
        <div className="col-md-5 col-4 d-flex justify-content-end">
          <Select
          value={options.find(option => option.value === selectedYear)}
          onChange={(selectedOption) => handleChangeYear(selectedOption?.value)}
          options={options}
          placeholder="Select Year"
          styles={{
          control: (base) => ({
          ...base,
          width: '100px',
          borderRadius: '10px',
          border: '1px solid #ccc',
          boxShadow: "1px 1px 4px -2px #000000",
          }),
          menu: (base) => ({
          ...base,
          // No need for overflowY here
          }),
          menuList: (base) => ({
          ...base,
          maxHeight: '150px', // Set maximum height for the dropdown list
          overflowY: 'auto' // Only scroll the list of options
          })
         }}
          />
        </div>
      </div>
      <div style={{ borderBottom: "2px solid #ccc", margin: "0 8px" }}></div>
      <div className="row" style={{ marginTop: '15px' }}>
        <div className="col-md-12">
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              data={transformData()}
              margin={{ top: 15, right: window.innerWidth < 768 ? 0 : 30, left: window.innerWidth < 768 ? 0 : 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                label={{ value: 'Months', position: 'insideBottom', offset: -5  }}
              />
              <YAxis domain={[0, 100]} label={{ value: 'Completion Percentage', angle: -90, position: 'insideLeft' ,fontSize:"10px", textAnchor: "middle", dy:50}} />
     
              <Tooltip formatter={tooltipFormatter} />
             
              <Bar
                dataKey="winningPercentage"
                fill={sectionColors.winningPercentage || "#4caf50"}
                barSize={30}
                isAnimationActive={false}
              />
              <Bar
                dataKey="lostPercentage"
                fill={sectionColors.lostPercentage || "#f44336"}
                barSize={30}
                isAnimationActive={false}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};
 
export default BarChartComp;
 
 
 
// first code
// const BarChartComp = ({
//   chartHeading,
//   xAxisData,
//   barChartData,
//   isSelectDropdownShown,
//   barChartSelectOptions,
//   selectDropdownDisabled,
//   boxWidth,
//   boxHeight,
//   sectionColors,
//   legendStyles
// }) => {
//       const { container = {}, items = [] } = legendStyles;
 
     
//   return (
//     <>
//       <div
//         style={{
//           backgroundColor: "#ffffff",
//           borderRadius: "8px",
//           border: '1px solid #ccc',
//           boxShadow: "2px 2px 5px -2px #000000",
//           padding: "2px",
//           width: boxWidth || "100%",
//           height: boxHeight || "auto",
//         }}
//       >
//         <div className="row p-2">
//           <div className="col-md-7">
//             <h6
//               className="p-2"
//               style={{
//                 color: "#1976d2",
//                 fontFamily: "Poppins, sans-serif",
//                 fontWeight: 600,
//                 fontSize: "14px",
//               }}
//             >
//               {chartHeading}
//             </h6>
//           </div>
//           <div className="col-md-5">
//           {isSelectDropdownShown && (
//               <select
//                 className="form-control"
//                 onChange={(e) => console.log("Selected value: ", e.target.value)}
//                 disabled={selectDropdownDisabled}
//                 style={{width:'120px', marginLeft: '100px', borderRadius:'10px', border: '1px solid #ccc', boxShadow: "1px 1px 4px -2px #000000",}}
//               >
//                 {barChartSelectOptions.map((option, index) => (
//                   <option key={index} value={option.value}>
//                     {option.label}
//                   </option>
//                 ))}
//               </select>
//             )}
//           </div>
//         </div>
//         <div style={{ borderBottom: "2px solid #ccc", margin: "0 8px" }}></div>
//         <div className="row" style={{marginTop:'15px'}}>
//           <div className="col-md-12">
//             <ResponsiveContainer width={580} height={200}>
//               <BarChart
//                 data={barChartData}
//                 margin={{ top: 15, right: 30, left: 20, bottom: 10 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <XAxis dataKey="day" />
//                 <YAxis />
//                 <Tooltip />
//                 <Legend
//                   wrapperStyle={container}
//                   payload={items}
//                 />
//                 {Object.keys(barChartData[0]).slice(1).map((category, index) => (
//                   <Bar
//                     key={index}
//                     dataKey={category}
//                     stackId="stack"
//                     fill={sectionColors[category] || "#000000"}
//                     barSize={30}
//                     isAnimationActive={false}
//                   />
//                 ))}
//               </BarChart>
//             </ResponsiveContainer>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

