
import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Grid, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { addresourceallocation, deleteresourceallocation, getresourceallocation, updateresourceallocation } from '../../services/resourceAllocationService';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { showToast } from '../../messages';

function ResourceAllocation({ open, handleClose,  assetId,updateResource }) {
  console.log(assetId,"assetIdassetId")
  const inputFieldStyle = { width: '100%' };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 10,
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '&:hover': {
      backgroundColor: 'green', // Very high intensity grey background on hover
    },
    '&.Mui-selected': {
      backgroundColor: '#E3F2FD !important', // Light blue background for selected row
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#bbdefb !important', // Light blue background for selected row on hover
    },
  }));
  
  
  

  const [latestResource, setLatestResource] = useState(null);
  // const [latestResource, setLatestResource] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileView = windowWidth < 768
  const handleRowClick = (data) => {
    console.log(data,"datadatadata")
    setSelectedRow(data);
  };

console.log(selectedRow,"setSelectedRow")


  const handleRowHover = (index) => {
    // Implement your hover effect here, e.g., add a class or update state
    // Example: console.log(`Hovered row index: ${index}`);
  };

  const handleRowHoverClear = () => {
    // Clear hover effect, e.g., remove a class or reset state
    // Example: console.log("Hover cleared");
  };



  const [formData, setFormData] = useState({
    resourceFullName: '',
    skillSetLevel: '',
    payPerHour: '',
    positionRole: '',
    contactInformation: '',
    yearOfExperience: '',
    educationLevel: '',
    customer_contractor_facility: '',
    domesticOrOverseas: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const isEmptyField = Object.values(formData).some(value => value === '');
      if (isEmptyField) {
        showToast('Warning.allFields')
        return;
      }
     
      const response = await addresourceallocation({
        formData,assetId
      });

      if (response?.status === 201) {
        showToast('Success.resourceAllocated')
        getResourcesAllocation();
        updateResource()

        setFormData({
          resourceFullName: '',
          skillSetLevel: '',
          payPerHour: '',
          positionRole: '',
          contactInformation: '',
          yearOfExperience: '',
          educationLevel: '',
          customer_contractor_facility: '',
          domesticOrOverseas: ''
        });
      }


      

      console.log("responseallocation", response);
    } catch (error) {
      console.error("Error resetting organization ID:", error);
      showToast('Error.orgIdReset')
    }
  };

  const getResourcesAllocation = async () => {
    try {
      if(assetId){
        let orgId = sessionStorage.getItem("organizationId");
        console.log(orgId,"ccccccccccccccc")
        const response = await getresourceallocation(assetId,orgId);
        setLatestResource(response.data);
      }
     else{
      console.log("assetId is required")
     }
    } catch (error) {
      console.error("Error fetching organization data:", error.message);
    }
  };

  useEffect(() => {
    getResourcesAllocation();
  }, [assetId]);



const handleDeleteAllocation = async() => {
  if (!selectedRow) {
    showToast('Organization.deleteResourceDetails')
    return;
  }
try {
  console.log(selectedRow,"selectedRowselectedRow")
  let id = selectedRow?._id
  const response = await deleteresourceallocation(id)
  console.log(response,"deleteresponse")
  if(response.status == 201){
    toast.success(response.message)
    getResourcesAllocation();
    setSelectedRow(null);
  }else if(response.status == 201){
    toast.warn(response.message)
  }
} catch (error) {
  
}
}



const handleOpenModalToUpdate = (row) => {
  if (!row) {
    showToast('Organization.editResourceDetails')
    return;
  }
  setSelectedRow(row);
  setFormData({
    resourceFullName: row.resourceFullName || '',
    skillSetLevel: row.skillSetLevel || '',
    payPerHour: row.payPerHour || '',
    positionRole: row.positionRole || '',
    contactInformation: row.contactInformation || '',
    yearOfExperience: row.yearOfExperience || '',
    educationLevel: row.educationLevel || '',
    customer_contractor_facility: row.customer_contractor_facility || '',
    domesticOrOverseas: row.domesticOrOverseas || '',
    createdBy: 'admin',
    modifiedBy: 'admin'
  });
  setOpenModal(true);
};



const handleCloseModal = () => {
  setOpenModal(false);
};

const handleUpdate = async () => {
  try {
    const updatedData = {
     
      resourceFullName: formData?.resourceFullName,
      skillSetLevel: formData?.skillSetLevel,
      payPerHour: formData?.payPerHour,
      positionRole: formData?.positionRole,
      contactInformation: formData?.contactInformation,
      yearOfExperience: formData?.yearOfExperience,
      educationLevel: formData?.educationLevel,
      customer_contractor_facility: formData?.customer_contractor_facility,
      domesticOrOverseas: formData?.domesticOrOverseas
    };



   let resourceId=selectedRow._id;
    const response = await updateresourceallocation({
      resourceId,
      ...updatedData,
    });

    console.log('Resource updated successfully:', response.data);
    getResourcesAllocation();
    handleCloseModal(); // Close the update modal/dialog
    setFormData({
      resourceFullName: '',
      skillSetLevel: '',
      payPerHour: '',
      positionRole: '',
      contactInformation: '',
      yearOfExperience: '',
      educationLevel: '',
      customer_contractor_facility: '',
      domesticOrOverseas: ''
    });
  } catch (error) {
    console.error('Error updating resource:', error.message);
  }
};


// const handleUpdate = async () => {
//   try {
//     const response = await updateresourceallocation(selectedRow._id, formData);
//     if (response.status === 200) {
//       toast.success("Resource updated successfully");
//       // Reload data or update state as needed
//       getResourcesAllocation();
//       handleCloseModal();
//     }
//   } catch (error) {
//     console.error("Error updating resource:", error);
//     toast.error("Failed to update resource");
//   }
// };



  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{ '& .MuiDialog-paper': { width: '1100px', maxWidth: 'none' } }}
    >
      <div style={{ width: "100%" }}>
        <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" style={{ color: '#1976d2', fontWeight: 'bold' }}>Resource Allocation</Typography>
          <div onClick={handleClose} style={{ color: 'red', fontWeight: "bold", marginBottom: "10px", fontSize: "20px", outline: "none", border: "none", paddingLeft: "20px", cursor: "pointer" }}><HighlightOffIcon /></div>
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: mobileView ? "column" : "row" }}>
          <Box style={{ width: mobileView ? "100%" : '95%', paddingRight: '10px' }}>
            <Grid container spacing={2} wrap="wrap">
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ width: '150px', fontWeight: 'bold' }}>Full Name:</Typography>
                  <TextField
                    name="resourceFullName"
                    value={formData.resourceFullName}
                    onChange={handleChange}
                    style={inputFieldStyle}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ width: '150px', fontWeight: 'bold' }}>Skill Set Level:</Typography>
                  <TextField
                    select
                    name="skillSetLevel"
                    value={formData.skillSetLevel}
                    onChange={handleChange}
                    style={inputFieldStyle}
                    variant="outlined"
                  >
                    <MenuItem value="Junior">Junior</MenuItem>
                    <MenuItem value="Mid">Mid</MenuItem>
                    <MenuItem value="Senior">Senior</MenuItem>
                  </TextField>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ width: '150px', fontWeight: 'bold' }}>Pay Per Hour:</Typography>
                  <TextField
                    name="payPerHour"
                    value={formData.payPerHour}
                    onChange={handleChange}
                    style={inputFieldStyle}
                    variant="outlined"
                    type="number" 
                    inputProps={{ min: 0 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ width: '150px', fontWeight: 'bold' }}>Position/Role:</Typography>
                  <TextField
                    name="positionRole"
                    value={formData.positionRole}
                    onChange={handleChange}
                    style={inputFieldStyle}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ width: '150px', fontWeight: 'bold' }}>Contact Information:</Typography>
                  <TextField
                    name="contactInformation"
                    value={formData.contactInformation}
                    onChange={handleChange}
                    style={inputFieldStyle}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ width: '150px', fontWeight: 'bold' }}>Years of Exp:</Typography>
                  <TextField
                    name="yearOfExperience"
                    value={formData.yearOfExperience}
                    onChange={handleChange}
                    style={inputFieldStyle}
                    variant="outlined"
                    type="number" 
                    inputProps={{ min: 0 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ width: '150px', fontWeight: 'bold' }}>Education Level:</Typography>
                  <TextField
                    name="educationLevel"
                    value={formData.educationLevel}
                    onChange={handleChange}
                    style={inputFieldStyle}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" style={{ width: '150px', fontWeight: 'bold' }}>Contractor/<br />Customer Facility:</Typography>
                  <TextField
                    name="customer_contractor_facility"
                    value={formData.customer_contractor_facility}
                    onChange={handleChange}
                    style={inputFieldStyle}
                    variant="outlined"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box display="flex" alignItems="center">
                <TextField
                  select
                  name="domesticOrOverseas"
                  label="Domestic/Overseas"
                  value={formData.domesticOrOverseas}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="Domestic">Domestic</MenuItem>
                  <MenuItem value="Overseas">Overseas</MenuItem>
                </TextField>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box style={{ width: mobileView ? "100%" : '5%', paddingLeft: mobileView ? "0px" : '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' , marginTop: mobileView ? "15px" : "0px"}}>
            <Button onClick={handleSubmit} color="primary" variant="contained" style={{ width: '100%' }}>Add</Button>
          </Box>
        </DialogContent>
      </div>

      <div style={{ width: "92%", display: "flex", justifyContent: "space-around" }}>
        <DialogActions style={{ width: "100%", marginLeft: "10px", display: "flex", justifyContent: "center" }}>
          <TableContainer component={Paper} style={{ maxHeight: '300px' }}>
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Sr. No.</StyledTableCell>
                  <StyledTableCell align="center">Resource Name</StyledTableCell>
                  <StyledTableCell align="center">Skill Level</StyledTableCell>
                  <StyledTableCell align="center">Hourly Pay</StyledTableCell>
                  <StyledTableCell align="center">Position/Role</StyledTableCell>
                  <StyledTableCell align="center">Contact Info</StyledTableCell>
                  <StyledTableCell align="center">Year of Exp</StyledTableCell>
                    <StyledTableCell align="center">Education Level</StyledTableCell>
                    <StyledTableCell align="center">Contractor/Customer</StyledTableCell>
                    <StyledTableCell align="center">Domestic/Overseas</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
  {latestResource?.map((row, index) => (
    <StyledTableRow
      key={index}
      onMouseEnter={() => handleRowHover(index)}
      onMouseLeave={handleRowHoverClear}
      onClick={() => handleRowClick(row)} // Assuming 'id' is the unique identifier for each row
      selected={selectedRow === row} // Control background color for selected row
      hover={true} // Enables hover effect
      sx={{ backgroundColor: selectedRow === row ? '#E3F2FD' : 'transparent' }} // Light blue background for selected row
    >
      <StyledTableCell align="center" component="th" scope="row">
        {index + 1}
      </StyledTableCell>
      <StyledTableCell align="center" component="th" scope="row">
        {row?.resourceFullName}
      </StyledTableCell>
      <StyledTableCell align="center">{row?.skillSetLevel}</StyledTableCell>
      <StyledTableCell align="center">{row?.payPerHour}</StyledTableCell>
      <StyledTableCell align="center">{row?.positionRole}</StyledTableCell>
      <StyledTableCell align="center">{row?.contactInformation}</StyledTableCell>
      <StyledTableCell align="center">{row?.yearOfExperience}</StyledTableCell>
      <StyledTableCell align="center">{row?.educationLevel}</StyledTableCell>
      <StyledTableCell align="center">{row?.customer_contractor_facility}</StyledTableCell>
      <StyledTableCell align="center">{row?.domesticOrOverseas}</StyledTableCell>
    </StyledTableRow>
  ))}
</TableBody>

              </Table>
            </TableContainer>
          {/* </Box> */}
        </DialogActions>
        <Box style={{ display: "flex", width: "7%", justifyContent: "space-around", padding: "10px", alignItems: "center" }}>
        <DeleteIcon onClick={handleDeleteAllocation} style={{ color: "red" }} />

        <EditIcon onClick={() => handleOpenModalToUpdate(selectedRow)} style={{ color: "green", marginLeft: "5px" }} />
        </Box>



        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
          <DialogTitle>Update Resource Allocation</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="resourceFullName"
                  label="Full Name"
                  value={formData.resourceFullName}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  name="skillSetLevel"
                  label="Skill Set Level"
                  value={formData.skillSetLevel}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="Junior">Junior</MenuItem>
                  <MenuItem value="Mid">Mid</MenuItem>
                  <MenuItem value="Senior">Senior</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="payPerHour"
                  label="Pay Per Hour"
                  value={formData.payPerHour}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="positionRole"
                  label="Position/Role"
                  value={formData.positionRole}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="contactInformation"
                  label="Contact Information"
                  value={formData.contactInformation}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="yearOfExperience"
                  label="Year of Experience"
                  value={formData.yearOfExperience}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="educationLevel"
                  label="Education Level"
                  value={formData.educationLevel}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="customer_contractor_facility"
                  label="Customer/Contractor Facility"
                  value={formData.customer_contractor_facility}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  name="domesticOrOverseas"
                  label="Domestic/Overseas"
                  value={formData.domesticOrOverseas}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value="Domestic">Domestic</MenuItem>
                  <MenuItem value="Overseas">Overseas</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdate} color="primary" variant="contained">Update</Button>
            <Button onClick={handleCloseModal} color="secondary" variant="contained">Cancel</Button>
          </DialogActions>
        </Dialog>

      </div>
    </Dialog>
  );
}

export default ResourceAllocation;






