import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
  CartesianGrid
} from "recharts";

const TaskCompletionChart = ({ chartData, chartHeading, sectionColors, boxHeight, boxWidth }) => {

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Project: ${label}`}</p>
          <p className="value">{`Complete: ${payload[0].value}`}</p>
          <p className="value">{`Pending: ${payload[1].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        border: "1px solid #ccc",
        boxShadow: "2px 2px 5px -2px #000000",
        padding: "2px",
        width: "100%" || boxWidth,
        height: "auto" || boxHeight,
        marginBottom: window.innerWidth<768?"15px":"25px",
        // overflowX:'auto'
      }}
    >
      <div className="row p-2">
        <div className="col-md-7">
          <h6
            className="p-2"
            style={{
              color: "#1976d2",
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            {chartHeading}
          </h6>
        </div>
      </div>
      <div style={{ borderBottom: "2px solid #ccc", margin: "0 8px" }}></div>
      <div className="row" style={{ marginTop: "15px" }}>
        <div className="col-md-12">
          <ResponsiveContainer width="100%" height={200}>
            <BarChart
              layout="horizontal"
              width={500}
              height={400}
              data={chartData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="projectTitle"
                type="category"
                tick={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  // angle: -90, 
                  // dy: 10, 
                  // dx: -5, 
                  // textAnchor: "end"
                }}
                tickFormatter={(projectTitle) => projectTitle.slice(0, 7 ) + "..."}
                label={{
                  value: 'Projects',
                  position: 'insideBottom',
                  offset: -5,
                }}
              />
              <YAxis type="number" label={{
                  value: 'Task Counts',
                  angle: -90,
                  position: 'insideLeft',
                  textAnchor: "middle", 
                  dy:50
                }}/>
              <Tooltip
                content={<CustomTooltip />}
                wrapperStyle={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc', width:'300px' }}
              />
              <Bar dataKey="Complete" fill={sectionColors.complete || "#82ca9d"}>
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} />
                ))}
              </Bar>
              <Bar dataKey="Pending" fill={sectionColors.pending || "#8884d8"}>
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TaskCompletionChart;
