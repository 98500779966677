import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  TextField,
  Button,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Paper,
  Menu,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import WindowIcon from "@mui/icons-material/Window";
import CircleIcon from '@mui/icons-material/Circle';
import RefreshIcon from "@mui/icons-material/Refresh";
import FolderIcon from "@mui/icons-material/Folder";
// import FolderIcon from '@mui/icons-material/FolderIcon';
import LensIcon from "@mui/icons-material/Lens";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DownloadIcon from "@mui/icons-material/Download";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { Link } from "react-router-dom";
import { DocumentGridCard, DocumentListCard } from "./MyDocumentsCards";
import documentService from "../services/document";
import { ListItemText } from "@material-ui/core";
import { toast } from "react-toastify";
import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import "./OpportunityManagement.css";
import RemoveIcon from "@mui/icons-material/Remove";
import SearchIcon from "@mui/icons-material/Search";
import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import opportunityService from "../services/opportunityService";
import federalorganizationsService from "../services/federalorganizationsService";
import MultiSelectDropdownWithSearch from "./ReusableComponents/MultiSelectDropDownMui";
import noticetypesService from "../services/noticetypesService";
import naicsService from "../services/naicsService";
import productnServiceCodeService from "../services/productnServiceCodeService";
import setasidesService from "../services/setasidesService";
import locationstateService from "../services/locationstateService";
import locationcountryService from "../services/locationcountryService";
import { limitList } from "../utils/pageLimit";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import organizationService from "../services/organizationService";
import "./FilterOpportunityinOrg.css";
import Loader from "../utils/loader";
import LZString from 'lz-string';
import ReactTooltip from "react-tooltip";
import CloseIcon from '@material-ui/icons/Close';
import { showToast } from "../messages";
let docList = [
  {
    _id: {
      $oid: "654b34066b055a3370d7b939",
    },
    documentName: "doc 1",
    tags: ["park", "car"],
    onlyDocumentContent:
      '{"blocks":[{"key":"82c9f","text":"Their going to the park theyre car is parked their.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    version: [],
    drafts: [],
    date: {
      $date: "2023-11-08T07:09:10.177Z",
    },
    activeDoc: false,
    comments: [],
    ratings: 0,
    totalRatings: 0,
    readOnly: false,
    isUpdated: false,
    ownerName: "",
    ownerDP: "",
    sharedWith: [],
    pendingDocuments: [],
    mergeDocuments: [],
    reviewStatus: null,
    reviewDate: "",
    isLatestDocument: true,
    mergeStatus: false,
    documentAudit: [],
    imageUrl: [],
    editor: {
      $oid: "654b2f6f6b055a3370d7b8f9",
    },
    documentType: "document",
    month: 11,
    year: 2023,
    status: "original",
    __v: 0,
    characterCount: 51,
    wordsCount: 10,
  },
  {
    _id: {
      $oid: "654b34066b055a3370d7b939",
    },
    documentName: "doc 1",
    tags: ["park", "car"],
    onlyDocumentContent:
      '{"blocks":[{"key":"82c9f","text":"Their going to the park theyre car is parked their.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}',
    version: [],
    drafts: [],
    date: {
      $date: "2023-11-08T07:09:10.177Z",
    },
    activeDoc: false,
    comments: [],
    ratings: 0,
    totalRatings: 0,
    readOnly: false,
    isUpdated: false,
    ownerName: "",
    ownerDP: "",
    sharedWith: [],
    pendingDocuments: [],
    mergeDocuments: [],
    reviewStatus: null,
    reviewDate: "",
    isLatestDocument: true,
    mergeStatus: false,
    documentAudit: [],
    imageUrl: [],
    editor: {
      $oid: "654b2f6f6b055a3370d7b8f9",
    },
    documentType: "document",
    month: 11,
    year: 2023,
    status: "original",
    __v: 0,
    characterCount: 51,
    wordsCount: 10,
  },
];

const FilterOpportunityinOrg = () => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [listView, setListView] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [downloadAnchorElDropdown, setDownloadAnchorElDropdown] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [myDocs, setMyDocs] = useState([]);
  const [deletedDocs, setDeletedDocs] = useState([]);
  const [sharedDocs, setSharedDocs] = useState([]);
  const [isFilterBlockVisible, setFilterBlockVisibility] = useState(true);
  const [isFedOrgFilter, setFedOrgFilter] = useState(false);
  const [isNoticeTypeFilter, setNoticeTypeFilter] = useState(false);
  const [isNaicsFilter, setNaicsFilter] = useState(false);
  const [isPronServiceFilter, setPronServiceFilter] = useState(false);
  const [isSetAsideFilter, setSetAsideFilter] = useState(false);
  const [isStateFilter, setStateFilter] = useState(false);
  const [isCountryFilter, setCountryFilter] = useState(false);
  const [isStatusFilter, setStatusFilter] = useState(false);
  const [initialMyDocs, setInitialMyDocs] = useState([]);
  const [initialDeletedDocs, setInitialDeletedDocs] = useState([]);
  const [initialSharedDocs, setInitialSharedDocs] = useState([]);
  const [opportunity, setOpportunity] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [fromPage, setFromPage] = useState(1);
  const [toPage, setToPage] = useState(25);
  const [results, setTotalReuslts] = useState();
  const [isFilterSideVisible, setIsFilterSideVisible] = useState(true);
  const [federalOrgData, setfederalOrgData] = useState([]);
  const [naicsData, setNaicsData] = useState([]);
  const [productnservicecodesData, setProductnservicecodesData] = useState([]);
  const [setasidesData, setsetasidesData] = useState([]);
  const [statesData, setstatesData] = useState([]);
  const [countriesData, setcountriesData] = useState([]);
  const [noticetypesData, setNoticetypesData] = useState([]);
  const [updateSpecificFields, setUpdateSpecificFields] = useState([]);
  const [selectFederal, setSelectFederal] = useState([]);
  const [value, setValue] = useState({});
  const [limitValue, setLimitValue] = useState(25);
  const [organizationData, setorganizationData] = useState(null);
  const [userdata, setuserdata] = useState(null);
  const [federalrefData, setfederalrefData] = useState(null);
  const [noticetypesrefData, setnoticetypesrefData] = useState(null);
  const [productnservicerefData, setproductnservicerefData] = useState(null);
  const [statesrefData, setstatesrefData] = useState(null);
  const [countriesrefData, setcountriesrefData] = useState(null);
  const [asidesetrefData, setasidesetrefData] = useState(null);
  const [naicsrefData, setnaicsrefData] = useState(null);
  const [formKey, setFormKey] = useState(0);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [percentage, setTotalPercentage] = useState(null);
  const [getData, setGetData] = useState([]);
  const [matchData, setMatchData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [onTimedata, setOntimeData] = useState([]);
  const [moderateDelayData, setModerateDelayData] = useState([]);
  const [severDelayData, setSevereDelayData] = useState([]);
  const [delayedDate, setDelayedData] = useState([]);
  const [noDateData, setNoDateData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [showRedirectButton, setShowRedirectButton] = useState(false)
  const [message, setMessage] = useState()
  // const [limitValue, setLimitValue] = useState(25);
  const email = sessionStorage.getItem("currentUserEmail");
  console.log(countriesrefData, "countriesrefData")
  console.log(noticetypesrefData, "noticetypesrefData")
  const fetchuserdata = async () => {
    try {
      let data = email;
      const response = await organizationService.getuserdata(data);
      setuserdata(response?.data?.organizationId);
    } catch (error) {
      console.error("Error fetching organization data:", error.message);
    }
  };

  useEffect(() => {
    fetchuserdata();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = userdata;
        const response = await organizationService.getOrganization(data);
        console.log("response_", response.data);
        const selectednaics = response?.data?.naicsref;
        // setnaicsrefData(selectednaics);
        const selectFederalOrg = response?.data?.federalorganizationsref;
        // setfederalrefData(selectFederalOrg);
        const selectNoticeType = response?.data?.noticetypesref;
        // setnoticetypesrefData(selectNoticeType);
        const selectProductnService = response?.data?.productnservicecodesref;
        // setproductnservicerefData(selectProductnService);
        const selectState = response?.data?.statesref;
        // setstatesrefData(selectState);
        const selectCountry = response?.data?.countriesref;
        // setcountriesrefData(selectCountry);
        const selectsetAside = response?.data?.setasidesref;
        // setasidesetrefData(selectsetAside);
        console.log(selectednaics, "selectednaicsselectednaics");
      } catch (error) {
        console.error("Error fetching organization data:", error.message);
      }
    };

    fetchData();
  }, [userdata, formKey]);

  console.log(opportunity, "oppor");

  const handleFilterSideClick = () => {
    setIsFilterSideVisible(!isFilterSideVisible); // Toggle visibility state
  };

  const handleFilterButtonClick = () => {
    setFilterBlockVisibility(!isFilterBlockVisible);
    setFormKey((prevKey) => prevKey + 1);
    setUpdateSuccess(true);
  };

  const handleFedOrgClick = () => {
    setFedOrgFilter(!isFedOrgFilter);
  };
  const handleNoticeTypeClick = () => {
    setNoticeTypeFilter(!isNoticeTypeFilter);
  };
  const handleNaicsClick = () => {
    setNaicsFilter(!isNaicsFilter);
  };
  const handlePronServiceClick = () => {
    setPronServiceFilter(!isPronServiceFilter);
  };
  const handleSetAsideClick = () => {
    setSetAsideFilter(!isSetAsideFilter);
  };
  const handleStateClick = () => {
    setStateFilter(!isStateFilter);
  };
  const handleCountryClick = () => {
    setCountryFilter(!isCountryFilter);
  };
  const handleStatusClick = () => {
    setStatusFilter(!isStatusFilter);
  };


  const handleNextPage = async () => {
    const page = currentPage + 1;
    setCurrentPage(page);
    // fetchData(page, limitValue); // Pass the updated page value to fetchData
  };

  console.log(currentPage);

  const handlePrevPage = () => {
    const page = currentPage - 1;
    setCurrentPage(page);
    // fetchData(page, limitValue);
  };

  const handlePageLimitChange = async (e) => {
    setLimitValue(e.target.value);
    setCurrentPage(1);
    // fetchData(1, e.target.value);
  };




  const fetchmatchingopportunity = async (currentPage, itemsPerPage) => {
    try {
      setLoading(true);


      let response = JSON.parse(LZString.decompressFromUTF16(sessionStorage.getItem("organizationOpportunities"))) || [];

      console.log(response, "organizationOpportunitiesPage");

      if (!response || response.length === 0) {
        setMessage("No Matching Found");
        setShowRedirectButton(true);
      } else {
        const opportunities = response?.map(item => ({
          opportunity: item.opportunity,
          matchedPercentage: item.matchedPercentage
        }));

        const startIdx = (currentPage - 1) * itemsPerPage;
        const endIdx = Math.min(startIdx + itemsPerPage, response?.length); // Ensure we don't go out of bounds

        //     // Slice the data to get current page records
        const currentRecords = response?.slice(startIdx, endIdx);
        //     console.log(currentRecords,"currentRecords")
        if (currentRecords.length === 0) {
          setMessage("No Matching Found");
          setShowRedirectButton(true);
        } else {
          const pageopportunities = currentRecords.map(item => ({
            opportunity: item.opportunity,
            matchedPercentage: item.matchedPercentage
          }));

          // setOpportunity(opportunities);

          setMatchData(opportunities);
          setOpportunity(pageopportunities);
          // setGetData(response?.getdata);
          setTotalReuslts(response?.length);
          setTotalPercentage(response?.matchedPercentage);
          setTotalPages(Math.ceil(response?.length / itemsPerPage)); // Update totalPages state

        }
      }
    } catch (error) {
      alert(error,"error")
      console.log(error);
    } finally {
      setLoading(false);
    }
    // }
  };






  useEffect(() => {
    fetchmatchingopportunity(currentPage, itemsPerPage);
  }, [currentPage, itemsPerPage]);

  console.log(noticetypesrefData, "noticetypesrefData")

  useEffect(() => {
    const categorizedData = matchData.map(data => {
      const { matchedPercentage, opportunity } = data;
      if (opportunity) {
        const today = new Date();
        const responseDate = new Date(opportunity.responseDeadLine);
        const timeRemaining = responseDate - today;
        const totalDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const percentageRemaining = (totalDays / 30) * 100;
        const categorizedOpportunity = { ...opportunity, matchedPercentage };

        if (opportunity.responseDeadLine) {
          if (totalDays <= 0) {
            return { ...data, opportunity: categorizedOpportunity, category: 'delayed' };
          } else if (percentageRemaining <= 33.33) {
            return { ...data, opportunity: categorizedOpportunity, category: 'severeDelay' };
          } else if (percentageRemaining <= 66.33) {
            return { ...data, opportunity: categorizedOpportunity, category: 'moderateDelay' };
          } else if (percentageRemaining > 66.33) {
            return { ...data, opportunity: categorizedOpportunity, category: 'ontime' };
          }
        } else {
          return { ...data, opportunity: categorizedOpportunity, category: 'noDate' };
        }
      }
      return data;
    });

    // Separate categorized data into different states as needed
    const delayedData = categorizedData.filter(item => item.category === 'delayed');
    const severeDelayData = categorizedData.filter(item => item.category === 'severeDelay');
    const moderateDelayData = categorizedData.filter(item => item.category === 'moderateDelay');
    const ontimeData = categorizedData.filter(item => item.category === 'ontime');
    const noDateData = categorizedData.filter(item => item.category === 'noDate');

    // Set state for each category
    setDelayedData(delayedData);
    setSevereDelayData(severeDelayData);
    setModerateDelayData(moderateDelayData);
    setOntimeData(ontimeData);
    setNoDateData(noDateData);
  }, [matchData]);
  console.log(onTimedata, "ontime")


  const updatePageRange = (currentPage, limitValue, totalResults) => {
    const from = (currentPage - 1) * limitValue + 1;
    const to = Math.min(currentPage * limitValue, totalResults);
    setFromPage(from);
    setToPage(to);
  };

  useEffect(() => {
    // fetchData(currentPage, limitValue); // Call fetchData with the initial currentPage value
  }, []);

  // Select all cards
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    setMyDocs((prevDocuments) => {
      return prevDocuments.map((doc) => ({
        ...doc,
        isChecked: isChecked,
      }));
    });


  };

  // Download pdf and word doc
  const handleDownloadDropdownClick = (event) => {
    setDownloadAnchorElDropdown(event.currentTarget);
  };

  const handleDownloadDropdownClose = () => {
    setDownloadAnchorElDropdown(null);
  };

  const handleWordDownload = () => {
    const selectedDocuments = documents.filter((docs) => docs.isChecked);

    if (selectedDocuments.length === 0) {
      showToast('Warning.selectDocDownload')
      return;
    }

    const wordDocContent = selectedDocuments
      .map((doc) => {
        const parsedJson = doc.onlyDocumentContent
          ? JSON.parse(doc.onlyDocumentContent)
          : { blocks: [{ text: "" }] };
        let description = parsedJson.blocks[0].text;
        return `${doc.documentName}<br/>${description}<br/>---------------------------------------------------<br/><br/>`;
      })
      .join("");

    asBlob(wordDocContent).then((docx) => {
      saveAs(docx, `Documents.docx`);
    });
  };
  const handlePDFDownload = () => {
    const selectedDocuments = documents.filter((docs) => docs.isChecked);

    if (selectedDocuments.length === 0) {
      showToast('Warning.selectDocDownload')
      return;
    }

    const pdfContent = selectedDocuments
      .map((doc) => {
        const parsedJson = doc.onlyDocumentContent
          ? JSON.parse(doc.onlyDocumentContent)
          : { blocks: [{ text: "" }] };
        let description = parsedJson.blocks[0].text;

        // Customize the HTML structure as needed
        return `<div><h5>${doc.documentName}</h5><p>${description}</p>---------------------------------------------------------</div>`;
      })
      .join("");

    const element = document.createElement("div");
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: "Documents.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }).then((pdf) => {
      saveAs(pdf, "Documents.pdf");
    });
  };
  const handleDropdownSelectforDownload = (selectedOption) => {
    console.log(selectedOption, "selected option");
    switch (selectedOption) {
      case "MS-Word":
        handleWordDownload();
        break;
      case "PDF":
        console.log("PDF");
        handlePDFDownload();
        break;
    }
    handleDownloadDropdownClose();
  };

  // Sorting documents
  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    const sortMyDocs = [...myDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc"
        ? titleA.localeCompare(titleB)
        : titleB.localeCompare(titleA);
    });
    const sortDeletedDocs = [...deletedDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc"
        ? titleA.localeCompare(titleB)
        : titleB.localeCompare(titleA);
    });
    const sortSharedDocs = [...sharedDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc"
        ? titleA.localeCompare(titleB)
        : titleB.localeCompare(titleA);
    });

    setMyDocs(sortMyDocs);
    setDeletedDocs(sortDeletedDocs);
    setSharedDocs(sortSharedDocs);
  };

  // Search Documents
  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value.toLowerCase();

    const filteredMyDocs = initialMyDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );
    const filteredDeletedDocs = initialDeletedDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );
    const filteredSharedDocs = initialSharedDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );

    setMyDocs(() => (newSearchTerm ? filteredMyDocs : initialMyDocs));
    setDeletedDocs(() =>
      newSearchTerm ? filteredDeletedDocs : initialDeletedDocs
    );
    setSharedDocs(() =>
      newSearchTerm ? filteredSharedDocs : initialSharedDocs
    );
  };

  // Individual Card Selection
  const handleMyDocsCardChange = (index) => {
    setMyDocs((prevDocuments) => {
      const newDocuments = [...prevDocuments];
      newDocuments[index].isChecked = !newDocuments[index].isChecked;
      return newDocuments;
    });
  };


  // API Calls for getting docs, deleted docs and shared docs
  const getDocs = async () => {
    setMyDocs(docList);
    setInitialMyDocs(docList);

  };

  const getDeletedDocs = async () => {
    try {
      const response = await documentService.getDeletedDocuments();
      if (response.status === 200) {
        setDeletedDocs(response.data);
        setInitialDeletedDocs(response.data);
      } else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const getSharedDocs = async () => {
    try {
      const response = await documentService.getSharedDocuments();
      if (response.status === 200) {
        setSharedDocs(response.data);
        setInitialSharedDocs(response.data);
      } else {
        console.error("Error fetching data:", response.message);
        setSharedDocs([]);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const getAllDocuments = () => {
    getDocs();
    getDeletedDocs();
    getSharedDocs();
  };

  useEffect(() => {
    getAllDocuments();
  }, []);

  useEffect(() => {
    setDocuments([...myDocs, ...deletedDocs, ...sharedDocs]);
  }, [myDocs, deletedDocs, sharedDocs]);

  const handleSearchOpportunity = async () => { };

  const getFederalorganization = async () => {
    try {
      const response = await federalorganizationsService.getfederalorgs();
      let federalorgResponse = response.allFederalOrgs;
      setfederalOrgData(federalorgResponse);
    } catch (error) {
      console.error("Error fetching federalorganizations data:", error.message);
      // setLoading(false);
    }
  };

  const getNoticetypes = async () => {
    try {
      const response = await noticetypesService.getnoticetypes();
      let noticetypesResponse = response.data;
      setNoticetypesData(noticetypesResponse);
    } catch (error) {
      console.error("Error fetching Noticetype data:", error.message);
      // setLoading(false);
    }
  };

  const getNaics = async () => {
    try {
      const response = await naicsService.getnaics();
      let naicsResponse = response.allnaics;
      setNaicsData(naicsResponse);
    } catch (error) {
      console.error("Error fetching Naic code data:", error.message);
      // setLoading(false);
    }
  };

  const getProductnServicecode = async () => {
    try {
      const response =
        await productnServiceCodeService.getproductnServiceCode();
      let productnServicecodeResponse = response.checkProduct;
      setProductnservicecodesData(productnServicecodeResponse);
    } catch (error) {
      console.error(
        "Error fetching product & service  code data:",
        error.message
      );
      // setLoading(false);
    }
  };

  const getSetasides = async () => {
    try {
      const response = await setasidesService.getsetAsides();
      let setAsidesResponse = response.checksetAsides;
      setsetasidesData(setAsidesResponse);
    } catch (error) {
      console.error("Error fetching setAside data:", error.message);
      // setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const response = await locationstateService.getlocationStates();
      let stateResponse = response.data;
      setstatesData(stateResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
      // setLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      const response = await locationcountryService.getcountry();
      let countryResponse = response.checkcountry;
      setcountriesData(countryResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
      // setLoading(false);
    }
  };

  const handleFederalOrganizationsChange = async (selectedOptions) => {
    const federal = selectedOptions.map(k => k.fullParentPathName);
    console.log(federal, "federal")
    // Filter opportunities based on the first part of fullParentPathName matching with federal
    const filteredOpportunities = matchData.filter(op => {
      const firstPart = op.opportunity?.fullParentPathName?.split('.')[0];
      return federal.includes(firstPart);
    });

    console.log(filteredOpportunities, "filteredOpportunities");

    setOpportunity(filteredOpportunities);
    setTotalReuslts(filteredOpportunities?.length);
    setTotalPages(filteredOpportunities?.length / 25);
  }



  const handleclickfederalorg = async () => {
    // alert("fedorgalert");
    console.log(federalrefData, "federalrefDataData__");
    const fedOrg = federalrefData.map((k) => k.fullParentPathName);
    console.log(fedOrg, "feddd");
    const data = {
      type: "federalOrganization",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponsessssss");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleclicknoticetypes = async () => {
    // alert("noticealert");
    console.log(noticetypesrefData, "noticetypesrefData__");
    const fedOrg = noticetypesrefData.map((k) => k.value);
    console.log(fedOrg, "feddd");
    const data = {
      type: "noticeType",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };
  const handleclicknaics = async () => {
    // alert("noticealert")
    console.log(naicsrefData, "naicsrefDataData__");
    const fedOrg = naicsrefData.map((k) => k.naicsCode);
    console.log(fedOrg, "feddd");
    const data = {
      type: "naics",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleclickpns = async () => {
    console.log(productnservicecodesData, "pnsData__");
    const fedOrg = productnservicerefData.map((k) => k.pscCode);
    console.log(fedOrg, "feddd");
    const data = {
      type: "productServiceCodes",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };
  const handleclicksetaside = async () => {
    console.log(setasidesData, "pnsData__");
    const fedOrg = asidesetrefData.map((k) => k.setAsideCode);
    console.log(fedOrg, "feddd");
    const data = {
      type: "setAsides",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleclickstates = async () => {
    console.log(statesrefData, "pnsData__");
    const fedOrg = statesrefData.map((k) => k.stateCode);
    console.log(fedOrg, "feddd");
    const data = {
      type: "states",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleclickcountries = async () => {
    console.log(countriesrefData, "pnsData__");
    const fedOrg = countriesrefData.map((k) => k.countrycode);
    console.log(fedOrg, "feddd");
    const data = {
      type: "countries",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNoticeTypeChange = async (selectedOptions) => {
    console.log(selectedOptions, "options")
    console.log(matchData, "matchData")
    const notice = selectedOptions?.map((k) => k.value);

    const response = matchData?.filter(k => notice.includes(k.opportunity.type))
    console.log(response, "two")
    setFilterData(response)
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = Math.min(startIdx + itemsPerPage, response?.length); // Ensure we don't go out of bounds

    //     // Slice the data to get current page records
    const currentRecords = response?.slice(startIdx, endIdx);
    console.log(currentRecords, "currentRecords")
    const pageopportunities = currentRecords?.map(item => ({
      opportunity: item.opportunity,
      matchedPercentage: item.matchedPercentage
    }));
    console.log(pageopportunities, "pageopportunities")
    setOpportunity(pageopportunities)
    setTotalReuslts(response.length)
    setTotalPages(Math.ceil(response?.length / 25));
  }




  const handleNaicsTypeChange = async (selectedOptions) => {
    console.log(selectedOptions, "selectedOptions")
    const codes = selectedOptions.map(k => k.naicsCode)
    console.log(codes, "codes")
    const response = matchData?.filter(k => codes.includes(k.opportunity.naicsCode));
    console.log(response, "two")
    setFilterData(response)
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = Math.min(startIdx + itemsPerPage, response?.data?.length); // Ensure we don't go out of bounds

    //     // Slice the data to get current page records
    const currentRecords = response?.data?.slice(startIdx, endIdx);
    const pageopportunities = currentRecords?.map(item => ({
      opportunity: item.opportunity,
      matchedPercentage: item.matchedPercentage
    }));
    setOpportunity(pageopportunities)
    setTotalReuslts(response.length)
    setTotalPages(response?.length / 25);
  }


  const handleProductnSericeTypeChange = async (selectedOptions) => {
    const pns = selectedOptions.map((k) => k.pscCode);
    console.log(pns, "pns");

    // Filter opportunities based on classification code matching pns
    const filteredOpportunities = matchData.filter(op => pns.includes(op.opportunity.classificationCode));

    console.log(filteredOpportunities, "filteredOpportunities");
    setFilterData(filteredOpportunities)
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = Math.min(startIdx + itemsPerPage, filteredOpportunities?.data?.length); // Ensure we don't go out of bounds

    //     // Slice the data to get current page records
    const currentRecords = filteredOpportunities?.data?.slice(startIdx, endIdx);
    const pageopportunities = currentRecords?.map(item => ({
      opportunity: item.opportunity,
      matchedPercentage: item.matchedPercentage
    }));
    setOpportunity(pageopportunities);
    setTotalReuslts(filteredOpportunities.length);
    setTotalPages(filteredOpportunities?.length / 25);
  }


  const handleSetAsideTypeChange = async (selectedOptions) => {
    const fedOrg = selectedOptions.map((k) => k.setAsideCode);
    const response = matchData?.filter(k => fedOrg.includes(k.opportunity.typeOfSetAside));
    console.log(response, "setasidetwo")
    setFilterData(response)
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = Math.min(startIdx + itemsPerPage, response?.data?.length); // Ensure we don't go out of bounds

    //     // Slice the data to get current page records
    const currentRecords = response?.data?.slice(startIdx, endIdx);
    const pageopportunities = currentRecords?.map(item => ({
      opportunity: item.opportunity,
      matchedPercentage: item.matchedPercentage
    }));
    setOpportunity(pageopportunities)
    setTotalReuslts(response?.length)
    setTotalPages(response?.length / 25);
  }



  const handleStateTypeChange = async (selectedOptions) => {
    const fedOrg = selectedOptions.map((k) => k.stateCode);
    console.log(fedOrg, "feddd");
    console.log(selectedOptions, "selectedoptions__");
    const data = {
      type: "states",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountryTypeChange = async (selectedOptions) => {
    const fedOrg = selectedOptions.map((k) => k.countrycode);
    console.log(fedOrg, "feddd");
    console.log(selectedOptions, "selectedoptions__");
    const data = {
      type: "countries",
      fedOrg,
      currentPage: currentPage,
      itemsPerPage: limitValue,
    };
    console.log(data, "data*");

    try {
      // Use the updated data directly
      const response = await opportunityService.searchOpportunityrefOrg(data);
      console.log(response, "opportunityResponse");
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(currentPage, limitValue, response?.total);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusFilterChange = async (e) => {
    // Determine the current checked state of each checkbox
    const isActive = document.getElementById("activeCheckbox").checked;
    const isInactive = document.getElementById("inactiveCheckbox").checked;

    console.log(isActive, isInactive, "activestatus");

    let status = "";

    if (isActive && isInactive) {
      status = "both"; // Both checkboxes checked
    } else if (isActive) {
      status = "Yes"; // Only active checkbox checked
    } else if (isInactive) {
      status = "No"; // Only inactive checkbox checked
    } else {
      status = "none"; // None of the checkboxes checked
    }

    const data = {
      type: "status",
      status,
      currentPage: 1,
      itemsPerPage: limitValue,
    };

    try {
      const response = await opportunityService.searchOpportunity(data);
      setOpportunity(response?.data);
      setTotalPage(response?.totalPages);
      setTotalReuslts(response?.total);
      updatePageRange(1, limitValue, response?.total); // Always reset currentPage to 1 when filter changes
    } catch (error) {
      console.log(error);
    }
  };

  console.log(updateSpecificFields, "specificFields");
  useEffect(() => {
    // fetchuserdata();
    getFederalorganization();
    getNaics();
    getNoticetypes();
    getProductnServicecode();
    getSetasides();
    getStates();
    getCountries();
  }, []);

  const handleClick = (status) => {
    alert(status)
    let opportunities = [];
    switch (status) {
      case 'onTime':
        opportunities = onTimedata;
        break;
      case 'moderateDelay':
        opportunities = moderateDelayData;
        break;
      case 'severeDelay':
        opportunities = severDelayData;
        break;
      case 'delayed':
        opportunities = delayedDate;
        break;
      case 'noDate':
        opportunities = noDateData;
        break;
      default:
        break;
    }
    setOpportunity(opportunities);
    setTotalReuslts(opportunities.length)
  };

  const colorInfo = {
    red: "Delayed",
    orange: "Moderately delayed",
    green: "On time",
    grey: "Response date not available",
    black: "Ended",
  };


  const getDataOrganization = async (req, res) => {
    const sessionOrganizationData = JSON.parse(sessionStorage.getItem("organizationData"));
    if (sessionOrganizationData)
      try {
        console.log(sessionOrganizationData, "getData")
        // const fedData=getData?.federalorganizationsref
        setfederalrefData(sessionOrganizationData?.federalorganizationsref)
        // const naicsData=getData?.naicsref
        setnaicsrefData(sessionOrganizationData?.naicsref)
        // const noticeData=getData?.noticetypesref
        // console.log(noticeData,"noticedata")
        setnoticetypesrefData(sessionOrganizationData?.noticetypesref)
        // const setAside=getData?.setasidesref
        setasidesetrefData(sessionOrganizationData?.setasidesref)
        setcountriesrefData(sessionOrganizationData?.countriesref)
        setstatesrefData(sessionOrganizationData?.statesref)
        const pnsData = getData?.productnservicecodesref
        setproductnservicerefData(sessionOrganizationData?.productnservicecodesref)
        // console.log(noticeData,"noticeData")
      } catch (error) {
        console.log(error)
      }

  }

  useEffect(() => {
    getDataOrganization();
  }, [])

  console.log(noticetypesrefData, "notice")
  console.log(federalrefData, "federal")

  const handleRefresh = async (req, res) => {
    try {
      setSortBy('')
      fetchmatchingopportunity(currentPage, itemsPerPage);
    } catch (error) {
      console.log(error);
    }
  }
  const [focusedDiv, setFocusedDiv] = useState(null);
  const getDivStyle = (options, isFocused) => {
    return {
      // backgroundColor: options && options.length > 0 ? "#B0E0E6" : "none",
      // border: options && options.length > 0 ? "2px solid black" : "none",
      // borderRadius: "6px",
      border: isFocused ? "none" : (options && options.length > 0 ? "2px solid black" : "none"),
    };
  };

  const handleFocus = (div) => {
    setFocusedDiv(div);
  };

  const handleBlur = () => {
    setFocusedDiv(null);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileView = windowWidth < 960

  const contentStyle = {
    flex: `0 0 ${mobileView ? '100%' : (isFilterSideVisible ? '66.6667%' : '100%')}`, // Full width on mobile view
    maxWidth: mobileView ? '100%' : (isFilterSideVisible ? '66.6667%' : '100%'),
    padding: '8px', // Adjust this to match Grid spacing
    // border: "1px solid green"
  };
  const [popupOpen, setPopupOpen] = useState(false);

  const handlePopupToggle = () => {
    setPopupOpen(!popupOpen);
  };


  const filterSideContent = (
    <div
      // className={`popup-container ${isFilterSideVisible ? 'popup-enter-active' : 'popup-exit-active'}`}
      style={{
        flex: `0 0 ${isFilterSideVisible ? '33.3333%' : '0'}`, // 4 columns on md breakpoint
        maxWidth: isFilterSideVisible ? (mobileView ? '100%' : '33.3333%') : '0',
        position: mobileView ? 'relative' : 'sticky',
        top: '0',
        maxHeight: 'calc(100vh - 74px)',
        overflowY: 'auto',
        display: isFilterSideVisible ? 'block' : 'none',
        '-ms-overflow-style': 'none', /* IE and Edge */
        scrollbarWidth: 'none', /* Firefox */
      }}
    >
      <Paper elevation={3} style={{ padding: '1rem', }}>


        {isFilterBlockVisible && (
          <>
            <>
              {mobileView && <CloseIcon onClick={() => handleFilterSideClick()} />}
              <div
                className="mt-2 mb-2"
                style={getDivStyle(federalrefData, focusedDiv === "federal")}
                onFocus={() => handleFocus("federal")}
                onBlur={handleBlur}
                tabIndex="0"
              >
                <MultiSelectDropdownWithSearch
                  optionList={federalrefData}
                  onChange={handleFederalOrganizationsChange}
                  labelKey="name"
                  label={
                    <span
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      Federal Organizations
                    </span>
                  }
                  fullWidth
                />
              </div>
            </>

            <>
              <div
                className="mt-2 mb-2"
                style={getDivStyle(noticetypesrefData, focusedDiv === "noticeType")}
                onFocus={() => handleFocus("noticeType")}
                onBlur={handleBlur}
              >
                <MultiSelectDropdownWithSearch
                  optionList={noticetypesrefData}
                  onChange={handleNoticeTypeChange}
                  labelKey="value"
                  label={
                    <span
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      Notice Type
                    </span>
                  }
                  fullWidth
                />
              </div>
            </>

            <>
              <div
                className="mt-2 mb-2"
                style={getDivStyle(naicsrefData, focusedDiv === "NAICS")}
                onFocus={() => handleFocus("NAICS")}
                onBlur={handleBlur}
              >
                <MultiSelectDropdownWithSearch
                  optionList={naicsrefData}
                  onChange={handleNaicsTypeChange}
                  labelKey="newconcatenate"
                  label={
                    <span
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      NAICS
                    </span>
                  }
                  fullWidth
                />
              </div>
            </>

            <>
              <div className="mt-2 mb-2"
                style={getDivStyle(productnservicerefData, focusedDiv === "productserveice")}
                onFocus={() => handleFocus("productserveice")}
                onBlur={handleBlur}
              >
                <MultiSelectDropdownWithSearch
                  optionList={productnservicerefData}
                  onChange={handleProductnSericeTypeChange}
                  labelKey="newconcatenate"
                  label={
                    <span
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      Product & Service Code
                    </span>
                  }
                  fullWidth
                />
              </div>
            </>

            <>
              <div className="mt-2 mb-2"
                style={getDivStyle(asidesetrefData, focusedDiv === "setAsideName")}
                onFocus={() => handleFocus("setAsideName")}
                onBlur={handleBlur}
              >
                <MultiSelectDropdownWithSearch
                  optionList={asidesetrefData}
                  onChange={handleSetAsideTypeChange}
                  labelKey="setAsideName"
                  label={
                    <span
                      style={{ color: "black", fontWeight: "bold" }}
                    >
                      Set Aside
                    </span>
                  }
                  fullWidth
                />
              </div>
            </>

            <div
              className={statesrefData?.length > 0 ? "border  selected_filterBlock" : "border  filterBlock"}
              onClick={handleStateClick}
            >
              <p
                className="filterBlockp1"
                onClick={handleclickstates}
              >
                State
              </p>
              <p
                className="filterBlockp2"
              >
                {!isStateFilter ? (
                  <AddIcon
                    className={statesrefData?.length > 0 ? "sel_addIcon" : "addIcon"}
                  />
                ) : (
                  <RemoveIcon
                    className={statesrefData?.length > 0 ? "sel_addIcon" : "addIcon"}
                  />
                )}
              </p>
            </div>
            {isStateFilter && (
              <>
                <div className="mt-2 mb-2">
                  <MultiSelectDropdownWithSearch
                    optionList={statesrefData}
                    onChange={handleStateTypeChange}
                    labelKey="state"
                    label={
                      <span
                        style={{ color: "black", fontWeight: "bold" }}
                      >
                        State
                      </span>
                    }
                    fullWidth
                  />
                </div>
              </>
            )}

            <div
              className={countriesrefData?.length > 0 ? "border  selected_filterBlock" : "border  filterBlock"}
              onClick={handleCountryClick}
            >
              <p
                // onClick={handleCountryClick}
                className="filterBlockp1"
              >
                Country
              </p>
              <p
                className="filterBlockp2"
              >
                {!isCountryFilter ? (
                  <AddIcon
                    className={countriesrefData?.length > 0 ? "sel_addIcon" : "addIcon"}
                  />
                ) : (
                  <RemoveIcon
                    className={countriesrefData?.length > 0 ? "sel_addIcon" : "addIcon"}
                  />
                )}
              </p>
            </div>
            {isCountryFilter && (
              <>
                <div className="mt-2 mb-2">
                  <MultiSelectDropdownWithSearch
                    optionList={countriesrefData}
                    onChange={handleCountryTypeChange}
                    labelKey="country"
                    label={
                      <span
                        style={{ color: "black", fontWeight: "bold" }}
                      >
                        Country
                      </span>
                    }
                    fullWidth
                  />
                </div>
              </>
            )}

            <div
              className="border bg-white  filterBlock"
              onClick={handleStatusClick}
            >
              <p
                className="filterBlockp1"
              >
                Status
              </p>
              <p
                className="filterBlockp2"
              >
                {!isStatusFilter ? (
                  <AddIcon
                    className="addIcon"
                  />
                ) : (
                  <RemoveIcon
                    className="addIcon"

                  />
                )}
              </p>
            </div>
            {isStatusFilter && (
              <>
                <input
                  type="checkbox"
                  id="activeCheckbox"
                  name="active"
                  value="active"
                  onChange={handleStatusFilterChange}
                />
                <label htmlFor="activeCheckbox">Active</label>
                <br />
                <input
                  type="checkbox"
                  id="inactiveCheckbox"
                  name="inactive"
                  value="inactive"
                  onChange={handleStatusFilterChange}
                />
                <label htmlFor="inactiveCheckbox">Inactive</label>
              </>
            )}
          </>
        )}
      </Paper>
    </div>
  )
  const dropdownRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [sortBy, setSortBy] = useState('');

  // useEffect(() => {
  //   if (sortBy) {
  //     updateCategoryStates(opportunity);
  //   }
  // }, [sortBy]);

  const handleSortChange = (event) => {


    const criterion = event.target.value;
    // alert("criterion",criterion)
    setSortBy(criterion);
    updateCategoryStates(opportunity,criterion);
  };

  const updateCategoryStates = (opportunities, criterion) => {
    // alert(sortBy,"sortBy")
    console.log(sortBy,opportunities, "Before sorting");

    let sortedOpportunities = opportunities;
    if (criterion === 'title') {
      // alert("updateCategoryStates")
      sortedOpportunities = sortOpportunitiesByTitle(opportunities);
    }
    else if (criterion === 'publishDate') {
      sortedOpportunities = sortOpportunitiesByPublishDate(opportunities);
    }

    else if (criterion === 'responseDate') {
      sortedOpportunities = sortOpportunitiesByResponseDate(opportunities);
    }

    else if (criterion === 'department') {
      sortedOpportunities = sortOpportunitiesByDepartment(opportunities);
    }

    else if (criterion === 'subtier') {
      sortedOpportunities = sortOpportunitiesBySubtier(opportunities);
    }


    else if (criterion === 'office') {
      sortedOpportunities = sortOpportunitiesByOffice(opportunities);
    }


    else if (criterion === 'noticeType') {
      sortedOpportunities = sortOpportunitiesBynoticeType(opportunities);
    }



    console.log(sortedOpportunities, "After sorting");
    setOpportunity(sortedOpportunities)
  };

  const handleIconClick = () => {
    setDropdownVisible(!dropdownVisible);
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);



  const sortOpportunitiesByTitle = (opportunities) => {
    console.log("sortOpportunitiesByTitle called with opportunities:", opportunities);
    // alert("sortOpportunitiesByTitle")
    return opportunities?.sort((a, b) => {
      console.log(a.opportunity.title,"__title")
      const titleA = a.opportunity.title || ''; // Default to empty string if title is not available
      const titleB = b.opportunity.title || ''; // Default to empty string if title is not available
      console.log(titleA.localeCompare(titleB), "checksort")
      return titleA.localeCompare(titleB);
    });
  };

  const sortOpportunitiesByPublishDate = (opportunities) => {
    console.log("sortOpportunitiesByPublishDate called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      console.log(a.opportunity.postedDate,"__postedDate")
      const dateA = new Date(a.opportunity.postedDate);
      const dateB = new Date(b.opportunity.postedDate);
      if (dateA - dateB) {
        console.log(dateA - dateB, "sortDate")
        return dateA - dateB;
      }
    });
  };


  const sortOpportunitiesByResponseDate = (opportunities) => {
    console.log("sortOpportunitiesByResponseDate called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      console.log(a.opportunity.responseDeadLine,"__responseDeadLine")
      const dateA = new Date(a.opportunity.responseDeadLine);
      const dateB = new Date(b.opportunity.responseDeadLine);
      if (dateA - dateB) {
        return dateA - dateB;
      }
    });
  };


  const sortOpportunitiesByDepartment = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      console.log(a.opportunity.fullParentPathName,"__fullParentPathName")
      const deptA = (a.opportunity.fullParentPathName?.split(".")[0]);
      const deptB = (b.opportunity.fullParentPathName?.split(".")[0]);
      // console.log(dateA,dateB,"dateBdateB")
      if (deptA && deptB) {

        return deptA.localeCompare(deptB)
      }
      // return dateA - dateB;
    });
  };


  const sortOpportunitiesBySubtier = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      console.log(a.opportunity.fullParentPathName,"__fullParentPathName")
      const subtierA = (a.opportunity.fullParentPathName?.split(".")[1]);
      const subtierB = (b.opportunity.fullParentPathName?.split(".")[1]);
      // console.log(dateA,dateB,"dateBdateB")
      if (subtierA && subtierB) {

        return subtierA.localeCompare(subtierB)
      }
      // return dateA - dateB;
    });
  };


  const sortOpportunitiesByOffice = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      console.log(a.opportunity.fullParentPathName, "fullParentPathName");
      let officeA
      let officeB
      if (a?.opportunity.fullParentPathName && b?.opportunity.fullParentPathName) {
        const splitA = a.opportunity.fullParentPathName.split(".");
        const splitB = b.opportunity.fullParentPathName.split(".");

        officeA = splitA[splitA.length - 1];
        officeB = splitB[splitB.length - 1];

        console.log(officeA, officeB, "dateBdateB");
      }



      if (officeA && officeB) {
        return officeA.localeCompare(officeB)
      }
      // return dateA - dateB;
    });
  };


  const sortOpportunitiesBynoticeType = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities?.sort((a, b) => {
      const noticeTypeA = (a.opportunity.type);
      const noticeTypeB = (b.opportunity.type);
      // console.log(dateA,dateB,"dateBdateB")
      if (noticeTypeA && noticeTypeB) {

        return noticeTypeA.localeCompare(noticeTypeB)
      }
      // return dateA - dateB;
    });
  };




  return (
    <>
      <div style={{ paddingLeft: mobileView < 960 ? "10px" : "0px", width: "100%" }}>
        <Loader loading={loading} />

        <div >
          {matchData?.length > 0 ? (
            <div style={{ display: 'flex', justifyContent: "space-between", gap: '16px', flexDirection: mobileView ? 'column' : 'row' }}>
              {isFilterSideVisible && (
                <>
                  {!mobileView ? (
                    filterSideContent
                  ) : (
                    <div className={`sliding-div ${isFilterSideVisible ? 'open' : ''}`} style={{ width: windowWidth < 600 ? "80%" : "60%" }}>
                      {filterSideContent}
                    </div>
                  )}
                </>

              )}
              <div style={contentStyle}>
                <Paper elevation={3}>

                  <div className="row">
                    <div className="col-md-7 mt-2">
                      <IconButton
                        style={{ outline: "none" }}
                        onClick={handleFilterSideClick}
                        data-tip data-for="filter"
                      >
                        <FilterAltIcon style={{ color: "green" }} />
                      </IconButton>

                    </div>
                    <ReactTooltip id="filter" place="bottom" effect="solid">
                      Filter
                    </ReactTooltip>
                    {/* {mobileView &&
                        <div style={{ width: "40%", justifyContent: "start" }}>
                          <p>Items Per Page</p>
                          <div style={{ marginTop: "-10px", width: "100px" }}>
                            <SelectDropdownMui
                              optionsList={limitList}
                              label={limitValue}
                              onChange={handlePageLimitChange}
                              value={limitValue}
                            />
                          </div>
                        </div>
                      } */}
                    <div className="col-md-5 d-flex justify-content-end">
                      <IconButton style={{ outline: 'none' }} title="Refresh" onClick={handleRefresh}>
                        <RefreshIcon />
                      </IconButton>
                      {windowWidth >= 768 && (
                      <IconButton style={{ outline: "none" }}>
                        { listView ? (
                          <div title="Grid View">
                            <WindowIcon
                              style={{ color: "#87CEEB" }}
                              onClick={() => setListView(false)}
                            />
                          </div>
                        ) : (
                          <div title="List View">
                            <TableRowsIcon
                              style={{ color: "#87CEEB" }}
                              onClick={() => setListView(true)}
                            />
                          </div>
                        )}
                      </IconButton>
                        )}
                      {/* <IconButton style={{ outline: "none" }}>
                        <div title="Sort">
                          <SortByAlphaIcon
                            style={{ color: "purple" }}
                            onClick={() => handleSortClick()}
                          />
                        </div>
                      </IconButton> */}
                      <IconButton style={{ border: "none", outline: "none" }}>
                        <div title="Sort">

                          {!dropdownVisible && ( // Conditionally render the icon
                            <SortByAlphaIcon onClick={handleIconClick} style={{ cursor: 'pointer', border: "none", outline: "none" }} />
                          )}
                          {/* <SortByAlphaIcon onClick={handleIconClick} style={{ cursor: 'pointer' }} /> */}
                          {dropdownVisible && (
                            <div style={{ width: "150px", height: "25px", fontSize: "12px", outline: "none" }}>
                              <select style={{ width: "100%", height: "100%", outline: "none", border: "none" }} id="sortSelect" ref={dropdownRef} value={sortBy} onChange={handleSortChange}>
                                <option value="">Select sorting criteria</option>
                                <option value="title">Title</option>
                                <option value="publishDate">Publish Date</option>
                                <option value="responseDate">Response Date</option>
                                <option value="department">Department </option>
                                <option value="subtier">Subtier </option>
                                <option value="office">Office </option>
                                <option value="noticeType">Notice Type </option>
                              </select>
                            </div>
                          )}

                        </div>
                      </IconButton>

                      <Menu
                        anchorEl={downloadAnchorElDropdown}
                        open={Boolean(downloadAnchorElDropdown)}
                        onClose={handleDownloadDropdownClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem
                          onClick={() => handleDropdownSelectforDownload("MS-Word")}
                        >
                          <ListItemText primary="MS-Word" />
                        </MenuItem>

                        <MenuItem
                          onClick={() => handleDropdownSelectforDownload("PDF")}
                        >
                          <ListItemText primary="PDF" />
                        </MenuItem>
                      </Menu>
                    </div>

                  </div>

                  <div className="matching_header" style={{ padding: "15px" }}>
                    <div className="matching_header_left">
                      <hr />
                      <p>
                        Showing {results} results
                      </p>
                    </div>

                    <div style={{ display: 'flex', gap: '7px', marginLeft: '20px' }}>
                      {Object.entries(colorInfo).map(([color, text]) => (
                        <span key={color}>
                          <CircleIcon data-tip data-for={color} style={{ color: color }} />
                          <ReactTooltip id={color} effect="solid" place="bottom">
                            <p>{text}</p>
                          </ReactTooltip>
                        </span>
                      ))}

                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    {opportunity ? <>
                      <div className="row"
                        style={{
                          overflowY: 'auto',
                          maxHeight: 'calc(100vh - 150px)',
                          width: "99%",
                        }}
                      >
                        {opportunity.map((document, index) => {
                          const parsedJson = document.onlyDocumentContent
                            ? JSON.parse(document.onlyDocumentContent)
                            : { blocks: [{ text: "" }] };
                          let description = parsedJson.blocks[0].text;
                          console.log(document?.active, "documents");
                          return (
                            <div
                              style={{
                                width: listView ? "100%" : "50%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              key={index}
                            >
                              {listView ? (
                                <DocumentListCard
                                  title={document.opportunity.title}
                                  description={document.opportunity.descriptionData}
                                  noticeId={document.opportunity.solicitationNumber}
                                  department={document.opportunity.fullParentPathName}
                                  subtier={document.opportunity.type}
                                  office={document?.opportunity.officeAddress}
                                  date={document?.opportunity.postedDate}
                                  date1={document?.opportunity.responseDeadLine}
                                  status={document?.opportunity.active}
                                  viewType="filterScreen"
                                  percentage={document?.matchedPercentage}
                                  id={document?.opportunity._id}
                                  // cardChecked={document.isChecked || false}
                                  documentId={document.opportunity._id}
                                  onCardCheckboxChange={() =>
                                    handleMyDocsCardChange(index)
                                  }
                                  getAllDocuments={getAllDocuments}
                                  type={"MyDocuments"}
                                />
                              ) : (
                                <DocumentGridCard
                                  title={document.opportunity.title}
                                  description={document.descriptionData}
                                  noticeId={document.opportunity.solicitationNumber}
                                  department={document.opportunity.fullParentPathName}
                                  subtier={document.opportunity.type}
                                  office={document?.opportunity.officeAddress}
                                  date={document?.opportunity.postedDate}
                                  date1={document?.opportunity.responseDeadLine}
                                  status={document?.opportunity.active}
                                  viewType="filterScreen"
                                  percentage={document?.matchedPercentage}
                                  id={document?.opportunity._id}
                                  documentId={document.opportunity._id}
                                  onCardCheckboxChange={() =>
                                    handleMyDocsCardChange(index)
                                  }
                                  getAllDocuments={getAllDocuments}
                                  type={"MyDocuments"}
                                />
                              )}
                            </div>
                          );
                        })}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            marginTop: "15px",
                            marginBottom: "15px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              padding: "0 10px",
                            }}
                          >
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifySelf: "flex-end",
                              }}
                            >
                              <button
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                              >
                                <ChevronLeftIcon />
                              </button>
                              <span className="mx-2">
                                Page {currentPage} of {totalPages}
                              </span>
                              <button
                                onClick={handleNextPage}
                                disabled={
                                  currentPage === totalPages || totalPages === 0
                                }
                              >
                                <ChevronRightIcon />
                              </button>
                            </div>
                            {!mobileView &&
                              <div style={{ width: "40%", justifySelf: "flex-start" }}>
                                <p>Items Per Page</p>
                                <div style={{ marginTop: "-10px", width: "100px" }}>
                                  <SelectDropdownMui
                                    optionsList={limitList}
                                    label={limitValue}
                                    onChange={handlePageLimitChange}
                                    value={limitValue}
                                  />
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </> : <>No match found</>}
                  </div>
                </Paper>
              </div>
            </div>) : (<>
              <div className="container">
                <p className="message">{message}</p>
                <Link to='/organization'>Go to Organization Profile...</Link>
              </div>
            </>)}
        </div>
        {showRedirectButton && (
          <div className="container">
            <p className="message">{message}</p>
            <Link to='/organization'>Go to Organization Profile</Link>
          </div>
        )}
      </div >
    </>
  );
};

export default FilterOpportunityinOrg;