import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import '../OrgProfileStyle.css';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input": {
            color: "black",
            border: "none",
            // boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
            // transition: 'box-shadow 0.3s',
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "700",
            // fontSize: '14px',
            fontSize: "1rem",
            height: { screen: "ManualOpportunity" } && "40px",

            "&:focus": {
              color: "black",
              outline: "none",
              // border: "2px solid brown"
              // boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
            },
          },
          "& .MuiInputLabel-root": {
            color: "black",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "700",

            // fontSize: '14px',
            fontSize: "0.9rem",
            "&.Mui-focused": {
              color: "black",
            },
            // '&:focus': {
            //     color: '#646464',
            // },
          },
          "& .MuiInputBase-multiline": {
            // boxShadow: "none"
            // minHeight: '10px', // Set a minimum height for multiline
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          // borderColor: '#646464', // Change the outline border color
          // borderWidth: '1px', // Add a border width
          "&:focus": {
            // borderColor: '#646464',
          },
        },
        input: {
          "&:focus": {
            boxShadow: "none !important",
          },
        },
        inputSingle: {
          // border: 'none', // Remove the inner border for single-line input
          // borderRadius: '8px', // Add border radius
          // padding: '10px', // Add padding
        },
        inputMultiline: {
          border: "none", // Remove the inner border for multiline input
          // borderRadius: '8px', // Add border radius
          // padding: '10px', // Add padding
          // padding: "none"
          boxShadow: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s",
        },
      },
    },
  },
});

const OrgProfileMultiselect = ({ optionList, onChange, labelKey, label, value, onDelete, onClearOptions }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const popperRef = useRef(null);

  useEffect(() => {
    if (value) {
      setSelectedItems(value);
    }
  }, [value]);

  const handleOnChange = (event, newValue) => {
    setSelectedItems(newValue);
    onChange(newValue);
  };

  const handleDelete = (deletedItem) => {
    const updatedItems = selectedItems.filter(item => item[labelKey] !== deletedItem[labelKey]);
    setSelectedItems(updatedItems);
    onChange(updatedItems);
    onDelete(deletedItem);
  };

  const handleClear = () => {
    setInputValue('');
    setSelectedItems([]);
    onChange([]);
  };

  // Custom clear icon with red color
  const CustomClearIcon = () => (
    <HighlightOffIcon className="clearButton" onClick={onClearOptions} />
  );

  return (
    // <div className="autocompleteContainer">
    <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        id="multiple-select"
        options={optionList}
        getOptionLabel={(option) => option[labelKey]}
        value={selectedItems}
        inputValue={inputValue}
        onChange={handleOnChange}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        isOptionDisabled={(option) => selectedItems.some(item => item[labelKey] === option[labelKey])}
        style={{ width: '100%', marginTop: '2px', background: "white" }}
        disablePortal
        renderInput={(params) => (

          <TextField
            {...params}
            label={label || 'Select Items'}
            placeholder='Select'
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              style: {
                maxHeight: '135px',
                overflowY: 'auto',
                // border: "2px solid red",
              },
              // Disable the blue underline by styling the pseudo-elements
              sx: {
                "&:before": {
                  borderBottom: "none !important", // Removes the default underline
                },
                "&:after": {
                  borderBottom: "none !important", // Removes the blue border when focused
                },
              },
              endAdornment: (
                <React.Fragment>
                  {inputValue && (
                    <button
                      onClick={handleClear}
                      className="clearButton"
                    >
                      Clear
                    </button>
                  )}
                  <DriveFileRenameOutlineIcon style={{ cursor: 'pointer', color: '#1976D2' }} onClick={() => { /* Handle edit action here */ }} />
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />

        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <div key={index} {...getTagProps({ index })} className="tag">
              {option[labelKey]}
              <CloseIcon
                className="closeIcon"
                onClick={() => handleDelete(option)}
              />
            </div>
          ))
        }
        clearIcon={<CustomClearIcon />}
      />
    </ThemeProvider>
    // </div>
  );
};

export default OrgProfileMultiselect;
