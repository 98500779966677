import React from 'react';


import 'draft-js/dist/Draft.css';
import CloseIcon from '@mui/icons-material/Close';

import TranslateIcon from '@mui/icons-material/Translate';
import { Button, IconButton, Typography, Modal } from '@material-ui/core';
import CoAuther_TranslatorPage from './CoAuther_TranslatorPage';
import CoAuther_ParaphraserPage from './CoAuther_ParaphraserPage';
import CoAuther_SummariserPage from './CoAuther_SummariserPage';
import { FaBookOpen } from "react-icons/fa";
import paraphrase_logo from "./../static/Paraphrase 1.png"
import paraphrase_logo1 from "./../static/Paraphrase_1purple.png"
import { useSelector } from 'react-redux';



import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';



const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);






const CoAuthorLowerModal = (props) => {

const  {selectedCoauthorContent}  = useSelector((state) => state.manageCoauthorSelectedContent);



const editorContent = props.content;

// Create a temporary DOM element to parse the HTML
const tempElement = document.createElement("div");
tempElement.innerHTML = editorContent;

const editableAreaDiv = tempElement.querySelector(".editable-area");

let textContent;

if (editableAreaDiv) {
  // If the div exists, get its text content
  textContent = editableAreaDiv.textContent || editableAreaDiv.innerText || "";
} else {
  // Otherwise, fall back to the entire content's text content
  textContent = tempElement.textContent || tempElement.innerText || "";
}

  return (

    <>


      {props.lowerModalOpen && (
        
        <div className="Serive_div"
          style={{ width:'468px', zIndex: '1000', height: '90vh', position: "fixed", top: "65px", right: "0px", border: '1px solid  grey', backgroundColor: "white" }}
        > 






          <div style={{ display: 'flex', width: '100%', justifyContent: "space-between", alignItems: "center", border: "1px solid grey ", position: "absolute", top: "0px" }}>
            <div style={{ display: 'flex' }}>
              <div style={{ border: "1px solid grey", borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none', alignItems: "center" }}>
                {props.paraphraserClicked ? (
                  <IconButton style={{ outline: 'none', color: props.paraphraserClicked ? 'red' : '#3d83fa',padding:"6px 10px"}} onClick={() => props.handleParaphraseClick()}>
                   <img
                    src={paraphrase_logo1}
                    alt="Paraphrase"
                    style={{width:'21px'}}
                />
                    <div style={{ color: props.paraphraserClicked ? '#3d83fa' : 'grey' }}></div>
                    {props.paraphraserClicked &&
                      <div><h6 style={{ color: '#5C02A9', marginTop: "7px", marginLeft: '2px' }}>Paraphrase</h6></div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title='Paraphrase'>
                    <IconButton style={{ outline: 'none', color: props.paraphraserClicked ? 'red' : '#3d83fa' ,marginTop:"6px",padding:"6px 10px"}} onClick={() => props.handleParaphraseClick()}>
                    <img
                    src={paraphrase_logo}
                    alt="Paraphrase" 
                    style={{width:'21px'}}
                />
                      <div style={{ color: props.paraphraserClicked ? '#3d83fa' : 'grey' }}></div>
                      {props.paraphraserClicked &&
                        <div><h6 style={{ color:  'indigo', marginTop: "7px", marginLeft: '2px' }}>Paraphrase</h6></div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>

              <div style={{ border: "1px solid grey", borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                {props.summariserClicked ? (
                  <IconButton style={{ outline: 'none', color: props.summariserClicked ? 'red' : '#3d83fa',padding:"6px 10px" }} onClick={() => props.handleSummariserClick()}>
                    <FaBookOpen style={{ marginRight: '3px', width: '18px', height: '18px' }} />
                    <div style={{ color: props.summariserClicked ? '#3d83fa' : 'grey' }}></div>
                    {props.summariserClicked &&
                      <div>
                        <h6 style={{ color: 'red', marginTop: "7px", marginLeft: '2px' }}>Summarize</h6>
                      </div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title='Summarize'>
                    <IconButton style={{ outline: 'none', color: props.summariserClicked ? 'red' : '#3d83fa' ,marginTop:"6px",padding:"6px 10px"}} onClick={() => props.handleSummariserClick()}>
                      <FaBookOpen style={{ marginRight: '3px', width: '18px', height: '18px' }} />
                      <div style={{ color: props.summariserClicked ? '#3d83fa' : 'grey' }}></div>
                      {props.summariserClicked &&
                        <div>
                          <h6 style={{ color: 'red', marginTop: "7px", marginLeft: '2px' }}>Summarize</h6>
                        </div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>

              <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                {props.translatorClicked ? (
                  <IconButton style={{ outline: 'none', color: props.translatorClicked ? 'orange' : '#3d83fa' ,padding:"6px 10px"}} onClick={() => props.handleTranslatorClick()}>
                    <TranslateIcon />
                    <div style={{ color: props.translatorClicked ? '#3d83fa' : 'grey' }}></div>
                    {props.translatorClicked &&
                      <div>
                        <h6 style={{ color: 'orange', marginTop: "7px", marginLeft: '2px' }}>Translator</h6>
                      </div>
                    }
                  </IconButton>
                ) : (
                  <CustomTooltip title="Translator">
                    <IconButton style={{ outline: 'none', color: props.translatorClicked ? 'orange' : '#3d83fa' ,marginTop:"6px",padding:"6px 10px"}} onClick={() => props.handleTranslatorClick()}>
                      <TranslateIcon />
                      <div style={{ color: props.translatorClicked ? '#3d83fa' : 'grey' }}></div>
                      {props.translatorClicked &&
                        <div>
                          <h6 style={{ color: 'orange', marginTop: "7px", marginLeft: '2px' }}>Translator</h6>
                        </div>
                      }
                    </IconButton>
                  </CustomTooltip>
                )}
              </div>



            </div>
            <div  style={{marginRight:"5px", cursor:'pointer'}} onClick={()=> props.setLowerModalOpen(false)}>
              <CloseIcon />
            </div>


          </div>
 
          <div style={{ position: "relative", marginTop: "50px", justifyContent: "center", width: "90%", margin: "auto" }}>




             {props.translatorClicked && (

              <CoAuther_TranslatorPage
                editorState={selectedCoauthorContent ? selectedCoauthorContent : textContent}
                setEditorState={props.setContent} 
                userId={props.userId}  
                lowerModalToggle={props.lowerModalToggle} 
                translatorClicked={props.translatorClicked}             
              />

            )}
            {props.summariserClicked && (

              <CoAuther_SummariserPage
                editorState={selectedCoauthorContent ? selectedCoauthorContent : textContent}
                setEditorState={props.setContent}
                userId={props.userId}
                lowerModalToggle={props.lowerModalToggle} 
                summariserClicked={props.summariserClicked}
                
              />
            )} 

            {props.paraphraserClicked && (

              <CoAuther_ParaphraserPage
                editorState={selectedCoauthorContent ? selectedCoauthorContent : textContent}
                setEditorState={props.setContent}
                userId={props.userId}  
                lowerModalToggle={props.lowerModalToggle} 
                paraphraserClicked={props.paraphraserClicked}              
              />

            )}
          </div> 
        </div>
      )}
    </>
  )
}

export default CoAuthorLowerModal;