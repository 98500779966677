import React,{useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import { createTheme } from '@material-ui/core';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from '@mui/material/Paper';
function SelectDropdownMui(
    {
        selectLabel, id, name, value, onChange, optionsList,
        margin,disabled, customColor, isDarkMode
    }
) {

// const theme = createTheme({
//     components: {
//         MuiSelect: {
//             styleOverrides: {
//                 select: {
//                     color: isDarkMode?"white": "#646464",
//                     borderStyle: "none",
//                     boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
//                     transition: "box-shadow 0.3s",
//                     // height: "40px",
//                     // padding: "7px",
//                     padding: "7px 13px",
//                     fontFamily: "Montserrat, sans-serif",
//                     fontWeight: "500",
//                     fontSize: "14px",
//                     "&:focus": {
//                         outline: "none",
//                         boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
//                         // borderColor: "red"
//                     }
//                 }
//             }
//         },
//         MuiMenuItem: {
//             styleOverrides: {
//                 root: {
//                     backgroundColor:isDarkMode?"gray":'',
//                     color:isDarkMode?"black":'',
//                     fontFamily: "Montserrat, sans-serif",
//                     fontWeight: "500",
//                     fontSize: "14px",
//                     // borderRadius: "4px", // Add border radius for dropdown options
//                     // border: "1px solid #ccc", // Add border color for dropdown options
//                     // boxShadow: "none", // Remove box shadow from dropdown options
//                     "&:hover": {
//                         backgroundColor: "#92C7CF" // Change the background color on hover
//                     }
//                 }
//             }
//         },
//         MuiInputLabel: {
//             styleOverrides: {
//                 root: {
//                     color: isDarkMode?"white": "#646464",
//                     fontFamily: "Montserrat, sans-serif",
//                     fontWeight: "500",
//                     fontSize: "0.9rem",
//                     // fontSize: "14px",
//                     lineHeight: "1.4375em",
//                     letterSpacing: "0.00938em",
//                     padding: 0,
//                     position: "relative",
//                     display: "block",
//                     transformOrigin: "top left",
//                     whiteSpace: "nowrap",
//                     overflow: "hidden",
//                     textOverflow: "ellipsis",
//                     maxWidth: "calc(100% - 24px)",
//                     position: "absolute",
//                     left: 0,
//                     top: 0,
//                     zIndex: 1,
//                     pointerEvents: "none",
//                     "&.Mui-focused": {
//                         color: "#646464",
//                         position: "absolute",
//                         left: 0,
//                         top: 0
//                     }
//                 }
//             }
//         }
//     }
// })

const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            color: isDarkMode ? "white" : "#646464",
            borderStyle: "none",
            boxShadow: isDarkMode ? "0px 3px 6px rgba(255, 255, 255, 0.1)" : "0px 3px 6px rgba(0, 0, 0, 0.1)",
            transition: "box-shadow 0.3s",
            padding: "7px 13px",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "500",
            fontSize: "14px",
            "&:focus": {
              outline: isDarkMode ? "none" : "none", // Outline will be removed when focused, regardless of mode
              boxShadow: isDarkMode ? "none" : "0px 3px 10px rgba(0, 0, 0, 0.2)", // Removes box-shadow if dark mode is true
              border: "none",
              color: isDarkMode ? "white" : "#646464" // Set text color to white when focused in dark mode
            }
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: isDarkMode ? "gray" : '',
            color: isDarkMode ? "white" : "black",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "500",
            fontSize: "14px",
            "&:hover": {
              backgroundColor: isDarkMode ? "#555" : "#92C7CF" // Darker hover color in dark mode
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: isDarkMode ? "white" : "#646464",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: "500",
            fontSize: "0.9rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
            padding: 0,
            position: "relative",
            display: "block",
            transformOrigin: "top left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "calc(100% - 24px)",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
            pointerEvents: "none",
            "&.Mui-focused": {
              color: isDarkMode ? "white" : "#646464", // Set label color to white when focused in dark mode
              position: "absolute",
              left: 0,
              top: 0
            }
          }
        }
      }
    }
  });
  

    console.log("optionslist", optionsList)

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ m: 1,background:isDarkMode?"gray":"white", margin: { margin } }}>
                <FormControl sx={{ m: 1, margin: "unset" }} fullWidth size="small">
                    <InputLabel id="demo-select-small-label">{selectLabel}</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        name={name}
                        id={id}
                        //  id="demo-simple-select"
                        // id="demo-select-small"
                        value={value}
                        label={selectLabel}
                        onChange={(e) => onChange(e)}
                        disabled={disabled}
                        MenuProps={{
                            PaperProps: {
                              style: {
                                color: customColor,
                              },
                            },
                          }}
                    //  placeholder={placeholder}
                    //  displayEmpty={displayEmpty}
                    //  autoWidth
                    >
                        {optionsList.length > 0 &&
                            optionsList.map((obj) => {
                                let { value, name } = obj
                                return <MenuItem value={value}>{name}</MenuItem>

                            })
                        }
                    </Select>
                </FormControl>
            </Box>
        </ThemeProvider>
    );
}

export default SelectDropdownMui