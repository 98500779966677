export const selectCoauthorContent = (content) => {
    return {
        type: 'SELECT_COAUTHOR_CONTENT',
        payload: content, 
    };
};

export const clearCoauthorContent = () => {
    return {
        type: 'CLEAR_COAUTHOR_CONTENT',
    };
};
