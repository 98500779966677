import React, { useEffect, useState, useRef } from "react";
import ReactFlow, {
  ReactFlowProvider,
} from "reactflow";
import CloseIcon from '@mui/icons-material/Close';
import {
  Container,
  TextField,
  Button,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Menu,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { addStages, addStatus, addWorkflow, deleteStage, deleteworkflows, getSelectedStage, getSpecificWorkFlow, getStages, getworkflows, updateWorkflow } from "../../services/workflowServices";
import { toast } from "react-toastify";
import { StatusCard } from "../MyDocumentsCards";
import Description from "@mui/icons-material/Description";
import { addnewtransition } from "../../services/transitionServices";
import { useSelector } from "react-redux";
import Loader from "../../utils/loader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NestedFlow from "../ReactFlow/NestedFlow";
import { showToast } from "../../messages";

export function WorkflowVisual(props) {
  const [loading, setLoading] = useState(false)
  const userDetails = useSelector((user) => user)
  console.log("userDetails", userDetails)
  const orgVirtualId = userDetails.user.currentUser.organizationId
  const [isStageModalOpen, setisStageModalOpen] = useState(false)
  const [stageName, setStageName] = useState('')
  const [stageDescription, setStageDescription] = useState('')
  const [myStages, setMyStages] = useState([]);
  const [statusModal, setStatusModal] = useState('')
  const [statusName, setStatusName] = useState('')
  const [statusDescription, setStatusDescription] = useState('')
  const [selectedStageId, setSelectedStageID] = useState('')
  const [isSelecetedStageModal, setSelecetedStageModal] = useState('')
  const [selecteStageExpand, setSelecetedStageExpand] = useState([])
  const [selecteStatusExpand, setSelecetedStatusExpand] = useState([])
  const [workFlowData, setWorkFlowData] = useState([])
  const [defaultWorkFlowData, setDefaultWorkFlowData] = useState([])
  const [workFlowDetails, setWorkFlowDetails] = useState([])
  const history = useHistory()
 

  useEffect(() => {
    console.log('props', props)
  }, [props])

  useEffect(() => {
    console.log("props.workflowId__", props.workflowId)
    // setMyStages('')
    getExistingStages()

  }, [props.workflowId])

  const [newStatus, setNewStatus] = useState()

  const addVertex = async () => {

    try {
      let data = { selectedStageId, statusName, statusDescription }
      console.log("addVertexdata", data)
      const response = await addStatus(selectedStageId, statusName, statusDescription)
      if (response.status === 201) {
        setNewStatus(data)
        showToast('Organization.statusAdded')
        // getExistingStages()
        getStatusforStage(selectedStageId)
        closeStatusModal()
        console.log("response__", response);
      } else if (response.status == 400) {
        toast.error(response.message)
      }
      else {
        console.error("Error while Adding:", response.data.message);
      }
    } catch (e) {
      console.log(e)
    }

  };

  const getExistingStages = async () => {
    try {
      const response = await getStages(props.workflowId)
      console.log(response, "responseexisting")
      setMyStages(response.data)

    } catch (e) {
      console.log("error while fetching stages ")
    }
  }

  const handleStageModalClose = () => {
    setisStageModalOpen(false)
  }
  const handleStageModalOpen = () => {
    setisStageModalOpen(true)
    setStageName('')
    setStageDescription('')
  }

  const handleStageName = (e) => {
    setStageName(e)
  }

  const handleStageDescription = (e) => {
    setStageDescription(e)
  }

  const handleSaveStage = async () => {
    try {
      const response = await addStages(props.workflowId, stageName, stageDescription)
      console.log("response_", response)
      if (response.status === 201) {
        handleStageModalClose()
        getExistingStages()
        workflowDetails()
        showToast('Organization.stageAdded')
        console.log("response__", response);
      } else if (response.status == 400) {
        toast.error(response.message)
      }
      else {
        console.error("Error while Adding:", response.data.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  }

  const handleDeleteStage = async (stageName) => {
    try {
      const response = await deleteStage(props.workflowId, stageName)
      console.log("response_", response)

      if (response.status === 200) {
        showToast('Organization.stageDelete')
        workflowDetails()
        getExistingStages()
        console.log("response__", response);
      } else if (response.status == 400) {
        toast.error(response.message)
        workflowDetails()
      }
      else {
        console.error("Error while Adding:", response.data.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  }


  const openStatusModal = (id) => {
    setStatusModal(true)
    setSelectedStageID(id)
    setStatusName('')
    setStatusDescription('')
  }

  const closeStatusModal = () => {
    setStatusModal(false)
  }

  const handleSaveStatus = async () => {
    try {
      const response = await addStatus(selectedStageId, statusName, statusDescription)
      if (response.status === 201) {
        showToast('Organization.statusAdded')
        getExistingStages()
        closeStatusModal()
        console.log("response__", response);
      } else if (response.status == 400) {
        toast.error(response.message)
      }
      else {
        console.error("Error while Adding:", response.data.message);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleStatusDescription = (e) => {
    setStatusDescription(e)
  }
  const handleStatusName = (e) => {
    setStatusName(e)
  }

  const getStatusforStage = async (id) => {
    try {
      const response = await getSelectedStage(id)
      if (response.status === 201) {
        console.log("response__", response);
        setSelecetedStatusExpand(response.existingStatus)
        setSelecetedStageExpand(response.existingStages)
      } else if (response.status == 400) {
        toast.error(response.message)
      }
      else {
        console.error("Error while Adding:", response.data.message);
      }
    } catch (e) {
      console.log(e)
    }
  }


  useEffect(() => {
    console.log("selecteStatusExpand", selecteStatusExpand)
  }, [selecteStatusExpand])

  const workflowDetails = async () => {
    setLoading(true)
    try {
      let email = sessionStorage.getItem("currentUserEmail")
      let response = await getworkflows(email)
      console.log("workflowDetailsresponse", response)
      setWorkFlowData(response.data)
      setDefaultWorkFlowData(response.defaultData)
    }
    catch (e) {
      console.log("workflowDetailserror", e)
    }
    setLoading(false)
  }

  useEffect(() => {
    workflowDetails()
  }, [])

  const handleOpenStageModal = () => {
    setSelecetedStageModal(true)
  }
  const handlecloseSelectedStageModal = () => {
    setSelecetedStageModal(false)
  }

  const handleSaveTransistion = async (stageId, vertexData) => {
    console.log("handleSaveTransistion", stageId, vertexData, selecteStatusExpand)
    if (vertexData.length == 0) {
      return
    }
    let statusData = []
    console.log("statusData", statusData)
    let sendData = {
      orgid: orgVirtualId,
      appname: workFlowData[0].appname,
      //  roles
      workflowid: props.workflowId,
      fromstageid: vertexData[0].stageid,
      fromstatusid: vertexData[0]._id,
      tostageid: vertexData[1].stageid,
      tostatusid: vertexData[1]._id,
      //  conditionid
      createdby: workFlowData[0].createdby,
      isactive: vertexData[0].isactive,
      roles:["T1","T2"]
    }
    console.log("handleSaveTransistionsendData", sendData)


    try {
      let response = await addnewtransition(sendData)
      console.log("addnewtransitionresponse", response)
    }
    catch (e) {

    }
  }

  return (
    <>
      <Loader loading={loading} />
      <div>
        <div>
          <h3 style={{ color: "#1976d2", marginTop: "10px", marginLeft: "5px", marginBottom: "5px", }}>
            {props.workflowName}
          </h3>
          <div style={{ position: "relative" }}>
            <TextField
              style={{ borderRadius: "20px", width: "100%" }}
              onChange={(e) => { props.handlDescription(e.target.value) }}
              value={props.InputValue}
              variant="outlined"
              disabled={!props.allowEdit}
              fullWidth
            />
            <div onClick={props.allowEditFunction} style={{ position: "absolute", top: "10%", right: "1%", color: "blue" }}>
              <button style={{ color: "#1976d2" }}><BorderColorIcon /></button>
            </div>
          </div>
        </div>
        <div style={{
          marginTop: "10px", boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: "10px", height: "70vh",
          overflowY: "auto"

        }}>
          {/* {myStages.length > 0 && (
            <div style={{ marginLeft: "20px" }}>
              {myStages
                .sort((a, b) => a.sequence - b.sequence) // Sort myStages based on sequence
                .map((document, index) => (
                  
                ))}
            </div>
          )} */}

          <div style={{ marginLeft: "20px" }}>
            {workFlowData.length > 0 && workFlowData.filter(obj => obj._id === props.workflowId).map(filteredObj => (
              <ReactFlowProvider>
                <NestedFlow
                  workFlowDataDetails={filteredObj}
                  workflowId={props.workflowId}
                  workflowStatus={props.stageOperations}
                />
              </ReactFlowProvider>
            ))}
            {defaultWorkFlowData.length > 0 && defaultWorkFlowData.filter(obj => obj._id === props.workflowId).map(filteredObj => (
              <ReactFlowProvider>
                <NestedFlow
                  workFlowDataDetails={filteredObj}
                  workflowId={props.workflowId}
                  workflowStatus={props.stageOperations}
                />
              </ReactFlowProvider>
            ))}
          </div>
        </div>

        {/*///////////////////////////////////////////////////////// Stage Modal////////////////////////////////////////////////////////////////// */}
        <Modal open={isStageModalOpen} onClose={handleStageModalClose} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px' }}>
          <div style={{width:"100%",border:"2px solid red", backgroundColor: 'white', padding: '20px', borderRadius: '5px', minWidth: '350px', minHeight: "300px" }}>
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "-10px", right: "-5px" }}>
                <IconButton onClick={handleStageModalClose} >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div style={{ display: "flex", margin: "10px", marginBottom: "20px", justifyContent: "center" }}>
              <h3>Add New Stage</h3>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <TextField
                style={{ borderRadius: "15px", width: "400px" }}
                label="Stage Name"
                placeholder="Enter Stage Name"
                value={stageName}
                onChange={(e) => handleStageName(e.target.value)}
                variant="outlined"
                fullWidth
              />
              <TextField
                style={{ marginBottom: '10px' }}
                label="Description"
                placeholder="Enter Description"
                value={stageDescription}
                onChange={(e) => handleStageDescription(e.target.value)}
                multiline
                rows={4}
                variant="outlined"
                InputProps={{
                  sx: {
                    width: "400px"
                  }
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>

              <Button
                variant='outlined'
                style={{
                  fontSize: '12px',
                  marginRight: '10px',
                  borderRadius: '10px',
                  color: '#1976D2',
                  borderColor: '#1976D2',
                  textTransform: 'capitalize',
                  boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)'
                }}
                onClick={handleSaveStage}
              >
                Save
              </Button>


              <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: '#1976D2', borderColor: '#1976D2', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }} onClick={handleStageModalClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        {/* stage modal start */}

        {/* stage modal end */}

        {/*/////////////////////////////////////////// Status Modal /////////////////////////////////////// */}
        <Modal open={statusModal} onClose={closeStatusModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '10px' }}>
          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', minWidth: '350px', minHeight: "300px" }}>
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: "-10px", right: "-5px" }}>
                <IconButton onClick={closeStatusModal} >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div style={{ display: "flex", margin: "10px", marginBottom: "20px", justifyContent: "center" }}>
              <h3>Add New Status</h3>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <TextField
                style={{ borderRadius: "15px", width: "400px" }}
                label="Status"
                value={statusName}
                onChange={(e) => handleStatusName(e.target.value)}
                variant="outlined"
                fullWidth
              />
              <TextField
                style={{ marginBottom: '10px' }}
                label="Description"
                placeholder="Enter Description"
                value={statusDescription}
                onChange={(e) => handleStatusDescription(e.target.value)}
                multiline
                rows={4}
                variant="outlined"
                InputProps={{
                  sx: {
                    width: "400px"
                  }
                }}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <Button
                variant='outlined'
                style={{
                  fontSize: '12px',
                  marginRight: '10px',
                  borderRadius: '10px',
                  color: '#1976D2',
                  borderColor: '#1976D2',
                  textTransform: 'capitalize',
                  boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)'
                }}
                onClick={addVertex}
              >
                Save
              </Button>

              <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: '#1976D2', borderColor: '#1976D2', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }} onClick={closeStatusModal}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal>

        {/*//////////////////////////////////////////////////// Selected Stage Modal ////////////////////////////////////*/}
        {/* <Modal open={isSelecetedStageModal} onClose={handlecloseSelectedStageModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',borderRadius: '10px' }}>
     <div style={{backgroundColor:"white",width:"80%",height:"80%"}}>
      <h3>
       Stage:{selecteStageExpand.stageName}
      </h3>
      <h5>
        Description: {selecteStageExpand.description}
      </h5>
    
      {selecteStatusExpand.length > 0 && (
      <div style={{marginLeft:"20px",display:"flex",flexWrap:"wrap",overflow:"auto"}}>
      {selecteStatusExpand
      .sort((a, b) => a.sequence - b.sequence) // Sort myStages based on sequence
      .map((document, index) => (
        <div key={index}>
          <StatusCard
            statusName={document.statusName}
            statusDescription={document.description}
          />
        </div>
      ))}
      </div>
    )}


     </div>
    </Modal> */}

        <Modal
          open={isSelecetedStageModal}
          onClose={handlecloseSelectedStageModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{
            backgroundColor: "white",
            width: "60%",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            maxHeight: "80vh",
            overflowY: "auto"
          }}>
            <div style={{ marginLeft: "35px" }}>
              <h5 style={{ color: "#1976d2", fontSize: "14px", marginBottom: "4px" }}>Stage: {selecteStageExpand.stageName}</h5>
              <h5 style={{ color: "#1976d2", fontSize: "14px", marginBottom: "4px", marginTop: "4px" }}>Description: {selecteStageExpand.description}</h5>
            </div>

            {selecteStatusExpand.length > 0 && (
              <div style={{ marginLeft: "20px", display: "flex", flexWrap: "wrap" }}>
                {selecteStatusExpand
                  .sort((a, b) => a.sequence - b.sequence)
                  .map((document, index) => (
                    <div key={index} style={{ marginRight: "10px", marginBottom: "10px" }}>
                      <StatusCard
                        statusName={document.statusName}
                        statusDescription={document.description}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>

  )




}