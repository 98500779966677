import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box,
  Modal,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./FAQ.css";
import GotQuestions from "../static/GotQuestions.png";
import Nodata from "../static/Nodata.png";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import VideoModel from "./VideoModel";

import { sendQuestions } from "../services/userService";
import { toast } from "react-toastify";
import { getVideoUrl } from "../services/assetService";

const faqData = [
  {
    question: "What is the main purpose of this application?",
    answer:
      "This application helps users find contract opportunities, extract RFPs, create proposals, and manage bids, along with providing access to FAR and DFAR clause libraries and workflow administration tools.",
  },
  {
    question: "How can I find contract opportunities in different countries?",
    answer:
      "Use the search functionality to filter opportunities by country, industry, or other criteria.",
   
  },
  {
    question: "How does the process of adding an opportunity to a project work?",
    answer:
      "Add an opportunity to a project to access and manage related RFPs, proposals, and bidding activities. [Refer to the video demo for detailed steps.]",
      videoUrl: "OpportunityFinder.mp4",
  },
  {
    question: "What is included in the FAR and DFAR clause library?",
    answer:
      "The library provides comprehensive clauses from the Federal Acquisition Regulation (FAR) and Defense Federal Acquisition Regulation Supplement (DFARS).",
    videoUrl: "ClauseGeneration.mp4",
  },
  {
    question:
      "Can I customize the proposal templates?",
    answer:
      "Yes, you can customize proposal templates to match your specific needs and project requirements.",
  },
  {
    question: "How does the workflow administration feature benefit collaborative work?",
    answer:
      "It streamlines project management by allowing team members to collaborate, track progress, and manage tasks effectively. [Refer to the video demo for detailed usage.]",
      videoUrl: "RoleManagement.mp4",
  },
  {
    question: "What steps are involved in submitting a bid?",
    answer:
      "Create and review your proposal, then submit it through the application’s bid submission module.",
  },
  {
    question: "How does the application help with resource allocation for proposals?",
    answer:
      "The application allows you to allocate resources to proposals efficiently, ensuring that your team’s skills and availability are optimized for each project. [Refer to the video demo for a detailed walkthrough.]",
      videoUrl: "ResourceAllocation.mp4",
  },
  {
    question: "What is requirement mapping, and how does it work in this application?",
    answer:
      "Requirement mapping aligns project requirements with proposal elements to ensure all needs are addressed. This feature helps you track and fulfill specific criteria effectively. [Refer to the video demo for a step-by-step guide.]",
      videoUrl: "RequirementMapping.mp4",
  },
  {
    question: "How does the application automate the RFP extraction process?",
    answer:
      "The application automatically extracts RFP details from opportunities, streamlining the proposal creation process without manual intervention.",
  },
  {
    question: "Can I track the progress of proposals within the application?",
    answer:
      "Yes, the application includes tools for tracking proposal progress, including status updates and deadlines.",
    videoUrl: "Dashboard.mp4",
  },
  {
    question: "How do I manage team collaboration on proposals?",
    answer: "Use the workflow administration features to assign tasks, track contributions, and ensure effective team collaboration on proposals. [Refer to the video demo for more details.]",
    videoUrl: "UserManagement.mp4",
  },
  {
    question: "What tools are available for analyzing contract opportunities?",
    answer:
      "The application provides analytical tools to assess contract opportunities, helping you make informed decisions about which projects to pursue.",
      videoUrl: "OpportunityFinder.mp4"
  },
  {
    question: "How can I ensure all project requirements are met in my proposal?",
    answer:
      "Utilize the requirement mapping feature to align your proposal with the project's requirements, ensuring comprehensive coverage.",
  },
  {
    question: "Is there a way to customize resource allocation based on project needs?",
    answer:
      "Yes, you can customize resource allocation to fit the specific needs and priorities of each project.",
      videoUrl: "ResourceAllocation.mp4"
  },
  {
    question: "How can I view and manage contract opportunities from different countries?",
    answer:
      "Use the application’s filtering options to view and manage contract opportunities by country, industry, or other relevant criteria.",
      videoUrl: "OpportunityFinder.mp4"
  },
  {
    question: "What support is available if I need help with these features?",
    answer:
      "You can access our support resources or contact our team for assistance with any feature-related questions or issues.",
  }
];

const FAQ = () => {
  const [search, setSearch] = useState("");
  const [question, setQuestion] = useState("");
  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [noResultsDialogOpen, setNoResultsDialogOpen] = useState(false);
  const email = sessionStorage.getItem("currentUserEmail");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };
  function arrayBufferToBase64(arrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(arrayBuffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
  
  const handleOpen = async (fileName) => {
    try {
      const { blob, contentType } = await getVideoUrl(fileName);
      const arrayBuffer = await blob.arrayBuffer(); 
      const base64String = arrayBufferToBase64(arrayBuffer);
      const videoUrl = `data:${contentType};base64,${base64String}`;
      setVideoUrl(videoUrl);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching video URL:", error);
      toast.error("Failed to retrieve video URL");
    }
  };
  
  const handleClose = () => {
    setOpen(false);
    setVideoUrl("");
  };
  const handleNodataClose = () => {
    setNoResultsDialogOpen(false);
    setManualClose(true); 
  };

  const handleSubmit = async () => {
    if (!question) {
      toast.warn("Please enter your question.");
    } else {
      let details = {
        userEmail: email,
        message: question,
      };
      console.log(details, "submit details");
      let response = await sendQuestions(details);
      console.log(response);
      if (response.status === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }
    setQuestion("");
  };

  const filteredFaqs = faqData.filter((faq) =>
    faq.question.toLowerCase().includes(search.toLowerCase())
  );
  const [manualClose, setManualClose] = useState(false);

  useEffect(() => {
    if (filteredFaqs.length === 0 && !manualClose) {
      setNoResultsDialogOpen(true);
    }
  }, [filteredFaqs, manualClose]);

  useEffect(() => {
    if (filteredFaqs.length > 0) {
      setManualClose(false);
    }
  }, [filteredFaqs]);
  return (
    <Container className="faq-container">
      <div className="faqpage" style={{flexDirection: windowWidth > 767 ? "row" : "column-reverse"}}>
        <div className="leftcontent"  style={{width: windowWidth > 767 ? "50%" : "100%",}}>
          <Typography className="main-title" variant="h4" gutterBottom>
            Frequently Asked Questions
          </Typography>
          {windowWidth < 601 && 
          <div style={{color: "#1976d2", fontSize: "2.125rem", marginBottom: "15px"}}>
            Frequently Asked Questions
          </div>}
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={search}
            onChange={handleSearchChange}
            sx={{
              marginBottom: "20px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "20px",
                "& fieldset": {
                  borderRadius: "20px",
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="#1976d2" />
                </InputAdornment>
              ),
            }}
          />
          <div className="faqCards" style={{paddingRight: windowWidth > 767 ? "10px" : "0px"}}>
            {filteredFaqs.length > 0 ? (
              filteredFaqs.map((faq, index) => (
                <Accordion
                  key={index}
                  sx={{
                    position: "relative",
                    marginTop: "15px",
                    border: "1px solid #ccc",
                    backgroundColor: "white",
                    borderRadius: "15px",
                    boxShadow: "rgba(0, 0, 0, 0.5) 0px 3px 8px",
                    overflow: "hidden",
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "8px",
                      height: "100%",
                      backgroundColor: "#1976d2",
                      zIndex: 1,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      padding: "10px 15px",
                      borderRadius: "15px",
                    }}
                  >
                    <Typography sx={{ color: "#1976d2", fontWeight: "bold" }}>
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "15px",
                      backgroundColor: "white",
                      border: "1px solid #ccc",
                      borderRadius: "15px",
                    }}
                  >
                    <Typography>{faq.answer}</Typography>
                    {faq.videoUrl && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          marginTop: "10px",
                          backgroundColor: "#1976d2",
                          "&:hover": { backgroundColor: "#115293" },
                        }}
                        onClick={() => handleOpen(faq.videoUrl)}
                      >
                        View Demo
                      </Button>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Modal
                open={noResultsDialogOpen}
                onClose={handleNodataClose}
                aria-labelledby="no-results-title"
                aria-describedby="no-results-description"
              >
                <Box className="noSearchDialogContainer">
                  <Box className="noSearchDialogContent">
                    <img src={Nodata} className="noSearchImage" alt="No Data" />
                    <Typography id="no-results-title" className="noSearchTitle">
                      {"We couldn't find an answer to your question."}
                    </Typography>
                    <Typography
                      id="no-results-description"
                      className="NosearchSubtitle"
                    >
                      Would you like to ask us directly? Please reach out to us
                      to submit your question.
                    </Typography>
                    <Box className="noSearchDialogActions">
                      <Button onClick={handleNodataClose} variant="outlined">
                        No Thanks
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            )}
          </div>
        </div>
        <div className="rightcontent" style={{width: windowWidth > 767 ? "50%" : "100%", marginLeft: windowWidth > 767 ? "30px" : "0px",marginBottom: windowWidth > 767 ? "0px" : "20px"}}>
          <div style={{display: windowWidth > 767 ? "block" : "none"}}>
            <img src={GotQuestions} alt="FAQ Image" className="faqImage" />
          </div>
          <Box className="questionBox" style={{width: windowWidth > 767 ? "90%" : "100%",}}>
            <Typography className="questionTitle" style={{fontSize: windowWidth > 767 ? "25px" : "2.125rem" }}>Got Questions?</Typography>
            <Typography
              className="questionSubtitle"
              variant="subtitle1"
              gutterBottom
            >
              Reach out anytime! Our team is ready to assist.
            </Typography>
            <TextField
              label="Enter your question"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={question}
              onChange={handleQuestionChange}
              className="questionField"
            />
          </Box>
          <div className="sendbtn" style={{ width: windowWidth > 767 ? undefined : "100%"}}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className="sendButton"
              style={{width : windowWidth > 767 ? "30%" : "100%"}}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
      <VideoModel open={open} handleClose={handleClose} videoUrl={videoUrl} />
    </Container>
  );
};

export default FAQ;
