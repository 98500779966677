import React, { useState, useRef, useEffect } from 'react';
import 'draft-js/dist/Draft.css';
import { Button, IconButton, TextField } from '@material-ui/core';

const CoAuthorTopMenubar = (props) => {


  return (
    <>
    <div className='top-menubar' style={{height:'50px', display:'flex', justifyContent:'flex-end', padding:'7px 40px', border:'1px solid #cacaca'}}>
              <div>
                <Button className='OutlinedDarkBtn2' style={{ textTransform: 'capitalize', fontSize:'12px', color:'blue', border:'1px solid blue'}} onClick={()=> props.ocrCheck()}>OCR</Button>
                </div>
                {/* <div >
                  <Button className='OutlinedDarkBtn2' style={{ textTransform: 'capitalize', fontSize:'12px',marginLeft:"7px",color:'blue', border:'1px solid blue' }}>Check Grammar</Button>
                </div> */}
    </div>
    </>
  );
};

export default CoAuthorTopMenubar;
