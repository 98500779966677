import React from 'react';
import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import queryString from "query-string";
import { addUserToTeam, getProfile, getProfile2 } from '../../services/userService';
import { connect } from 'react-redux';
import { setCurrentUser } from "../../redux/user/user.actions";
import {setUserFeatures} from "../../redux/features/features.action";
import { withRouter } from 'react-router-dom';
import Loader from '../../utils/loader';
import _ from 'lodash';
import { matchingOppPercentage, matchingOppPercentageAdditional } from '../../services/opportunityService';
import LZString from 'lz-string';
import {getExtraLoginDetails} from '../../services/authService';

const SocialMediaAuth = ({ location, setCurrentUser, history, setUserFeatures }) => {
    const [userID, setuserID] = useState('');
    const [team, setTeam] = useState({referedEmail:'',accountType:'Personal'});

    useEffect(() => {

        const fetchData = async () => {
        const { token, userID, organizationId, currentUserEmail, Role } = queryString.parse(
            location.search
        );

        const res = await getExtraLoginDetails(userID)
        const { features } = res;
        setUserFeatures(features)



        if (!token) {
            history.push('/login');
        }
        localStorage.setItem('token', token);
        sessionStorage.setItem('token', token);
        setuserID(userID);
        localStorage.setItem('organizationId', organizationId);
        localStorage.setItem('currentUserEmail', currentUserEmail);
        localStorage.setItem('Role', Role);
        sessionStorage.setItem('organizationId', organizationId);
        sessionStorage.setItem('currentUserEmail', currentUserEmail);
        sessionStorage.setItem('Role', Role);
        const data= res?.organizationData?.data
        sessionStorage.setItem("organizationData", JSON.stringify(data));
        sessionStorage.setItem("FeatureRoleAccess", JSON.stringify(res.mergedRoles[0]));
        sessionStorage.setItem("orgToken",organizationId);



        const orgresponse = await matchingOppPercentage(organizationId);
      const dataorg = orgresponse?.data;
      console.log(orgresponse, "matchingresponseinlogin");
      
      
      // Store the updated data back in session storage
      try {
        sessionStorage.setItem("organizationOpportunities", LZString.compressToUTF16(JSON.stringify(dataorg)));
        console.log("Data successfully stored in session storage");
      } catch (error) {
        console.error("Failed to store data in session storage:", error);
      }
      
      if (orgresponse) {
        console.log(orgresponse, "checkif");
        const additionalOrgResponse = await matchingOppPercentageAdditional(data);
        console.log(additionalOrgResponse, "additionalOrgResponse");
        const dataorg1 = additionalOrgResponse?.data;
      
        // const essentialDataOrg1 = extractEssentialData(dataorg1);
      
        // Retrieve the updated data from session storage
       let  existingOpportunities = JSON.parse(LZString.decompressFromUTF16(sessionStorage.getItem("organizationOpportunities"))) || [];
        console.log(existingOpportunities, "existingOpportunities");
        
        // Append the additional data
        const updatedOpportunities = existingOpportunities.concat(dataorg1);
        console.log(updatedOpportunities, "updatedOpportunities");
      
        // Compress and store the updated data back in session storage
        try {
          sessionStorage.setItem("organizationOpportunities", LZString.compressToUTF16(JSON.stringify(updatedOpportunities)));
          console.log("Additional data successfully stored in session storage");
        } catch (error) {
          console.error("Failed to store additional data in session storage:", error);
        }
      }

    }

    fetchData()
    }, [location]);

    useEffect(() => {
        const emptyTeam = _.some(team, _.isEmpty);
        console.log("emptyTeam", emptyTeam);
        console.log("Team===>", team);
        if(!emptyTeam){
            console.group("Now add to Team");
            addNewUserToRespectiveTeam(team.referedEmail, team.accountType);
        }else{
            console.group("all are empty");
        }
    },[team])

    useEffect(() => {
        let referedEmail = localStorage.getItem("referedEmail") !== "undefined" || null
            ? localStorage.getItem("referedEmail")
            : "";
        let accountType = localStorage.getItem("accountType") !== "undefined" || null
            ? localStorage.getItem("accountType")
            : "";
        console.log(referedEmail, accountType);
        if (referedEmail) {
            if (accountType) {
                setTeam({ referedEmail, accountType });
            } else {
                setTeam({ referedEmail });
            }
        }
        // const organizationId = localStorage.getItem('organizationId');
        // const currentUserEmail = localStorage.getItem('currentUserEmail')
        // const Role = localStorage.getItem('Role');
        // const orgToken = localStorage.getItem('orgToken')
        // console.log("organizationId", organizationId);
        // console.log("currentUserEmail", currentUserEmail);
        // console.log("Role", Role);
        // console.log("orgToken", orgToken);
        // console.log("userID", userID);
        
    }, []);




    const getUserProfile = async () => {
       try{
        const profile = await getProfile2(userID);
        console.log(profile, "profile");
        setCurrentUser({ ...profile.data });
        console.log(profile.data.isAdmin, "roleeee")
        console.log(profile.data.isAdmin, "roleeee")
        if (profile.data.isAdmin === true && profile.data.isSubAdmin === false) {
            return history.push('/admindashboard');
        } else if (profile.data.isAdmin === false && profile.data.isSubAdmin === true) {
            // return history.push('/subAdminManageUser');
            return history.push('/subAdminDashboard');
        } else {
            return history.push('/dashboard');
        }
       }catch(error){
        console.log(error,"error to fetch profile")
       }
    }

    useEffect(() => {
        if (userID) {
            getUserProfile();
        }
    }, [userID]);
    const addNewUserToRespectiveTeam = async (referedEmail, accountType) => {
        console.log('addNewUserToRespectiveTeam',referedEmail, accountType);
        const response = await addUserToTeam({ referedEmail, accountType });
        if (response.status === 200) {
            localStorage.removeItem("accountType");
            localStorage.removeItem("referedEmail");
        }else{
            //do something
        }
    }
    return (
        <Loader />
    );
}
const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    setUserFeatures: (features) => dispatch(setUserFeatures(features)),
});

export default connect(null, mapDispatchToProps)(withRouter(SocialMediaAuth));