import React, { useState } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import image1 from "../static/Group5702x.png";
import bgImage from "../static/Group244.png";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";
import authService from "../services/authService.js";
import { Link, withRouter } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./LoginForm.css";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import { setUserFeatures } from "../redux/features/features.action";
import { getHomeCMS } from "../services/adminServices";
import LZString from 'lz-string';
import ChatServices from "../services/ChatServices.js";
import {
  Card,
  InputGroup,
  FormControl,
  Form,
  Button,
  ButtonGroup,
  Carousel,
} from "react-bootstrap";
import loginBackground from "../static/loginBackground.png";
import Login1 from "../static/signup_login/LogIn_background.jpg";
import Login2 from "../static/login-2.png";
import Login3 from "../static/login-1.png";
import PetLogo from "../static/signup_login/PetLogo.png";
import ContactProLogo from "../static/signup_login/contactPrologo.PNG";
import googleSignin from "../static/signup_login/googleSignin.png";
import facebookSignin from "../static/signup_login/facebookSignin.png";
import linkedinSignin from "../static/signup_login/linkedinSignin.png";
import microsoft365Signin from "../static/signup_login/microsoft365Signin.png";
import InputBoxMui from "./ReusableComponents/InputBoxMui.jsx";
import CheckboxMui from "./ReusableComponents/CheckBoxMui.jsx";
import InputBoxPasswordMui from "./ReusableComponents/InputBoxPasswordMui.jsx";
import { apiUrl } from "../services/config.json";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { userIsConfirmed } from "../services/userConfirmationServices.js";
import opportunityService from "../services/opportunityService.js";
import { showToast } from "../messages.js";
// import lastVisitedReducer from "../redux/lastVisited/lastVisited.reducer.js";

const useStyles = makeStyles((theme) => ({
  // container: {
  //   display: "flex",
  //   flexWrap: "wrap",
  // },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   width: "100%",
  // },
  // dense: {
  //   marginTop: 19,
  // },
}));

function Login({ history, setCurrentUser, currentUser, setUserFeatures }) {
  const classes = useStyles();
  const facebookUrl = `${apiUrl}/api/auth/facebook`;
  let [email, setEmail] = useState("");
  const [values, setValues] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading, showPassword] = useState(false);
  const [isRememberMe, setRemeberMe] = useState(false);
  const [emailerrors, setEmailErrors] = useState("");
  const [passerrors, setPassErrors] = useState("");
  const [cms, setCms] = useState({});
  // const { confirmEmail, token } = useParams()
  const { loginParam } = useParams();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    console.log("loginformvalue", loginParam);
    if (undefined !== loginParam && loginParam !== "") {
      showToast('signup.emailVerification')
    }
  }, [loginParam]);

  // useEffect(() => {
  //     if (undefined !== confirmEmail && undefined !== token) {
  //         handleVerify(confirmEmail, token)
  //     }
  // }, [confirmEmail])

  // const handleVerify = async () => {
  //     setLoading(true)
  //     try {
  //         let response = await userIsConfirmed(confirmEmail, token)
  //         if (response.status == 200) {
  //             // toast.success(response.message)
  //             toast.success("Email Verification is Successful")
  //         }
  //         else {
  //             toast.info(response.message)
  //         }
  //     }
  //     catch (error) {
  //         console.log("error", error)
  //     }
  //     setLoading(false)
  // }

  async function handleEmail(e) {
    let email = e.target.value;
    setEmail(e.target.value);
    console.log(email);
    // var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+[a-zA-Z]$/;
    if (email === "") {
      setEmailErrors("Email is not allowed to be empty.");
    } else {
      setEmailErrors("");
      console.log("email_", reg.test(email));
      if (reg.test(email) === false) {
        setEmailErrors("Invalid Email.");
      } else {
        setEmailErrors(" ");
      }
      //  setPassword(password);
    }
  }
  async function handlePassword(e) {
    let password = e.target.value;
    if (password === "") {
      setPassErrors("Password is not allowed to be empty.");
    } else {
      setPassErrors("");
      setPassword(password);
    }
  }

  async function handleLogin() {
    setLoading(true);
    const hashedPassword = CryptoJS.SHA256(password).toString();
    let userEntry = {
      email: email.toLowerCase(),
      password: hashedPassword,
      // password: password,
    };
    let response = await authService.login(userEntry);
    console.log("handleLoginResponse", response);
    if (response.status !== 200) {
      setLoading(false);
      toast.error(response.message);
    } else {
      const { user, features } = response;
      setCurrentUser(user);
      setUserFeatures(features);
      // toast.info(response.message);
      // console.log(currentUser);
      //console.log(response.matchedRoles[0],"kjkjkjkj")
      localStorage.setItem("organizationId", user.organizationId);
      localStorage.setItem("accountType", user.accountType);
      localStorage.setItem("Role", user.Role);
      localStorage.setItem("token", response.token);
      localStorage.setItem("orgToken", response.user.organizationId);
      localStorage.setItem("currentUserEmail", userEntry.email);
      localStorage.setItem("FeatureRoleAccess", JSON.stringify(response.matchedRoles[0]));
      await ChatServices.setStatus(user._id, 'Online');
      const data=response?.organizationData?.data
      sessionStorage.setItem("organizationData", JSON.stringify(data));
      if (localStorage.getItem("rememberMe")) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("orgToken", response.user.organizationId);
      }
      sessionStorage.setItem("organizationId", user.organizationId);
      sessionStorage.setItem("accountType", user.accountType);
      sessionStorage.setItem("Role", user.Role);
      sessionStorage.setItem("userId", user._id);
      sessionStorage.setItem("token", response.token);
      sessionStorage.setItem("orgToken", response.user.organizationId);
      sessionStorage.setItem("currentUserEmail", userEntry.email);
      sessionStorage.setItem("FeatureRoleAccess", JSON.stringify(response.matchedRoles[0]));
      sessionStorage.setItem("organizationData", JSON.stringify(data));
      if (sessionStorage.getItem("rememberMe")) {
        sessionStorage.setItem("token", response.token);
        sessionStorage.setItem("orgToken", response.user.organizationId);
      }
      // const user = await authService.getCurrentUser();
      setLoading(false);
    
      if (user.isAdmin === true && user.isSubAdmin === false) {
        history.push({
          pathname: `/admindashboard`,
        });
      } else if (user.isAdmin === false && user.isSubAdmin === false) {
        history.push({
          pathname: `/dashboard`,
          // pathname: `/otpverification`,
          // pathname:user.is2Factor?`/otpverification`:`/dashboard`,
          state: user,
        });
      } else {
        history.push({
          // pathname: `/subAdminManageUser`,
          pathname: `/subAdminDashboard`,
        });
      }


      const orgresponse = await opportunityService.matchingOppPercentage(response.user.organizationId);
      const dataorg = orgresponse?.data;
      console.log(orgresponse, "matchingresponseinlogin");
      
      
      // Store the updated data back in session storage
      try {
        sessionStorage.setItem("organizationOpportunities", LZString.compressToUTF16(JSON.stringify(dataorg)));
        console.log("Data successfully stored in session storage");
      } catch (error) {
        console.error("Failed to store data in session storage:", error);
      }
      
      if (orgresponse) {
        console.log(orgresponse, "checkif");
        const additionalOrgResponse = await opportunityService.matchingOppPercentageAdditional(data);
        console.log(additionalOrgResponse, "additionalOrgResponse");
        const dataorg1 = additionalOrgResponse?.data;
      
        // const essentialDataOrg1 = extractEssentialData(dataorg1);
      
        // Retrieve the updated data from session storage
       let  existingOpportunities = JSON.parse(LZString.decompressFromUTF16(sessionStorage.getItem("organizationOpportunities"))) || [];
        console.log(existingOpportunities, "existingOpportunities");
        
        // Append the additional data
        const updatedOpportunities = existingOpportunities.concat(dataorg1);
        console.log(updatedOpportunities, "updatedOpportunities");
      
        // Compress and store the updated data back in session storage
        try {
          sessionStorage.setItem("organizationOpportunities", LZString.compressToUTF16(JSON.stringify(updatedOpportunities)));
          console.log("Additional data successfully stored in session storage");
        } catch (error) {
          console.error("Failed to store additional data in session storage:", error);
        }
      }

    }
  }


  const getCms = async () => {
    let cms = await getHomeCMS();
    setCms(cms.data);
    console.log(cms);
  };

  useEffect(() => {
    if (localStorage.getItem("rememberMe")) {
      history.push("/login");
    }
    getCms();
    // return () => toast.dismiss();
  }, []);

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      handleLogin();
    }
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleRememberMe = (e) => {
    const isRememberMe = e.target.checked;
    // if (isRememberMe) {
    //     localStorage.setItem("rememberMe", true);
    // } else {
    //     localStorage.setItem("rememberMe", false);
    // }
    setRemeberMe(isRememberMe);
  };
  function statusChangeCallback(response) {
    // Called with the results from FB.getLoginStatus().
    console.log("statusChangeCallback");
    console.log(response); // The current login status of the person.
    if (response.status === "connected") {
      // Logged into your webpage and Facebook.
      testAPI();
    } else {
      // Not logged into your webpage or we are unable to tell.
      document.getElementById("status").innerHTML =
        "Please log " + "into this webpage.";
    }
  }

  function checkLoginState() {
    // Called when a person is finished with the Login Button.
    window.FB.getLoginStatus(function (response) {
      // See the onlogin handler
      statusChangeCallback(response);
    });
  }


  (function (d, s, id) {
    // Load the SDK asynchronously
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  function testAPI() {
    // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
    console.log("Welcome!  Fetching your information.... ");
    window.FB.api("/me", function (response) {
      console.log("Successful login for: " + response.name);
      document.getElementById("status").innerHTML =
        "Thanks for logging in, " + response.name + "!";
    });
  }
  return (
    <div
      className="container-fluid"
      style={{
        backgroundImage: ` url(${Login1})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "auto",
        minHeight: "-webkit-fill-available",
        position: "absolute",
        top: 0,
        left: 0,
        // position: "relative"
      }}
    >
      <Loader loading={loading} />
      <div className="row mt-2 px-3 py-2" style={{ height: "3%" }}>
        <Link to="/">
          <img
            // src={image1}
            // alt="image1"
            // className="petLogo"
            alt="Group570"
            // src={PetLogo}
            src={ContactProLogo}
            style={{
              left: "0",
              width: "13%",
              height: "100%",
              objectFit: "cover",
            }}
          ></img>
        </Link>
      </div>

      <div
        className="row justify-content-end"
        style={{
          display: "flex",
          alignItems: "center",
          height: "90vh",
        }}
      >
        {windowWidth > 450 ? <>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex  justify-content-center text-center">

          <Card
            style={{
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              marginTop: "-20px",
            }}
            className="justify-content-center card-comp px-5 p-4"
          >
            <div className="text-center">
              <h3 className="mainHeading">Login</h3>
            </div>

            <div className="mt-3 py-2">
              <InputBoxMui
                fullWidth
                type="email"
                label="Email"
                value={email}
                onChange={(e) => handleEmail(e)}
                autoComplete="off"
                // placeholder="Username or Email"
              />
              <p
                className="p-2"
                style={{ color: "red", fontSize: "10px", textAlign: "left" }}
              >
                {emailerrors}
              </p>
            </div>

            <div className="py-2">
              <InputBoxPasswordMui
                fullWidth
                label="Password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={(e) => handlePassword(e)}
                autoComplete="off"
                // placeholder="Password"
              />
              <p
                className="p-2"
                style={{ color: "red", fontSize: "10px", textAlign: "left" }}
              >
                {passerrors}
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center" style={{width:"100%",display:"flex",flexDirection:"column"}}>
              <CheckboxMui
                label="Remember Me"
                isChecked={isRememberMe}
                onChange={handleRememberMe}
              />
              <Link to="/forgotpassword" className="forgotpass">
                Forgot Password?
              </Link>
            </div>

            <div className="text-center">
              <Button
                variant="primary"
                className="loginBtn"
                onClick={handleLogin}
                style={{width:"40%",marginTop:"10px"}}
              >
                Login
              </Button>
            </div>
            <p
              className="hr-lines py-2"
              style={{ color: "black", fontSize: "18px" }}
            >
              or
            </p>

            <a href={`${apiUrl}/auth/microsoft/callback`}>
              <div className="mt-2 text-center">
                <Button variant="primary" className="smBtn">
                  <img
                    className="smImg"
                    src={microsoft365Signin}
                    style={{height:'35px', width:'35px'}}
                  />
                  <a className="googlefblink">
                    Login with <strong>Microsoft 365</strong>
                  </a>
                </Button>
              </div>
            </a>

            <div>
              <p
                style={{
                  fontSize: "0.9rem",
                  lineHeight: "1.67",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                Don't have an account?{" "}
                <Link
                  to="/signup"
                  className="signinLink"
                  style={{ cursor: "pointer", fontSize: "0.9rem" }}
                >
                  Sign-up
                </Link>
              </p>
            </div>
            {/* <div className="mt-2 text-center">
                            <Button
                                variant="primary"
                                className="smBtn"
                                href={`${apiUrl}/auth/google/callback`}
                            >
                                <img
                                    className="smImg"
                                    src={googleSignin}
                                />
                                <a
                                     href={`${apiUrl}/auth/google`}
                                    className="googlefblink"
                                >Login with <strong>Google</strong></a>

                            </Button>
                        </div>

                        <div className="mt-2 text-center">
                            <Button
                                variant="primary"
                                className="smBtn"
                                href={`${apiUrl}/auth/facebook`}
                            >
                                <img
                                    className="smImg"
                                    src={facebookSignin}
                                />
                                <a
                                     href="https://m.facebook.com/login/?locale=en_GB"
                                    // href={`${apiUrl}/api/auth/facebook`}
                                    className="googlefblink"
                                >Login with <strong>Facebook</strong></a>   

                            </Button>
                        </div>

                        <div className="mt-2 text-center">
                            <Button
                                variant="primary"
                                className="smBtn"
                               // href={`${apiUrl}/auth/facebook`}
                            >
                                <img
                                    className="smImg"
                                    src={linkedinSignin}
                                />
                                <a
                                href="https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin"
                              className="googlefblink"
                                 >
                                  Login with <strong>LinkedIn</strong>
                                   </a>

                            </Button>
                        </div>

                        <div className="mt-2 text-center">
                            <Button
                                variant="primary"
                                className="smBtn"
                                // href={`${apiUrl}/auth/facebook`}
                            >
                                <img
                                    className="smImg"
                                    src={microsoft365Signin}
                                />
                                <a
                                     href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=3cf6df92-2745-4f6f-bbcf-19b59bcdb62a&scope=openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Fconfig.office.com%2FofficeSettings&client-request-id=3158f2ae-6814-4bc7-94ef-32890f7cdd4a&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.31.0&client_info=1&code_challenge=nx80Fwp77inPsfO3_qHLdO73j6eZ_eDc0x1TCiZ_3P0&code_challenge_method=S256&nonce=43365af5-c5f1-4982-a707-41999653a52c&state=eyJpZCI6ImJmZjhjZmQ3LWZkYjUtNGI1OC04NGRlLTUyOTVkZmI1NjZhNSIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D&claims=%7B%22access_token%22%3A%7B%22xms_cc%22%3A%7B%22values%22%3A%5B%22CP1%22%5D%7D%7D%7D"
                                    // href={`${apiUrl}/api/auth/facebook`}
                                    className="googlefblink"
                                >Login with <strong>Microsoft365</strong></a>

                            </Button>
                        </div> */}

            <div className="text-center mt-3">
              <Link
                to="/privacypolicy"
                target={"_blank"}
                className="privacyLink"
              >
                Term of use. Privacy policy
              </Link>
            </div>
          </Card>
        </div>
        </> : <>
      <div
  style={{
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
    flex: "0 0 100%",
    maxWidth: "100%",
    // Styles for larger screen sizes
    "@media (min-width: 576px)": { flex: "0 0 100%", maxWidth: "100%" },
    "@media (min-width: 768px)": { flex: "0 0 50%", maxWidth: "50%" },
    "@media (min-width: 992px)": { flex: "0 0 25%", maxWidth: "25%" },
    "@media (min-width: 1200px)": { flex: "0 0 25%", maxWidth: "25%" },
    // Styles specifically for min-width: 300px
    "@media (min-width: 300px)": { width: "100%"},
  }}
>

<Card
  style={{
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    marginTop: "-20px",
  }}
  className="justify-content-center card-comp px-5 p-4"
>
  <div className="text-center">
    <h3 className="mainHeading">Login</h3>
  </div>

  <div className="mt-3 py-2">
    <InputBoxMui
      fullWidth
      type="email"
      label="Email"
      value={email}
      onChange={(e) => handleEmail(e)}
      autoComplete="off"
      // placeholder="Username or Email"
    />
    <p
      className="p-2"
      style={{ color: "red", fontSize: "10px", textAlign: "left" }}
    >
      {emailerrors}
    </p>
  </div>

  <div className="py-2">
    <InputBoxPasswordMui
      fullWidth
      label="Password"
      type={values.showPassword ? "text" : "password"}
      value={values.password}
      onChange={(e) => handlePassword(e)}
      autoComplete="off"
      // placeholder="Password"
    />
    <p
      className="p-2"
      style={{ color: "red", fontSize: "10px", textAlign: "left" }}
    >
      {passerrors}
    </p>
  </div>
  <div className="d-flex justify-content-between align-items-center" style={{width:"100%",display:"flex",flexDirection:"column"}}>
    <CheckboxMui
      label="Remember Me"
      isChecked={isRememberMe}
      onChange={handleRememberMe}
    />
    <Link to="/forgotpassword" className="forgotpass">
      Forgot Password?
    </Link>
  </div>

  <div className="text-center">
    <Button
      variant="primary"
      className="loginBtn"
      onClick={handleLogin}
      style={{width:"80%",marginTop:"10px"}}
    >
      Login
    </Button>
  </div>
  <p
    className="hr-lines py-2"
    style={{ color: "black", fontSize: "18px" }}
  >
    or
  </p>

  <a href={`${apiUrl}/auth/microsoft/callback`}>
    <div className="mt-2 text-center">
      <Button variant="primary" className="smBtn">
        <img
          className="smImg"
          src={microsoft365Signin}
          style={{height:'35px', width:'35px',margin:"auto"}}
        />
        <a className="googlefblink" >
          <p style={{fontSize:"9px",marginTop:"15px"}}>Login with <strong>Microsoft 365</strong></p>
        </a>
      </Button>
    </div>
  </a>

  <div>
    <p
      style={{
        fontSize: "0.9rem",
        lineHeight: "1.67",
        textAlign: "center",
        marginTop: "10px",
      }}
    >
      Don't have an account?{" "}
      <Link
        to="/signup"
        className="signinLink"
        style={{ cursor: "pointer", fontSize: "0.9rem" }}
      >
        Sign-up
      </Link>
    </p>
  </div>
  {/* <div className="mt-2 text-center">
                  <Button
                      variant="primary"
                      className="smBtn"
                      href={`${apiUrl}/auth/google/callback`}
                  >
                      <img
                          className="smImg"
                          src={googleSignin}
                      />
                      <a
                           href={`${apiUrl}/auth/google`}
                          className="googlefblink"
                      >Login with <strong>Google</strong></a>

                  </Button>
              </div>

              <div className="mt-2 text-center">
                  <Button
                      variant="primary"
                      className="smBtn"
                      href={`${apiUrl}/auth/facebook`}
                  >
                      <img
                          className="smImg"
                          src={facebookSignin}
                      />
                      <a
                           href="https://m.facebook.com/login/?locale=en_GB"
                          // href={`${apiUrl}/api/auth/facebook`}
                          className="googlefblink"
                      >Login with <strong>Facebook</strong></a>   

                  </Button>
              </div>

              <div className="mt-2 text-center">
                  <Button
                      variant="primary"
                      className="smBtn"
                     // href={`${apiUrl}/auth/facebook`}
                  >
                      <img
                          className="smImg"
                          src={linkedinSignin}
                      />
                      <a
                      href="https://www.linkedin.com/login?fromSignIn=true&trk=guest_homepage-basic_nav-header-signin"
                    className="googlefblink"
                       >
                        Login with <strong>LinkedIn</strong>
                         </a>

                  </Button>
              </div>

              <div className="mt-2 text-center">
                  <Button
                      variant="primary"
                      className="smBtn"
                      // href={`${apiUrl}/auth/facebook`}
                  >
                      <img
                          className="smImg"
                          src={microsoft365Signin}
                      />
                      <a
                           href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=3cf6df92-2745-4f6f-bbcf-19b59bcdb62a&scope=openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Fconfig.office.com%2FofficeSettings&client-request-id=3158f2ae-6814-4bc7-94ef-32890f7cdd4a&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.31.0&client_info=1&code_challenge=nx80Fwp77inPsfO3_qHLdO73j6eZ_eDc0x1TCiZ_3P0&code_challenge_method=S256&nonce=43365af5-c5f1-4982-a707-41999653a52c&state=eyJpZCI6ImJmZjhjZmQ3LWZkYjUtNGI1OC04NGRlLTUyOTVkZmI1NjZhNSIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3D&claims=%7B%22access_token%22%3A%7B%22xms_cc%22%3A%7B%22values%22%3A%5B%22CP1%22%5D%7D%7D%7D"
                          // href={`${apiUrl}/api/auth/facebook`}
                          className="googlefblink"
                      >Login with <strong>Microsoft365</strong></a>

                  </Button>
              </div> */}

  <div className="text-center mt-3">
    <Link
      to="/privacypolicy"
      target={"_blank"}
      className="privacyLink"
    >
      Term of use. Privacy policy
    </Link>
  </div>
</Card>
</div>
        </>}
      
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setUserFeatures: (features) => dispatch(setUserFeatures(features)),
});

export default connect(null, mapDispatchToProps)(withRouter(Login));