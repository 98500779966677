import React, { useContext, useEffect, useRef, useState } from "react";
import ChatInput from "../ChatInput/ChatInput";
import ReceiverMessage from "../ReceiverMessage/ReceiverMessage";
import SenderMessage from "../SenderMessage/SenderMessage";
import "./ChatArea.css";
import { ChatContext } from "../../../contexts/ChatContext";
import ChatServices, { fetchMessages } from "../../../services/ChatServices";
import { useQuery } from "react-query";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, Grid, Typography } from "@mui/material";
import useRecorder from "../ChatInput/useRecorder";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import LetterAvatars from "../../Avatar";
import { socket } from '../Socket/Socket';
import LetterAvatarsecond from "../../AvatarSecond";
import { toast } from "react-toastify";
import DraftDialog from "../Dialog/DraftDialog";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ChatArea = ({aid, usr}) => {

  const { selectedContactId, userId, selectedGroupId, audioURL, showFilePage, audios, setAudios } = useContext(ChatContext);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [messageType, setMessageType] = useState("");
  const [disable, setDisable] = useState(false);
  const [latestMessage, setLatestMessage] = useState(null);
  const [AllMessage, setAllMessage] = useState([]);
  const [openDraftDialog, setOpenDraftDialog]= useState(false);
  const [from, setFrom] = useState('');
  const [audioIndex , setAudioIndex]= useState('');
  const dummy = useRef();

  useEffect(() => {
    if (dummy?.current) dummy.current.scrollIntoView({ behavior: "smooth" });
  }, [AllMessage, audios , selectedFile]);

  
  const isLoadingMsg = ( 
  <div className="chatarea">
  <div className="chatarea__container"></div>
  <div className="chatarea__messages"></div>
  <Grid container display='flex' flexDirection='row' justifyContent='center' alignItems="center" alignContent='center'>
  <Box py={2}>
  <Typography>
  Loading your latest messages. Sit tight, we're almost there!</Typography>
  </Box>
  </Grid>
  </div>
    );

  const isErrorMsg = (msg) => (
    <div className="chatarea">
      <div className="chatarea__container"></div>
      <div className="chatarea__messages"></div>
      <Grid container display='flex' flexDirection='row' justifyContent='center' alignItems="center" alignContent='center'>
        <Box py={2}>
          <Typography>
            "An error has occurred: " + {msg}
            </Typography>
        </Box>
      </Grid>
    </div>
  );


  const { data, error, isLoading, refetch } = useQuery(
    "contactMessages",
    async () => {
      if (selectedContactId && selectedContactId !== null) {
        const response = await ChatServices.fetchContactMessages(
          userId,
          selectedContactId
        );
        
        return response;
      }
      if (selectedGroupId && selectedGroupId !== null) {
        const response = await ChatServices.fetchGroupMessages(
          userId,
          selectedGroupId
        );

        return response;
      }
    },
    {
      enabled: !!selectedContactId || !!selectedGroupId, 
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (data) {
      setAllMessage(data.messages);
    }
  }, [data]);
   
  const handleReceiveMessage = (dataRe) => {
    if (dataRe) {
      console.log(dataRe, 'Received');

      setAllMessage(prevMessages => {
        const isDuplicate = prevMessages.some(message => message._id === dataRe._id);
        if (!isDuplicate) {
          return [...prevMessages, dataRe];
        }
        return prevMessages;
      });

        try {
          const response = ChatServices.markReadMsg(userId, selectedContactId);
          if (response.status === 200) {
            console.log('Message Read');
          } else {
            console.error("Error Opening Chat:", response.message);
          }
        } catch (error) {
          console.error("Error calling backend API:", error.message);
        }
      }
  };

  useEffect(() => {
    refetch();
  }, [selectedContactId, selectedGroupId]);

  // useEffect(() => {
  //   if (audios.length <= 0) {
  //     setAudios([audioURL]);
  //     setSelectedFile(null);
  //     setSelectedFileName(null);
  //     setMessageType('audio');
  //   } else {
  //     setAudios([audioURL]);
  //     setMessageType('audio');
  //     setSelectedFile(null)
  //     setSelectedFileName(null)
  //     setDisable(true);
  //   }
  // }, [audioURL]);

  useEffect(() => {
    if (audioURL && audioURL.data) {
      setAudios([audioURL]);
    }
  }, [audioURL]);


  if (isLoading) return isLoadingMsg;

  if (error) return isErrorMsg(error.message);

  function Deletedata(id) {
    const data = audios.filter((e, index) => {
      return index !== id;
    });
    setAudios(data);
    setSelectedFile(null)
    setSelectedFileName(null)
    setMessageType('')
    setDisable(false);
    handleClose();
  }

  const handleDeleteFileDraft = () => {
      setSelectedFile(null);
      setDisable(false);
      handleClose();
  }

  function stateaudio(e, index) {
    if (e.target.classList.contains("fa-play")) {
      e.target.classList.remove("fa-play");
      e.target.classList.add("fa-pause");
    } else if (e.target.classList.contains("fa-pause")) {
      e.target.classList.remove("fa-pause");
      e.target.classList.add("fa-play");
    }

    var myAudio = document.getElementById(`audioId${index}`);
    return myAudio.paused ? myAudio.play() : myAudio.pause();
  }

  const handleDownload = (base64Data, fileName , id) => {
    const element = document.createElement('a');
    const file = new Blob([base64Data], { type: 'application/octet-stream' });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    try {
      const response = ChatServices.markAsDownloaded(id, true);
        if (response.status === 200) {
          refetch();
        } else {
          console.error("Error Opening Chat:", response.message);
        }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }

  };

  const viewDocument = (base64Data, fileName) => {
    // Extracting the file extension
    const fileExtension = fileName.split('.').pop();

    // Mapping file extensions to MIME types
    const mimeTypes = {
      pdf: 'application/pdf',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      // Add more mappings as needed
    };

    // Determining the MIME type based on the file extension
    const mimeType = mimeTypes[fileExtension.toLowerCase()] || 'application/octet-stream';

    // Creating a Blob with the appropriate MIME type
    const file = new Blob([base64Data], { type: mimeType });

    // Creating a URL for the Blob
    const fileURL = URL.createObjectURL(file);

    // Opening the document in a new tab or window
    window.open(fileURL, '_blank');
  };


  const formattedDateTime = (date) =>{
    const dateTime = new Date(date);
    return dateTime.toLocaleString();
  }


    const deleteParticularMessage = async (id) => {
      try {
        let response;
        if (!selectedGroupId && selectedGroupId === null) {
          // If it's a personal chat
          response = await ChatServices.deleteMessage(userId, selectedContactId, id);
        } else {
          // If it's a group chat
          response = await ChatServices.deleteGroupMessage(userId, selectedGroupId, id);
        }
        if (response.status === 200) {
          await refetch();
        }
        else {
          console.error("Error Opening Chat:", response.message);
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error calling backend API:", error.message);
        toast.error(error.message);
      }
    }


  const HandleDialog = (item) => {
    setFrom(item);
    setOpenDraftDialog(true);
  }

  const handleClose = () => {
    setOpenDraftDialog(false);
  };



  return (
    <>
      <DraftDialog 
      openDraftDialog={openDraftDialog}
      setOpenDraftDialog={setOpenDraftDialog}
      from={from}
      handleClose={handleClose}
      Deletedata={()=>Deletedata(audioIndex)}
      handleDeleteFileDraft={handleDeleteFileDraft}
      />
    {
      showFilePage ? (
          <div className="chatarea">
      <div className="chatarea__container"></div>
      <div className="chatarea__messages">
        { 
          data.files?.map((message, index) => (
            <>
            {
            selectedGroupId ?
            <Box display='flex' flexDirection='row' gap='2px' > 
            <LetterAvatarsecond name={message?.userName} />
            <Box my={1} display='flex' justifyContent='start' p={1} sx={{ width: '100%' }}>
            <Card sx={{ bgcolor: '#F2F1EB !important', width: '100%' }}>
             <Box p={2}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' p={1}>
            <Typography>{message?.fileName}</Typography>
            {
            message?.download ?
            <CheckCircleIcon sx={{ fontSize: '40px', cursor: 'pointer' }}  />
            :
            <DownloadForOfflineIcon sx={{ fontSize: '40px', cursor: 'pointer' }} onClick={() => handleDownload(message?.fileContent, message?.fileName, message.messageId)} />
            }
            </Box>
            <Box p={1} display='flex' justifyContent='space-between'>
            <Typography variant="caption" fontSize='10px'>
            {message?.userName}
            </Typography>
            <Typography variant="caption" fontSize='10px'>
            {formattedDateTime(message?.dateTime)}
            </Typography>
            </Box>
            </Box>
            </Card>
            </Box>
            </Box>
            :
            <Box my={1} display='flex' justifyContent='start' p={1} sx={{ width: '100%' }}>
            <Card sx={{ bgcolor: '#F2F1EB !important', width:'100%' }}>
             <Box p={2}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' p={1}>
            <Typography>{message?.fileName}</Typography>
              {
              !message?.download ?
              <CheckCircleIcon sx={{ fontSize: '40px', cursor: 'pointer' }}  />
              :
              <DownloadForOfflineIcon sx={{ fontSize: '40px', cursor: 'pointer' }} onClick={() => handleDownload(message?.fileContent, message?.fileName, message.messageId)} />
              }           
              </Box>
            <Box p={1} display='flex' justifyContent='space-between'>
            <Typography variant="caption" fontSize='10px'>
            {formattedDateTime(message?.dateTime)}
            </Typography>
            </Box>
            </Box>
            </Card> 
            </Box>
            }
            </>
     
        ))}
        </div>
        </div>
        )
      :
      (
            <div className="chatarea">
            <div className="chatarea__container"></div>
            <div className="chatarea__messages">
                {AllMessage?.map((message, index) => {
                return message.userId === userId ? (
                  <SenderMessage key={index} message={message} handleDownload={() => handleDownload(message?.fileContent, message?.fileName, message.messageId)} deleteParticularMessage={() => deleteParticularMessage(message._id)} />
                ) : (
              <Box display='flex' flexDirection='row' gap='2px' key={index}> 
             <LetterAvatarsecond name={message.userName ? message.userName : 'Unknown'} />
              <ReceiverMessage handleDownload={() => handleDownload(message?.fileContent, message?.fileName, message.messageId)} message={message} />
             </Box>
                );
              })}
         


            {audios.length > 0 && audios[0].data ?
              <Grid container display='flex' flexDirection='row' justifyContent='center' alignItems="center" alignContent='center'>
                <Box component={Card} py={2} width='50ch'>
                  {audios?.map((res, index) =>
                    res.data ?
                      <div
                      key={index}
                        style={{
                          width: "100%",
                          height: 40,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          flexDirection: "row"
                        }}
                      >
                        <div>
                          <audio controls>
                            <source src={URL.createObjectURL(res.data)} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                        <DeleteIcon onClick={() =>{
                        HandleDialog('DraftAudio')
                        setAudioIndex(index)
                        }} sx={{cursor:'pointer' , verticalAlign: 'middle', fontSize: '35px', color: 'red' }} />
                      </div>
                      :
                      null
                  )}
                </Box>
              </Grid>
              :
              null
            }

            {selectedFile && selectedFile !== null ?
              <Grid container display='flex' flexDirection='row' justifyContent='center' alignItems="center" alignContent='center'>
                <Box component={Card} p={2} width='20ch' display='flex' flexDirection='row' gap={2}>
                  <Typography>{selectedFileName}</Typography>
                      <DeleteIcon onClick={() => HandleDialog('DraftFile')} sx={{ cursor: 'pointer', verticalAlign: 'middle', fontSize: '30px', color: 'red' }} />
                </Box>
              </Grid>
              :
              null
            }
                <div ref={dummy}></div>
              </div>

              <ChatInput aid={aid} usr={usr} handleReceiveMessage={handleReceiveMessage} disable={disable} setDisable={setDisable} messageType={messageType} setMessageType={setMessageType} selectedFileName={selectedFileName} setSelectedFileName={setSelectedFileName} selectedFile={selectedFile} setSelectedFile={setSelectedFile}  />
          </div>
        )
         }
    </>
  );
};

export default ChatArea;
