import React, { useState, useEffect } from "react";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import team from "../static/noteams.png";

import DataGridMui from "./ReusableComponents/DataGridMui";
import { DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import "../components/pages/AppFeatureRoleLists.css";
import SaveIcon from "@mui/icons-material/Save";
import { showToast } from "../messages";
import userService from "../services/userService";

import ModalFeatureMUI from "./ReusableComponents/ModalFeatureMUI";
import {
  editfeaturelist,
  postfeaturelist,
  getfeaturelist,
  deletefeaturelist,
} from "../services/features";
import {
  getappfeaturesrole,
  editappfeaturerole,
  deleteappfeaturerole,
  postappfeaturelist,
  saveFeatures,
  updateFeatures,
  getappfeaturerolesDefault,
} from "../services/appfeaturesrole";

import ModalFeatureUpdate from "./ReusableComponents/ModalFeatureUpdate";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { IconButton } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import FeatureLists from "./FeatureLists";

import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
import RoleModal from "./ReusableComponents/RoleModal";
import FeatureModal from "./ReusableComponents/FeatureModal";

const AppFeatureRoleLists = ({ currentUser, masterFeaturesdata }) => {
  const [inviteEmails, setInviteEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [inviteLimit, setInviteLimit] = useState(currentUser?.userCount);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [adminModalShow, setAdminModalShow] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [age, setAge] = React.useState("");
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [editableRowId, setEditableRowId] = useState(null); //new edit //
  const [editedData, setEditedData] = useState({}); //new edit //
  const [isUpdatemodalOpen, setIsUpdatemodalOpen] = useState(false);
  const [openPopup, setOpenPopUp] = useState(false);
  // const [features, setFeatures] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [masterRowdata, setMasterRowdata] = useState([]);
  const [currentRowData, setCurrentRowData] = useState();
  const [selectedRoleData, setSelectedRoleData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editValuesData, setEditValuesData] = useState(null);
  const [openFeatureDialog, setOpenFeatureDialog] = useState(false);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [isAdmin,setIsAdmin]=useState(false);
  const userDetails = useSelector((user) => user);
  console.log("userDetails", userDetails);
  const orgVirtualId = userDetails?.user?.currentUser?.organizationId || "";
  const sidebarOpen = useSelector((state) => state.sideNavbar.isOpen.stateValue);
  console.log(selectedRoleData, "setCurrentRow");

  let screenName = "Features Management";
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileView = windowWidth < 960

  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  console.log(features, "features")
  const featureArray = features?.featuresAccess;

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(feature => feature.ScreenName == screenName);

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features
      // setAccessTofeature(accessFeatures)
      // console.log("accessFeatures", accessFeatures);
    } else {
      console.log("No feature found with screen name:", screenName);
    }
  } else {
    console.log("No features found in the storage.");
  }

  const handleClick = (params) => {
    
    setSelectedRoleData(params.row); // Set the clicked row's data to the state
    let featurename = "View"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {
      showToast('Restriction.viewFeature')
    } else {
      setOpenFeatureDialog(true);
    }

  };


  const getUserData=async()=>{
    try {
      const email=sessionStorage.getItem("currentUserEmail");
      const data=email;
      const response=await userService.getuserdata(data);
      if(response.status==200){
        console.log(response?.data,"getuserResponse")
       if(response?.data?.isAdmin){
          setIsAdmin(true);
        }
      }else{
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
   }
  
   useEffect(()=>{
    getUserData();
   },[])

  const handleCloseFeatureDialog = () => {
    setOpenFeatureDialog(false);
  };
  const handleCloseRoleDialog = async () => {
    // alert("Closing")
    setOpenRoleDialog(false);
    getAppFeatureRole();
    setEditValuesData("");
  };



  const [editValues, seteditValues] = useState([]);
  const closeUpdateModal = () => {
    setIsUpdatemodalOpen(false);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const getAppFeatureRole = async () => {
    setLoading(true);
    try {
      const data = orgVirtualId;
      const response = await getappfeaturesrole({ data });
      console.log("getAppFeatureRoleresponse", response);
      if (response.status === 200) {
        console.log(response);
        if(isAdmin){
          setMasterRowdata(response.data);
        }else{
          const rowData = response.data.filter((data)=> data.rolename !== 'System Admin' && data.rolename !== 'Application Admin')
          setMasterRowdata(rowData);
        }
        
        console.log("response.data", response.data);
      } else {
        console.error("Error fetching details:", response.message);
      }
    } catch (error) {
      console.log("Error calling backend API:", error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    //    getDefaultOrgRoles();
      getAppFeatureRole();
    
    
  }, [isAdmin]);

  const handleEditOpen = (params) => {
    let featurename = "Edit"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {

      showToast('Restriction.editRole')
    } else {
      setOpenRoleDialog(true);
      setEditValuesData(params.row);
    }
  };
  // console.log(editValuesData,"editValuesData")
  const openRoleModal = async () => {
    // alert("open")
    let featurename = "Add Role"
    const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
    console.log(checkfeatureName[0].access, "checkfeatureName")
    if (!checkfeatureName[0].access) {

      showToast('Restriction.addRole')
    } else {
      setOpenRoleDialog(true)
    }

  }

  console.log(masterRowdata, "masterRowdatafor");
  console.log(selectedRoleData, "SelectedRowData");

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = [
    
    { 
      field: "rolename", 
      headerName: "Name", 
      // flex: 1 
      width: !mobileView && !sidebarOpen ? 400 : 310
    },
    { 
      field: "rolegroup", 
      headerName: "Group", 
      // flex: 1 
      width: !mobileView && !sidebarOpen ? 400 : 310
    },
    {
      field: "Features",
      headerName: "Features",
      // flex: 0.8,
      width: !mobileView && !sidebarOpen ? 252 : 300,
      renderCell: (params, orgid) => {
        return (
          <>
            <IconButton onClick={() => handleClick(params)}>
              <ControlPointIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      width: !mobileView && !sidebarOpen ? 160 : 120,
      renderCell: (params) => {
        const handleDeleteClick = async (_id) => {
          try {
            const data = params.row._id;
            let featurename = "Delete"
            const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
            console.log(checkfeatureName[0].access, "checkfeatureName")
            if (!checkfeatureName[0].access) {

              showToast('Restriction.deleteRole')
            } else {
              const response = await deleteappfeaturerole({ data });
              if (response.status === 200) {
                console.log("Item deleted successfully");

                setMasterRowdata(
                  masterRowdata.filter(
                    (feature) => feature._id !== params.row._id
                  )
                );
                toast.success(response.message);
              } else {
                console.error("Failed to delete item:", response.error);
                toast.error(response.message);
              }
            }

          } catch (error) {
            console.error("Error deleting item:", error);
          }
        };

        return (
          <button className="btn btn-md" onClick={handleDeleteClick}>
            <DeleteRoundedIcon style={{ color: "#ff0000" }} fontSize="small" />
          </button>
        );
      },
    },

    {
      field: "Save",
      headerName: "Edit",
      width: !mobileView && !sidebarOpen ? 170 : 130,
      renderCell: (params) => {
        return (
          <button className="btn btn-md">
            {<DriveFileRenameOutlineIcon onClick={() => handleEditOpen(params)} style={{ color: "#007bff" }} />}
            {/* <RoleModal
              open={isUpdatemodalOpen}
              close={handleCloseRoleDialog}
              editValues={editValuesData} // Pass the data of the row being edited
            /> */}
          </button>
        );
      },
    },
  ];





  return (
    <div style={{marginLeft: mobileView < 960 ? "10px" : "0px",}}>
      <Loader loading={loading} />

      <div  style={{ background: "#f0f0f7", paddingLeft: "5px" }}>
        <div className="row justify-content-end w-100 mb-3">
          <div className="col-md-2 justify-content-end"></div>
          {/* <ModalMUI open={modalOpen} onClose={() => setModalOpen(false)} /> */}
          {/* <ModalFeatureMUI   
                              getAppFeatureRole={getAppFeatureRole} 

                             getDefaultOrgRoles={getDefaultOrgRoles}
                    /> */}
          <IconButton

          >
            <button

              className="btn btn-primary"
              style={{
                width: "auto",
               
                marginTop: "-15px",
                zIndex: "100",
              }}
              onClick={openRoleModal}
            >
              Add Roles
            </button>
          </IconButton>
          {/* <RoleModal /> */}
        </div>

        <div className="row mt-2 w-100">
          {/* {filterTeam.length === 0 ? ( */}

          <>
          <div style={{ width: mobileView ? "95vw" : (sidebarOpen ? "76vw" : "90vw")}}>
              <DataGridMui
                height="60vh"
                getRowId={(data) => data._id}
                columns={columns}
                rows={masterRowdata}
                checkboxSelection={false}
                bgWhite={true}
              />
            </div>
          </>
        </div>
      </div>

      <Dialog
        open={openFeatureDialog}
        onClose={handleCloseFeatureDialog}
        fullWidth
        PaperProps={{
          className: "dialog",
          style: { zIndex: 9999 }, // Adjust the value as needed
        }}
      >
        <FeatureModal
          rowData={selectedRoleData}
          onClose={handleCloseFeatureDialog}
          roleName={selectedRoleData?.rolename} // Pass the role name
        />
      </Dialog>

      <Dialog
        open={openRoleDialog}
        onClose={handleCloseRoleDialog}
        fullWidth
        PaperProps={{
          className: "dialog",
          style: { zIndex: 9999 }, // Adjust the value as needed
        }}
      >
        <RoleModal
          editValues={editValuesData}
          onClose={handleCloseRoleDialog}
        // roleName={selectedRoleData?.rolename} // Pass the role name
        />
      </Dialog>
    </div>
  );
};
// new code//
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppFeatureRoleLists));
