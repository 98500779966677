import React from 'react';
import './ReorderPopup.css';
import { Dialog, DialogContent } from '@material-ui/core';

const ReorderPopup = ({ message, onClose, open }) => {
  console.log(message, onClose, "++++vvvvv")
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          minWidth: '300px',
          maxWidth: '40vw',
          padding: "15px",
          zIndex: 11000, 
          position: 'relative',
        },
      }}
      style={{ zIndex: 11000 }} 
    >
      <DialogContent >
        <p>{message}</p>
        <button onClick={onClose} style={{backgroundColor: 'rgb(61, 131, 250)', color: "white"}}>Got it</button>
      </DialogContent>
    </Dialog>

  );
};
export default ReorderPopup;
																																			