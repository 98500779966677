import React, { useState, useEffect, useRef } from "react";
import './ContentLibrary.css'
import xCircle from '../components/Images/xCircle.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import "../components/pages/ShareAssetsPage.css";

import { saveContentLibrary, getContentLibrary, getClausesList } from "../services/trackChanges";
import { toast } from "react-toastify";




const ContentLibrary = ({editorContent, openEditor, setContent, setHtmlContent, isOpen,modalClose, orgName, assetId, sectionId, userId}) =>{

    const fields = [
        "Organization Name",
        "Organization Logo",
        "Contact Person",
        "Contact No",
        "Contact Fax",
        "Organization Address"
      ];

    const performanceMetrics = {
        "Project Similarity and Scope": "Detailed descriptions of past projects that are similar in scope, complexity, and industry to the current project. Include specifics about the nature of the project, objectives, and the industry context.",
        "Success in Meeting Requirements": "Describe how company met or exceeded the requirements of previous contracts. Provide examples of deliverables that surpassed client expectations and any recognition or accolades received for outstanding performance.",
        "Quantitative Performance Metrics": "Provide measurable metrics from past projects, such as staying on budget, meeting deadlines, and client satisfaction ratings.",
        "Overcoming Project Challenges": "Describe any significant challenges encountered during past projects and the strategies implemented to overcome them. Provide examples of problem-solving approaches, adjustments made, and the outcomes of these efforts."
    };

    const clauses = ['FAR Clauses', 'DFAR Clauses']


    const [editStates, setEditStates] = useState({});
    const [fieldValues, setFieldValues] = useState({
        "Organization Name": "",
        "Organization Logo": "",
        "Contact Person": "",
        "Contact No": "",
        "Contact Fax": "",
        "Organization Address": "",
        "Project Similarity and Scope":"",
        "Success in Meeting Requirements":"",
        "Quantitative Performance Metrics": "",
        "Overcoming Project Challenges":"",
        "FAR Clauses": [],
        "DFAR Clauses": [],
        "Organization Logo Name":''

    });

    const [farClauses, setFarClauses] = useState([])
    const [dfarClauses, setDfarClauses] = useState([])
    const [clauseApiCalled, setClauseApiCalled] = useState(false)
   
    const [selectedFields, setSelectedFields] = useState({})

    const [allSelectedOD, setAllSelectedOD] = useState(false);
    const [selectedFieldsOD, setSelectedFieldsOD] = useState(fields.reduce((acc, field) => ({ ...acc, [field]: false }), {}));

    const handleSelectAllOD = (event) => {
        const checked = event.target.checked;
        setAllSelectedOD(checked);
        const updatedSelection = fields.reduce((acc, field) => ({ ...acc, [field]: checked }), {});
        setSelectedFieldsOD(updatedSelection);
    };

    const handleCheckboxChangeOD = (field) => {
        const updatedSelection = { ...selectedFieldsOD, [field]: !selectedFieldsOD[field] };
        setSelectedFieldsOD(updatedSelection);
        const allChecked = Object.values(updatedSelection).every((isSelected) => isSelected);
        setAllSelectedOD(allChecked);
    };

    const [allSelectedPM, setAllSelectedPM] = useState(false);
    const [selectedFieldsPM, setSelectedFieldsPM] = useState(Object.keys(performanceMetrics).reduce((acc, field) => ({ ...acc, [field]: false }), {}));

    const handleSelectAllPM = (event) => {
        const checked = event.target.checked;
        setAllSelectedPM(checked);
        const updatedSelection = Object.keys(performanceMetrics).reduce((acc, field) => ({ ...acc, [field]: checked }), {});
        setSelectedFieldsPM(updatedSelection);
    };

    const handleCheckboxChangePM = (field) => {
        const updatedSelection = { ...selectedFieldsPM, [field]: !selectedFieldsPM[field] };
        setSelectedFieldsPM(updatedSelection);
        const allChecked = Object.values(updatedSelection).every((isSelected) => isSelected);
        setAllSelectedPM(allChecked);
    };



    ////////////////////////////////////////////////////////////CLAUSES CHECKBOX/////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        const [clausesCheckbox, setClausesCheckbox] = useState(false)
        const [farCheckbox, setFarCheckbox] = useState(false);
        const [farClauseCheckboxes, setFarClauseCheckboxes] = useState([]);    
        const [selectedFarClauses, setSelectedFarClauses] = useState([]);
        const [searchFarQuery, setSearchFarQuery] = useState('');
        const [searchDfarQuery, setSearchDfarQuery] = useState('');
        const [filteredFarClauses, setFilteredFarClauses] = useState(farClauses);
        const [filteredDfarClauses, setFilteredDfarClauses] = useState(dfarClauses);
        const [selectedFieldsCL, setSelectedFieldsCL] = useState(clauses.reduce((acc, field) => ({ ...acc, [field]: false }), {}));
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
        
        useEffect(()=>{
            setFarClauseCheckboxes(new Array(filteredFarClauses.length).fill(false));
            
        },[clauseApiCalled])

        useEffect(() => {
    
            if (searchFarQuery === '') {
              setFilteredFarClauses(farClauses);
            } else {
              const lowercasedQuery = searchFarQuery.toLowerCase();
              setFilteredFarClauses(
                farClauses.filter(clause =>
                  clause.toLowerCase().includes(lowercasedQuery)
                )
              );
            }
          }, [searchFarQuery, farClauses]);

          useEffect(() => {
    
            if (searchDfarQuery === '') {
              setFilteredDfarClauses(dfarClauses);
            } else {
              const lowercasedQuery = searchDfarQuery.toLowerCase();
              setFilteredDfarClauses(
                dfarClauses.filter(clause =>
                  clause.toLowerCase().includes(lowercasedQuery)
                )
              );
            }
          }, [searchDfarQuery, dfarClauses]);

          const handleFarSearchChange = (e) => {
            setSearchFarQuery(e.target.value);
          };

          const handleDfarSearchChange = (e) => {
            setSearchDfarQuery(e.target.value);
          };

          const handleClausesCheckboxChange = (e) => {
            const checked = e.target.checked;
            setClausesCheckbox(checked);
            setFarCheckbox(checked)
            setDfarCheckbox(checked)
            // setSelectedFarClauses(checked ? farClauses : []);
            // setSelectedDfarClauses(checked ? dfarClauses : []);
            const updatedSelection = clauses.reduce((acc, field) => ({ ...acc, [field]: checked }), {});
            setSelectedFieldsCL(updatedSelection);
        };
        
        const handleFarCheckboxChange = (e) => {
            const updatedSelection = { ...selectedFieldsCL, ['FAR Clauses']: !selectedFieldsCL['FAR Clauses'] };
            setSelectedFieldsCL(updatedSelection);
            const checked = e.target.checked;
            setFarCheckbox(checked);
            // setFarClauseCheckboxes(new Array(farClauses.length).fill(checked));
            // if (checked) {
            //     setSelectedFarClauses(farClauses);
            // } else {
            //     setSelectedFarClauses([]);
            // }
            
        };
        
        const handleFarClausesCheckboxChange = (index) => (e) => {
            const checked = e.target.checked;
            const updatedCheckboxes = [...farClauseCheckboxes];
            updatedCheckboxes[index] = checked;
            
            setFarClauseCheckboxes(updatedCheckboxes);
            
            if (checked) {
                setSelectedFarClauses((prevSelected) => [...prevSelected, farClauses[index]]);
            } else {
                setSelectedFarClauses((prevSelected) =>
                    prevSelected.filter((clause) => clause !== farClauses[index])
            
        );
      }
    
    // setFarCheckbox(updatedCheckboxes.every((checkbox) => checkbox));
    };


        const [dfarCheckbox, setDfarCheckbox] = useState(false);
        const [dfarClauseCheckboxes, setDfarClauseCheckboxes] = useState([]);    
        const [selectedDfarClauses, setSelectedDfarClauses] = useState([]);
        
        useEffect(()=>{
            setDfarClauseCheckboxes(new Array(dfarClauses.length).fill(false));
            // const dfarClausesFromResponse = fieldValues["DFAR Clauses"];
            // console.log(dfarClausesFromResponse,"lololol")
    
            // // Update dfarClauseCheckboxes state based on API response
            // const updatedDfarClauseCheckboxes = filteredDfarClauses.map(clause => 
            //     dfarClausesFromResponse.includes(clause)
            // );
            // console.log(updatedDfarClauseCheckboxes,"lololol")
            
            // setDfarClauseCheckboxes(updatedDfarClauseCheckboxes);
        },[clauseApiCalled])
        
        const handleDfarCheckboxChange = (e) => {
            const updatedSelection = { ...selectedFieldsCL, ['DFAR Clauses']: !selectedFieldsCL['DFAR Clauses'] };
            setSelectedFieldsCL(updatedSelection);
            const checked = e.target.checked;
            setDfarCheckbox(checked);
            // setDfarClauseCheckboxes(new Array(dfarClauses.length).fill(checked));
            // if (checked) {
            //     setSelectedDfarClauses(dfarClauses);
            // } else {
            //     setSelectedDfarClauses([]);
            // }
        };
        
        const handleDfarClausesCheckboxChange = (index) => (e) => {
            const checked = e.target.checked;
            const updatedCheckboxes = [...dfarClauseCheckboxes];
            updatedCheckboxes[index] = checked;

            
            setDfarClauseCheckboxes(updatedCheckboxes);
            
            if (checked) {
                setSelectedDfarClauses((prevSelected) => [...prevSelected, dfarClauses[index]]);
            } else {
                setSelectedDfarClauses((prevSelected) =>
                    prevSelected.filter((clause) => clause !== dfarClauses[index])
            
        );
      }
    
    // setDfarCheckbox(updatedCheckboxes.every((checkbox) => checkbox));
    };


    
    ////////////////////////////////////////////////////////////CLAUSES CHECKBOX/////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    

    useEffect(()=>{
        fetchContentLibrary()
        fetchClausesList()
    },[])

    useEffect(()=>{

       setSelectedFields({...selectedFieldsOD, ...selectedFieldsPM, ...selectedFieldsCL})
    },[selectedFieldsOD, selectedFieldsPM,selectedFieldsCL])

    useEffect(()=>{
        
        setFieldValues(prevValues => ({
            ...prevValues,
            "FAR Clauses": selectedFarClauses,
            "DFAR Clauses": selectedDfarClauses
          }));
    },[selectedFarClauses, selectedDfarClauses])

    useEffect(()=>{
        console.log(fieldValues,"jojojo")
    },[fieldValues])



    const fetchClausesList = async () => {
        const response = await getClausesList()
        const processClauses = (clauses) => {
            return clauses.map(clause => clause.split('#')[1]);
          };
  
          setFarClauses(processClauses(response?.farClauses));
          setDfarClauses(processClauses(response?.dfarClauses));
          setClauseApiCalled(true)
    }

    const fetchContentLibrary = async () => {
        const response = await getContentLibrary(orgName, assetId, sectionId, userId)
        setFieldValues({
            "Organization Name": response?.data?.fieldValues["Organization Name"] ,
            "Organization Logo": response?.data?.fieldValues["Organization Logo"],
            "Contact Person": response?.data?.fieldValues["Contact Person"],
            "Contact No": response?.data?.fieldValues["Contact No"],
            "Contact Fax": response?.data?.fieldValues["Contact Fax"],
            "Organization Address": response?.data?.fieldValues["Organization Address"],
            "Project Similarity and Scope":response?.data?.fieldValues["Project Similarity and Scope"],
            "Success in Meeting Requirements":response?.data?.fieldValues["Success in Meeting Requirements"],
            "Quantitative Performance Metrics": response?.data?.fieldValues["Quantitative Performance Metrics"],
            "Overcoming Project Challenges":response?.data?.fieldValues["Overcoming Project Challenges"],
            "FAR Clauses": response?.data?.fieldValues["FAR Clauses"],
            "DFAR Clauses": response?.data?.fieldValues["DFAR Clauses"],
            "Organization Logo Name": response?.data?.fieldValues["Organization Logo Name"]

        });
        

    }

    const [searchTerm, setSearchTerm] = useState("");

    const orgDetailsRef = useRef(null);
    const performanceMetricsRef = useRef(null);
    const clausesRef = useRef(null);


    const handleSearch = () => {
        const lowercasedTerm = searchTerm.toLowerCase();
        if (orgDetailsRef.current && "organization details".includes(lowercasedTerm)) {
            orgDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        } else if (performanceMetricsRef.current && "performance metrics".includes(lowercasedTerm)) {
            performanceMetricsRef.current.scrollIntoView({ behavior: "smooth" });
        } else if (clausesRef.current && "clauses".includes(lowercasedTerm)) {
            clausesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleLogoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFieldValues(prevState => ({
                    ...prevState,
                    "Organization Logo": e.target.result,
                    "Organization Logo Name": file.name
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleEditClick = (field) => {
        setEditStates({ ...editStates, [field]: true });
    };

    const handleDeleteClick = (field) => {
        // if(field==="Organization Logo"){
        //     setLogoFileName("")
        // }
        setFieldValues({ ...fieldValues, [field]: "" });
    };

    const handleFieldChange = (field, value) => {
        setFieldValues({ ...fieldValues, [field]: value });
    };



    const handleAddContentLibrary = async () => {
        try {
          let response = await saveContentLibrary(orgName, assetId, sectionId, userId, fieldValues);
          modalClose();
        } catch (error) {
          console.error("Error saving content library:", error);
        }
      
        const template = generateTemplate();
        const checkTemplate = await checkTemplateExists(template); // Wait for checkTemplateExists to complete
        // console.log("CheckTemplate", checkTemplate);
      
        if (checkTemplate) {
          setContent(checkTemplate);
          setHtmlContent(checkTemplate);
        } else {
          setContent(editorContent + template);
          setHtmlContent(editorContent + template);
        }
      };



      const checkTemplateExists = async (template) => {
        try{
            if (!editorContent) {
                console.warn("No editor content available.");
                return false;
            }
        
            const maxHeight = 1060;
            const tempDiv = document.createElement('div');
            tempDiv.style.position = 'absolute';
            tempDiv.style.visibility = 'hidden';
            tempDiv.style.top = '0';
            tempDiv.style.left = '0';
            tempDiv.style.width = '803px';
            tempDiv.style.display = 'none';
            tempDiv.style.top = '-9999px';
            tempDiv.style.left = '-9999px';
            tempDiv.style.padding = `0px 0px`;
            tempDiv.style.boxSizing = 'border-box';
            tempDiv.style.whiteSpace = 'pre-wrap';
            // tempDiv.style.lineHeight = '1.5';
            tempDiv.style.height = `${maxHeight}px`;
            tempDiv.innerHTML = editorContent;
            document.body.appendChild(tempDiv);
        
            const pageContainer = tempDiv.querySelector('#page-container');
            const firstPfElement = tempDiv.querySelector('.pf');
        
            if (!pageContainer || !firstPfElement) {
                console.warn("Page Container or first .pf element not found in the provided content.");
                return false;
            }
            let selectedParentElement = await getEditableAreaWithRetries()
           if(selectedParentElement){
            return new Promise((resolve) => {
               
               
                // console.log("selectedParentElement",selectedParentElement)
               
                //  getEditableAreaWithRetries().then((selectedParentElement) => {
                setTimeout(() => {
                    let currentPfElement = selectedParentElement?.closest('.pf');
                    let editableArea = currentPfElement.querySelector('.editable-area');
        
                    // Capture content before the cursor
                    const rangeBefore = document.createRange();
                    rangeBefore.setStart(editableArea, 0);
                    rangeBefore.setEnd(window.getSelection().getRangeAt(0).startContainer, window.getSelection().getRangeAt(0).startOffset);
                    const beforeCursorContentFragment = rangeBefore.cloneContents();
                    const tempBeforeDiv = document.createElement('div');
                    tempBeforeDiv.appendChild(beforeCursorContentFragment);
                    const beforeCursorContent = tempBeforeDiv.innerHTML;
                    // console.log("Content Before Cursor Position:", beforeCursorContent);
        
                    // Merge content from the current cursor position onward
                    const rangeAfter = document.createRange();
                    rangeAfter.setStart(window.getSelection().getRangeAt(0).startContainer, window.getSelection().getRangeAt(0).startOffset);
                    rangeAfter.setEnd(editableArea, editableArea.childNodes.length);
                    const afterCursorContentFragment = rangeAfter.cloneContents();
                    const tempAfterDiv = document.createElement('div');
                    tempAfterDiv.appendChild(afterCursorContentFragment);
                    const afterCursorContent = tempAfterDiv.innerHTML;
                    // console.log("Merged Content From Cursor Position:", afterCursorContent);
        
                    let mergedContent = afterCursorContent;
                    let nextPfElement = currentPfElement.nextElementSibling;
        
                    while (nextPfElement) {
                        mergedContent += nextPfElement.querySelector('.editable-area').innerHTML;
                        nextPfElement = nextPfElement.nextElementSibling;
                    }
        
                    let currentPageNo = parseInt(currentPfElement.getAttribute('data-page-no'));
                    let nextPageNo = currentPageNo + 1;
        
                    // Ensure that we are targeting .pf elements inside tempDiv
                    while (tempDiv.querySelector(`#pf${nextPageNo}`)) {
                        const pageToRemove = tempDiv.querySelector(`#pf${nextPageNo}`);
                        console.log(`Removing page: pf${nextPageNo} from tempDiv`);
                        pageToRemove.remove();
                        nextPageNo++;
                    }
        
                    // console.log(`Current tempDiv after removing pages:`, tempDiv.innerHTML);
        
                    editableArea.innerHTML = beforeCursorContent;
                    // console.log("Content Before Table Insert:", editableArea.innerHTML);
        
                    // Additional logic for handling the insertion of tables, overflow handling, etc., remains unchanged
                    let newHeight = editableArea.scrollHeight;
                    const tempContainer = document.createElement('div');
                    tempContainer.innerHTML = template;
                    const elements = Array.from(tempContainer.querySelectorAll('tr, h5, h6, p, div, strong, b'));
                    let rowIndex = 0;
        
                    let currentTableWrapper = document.createElement('table');
                    let currentTableBody = document.createElement('tbody');
                    currentTableWrapper.appendChild(currentTableBody);
                    currentTableWrapper.style.width = '100%';
                    let tableRows = [];
                    
                    const dummy2Element = document.createElement('div');
                    
                    // Clone the editableArea's structure
                    dummy2Element.style.position = 'absolute';
                    dummy2Element.style.visibility = 'hidden';
                    dummy2Element.style.width = editableArea.offsetWidth + 'px';
                    dummy2Element.style.whiteSpace = 'pre-wrap'; // Ensure word wrapping behaves correctly
                    dummy2Element.style.lineHeight = '1.5'; // Match line height if needed
                    document.body.appendChild(dummy2Element)
                    
                    while (rowIndex < elements.length) {
                        const element = elements[rowIndex];
                        if (element.tagName === "B") {
                            if (tableRows.length > 0) {
                                currentTableBody.innerHTML = "";
                                tableRows.forEach(row => currentTableBody.appendChild(row));
                                editableArea.appendChild(currentTableWrapper);
                                tableRows = [];
                            }
        
                            editableArea.appendChild(element);
                            dummy2Element.innerHTML = editableArea.innerHTML
                            newHeight = dummy2Element.scrollHeight;
                            console.log("dummy2Element height first  ",newHeight)
                            if (newHeight > maxHeight-20) {
                                editableArea.removeChild(element);
                           
                                    tempDiv.querySelector(`#${currentPfElement.id} .editable-area`).innerHTML = editableArea.innerHTML;
                            
        
                                let nextPfElement = currentPfElement.nextElementSibling;
                                if (!nextPfElement || !nextPfElement.classList.contains('pf')) {
                                    nextPfElement = firstPfElement.cloneNode(true);
                                    const newEditableArea = nextPfElement.querySelector('.editable-area');
                                    newEditableArea.innerHTML = "";
        
                                    const newPfNumber = parseInt(currentPfElement.getAttribute('data-page-no')) + 1;
                                    nextPfElement.id = `pf${newPfNumber}`;
                                    nextPfElement.setAttribute('data-page-no', newPfNumber.toString());
        
                                    pageContainer.appendChild(nextPfElement);
                                    tempDiv.querySelector('#page-container').appendChild(nextPfElement);
                                }
        
                                currentPfElement = nextPfElement;
                                editableArea = nextPfElement.querySelector('.editable-area');
                                editableArea.appendChild(element);
                            }
        
                            currentTableWrapper = document.createElement('table');
                            currentTableBody = document.createElement('tbody');
                            currentTableWrapper.appendChild(currentTableBody);
                            currentTableWrapper.style.width = '100%';
                            currentTableBody.style.width = '100%';
                        } else {
                            tableRows.push(element);
                            currentTableBody.innerHTML = "";
                            tableRows.forEach(row => currentTableBody.appendChild(row));
                            editableArea.appendChild(currentTableWrapper);
                            dummy2Element.innerHTML = editableArea.innerHTML
                            newHeight = dummy2Element.scrollHeight;
    
                            // console.log("dummy2Element height last ",newHeight)
        
                            if (newHeight > maxHeight-20) {
                                tableRows.pop();
                                editableArea.removeChild(currentTableWrapper);
                                currentTableBody.innerHTML = "";
                                tableRows.forEach(row => currentTableBody.appendChild(row));
                                editableArea.appendChild(currentTableWrapper);
                            
                                    tempDiv.querySelector(`#${currentPfElement.id} .editable-area`).innerHTML = editableArea.innerHTML;
                               
        
                                let nextPfElement = firstPfElement.cloneNode(true);
                                const newEditableArea = nextPfElement.querySelector('.editable-area');
                                newEditableArea.innerHTML = "";
                                const newPfNumber = parseInt(currentPfElement.getAttribute('data-page-no')) + 1;
                                nextPfElement.id = `pf${newPfNumber}`;
                                nextPfElement.setAttribute('data-page-no', newPfNumber.toString());
                                pageContainer.appendChild(nextPfElement);
                                tempDiv.querySelector('#page-container').appendChild(nextPfElement);
                                console.log(`Created new page: pf${newPfNumber}`);
        
                                currentPfElement = nextPfElement;
                                editableArea = nextPfElement.querySelector('.editable-area');
                                tableRows = [element];
                                newHeight = editableArea.scrollHeight;
                            }
                        }
                        rowIndex++;
                    }
        
                    // console.log("newHeight after adding table", newHeight , tempDiv.innerHTML );
        
                    // Now add the remaining words
                    let remainingContent = mergedContent;
                    let words = remainingContent?.replace(/\n/g, '<br>').split(/(<br>|\s+)/).filter(Boolean);
                    let currentPageContent = editableArea.innerHTML;
                    const dummyElement = document.createElement('div');
                    
                    // Clone the editableArea's structure
                    dummyElement.style.position = 'absolute';
                    dummyElement.style.visibility = 'hidden';
                    dummyElement.style.width = '800px';
                    dummyElement.style.whiteSpace = 'pre-wrap'; // Ensure word wrapping behaves correctly
                    dummyElement.style.lineHeight = '1.5'; // Match line height if needed
                    document.body.appendChild(dummyElement);
                    
    
                    let containsTableRows = editableArea.querySelectorAll('tr').length > 0;
                    let adjustedMaxHeight = 1070;
    
                    // If table rows are present, calculate their total height
                    if (containsTableRows) {
                        let totalTableRowsHeight = 0;
                        const tableRows = editableArea.querySelectorAll('tr');
                        tableRows.forEach((row) => {
                            const rowDummy = document.createElement('div');
                            rowDummy.style.position = 'absolute';
                            rowDummy.style.visibility = 'hidden';
                            rowDummy.style.width = '800px';
                            rowDummy.style.whiteSpace = 'pre-wrap';
                            rowDummy.style.lineHeight = '1.5';
                            rowDummy.innerHTML = row.outerHTML;
                            document.body.appendChild(rowDummy);
                            totalTableRowsHeight += rowDummy.scrollHeight;
                            document.body.removeChild(rowDummy);
                        });
    
                        // Adjust the maxHeight based on the height taken by table rows
                        adjustedMaxHeight = maxHeight - totalTableRowsHeight;
                    }
    
    
                    words.forEach((word) => {
                    const trimmedWord = word.trim();
                    if (trimmedWord === '<br>' && currentPageContent === '') {
                        return; // Skip this iteration
                    }
                    const newContent = currentPageContent + trimmedWord + " ";
    
                    // Use the exact HTML structure to replicate accurate height
                    dummyElement.innerHTML = newContent;
                    const newHeight = dummyElement.scrollHeight;
                    // console.log("scroll height in words", newHeight, currentPageContent);
    
                    // Check if adding this word pushes the height past the allowed max or triggers a new line
                    if (newHeight > adjustedMaxHeight) {
                        // Overflow detected - create new page
                        // console.log("adjustedMaxHeight",adjustedMaxHeight)
                        tempDiv.querySelector(`#${currentPfElement.id} .editable-area`).innerHTML = currentPageContent;
    
                        let nextPfElement = firstPfElement.cloneNode(true);
                        const newEditableArea = nextPfElement.querySelector('.editable-area');
                        newEditableArea.innerHTML = "";
                        const newPfNumber = parseInt(currentPfElement.getAttribute('data-page-no')) + 1;
                        nextPfElement.id = `pf${newPfNumber}`;
                        nextPfElement.setAttribute('data-page-no', newPfNumber.toString());
                        pageContainer.appendChild(nextPfElement);
                        adjustedMaxHeight = 1070
                        // console.log(`Created new page: pf${newPfNumber} due to content overflow.`);
    
                        // Reset for the new page
                        currentPfElement = nextPfElement;
                        editableArea = newEditableArea;
                        currentPageContent = trimmedWord === '<br>' ? '' : trimmedWord + " ";
                    } else {
                        // No overflow - continue appending to the current page
                        currentPageContent = newContent;
                    }
                    });
                    
                    // Clean up the dummy element
                    document.body.removeChild(dummyElement);
                    
                    // Push the last page's content if there's any remaining
                    if (currentPageContent) {
                        editableArea.innerHTML = currentPageContent.trim(); // Ensure no trailing spaces or line breaks
                        // console.log("Final page content:", pageContainer.innerHTML);
                    }
    
                    tempDiv.querySelector(`#${currentPfElement.id} .editable-area`).innerHTML = editableArea.innerHTML
                    resolve(tempDiv.innerHTML);
                    document.body.removeChild(tempDiv);
                }, 3000);
            ;
        // )}
        });
           }
        }catch(error){
            toast.error("error occured")
            console.log("error in ",error)
        }
      
    };
    
    
    function getEditableAreaWithRetries(retries = 15, delay = 300) {
 
        return new Promise((resolve, reject) => {
            function attempt() {
                const selection = window.getSelection();
                if (!selection || selection.rangeCount === 0) {
                    console.warn("No selection found or range is empty.");
                    return;
                }
    
                const range = selection.getRangeAt(0);
                const anchorNode = range.startContainer;
                let selectedParentElement = anchorNode.nodeType === Node.ELEMENT_NODE ? anchorNode : anchorNode.parentElement;
    
                if (selectedParentElement) {
                    // If the element is found, resolve the promise
                    resolve(selectedParentElement)
                } else if (retries > 0) {
                    // Retry mechanism
                     setTimeout(() => {
                        retries--;
                        attempt();
                    }, delay);
                } else {
                    // If retries are exhausted, reject the promise
                    console.warn("Failed to find the selected parent after multiple attempts.");
                    reject(false)  ;
                }
            }
            
            attempt();
        });
    }
    


    

    const generateTemplate = () => {
        let template = "";
    
        const allFieldsSelected = Object.values(selectedFields).every(value => !value);
    
        // If all fields are false in selectedFields, display all fields in fieldValues
        if (allFieldsSelected) {
            return "";
            // template += "<br/><h2>Organization Details</h2>";
            // template += "<ul>";
    
            // // Iterate over all fields in fieldValues
            // Object.entries(fieldValues).slice(0, 6).forEach(([key, value]) => {
                
            //     if (key === "Organization Logo") {
            //         template += `<li><strong>${key}:</strong> <img src="${value}" alt="Organization Logo" style="max-width: 100px; max-height: 100px;" /></li>`;
            //     } else {
            //         template += `<li><strong>${key}:</strong> ${value}</li>`;
            //     }
                
            // });
            // template += "<hr>";
            //     template += "<h2>Performance Metrics</h2>";
            //     template += "<ul>";
    
            //     // Iterate over Performance Metrics fields
            //     Object.entries(fieldValues).slice(6,10).forEach(([key, value]) => {
                    
            //             template += `<li><strong>${key}:</strong> ${value}</li><br/>`;
                    
            //     });
    
    
            // template += "</ul>";
            
            // template += "<hr>";
            // template += "<h2>Clauses</h2>";
            // template += "<ul>";

            // Object.entries(fieldValues).slice(10,12).forEach(([key, value]) => {
                    
            //     template += `<li><strong>${key}:</strong> ${value}</li><br/>`;
            
            //     });
            //     template += "</ul>";

        } else {
            
            if (selectedFields["Organization Name"] || selectedFields["Organization Logo"] || selectedFields["Contact Person"] || selectedFields["Contact No"] || selectedFields["Contact Fax"] || selectedFields["Organization Address"]) {
                template += "<b>ORGANIZATION DETAILS</b>";
                template += "<table class='full-width-table'>";
            
                const fieldEntries = Object.entries(fieldValues).slice(0, 6);
                for (let i = 0; i < fieldEntries.length; i++) {
                    const [key, value] = fieldEntries[i];
                    if (selectedFields[key]) {
                        template += "<tr>";
                        if (key === "Organization Logo") {
                            template += `<td>${key}:</td><td><img src="${value || ''}" alt="Organization Logo" class="organization-logo" /></td>`;
                        } else {
                            template += `<td>${key}:</td><td>${value || ''}</td>`;
                        }
                        template += "</tr>";
                    }
                }
                template += "</table>";
            }
    
            // Add Performance Metrics section if selected
            if (selectedFields["Project Similarity and Scope"] || selectedFields["Success in Meeting Requirements"] || selectedFields["Quantitative Performance Metrics"] || selectedFields["Overcoming Project Challenges"]) {
                template += "<hr>";
                template += "<b>PERFORMANCE METRICS</b>";
                template += "<table class='full-width-table'>";
                
                const fieldEntries = Object.entries(fieldValues).slice(6, 10);
                for (const [key, value] of fieldEntries) {
                    if (selectedFields[key]) {
                        template += "<tr>";
                        template += `<td class='half-width-cell'>${key}:</td><td class='half-width-cell'>${value || ''}</td>`;
                        template += "</tr>";
                    }
                }
            
                template += "</table>";
            }
            
            if (selectedFields["FAR Clauses"] || selectedFields["DFAR Clauses"]) {
                template += "<hr>";
    template += "<b>CLAUSES</b>";
    template += "<table class='full-width-table'>";

    const fieldEntries = Object.entries(fieldValues).slice(10, 12);
    for (const [key, value] of fieldEntries) {
        if (selectedFields[key]) {
            template += "<tr>";
            template += `<td class='half-width-cell'>${key}:</td>`;
            template += "<td class='half-width-cell'>";

            // Check if value is an array and create a list
            if (Array.isArray(value)) {
                template += "<ul class='indented-list'>";
                value.forEach(item => {
                    template += `<li>${item}</li>`;
                });
                template += "</ul>";
            } else {
                template += `${value}`;
            }

            template += "</td>";
            template += "</tr>";
        }
    }

    template += "</table>";
            }

            
        }
    
        return template;
    };
    
    const formatPhoneNumber = (value) => {
        const cleaned = ('' + value).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            const [, areaCode, centralOfficeCode, lineNumber] = match;
            if (lineNumber) {
                return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
            } else if (centralOfficeCode) {
                return `(${areaCode}) ${centralOfficeCode}`;
            } else if (areaCode) {
                return `(${areaCode}`;
            }
        }
        return value;
    };
    
    const handlePhoneNumberChange = (field, value) => {
        // Limit input to numbers and ensure it doesn't exceed 10 digits
        const cleaned = value.replace(/\D/g, '').slice(0, 10);
        const formattedNumber = formatPhoneNumber(cleaned);
        handleFieldChange(field, formattedNumber);
    };
  


   
    

    return(
        <>
        <div className={`modalshr ${isOpen ? 'open' : ''}`}>
        <div className={windowWidth <800 ?"modalshr-content-data" :"modalshr-content"}>

            <div className='headingContainer'>
                <div><h2 className='MainheadingShr'>Content Library</h2></div>
                <div className="closeshr-btn"><img src={xCircle} onClick={modalClose} /></div>
            </div>
    

            <div className="row search-bar">
                <div className="col-md-9 search-field">
                    <TextField placeholder="Search" fullWidth variant="outlined" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                <div className="col-md-3 search-button">
                    <Button variant="contained" fullWidth onClick={handleSearch}>Search</Button>
                </div>
            </div>

            <div className={windowWidth<800 ? "content-library-content" :"content-library-content"}>
                <div ref={orgDetailsRef} className="organization-details">
                    <div className="org-details-header">
                        <Checkbox checked={allSelectedOD} onChange={handleSelectAllOD}/>
                        <h3 className='MainheadingShr'>Organization Details</h3>
                    </div>

                    <div className="org-details-form">
                        {fields.map((field, index) => (
                            <div className="row org-detail-row" key={index}>
                                
                            <div className="col-md-4 org-labels">
                                <Checkbox checked={selectedFieldsOD[field]} onChange={() => handleCheckboxChangeOD(field)}/>
                                <label style={{marginBottom:"0px"}}>{field}</label>
                            </div>
                            <div className="col-md-5 org-fields">
                                {/* {field === "Organization Logo" ? (
                                <>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="logo-upload"
                                        type="file"
                                        onChange={handleLogoUpload}
                                    />
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={fieldValues["Organization Logo Name"] || ""}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <label htmlFor="logo-upload">
                                                        <IconButton component="span">
                                                            <AttachFileIcon color='primary' />
                                                        </IconButton>
                                                    </label>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onClick={() => editStates[field] && document.getElementById('logo-upload').click()}
                                    />
                                </>
                            ) : ( */}

                                <>
                                 <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="logo-upload"
                                        type="file"
                                        onChange={handleLogoUpload}
                                    />
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline={field === "Organization Address"}
                                    rows={field === "Organization Address" ? 3 : 1}
                                    value={field === "Organization Logo" ? fieldValues['Organization Logo Name'] :(field === "Contact No" || field === "Contact Fax") ?
                                        formatPhoneNumber(fieldValues[field] ): 
                                        fieldValues[field]}
                                        onChange={field === "Organization Logo" ? "" : (e) => {
                                            if (field === "Contact No" || field === "Contact Fax") {
                                                handlePhoneNumberChange(field, e.target.value);
                                            } else {
                                                handleFieldChange(field, e.target.value);
                                            }
                                        }}
                                    InputProps={
                                        field === "Organization Logo" ? {
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <label htmlFor="logo-upload">
                                                        <IconButton component="span">
                                                            <AttachFileIcon color='primary' />
                                                        </IconButton>
                                                    </label>
                                                </InputAdornment>
                                            ),
                                        } : {
                                            readOnly: !editStates[field],
                                            disabled: !editStates[field],
                                            placeholder: field === "Contact No" ? "(123) 456-7890" : 
                                            field === "Contact Fax" ? "(333) 345-7890" : ""
                                        }
                                    }
                                    onClick={field === "Organization Logo" ? () => editStates[field] && document.getElementById('logo-upload').click() : "" }
                                />
                                </>
                            {/* )} */}
                            </div>
                            <div className="col-md-3 org-options">
                                <div className="icon-buttons">
                                <IconButton><EditOutlinedIcon color="primary" onClick={() => handleEditClick(field)}/></IconButton>
                                <IconButton><DeleteOutlinedIcon color="error" onClick={() => handleDeleteClick(field)}/></IconButton>
                                </div>
                            </div>
                            </div>
                        ))}

                    </div>

                </div>

                <hr/>

                <div ref={performanceMetricsRef} className="performance-metrics">
                    <div className="org-details-header">
                        <Checkbox checked={allSelectedPM} onChange={handleSelectAllPM}/>
                        <h3 className='MainheadingShr'>Performance Metrics</h3>
                    </div>
                    <div className="performance-metrics-content">
                            {Object.entries(performanceMetrics).map(([key, value], index) => (
                        <div className="performance-metrics-data" key={index}>
                            <div className="performance-metrics-data-header">
                                <div className="sub-heading">
                                    <Checkbox checked={selectedFieldsPM[key]} onChange={() => handleCheckboxChangePM(key)}/>
                                    <h4 className="sub-heading-title">{key}</h4>
                                </div>
                                <div className="icon-buttons">
                                    <IconButton onClick={() => handleEditClick(key)}><EditOutlinedIcon color="primary"/></IconButton>
                                    <IconButton onClick={() => handleDeleteClick(key)}><DeleteOutlinedIcon color="error"/></IconButton>
                                </div>
                            </div>
                            <div className="performance-metrics-data-textfield">
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    placeholder={value}
                                    value={fieldValues[key]}
                                    onChange={(e) => handleFieldChange(key, e.target.value)}
                                    InputProps={{
                                        readOnly: !editStates[key],
                                        disabled: !editStates[key],
                                    }}
                                />
                            </div>
                        </div>
                    ))}

                    </div>

                </div>

                <hr/>

                <div ref={clausesRef} className="clauses">
                    <div className="org-details-header">
                        <Checkbox checked={clausesCheckbox} onChange={handleClausesCheckboxChange}/>
                        <h3 className='MainheadingShr'>Clauses</h3>
                    </div>
                    <div className="clauses-content">
                        <div className="far-clauses">
                            <div className="sub-heading-clauses">
                                <div className="sub-heading">
                                    <Checkbox checked={farCheckbox} onChange={handleFarCheckboxChange}/>
                                    <h4 className="sub-heading-title">FAR Clause</h4>
                                </div>
                                <div>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Search FAR Clauses"
                                        onChange={handleFarSearchChange}
                                        InputProps={{
                                            endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                            ),
                                        }}
                                        style={{ marginLeft: '10px' }}
                                        />
                                </div>
                            </div>
                            <div className="far-clause-content">
                                {filteredFarClauses.slice(0,100).map((clause, index) => (
                                    <div key={index} className="sub-heading">
                                    <Checkbox checked={farClauseCheckboxes[index]} onChange={handleFarClausesCheckboxChange(index)}/>
                                    <span className="sub-heading-title">{clause}</span>
                                    </div>
                                ))}

                            </div>

                        </div>
                        <div className="far-clauses">
                            <div className="sub-heading-clauses">
                                <div className="sub-heading">
                                    <Checkbox checked={dfarCheckbox} onChange={handleDfarCheckboxChange}/>
                                    <h4 className="sub-heading-title">DFAR Clause</h4>
                                </div>
                                <div>
                                    <TextField
                                            variant="outlined"
                                            size="small"
                                            placeholder="Search DFAR Clauses"
                                            onChange={handleDfarSearchChange}
                                            InputProps={{
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                                ),
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            />

                                </div>
                            </div>
                            <div className="far-clause-content">

                                {filteredDfarClauses.slice(0,100).map((clause, index) => (
                                    <div key={index} className="sub-heading">
                                    <Checkbox checked={dfarClauseCheckboxes[index]} onChange={handleDfarClausesCheckboxChange(index)}/>
                                    <span className="sub-heading-title">{clause}</span>
                                    </div>
                                ))}

                            </div>

                        </div>

                    </div>

                </div>

                <div className="final-buttons">
                    <Button variant="outlined" color="error" onClick={modalClose} >Cancel</Button>
                    <Button variant="contained" color="primary" style={{marginLeft:'10px'}} onClick={()=>handleAddContentLibrary()}>Add</Button>
                </div>

            </div>


        </div>
        </div>
        </>
    )

}

export default ContentLibrary