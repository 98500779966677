
import React, { useEffect, useState } from "react";
import { Modal, IconButton, TextField, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SelectDropdownMui from "./SelectDropdownMui";
import "./RoleModal.css";
import InputTextField from "./InputTextField.jsx";
import SingleSelectDropDown from "./SigleSelectDropDown.jsx";
import { getFeature } from "../../services/appfeaturesrole.js";
import { toast } from "react-toastify";
import userService from "../../services/userService.js";
import { showToast } from "../../messages.js";

let screenName = "Features Management";

const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
const featureArray = features?.featuresAccess;

// console.log(featureArray,"featureArray");
let accessFeatures;
if (featureArray) {
    const matchingFeature = featureArray.filter(feature => feature.ScreenName ==screenName);
   
    if (matchingFeature) {
       accessFeatures = matchingFeature[0].features
      // setAccessTofeature(accessFeatures)
        // console.log("accessFeatures", accessFeatures);
    } else {
        console.log("No feature found with screen name:", screenName);
    }
} else {
    console.log("No features found in the storage.");
}

console.log(accessFeatures,"accessFeatures")

const RoleModal = ({editValues,onClose}) => {
  const [open, setOpen] = useState(false);
  const [isAdmin,setIsAdmin]=useState(false);
  const [issubAdmin,setIssubAdmin]=useState(false);

  const defaultRoleGroup = issubAdmin ? "Given" : "Default";
  const [groupValue, setGroupValue] = useState(editValues?.rolegroup || defaultRoleGroup);

  const [formData, setFormData] = useState({
    userRole: editValues?.rolename || "",
    sequence: editValues?.sequence || "",
    hierarchy: editValues?.hierarchy || "",
    //id: editValues?._id || ""
  });

  // console.log(editValues,"editValuesrole");
  // Introduce a state variable to trigger rerender
  const [rerenderFlag, setRerenderFlag] = useState(false);

  useEffect(() => {
     setGroupValue(editValues?.rolegroup || defaultRoleGroup);
    setFormData({
      userRole: editValues?.rolename || "",
      sequence: editValues?.sequence || "",
      hierarchy: editValues?.hierarchy || "",
      //id: editValues?._id || ""
    });
  }, [editValues,defaultRoleGroup]);

  console.log(editValues, "editValues");

  const disallowedRoles = [
    'Blue Team', 
    'Pink Team', 
    'Red Team', 
    'Green Team', 
    'Gold Team', 
    'White Team'
];

const isRoleDisallowed = (role) => {
  return disallowedRoles.some(disallowedRole => disallowedRole.toLowerCase() === role.toLowerCase());
};

  const handleSave = async () => {
    
    // Prepare data to be sent to backend
    const email = sessionStorage.getItem("currentUserEmail");
    const orgid = sessionStorage.getItem("organizationId");
    if (isRoleDisallowed(formData.userRole)) {
      return showToast(`roleModal.rolenameAuth`);
  }
  if(!formData.sequence){
    return toast.warn("please enter a sequence.")
  }
  if(!formData.userRole){
    return toast.warn("please enter user role.")
  }
    const dataToSend = {
      email: email,
      orgid: orgid,
      rolename: formData.userRole,
      rolegroup: groupValue,
      sequence: formData.sequence,
      id:editValues?._id
      // hierarchy: formData.hierarchy,
    };
    console.log(dataToSend, "datatosend");
    try {
    //   setLoading(false);
      const data = dataToSend;
      const response = await getFeature(data);
      console.log(response, "saveResponse");
      if (response.success == true) {
        toast.success(response.message);
        // Trigger rerender by toggling the flag
        setRerenderFlag(!rerenderFlag);
        onClose();
      } 
      else if(response.success == false ){
        toast.warn("Role name already exist.")
      }
      else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      // Handle error here, e.g., show an error message to the user
    } finally {
    //   setLoading(true);
    }
  };

  const roleGroupList = [
    { name: "Default", value: "Default" },
    { name: "Given", value: "Given" },
    // Add more objects here as needed
  ];

  const handleGroupChange = async (event) => {
    const { value } = event.target;
    setGroupValue(value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 const getUserData=async()=>{
  try {
    const email=sessionStorage.getItem("currentUserEmail");
    const data=email;
    const response=await userService.getuserdata(data);
    if(response.status==200){
      console.log(response?.data,"getuserResponse")
      if(response?.data?.isSubAdmin){
        setIssubAdmin(true)
      }else if(response?.data?.isAdmin){
        setIsAdmin(true);
      }
    }else{
      toast.error(response.message);
    }
  } catch (error) {
    console.log(error);
  }
 }

 useEffect(()=>{
  getUserData();
 },[])

  return (
    <>
      {/* <Loader loading={loading} /> */}
      {/* <div style={{ backgroundColor: "#f0f0f0", opacity: 2,height:"600px" }}> */}
      
          <div className="modalContainer">
            <div className="modalHeader">
              <p className="modalHeaderData">{editValues ? "Update Roles" :"Add Roles"}</p>
              <IconButton className="modalClose" onClick={onClose}>
                <HighlightOffIcon className="modalClose" />
              </IconButton>
            </div>
            <div className="modalFormData">
              <div className="formRow">
              <div>
                <SelectDropdownMui
                  optionsList={roleGroupList}
                  selectLabel={"Group Name"}
                  onChange={handleGroupChange}
                  value={groupValue}
                  height="40px"
                  width="270px"
                  disabled={!isAdmin}
                />
              </div>
                <div>
                  <InputTextField
                    name="userRole"
                    label="User Role"
                    width="100%"
                    height="40px"
                    variant="outlined"
                    value={formData.userRole}
                    onChange={handleInputChange}
                  />
                </div>
              
                <div>
                  <InputTextField
                    name="sequence"
                    label="Sequence"
                    width="100%"
                    height="40px"
                    variant="outlined"
                    value={formData.sequence}
                    onChange={handleInputChange}
                  />
                </div>
                {/* <div>
                  <InputTextField
                    name="hierarchy"
                    label="Hierarchy"
                    width="270px"
                    height="40px"
                    variant="outlined"
                    value={formData.hierarchy}
                    onChange={handleInputChange}
                  />
                </div> */}
              </div>
              <div className="buttonRow">
                <Button variant="outlined" color="error" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </div>
          </div>
      {/* </div> */}
    </>
  );
};

export default RoleModal;

