import React, { useState, useEffect } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import organizationService, { addorgdata, setnullreforg, getorgdetails } from "../services/organizationService";
import { toast } from "react-toastify";
import InputTextField from "./ReusableComponents/InputTextField";
import MultiSelectDropdownWithSearch from "./ReusableComponents/MultiSelectDropDownMui";
import federalorganizationsService from "../services/federalorganizationsService";
import naicsService from "../services/naicsService";
import noticetypesService from "../services/noticetypesService";
import productnServiceCodeService from "../services/productnServiceCodeService";
import setasidesService from "../services/setasidesService";
import locationstateService from "../services/locationstateService";
import locationcountryService from "../services/locationcountryService";
import Loader from "../utils/loader";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Completed from ".././static/admin_dashboard/Completed.png";
import matching from ".././static/admin_dashboard/matching.png";
import { AttachFile, Clear } from "@mui/icons-material";
import ImageCard from "./ReusableComponents/ImageCard";
import OrgProfileMultiselect from "./ReusableComponents/OrgProfileMultiselect";
import "./OrgProfileStyle.css";
import opportunityService, { matchingOppPercentage } from "../services/opportunityService";
import { Link } from "react-router-dom";
import axios from "axios"
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { sendInvitation } from "../services/userService";
import LZString from 'lz-string';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from 'react-select';
import Box from '@mui/material/Box';
import { showToast } from "../messages";
import assetService, {
  getCompletedProjects
} from "../services/assetService";

const Organization = () => {
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [organizationData, setOrganizationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userdata, setuserdata] = useState({});
  const [federalorganizations, setfederalorganizations] = useState([]);
  const [naicsData, setNaicsData] = useState([]);
  const [noticetypesData, setNoticetypesData] = useState([]);
  const [productnservicecodesData, setProductnservicecodesData] = useState([]);
  const [setasidesData, setsetasidesData] = useState([]);
  const [statesData, setstatesData] = useState([]);
  const [countriesData, setcountriesData] = useState([]);
  const [selectedShow, setselectedShow] = useState([]);
  const [selectFederal, setSelectFederal] = useState([]);
  const [selectNotice, setSelectNotice] = useState([]);
  const [selectProduct, setSelectproduct] = useState([]);
  const [selectStates, setSelectStates] = useState([]);
  const [countryState, setCountryState] = useState([]);
  const [selectCountries, setSelectCountries] = useState([]);
  const [selectSetAsides, setSelectAsides] = useState([]);
  const [file, setFile] = useState(null);
  const [matchingOppCount, setMatchingOppCount] = useState(0)
  const [logoHeadingShrink, setLogoHeadingShrink] = useState(false);
  const [orgLogoFileName, setOrgLogoFileName] = useState(null); // New state variable
  const [inviteEmails, setInviteEmails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [updateOrgSpecificFields, setUpdateOrgSpecificFields] = useState({
    address: '',
    city: '',
    selectedCountry: null,
    selectedState: null,
    postalCode: '',
    phoneNumber: '',
    fax: '',
    domain: '',
  });
  const [completedProjectCount, setCompletedProjectCount] = useState(0);
  // const [existingFileName, setExistingFileName] = useState("");
  console.log(selectedCountry, "__selectedCountry")
  console.log(selectedState, "__selectedState")

  const fetchCompletedprojectsCount = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail")
      const data = { email }
      const response = await getCompletedProjects(data);
      if (response.status === 200) {
        const projectsCount = response.count
        setCompletedProjectCount(projectsCount);
      } else {
        console.error('Error fetching projects:', response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    fetchOrgData()
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setUpdateOrgSpecificFields({
      address: '',
      city: '',
      selectedCountry: null,
      selectedState: null,
      postalCode: '',
      phoneNumber: '',
      fax: '',
      domain: '',
    });
  }

  const handleSubmit = async () => {
    const orgData = {
      address: updateOrgSpecificFields.address,
      city: updateOrgSpecificFields.city,
      country: selectedCountry?.label || '',
      state: selectedState?.label || '',
      postalCode: updateOrgSpecificFields.postalCode,
      phoneNumber: updateOrgSpecificFields.phoneNumber,
      fax: updateOrgSpecificFields.fax,
      domain: updateOrgSpecificFields.domain,
    };
    console.log(orgData, "+++orgData")
    try {
      if (!orgData.address || !orgData.city || !orgData.country || !orgData.address || !orgData.state ||
        !orgData.postalCode || !orgData.phoneNumber || !orgData.fax || !orgData.domain) {
        return showToast('Warning.allFields')
      }
      const orgId = sessionStorage.getItem("organizationId")
      const data = { orgId, orgData }
      const response = await addorgdata(data);
      if (response.status === 200) {
        showToast('Organization.dataAdded')
        fetchOrgData()
      } else {
        console.error("Failed to update organization data:", response.error);
      }
    } catch (error) {
      console.error("Error updating organization data:", error);
    }
    closeModal();
  };


  const handleInputChange = (e) => {
    console.log(e.target, "Etarget")
    const { name, value } = e.target;
    setUpdateOrgSpecificFields({
      ...updateOrgSpecificFields,
      [name]: value,
    });
  };

  const handleCountryChange = (selectedOption) => {
    console.log(selectedOption, "{{selectedOption")
    setSelectedCountry(selectedOption);
    if (selectedOption) {
      getStates(selectedOption.value);
    } else {
      setstatesData([]); // Clear states if no country is selected
    }
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
  };




  let screenName = "Organization";

  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const featureArray = features?.featuresAccess;

  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(feature => feature.ScreenName == screenName);

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features
      // setAccessTofeature(accessFeatures)
      // console.log("accessFeatures", accessFeatures);
    } else {
      console.log("No feature found with screen name:", screenName);
    }
  } else {
    console.log("No features found in the storage.");
  }

  // const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];

  //   if (selectedFile && allowedFormats.includes(selectedFile.type)) {
  //     setFile(selectedFile);
  //   } else {
  //     toast.error("Please select a file with JPG, JPEG, or PNG format.");
  //     setFile(null);
  //   }
  // };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];

    if (selectedFile && allowedFormats.includes(selectedFile.type)) {
      setFile(selectedFile);
      setOrgLogoFileName(selectedFile.name); // Optionally set the logo file name
    } else {
      showToast('Warning.imgUpload')
      setFile(null);
    }
  };



  // const FileUploadInList = async () => {
  //   const allowedFormats = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];
  //   const email = sessionStorage.getItem("currentUserEmail");

  //   const formData = new FormData();
  //   formData.append('email', email);
  //   formData.append('filename', file);

  //   if (!file) {
  //     toast.warn("No file selected.");
  //     return;
  //   }

  //   if (!allowedFormats.includes(file.type)) {
  //     toast.warn("Please upload a file with DOCX format only.");
  //     return;
  //   }
  //   console.log(formData,"++formData")
  //   try {
  //     const response = await axios.post(`http://api.contractpro360.com/api/bucket/uploadfromlocal`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     if (response) {
  //       console.log("File uploaded successfully.");
  //       // toast.success("File uploaded successfully.");
  //       fetchData();
  //       // fetchDataOrg()
  //     }
  //   } catch (error) {
  //     console.error('Error uploading file:', error.message);
  //     toast.warn("Error while uploading the file.");
  //   }
  // };

  const sendInvite = async () => {
    try {
      let featurename = "InviteLink"
      const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
      console.log(checkfeatureName[0].access, "checkfeatureName")
      if (!checkfeatureName[0].access) {

        showToast('Organization.inviteUserNotAllowed')
      } else {
        if (inviteEmails.length == 0) { return showToast('signup.email') }
        const response = await sendInvitation({ emails: inviteEmails, organizationId: organizationData, fromOrg: true });
        if (response.status === 200) {
          showToast('Organization.invitationsSent')
        } else {
          if (response.status == 400 && response.isUserIsNotOrgMember) {
            return showToast('Organization.errUserExist')
          }
          showToast('Organization.invitationsFailed')
        }
      }

    } catch (error) {
      console.error("Error sending invitations:", error.message);
     showToast('Organization.invitationsFailed')
    }
  };


  const email = sessionStorage.getItem("currentUserEmail");
  const [formKey, setFormKey] = useState(0);
  console.log(selectCountries, "selectCountriesselectCountries");
  console.log(userdata, "data--");

  const handleDeleteItem = async (selectedOptions) => {
    const data = { userdata, selectedOptions };
    // alert("ondelete")
    try {
      console.log(selectedOptions, "countriesselectedOptions");
      const removeItem = await organizationService.deleteorganization(data);
      if (removeItem) {
        // alert("deleted")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFederalorganization = async () => {
    try {
      const response = await federalorganizationsService.getfederalorgs();
      let federalorgResponse = response.allFederalOrgs;
      setfederalorganizations(federalorgResponse);
    } catch (error) {
      console.error("Error fetching federalorganizations data:", error.message);
      setLoading(false);
    }
  };

  const getNaics = async () => {
    try {
      const response = await naicsService.getnaics();
      let naicsResponse = response.allnaics;
      setNaicsData(naicsResponse);
    } catch (error) {
      console.error("Error fetching Naic code data:", error.message);
      setLoading(false);
    }
  };

  const getNoticetypes = async () => {
    try {
      const response = await noticetypesService.getnoticetypes();
      let noticetypesResponse = response.data;
      setNoticetypesData(noticetypesResponse);
    } catch (error) {
      console.error("Error fetching Noticetype data:", error.message);
      setLoading(false);
    }
  };

  const getProductnServicecode = async () => {
    try {
      const response =
        await productnServiceCodeService.getproductnServiceCode();
      let productnServicecodeResponse = response.checkProduct;
      setProductnservicecodesData(productnServicecodeResponse);
    } catch (error) {
      console.error(
        "Error fetching product & service  code data:",
        error.message
      );
      setLoading(false);
    }
  };

  const getSetasides = async () => {
    try {
      const response = await setasidesService.getsetAsides();
      let setAsidesResponse = response.checksetAsides;
      setsetasidesData(setAsidesResponse);
    } catch (error) {
      console.error("Error fetching setAside data:", error.message);
      setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const response = await locationstateService.getlocationStates();
      let stateResponse = response.data;
      setstatesData(stateResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
      setLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      const response = await locationcountryService.getcountry();
      let countryResponse = response.checkcountry;
      setcountriesData(countryResponse);
    } catch (error) {
      console.error("Error fetching States data:", error.message);
      setLoading(false);
    }
  };

  const fetchuserdata = async () => {
    try {
      let data = email;
      const response = await organizationService.getuserdata(data);
      setuserdata(response?.data?.organizationId);
    } catch (error) {
      console.error("Error fetching organization data:", error.message);
      setLoading(false);
    }
  };

  // useEffect(()=>{
  //   fetchuserdata();
  // },[])

  useEffect(() => {
    fetchuserdata();
    getFederalorganization();
    getNaics();
    getNoticetypes();
    getProductnServicecode();
    getSetasides();
    getStates();
    getCountries();
  }, [updateSuccess]);

  const fetchDataOrg = async () => {
    try {
      setLoading(true);
      const id = sessionStorage.getItem("organizationId")
      let data = id;
      console.log(data, "organizationpayload")
      const response = await organizationService.getOrganization(data);
      console.log("response_", response.data);

      const essentialData = {
        id: response.data._id,
        orgVirtualId: response.data.orgVirtualId,
        orgName: response.data.orgName,
        naicsref: response.data.naicsref,
        federalorganizationsref: response.data.federalorganizationsref,
        noticetypesref: response.data.noticetypesref,
        productnservicecodesref: response.data.productnservicecodesref,
        statesref: response.data.statesref,
        countriesref: response.data.countriesref,
        setasidesref: response.data.setasidesref
      };
      console.log(essentialData, "essentialData update");

      // Store the essential data in session storage with additional error handling
      try {
        sessionStorage.setItem("organizationData", JSON.stringify(essentialData));
        console.log("Data successfully stored in session storage");
      } catch (storageError) {
        console.error("Failed to store data in session storage:", storageError);
      }

      // Extract references from the response data
      const {
        naicsref: selectednaics,
        federalorganizationsref: selectFederalOrg,
        noticetypesref: selectNoticeType,
        productnservicecodesref: selectProductnService,
        statesref: selectState,
        countriesref: selectCountry,
        setasidesref: selectsetAside
      } = response.data;

      // Update state with the extracted references
      setselectedShow(selectednaics);
      setSelectFederal(selectFederalOrg);
      setSelectNotice(selectNoticeType);
      setSelectproduct(selectProductnService);
      setSelectStates(selectState);
      setSelectCountries(selectCountry);
      setSelectAsides(selectsetAside);

      // Update organization data and fields
      setOrganizationData(response.data._id);
      setUpdateSuccess(false);
      setUpdateSpecificFields({
        orgVirtualId: response.data.orgVirtualId || "",
        orgName: response.data.orgName || "",
        selectedFederalOrganizations: [],
        selectednaics: [],
        selectednoticetypes: [],
        selectedproductnservicecodestypes: [],
        selectedsetasides: [],
        selectedstates: [],
        selectedcountries: [],
      });
      setOrgLogoFileName(response?.data?.orgLogoFileName || null);
      console.log(response?.data?.orgLogoFileName, "orglogo")
      //sessionstorage code
      // const data = {
      //   data: organizationData,
      //   currentPage: 1,
      //   itemsPerPage: 10,

      // };
      const orgresponse = await matchingOppPercentage(data);
      console.log(orgresponse, "orgresponse")
      const dataorg = orgresponse?.data;
      console.log(orgresponse, "matchingresponseinlogin");



      // Retrieve existing data from session storage
      try {
        sessionStorage.setItem("organizationOpportunities", LZString.compressToUTF16(JSON.stringify(dataorg)));
        console.log("Data successfully stored in session storage");
      } catch (error) {
        console.error("Failed to store data in session storage:", error);
      }

      if (orgresponse?.success === true) {
        console.log(orgresponse, "checkif");
        const additionalOrgResponse = await opportunityService.matchingOppPercentageAdditional(data);
        console.log(additionalOrgResponse, "additionalOrgResponse");
        const dataorg1 = additionalOrgResponse?.data;

        // Retrieve the updated data from session storage
        let existingOpportunities = JSON.parse(LZString.decompressFromUTF16(sessionStorage.getItem("organizationOpportunities"))) || [];
        console.log(existingOpportunities, "existingOpportunities");

        // Append the additional data
        const updatedOpportunities = existingOpportunities.concat(dataorg1);
        console.log(updatedOpportunities, "updatedOpportunities");

        // Compress and store the updated data back in session storage
        try {
          // sessionStorage.setItem("organizationOpportunities", LZString.compressToUTF16(JSON.stringify(updatedOpportunities)));
          sessionStorage.setItem("organizationOpportunities", LZString.compressToUTF16(JSON.stringify(existingOpportunities)));
          console.log("Additional data successfully stored in session storage");
        } catch (error) {
          console.error("Failed to store additional data in session storage:", error);
        }
      }

    } catch (error) {
      console.error("Error fetching organization data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const sessionOrganizationData = JSON.parse(sessionStorage.getItem("organizationData"));
  console.log(sessionOrganizationData, "sessionOrganizationData")

  const fetchData = async () => {
    if (sessionOrganizationData) {
      const id = sessionStorage.getItem("organizationId")
      setselectedShow(sessionOrganizationData?.naicsref);
      setSelectFederal(sessionOrganizationData?.federalorganizationsref);
      setSelectNotice(sessionOrganizationData?.noticetypesref);
      setSelectproduct(sessionOrganizationData?.productnservicecodesref);
      setSelectStates(sessionOrganizationData?.statesref);
      setSelectCountries(sessionOrganizationData?.countriesref);
      setSelectAsides(sessionOrganizationData?.setasidesref);
      setOrganizationData(id);
      setUpdateSuccess(false);
      setUpdateSpecificFields({
        orgVirtualId: sessionOrganizationData?.orgVirtualId || "",
        orgName: sessionOrganizationData?.orgName || "",
        selectedFederalOrganizations: [],
        selectednaics: [],
        selectednoticetypes: [],
        selectedproductnservicecodestypes: [],
        selectedsetasides: [],
        selectedstates: [],
        selectedcountries: [],
      });
    }
  }
  console.log(selectFederal, selectNotice, selectProduct, selectedShow, selectSetAsides, selectCountries, selectStates, "sessiondatatocheck")
  useEffect(() => {
    fetchData();
  }, [userdata, formKey]);
  console.log(userdata, "userdataorg")

  const [updateSpecificFields, setUpdateSpecificFields] = useState({
    orgVirtualId: "",
    orgName: "",
    selectedFederalOrganizations: [],
    selectednaics: [],
    selectednoticetypes: [],
    selectedproductnservicecodestypes: [],
    selectedsetasides: [],
    selectedstates: [],
    selectedcountries: [],
  });
  console.log(updateSpecificFields, "updateSpecificFieldsupdateSpecificFields");
  const handleCancel = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  const handleChange = (e) => {
    setUpdateSpecificFields({
      ...updateSpecificFields,
      [e.target.name]: e.target.value,
    });
  };

  const handleFederalOrganizationsChange = (selectedOptions) => {
    setUpdateSpecificFields({
      ...updateSpecificFields,
      selectedFederalOrganizations: selectedOptions,
    });
    setSelectFederal(selectedOptions);
  };

  const handleNaicsChange = (selectedOptions) => {
    const uniqueSelectedNaics = selectedOptions.reduce(
      (uniqueOptions, option) => {
        if (
          !uniqueOptions.some((uniqueOption) => uniqueOption._id === option._id)
        ) {
          uniqueOptions.push(option);
        }
        return uniqueOptions;
      },
      []
    );

    setUpdateSpecificFields({
      ...updateSpecificFields,
      selectednaics: uniqueSelectedNaics,
    });
    setselectedShow(selectedOptions);
  };

  const handleNoticetypesChange = (selectedOptions) => {
    setUpdateSpecificFields({
      ...updateSpecificFields,
      selectednoticetypes: selectedOptions,
    });
    setSelectNotice(selectedOptions);
  };

  const handleProductnservicecodesChange = (selectedOptions) => {
    setUpdateSpecificFields({
      ...updateSpecificFields,
      selectedproductnservicecodestypes: selectedOptions,
    });
    setSelectproduct(selectedOptions);
  };

  const handleSetasidesChange = (selectedOptions) => {
    setUpdateSpecificFields({
      ...updateSpecificFields,
      selectedsetasides: selectedOptions,
    });
    console.log("response_1", selectedOptions);
    setSelectAsides(selectedOptions);
  };

  const handleStatesChange = (selectedOptions) => {
    console.log(selectedOptions, "__selectedOptions")

    // Check if selectedOptions is not empty
    const correspondingcountry = selectedOptions.length > 0
      ? selectedOptions[0].country.countryFullName
      : null;
    console.log(
      correspondingcountry,
      "correspondingcountrycorrespondingcountrycorrespondingcountry"
    );
    setUpdateSpecificFields({
      ...updateSpecificFields,
      selectedstates: selectedOptions,
    });
    setSelectStates(selectedOptions);
  };

  const handleCountriesChange = (selectedOptions) => {
    console.log(selectedOptions, "countrydatas");
    setUpdateSpecificFields({
      ...updateSpecificFields,
      selectedcountries: selectedOptions,
    });
    setSelectCountries(selectedOptions);

    // Filter statesData based on countryCode2 of each selected option
    const filteredData = selectedOptions
      .map((option) =>
        statesData.filter(
          (state) => state.country.countryCode2 === option.countryCode2
        )
      )
      .flat();

    console.log(filteredData, "filteredData");

    // Set the filtered data as country state
    setCountryState(filteredData);
  };

  console.log(countryState, "statesssssss");
  console.log(
    statesData.map((a) => a.country).map((c) => c.countryFullName),
    "staesdatadatadatadat"
  );

  const fetchMatchingOppCount = async () => {
    try {
      // const data = {
      const data = sessionStorage.getItem("organizationId")
      //   currentPage: 1,
      //   itemsPerPage: 10,

      // };
      const response = await matchingOppPercentage(data);
      setMatchingOppCount(response.total);
      console.log("count", response.total)
    } catch (error) {
      console.error("Error fetching matching opportunities count:", error.message);
    }
  };
  const handleUpdate = async () => {
    try {
      console.log(updateSpecificFields, "__updateSpecificFields")
      setLoading(true)
      let featurename = "Create";
      const checkfeatureName = accessFeatures?.filter(k => k.name == featurename)
      console.log(checkfeatureName[0].access, "checkfeatureName")
      if (!checkfeatureName[0].access) {

        showToast('Organization.createOrgNotAllowed')
      } else {
        console.log(updateSpecificFields.selectedFederalOrganizations,
          updateSpecificFields.selectednaics,
          updateSpecificFields.selectednoticetypes,
          updateSpecificFields.selectedproductnservicecodestypes,
          updateSpecificFields.selectedsetasides,
          updateSpecificFields.selectedstates,
          updateSpecificFields.selectedcountries,
          orgLogoFileName, "_SSS")
        if (updateSpecificFields.selectedFederalOrganizations.length == 0 &&
          updateSpecificFields.selectednaics.length == 0 &&
          updateSpecificFields.selectednoticetypes.length == 0 &&
          updateSpecificFields.selectedproductnservicecodestypes.length == 0 &&
          updateSpecificFields.selectedsetasides.length == 0 &&
          updateSpecificFields.selectedstates.length == 0 &&
          updateSpecificFields.selectedcountries.length == 0 &&
          selectFederal.length == 0 &&
          selectNotice.length == 0 &&
          selectProduct.length == 0 &&
          selectedShow.length == 0 &&
          selectSetAsides.length == 0 &&
          selectCountries.length == 0 &&
          selectStates.length == 0 &&
          !orgLogoFileName
        ) {
          setLoading(false)
          return showToast('Organization.selectOneField')

        }
        const data = {
          id: organizationData,
          ...updateSpecificFields,
          federalorganizationsref:
            updateSpecificFields.selectedFederalOrganizations.map(
              (org) => org._id
            ),
          naicsref: updateSpecificFields.selectednaics.map((naic) => naic._id),
          noticetypesref: updateSpecificFields.selectednoticetypes.map(
            (notice) => notice._id
          ),
          productnservicecodesref:
            updateSpecificFields.selectedproductnservicecodestypes.map(
              (product) => product._id
            ),
          setasidesref: updateSpecificFields.selectedsetasides.map(
            (aside) => aside._id
          ),
          statesref: updateSpecificFields.selectedstates.map(
            (state) => state._id
          ),
          countriesref: updateSpecificFields.selectedcountries.map(
            (country) => country._id
          ),
        };
        console.log(data, "datatopassed")
        const updatedData = await organizationService.updateorganization(data);
        setOrganizationData(updatedData.data);

        setUpdateSuccess(true);
        setFormKey((prevKey) => prevKey + 1);
        if (updatedData.success) {
          console.log(updatedData.message, updatedData.data, "_updatedData");

        }
        // fetchMatchingOppCount();
        if (file && file.type) {
          await FileUploadInList();
        }
        // setFile(null)

        await fetchDataOrg()
        await fetchMatchingOppCount()
        showToast('Success.dataUpdated')
        // fetchOrgData()
      }

    } catch (error) {
      console.error("Error updating organization data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  //   const modifiedFederalOrganizations = federalorganizations.map(org => ({
  //     ...org,
  //     concatenatedLabel: `${org.name} - ${org.code}`
  // }));

  useEffect(() => {
    if (organizationData) {
      fetchMatchingOppCount(); // Fetch matching opportunities count when organizationData is available
    }
  }, [organizationData]);

  const popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999", padding: "2px", width: '500px' }}>
      <Popover.Content>

        <ReactMultiEmail
          emails={inviteEmails}
          onChange={(_emails) => {
            setInviteEmails(_emails);
          }}
          validateEmail={email => {
            return isEmail(email);
          }}
          placeholder="Enter Emails.."
          getLabel={(
            email,
            index,
            removeEmail
          ) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />


        <small>*Multiple invites can be sent</small>
        <Button
          className="btn btn-outline w-100 mt-2"
          onClick={sendInvite}
          style={{ backgroundColor: "#3f51b5", border: "#3f51b5" }}
        >
          Send Invite
        </Button>
      </Popover.Content>
    </Popover>
  );

  const FileUploadInList = async () => {
    const allowedFormats = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'];
    const email = sessionStorage.getItem("currentUserEmail");
    console.log("File selected", file, file.type);

    const formData = new FormData();
    formData.append('email', email);
    formData.append('filename', file);

    if (!file) {
      showToast('Warning.noFile')
      return;
    }
    if (!allowedFormats.includes(file.type)) {
      showToast('Warning.docUpload')
      return;
    }


    try {
      const response = await axios.post(`https://api.contractpro360.com/api/bucket/uploadlogo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response) {
        console.log("upload response", response);
        // toast.success("File uploaded successfully.");
        // fetchData();
      }
    } catch (error) {
      console.error('Error uploading file:', error.message);
      showToast('Error.somethingFailed')
    }
  };



  const handleClearOptions = async (refcollectionName) => {
    try {
      let data = { _id: organizationData, refcollectionName }
      const response = await setnullreforg(data);
      console.log(response, "response")
    } catch (error) {
      console.error("Error clearing options:", error.message);
    }
  };

  const fetchOrgData = async () => {
    try {
      const orgId = sessionStorage.getItem("organizationId")
      const data = {
        orgId
      }
      console.log(data, "{{data")
      const response = await getorgdetails(data);
      console.log("DATA 000  ", response.data);
      setUpdateOrgSpecificFields({
        address: response?.data?.orgData?.address || '',
        city: response?.data?.orgData?.city || '',
        selectedCountry: response?.data?.orgData?.country || null,
        selectedState: response?.data?.orgData?.state || null,
        postalCode: response?.data?.orgData?.postalCode || '',
        phoneNumber: response?.data?.orgData?.phoneNumber || '',
        fax: response?.data?.orgData?.fax || '',
        domain: response?.data?.orgData?.domain || '',
      })
      setOrgLogoFileName(response?.data?.logoName)
      setFile(response?.data?.orgLogo)
      setSelectedCountry(response?.data?.orgData?.country ? {
        value: response?.data?.orgData?.country,// add null
        label: response?.data?.orgData?.country,
      } : null);
      setSelectedState(response?.data?.orgData?.state ? {
        value: response?.data?.orgData?.state,
        label: response?.data?.orgData?.state,
      } : null);
    } catch (error) {
      console.error('Error fetching templates:', error.message);
    }
  };
  useEffect(() => {
    fetchOrgData()
    fetchCompletedprojectsCount()
  }, [])
  console.log(organizationData, "____file")
  console.log(orgLogoFileName, "00ppp")

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define styles based on the window width
  const firstDivStyle = {
    width: windowWidth >= 992 ? '80%' : '100%',
    // border: "2px solid red"
  };

  const secondDivStyle = {
    width: windowWidth >= 992 ? '17%' : '100%',
    marginBottom: windowWidth >= 992 ? "none" : "15px",
    // border: "2px solid black"
  };

  //575 <=   992 >=

  const isRow = windowWidth > 575 && windowWidth < 992;

  const containerStyle = {
    display: 'flex',
    flexDirection: isRow ? 'row' : 'column',
    ...(isRow && { justifyContent: 'space-between' }),
    // border: '2px solid green',
    margin: '0',
  };

  const childStyle = {
    // border: '2px solid red',
    marginBottom: isRow ? '0' : '1rem', 
    width: '100%',
    ...(isRow && {display: 'flex', justifyContent: 'center', alignItems: "center" }),
  };

  const buttonStyle = {
    width:  isRow ? '94%' :'100%',
    backgroundColor: '#3f51b5',
    border: '#3f51b5',
  };


  return (
    <>
      <Loader loading={loading} />
      <div style={{ paddingLeft: windowWidth < 960 && windowWidth > 600 ? "20px" : "0px", }}>
        <div className="d-flex flex-column-reverse flex-md-column-reverse flex-lg-row justify-content-between " style={{ alignItems: "flex-start" }}>
          <div style={firstDivStyle}>
            {organizationData && (
              <Row >
                <Col sm={6} >
                  <Form key={formKey}>
                    <div className="input-container">
                      <Form.Group
                        controlId="formOrgVirtualId"
                        style={{
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <InputTextField
                          type="text"
                          name="orgVirtualId"
                          value={updateSpecificFields.orgVirtualId}
                          onChange={handleChange}
                          label={
                            <span
                              style={{ color: "black", fontWeight: "bold" }}
                            >
                              Virtual ID
                            </span>
                          }
                          disabled={true}
                          fullWidth
                        />
                      </Form.Group>
                    </div>
                    <div className="file-upload-container">
                      <Form.Group
                        controlId="formFile"
                        style={{
                          position: "relative",
                          backgroundColor: "white",
                          paddingTop: "12px",
                          paddingBottom: "20px",
                          borderColor: "#b0b0b0",
                          borderBottom: "1px solid #ccc"
                        }}
                      >
                        <Form.Label>
                          <span className="label-text-logo">Upload Logo</span>
                        </Form.Label>
                        <Form.Control
                          id="file"
                          type="file"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                        <AttachFile
                          onClick={() => document.getElementById("file").click()}
                          className="attachlogo"
                        />
                        {file && (
                          <div >
                            {orgLogoFileName && <span>{orgLogoFileName}</span>}
                          </div>
                        )}
                      </Form.Group>
                    </div>

                    <div className="input-container">
                      <Form.Group
                        controlId="formFederalOrganizations"
                        className="federalorgfield"
                      >
                        <OrgProfileMultiselect
                          optionList={federalorganizations}
                          onChange={handleFederalOrganizationsChange}
                          labelKey="name"
                          label={
                            <span className="label-text">
                              Federal Organization
                            </span>
                          }
                          fullWidth
                          value={selectFederal?.length > 0 ? selectFederal : []}
                          onDelete={handleDeleteItem}
                          onClearOptions={() => handleClearOptions('federalorganizationsref')}
                        />
                      </Form.Group>
                    </div>

                    <div className="input-container">
                      <Form.Group
                        controlId="formNotice"
                      >
                        <OrgProfileMultiselect
                          optionList={noticetypesData}
                          onChange={handleNoticetypesChange}
                          labelKey="value"
                          label={<span className="label-text">Notice Types</span>}
                          fullWidth
                          value={selectNotice?.length > 0 ? selectNotice : []}
                          onDelete={handleDeleteItem}
                          onClearOptions={() => handleClearOptions('noticetypesref')}

                        />
                      </Form.Group>
                    </div>

                    <div className="input-container">
                      <Form.Group
                        controlId="formProductnservicecodes"
                      >
                        <OrgProfileMultiselect
                          optionList={productnservicecodesData}
                          onChange={handleProductnservicecodesChange}
                          labelKey="newconcatenate"
                          label={
                            <span className="label-text">
                              Product & Service Code
                            </span>
                          }
                          fullWidth
                          value={selectProduct?.length > 0 ? selectProduct : []}
                          onDelete={handleDeleteItem}
                          onClearOptions={() => handleClearOptions('productnservicecodesref')}
                        />
                      </Form.Group>
                    </div>
                  </Form>
                </Col>
                <Col sm={6}>
                  <Form key={formKey}>
                    <div
                      className="input-container"
                    >
                      <Form.Group
                        controlId="formOrgName"
                        style={{ backgroundColor: "white" }}
                      >
                        <InputTextField
                          type="text"
                          name="orgName"
                          value={updateSpecificFields?.orgName}
                          onChange={handleChange}
                          label={
                            <span className="label-text">
                              Organization Name
                            </span>
                          }
                          disabled={true}
                          fullWidth
                        />
                      </Form.Group>
                    </div>

                    <div className="input-container">
                      <Form.Group
                        controlId="formNaics"
                      >
                        <OrgProfileMultiselect
                          optionList={naicsData}
                          onChange={handleNaicsChange}
                          labelKey="newconcatenate"
                          label={<span className="label-text">NAICS Code</span>}
                          fullWidth
                          value={selectedShow?.length > 0 ? selectedShow : []}
                          onDelete={handleDeleteItem}
                          onClearOptions={() => handleClearOptions('naicsref')}
                        />
                      </Form.Group>
                    </div>

                    <div className="input-container">
                      <Form.Group
                        controlId="formSetasides"
                      >
                        <OrgProfileMultiselect
                          optionList={setasidesData}
                          onChange={handleSetasidesChange}
                          labelKey="setAsideName"
                          label={<span className="label-text">Set Aside</span>}
                          fullWidth
                          value={
                            selectSetAsides?.length > 0 ? selectSetAsides : []
                          }
                          onDelete={handleDeleteItem}
                          onClearOptions={() => handleClearOptions('setasidesref')}

                        />
                      </Form.Group>
                    </div>

                    <div className="input-container">
                      <Form.Group
                        controlId="formCountries"
                      >
                        <OrgProfileMultiselect
                          optionList={countriesData}
                          onChange={handleCountriesChange}
                          labelKey="country"
                          label={<span className="label-text">Countries</span>}
                          fullWidth
                          value={
                            selectCountries?.length > 0 ? selectCountries : []
                          }
                          onDelete={handleDeleteItem}
                          onClearOptions={() => handleClearOptions('federalorganizationsref')}

                        />
                      </Form.Group>
                    </div>

                    <div className="input-container">
                      <Form.Group
                        controlId="formSstates"
                      >
                        <OrgProfileMultiselect
                          optionList={countryState}
                          onChange={handleStatesChange}
                          labelKey="state"
                          label={<span className="label-text">States</span>}
                          fullWidth
                          value={selectStates?.length > 0 ? selectStates : []}
                          onDelete={handleDeleteItem}
                          onClearOptions={() => handleClearOptions('countriesref')}
                        />
                      </Form.Group>
                    </div>
                  </Form>
                </Col>
              </Row>
            )}
            {organizationData ? (
              <>
                <div
                  style={{ width: "100%", display: "flex", marginTop: "15px", justifyContent: "center" }}
                >
                  <div className="Buttonscontent">
                    <Button
                      variant="outlined"
                      onClick={handleCancel}
                      style={{
                        width: "100px",
                        border: "1px solid #FF0000",
                        color: "#FF0000",
                        borderRadius: "10px",
                        height: "40px",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleUpdate}
                      style={{
                        width: "100px",
                        marginLeft: "30px",
                        backgroundColor: "#1976D2",
                        borderRadius: "10px",
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>

              </>
            )}
          </div>
          <div style={secondDivStyle}>
            <div className="row d-flex flex-wrap ">
              <div className="col-6 col-lg-12 col-sm-6 col-md-6 ">
                <Link to='/filterOpportunityinOrg'>
                  <ImageCard
                    bgColor="#46A57F"
                    altImg="Matching"
                    imgSrc={matching}
                    cardName="Matching"
                    number={matchingOppCount}
                    height="100px"
                    width="100%"
                  />
                </Link>
              </div>
              <div className="col-6 col-lg-12 col-sm-6 col-md-6 ">
                <Link to='/OppourtunityManagementSubadmin'>
                  <ImageCard
                    bgColor="#D8BC15"
                    altImg="Submitted"
                    imgSrc={Completed}
                    cardName="Submitted"
                    number={completedProjectCount}
                    height="100px"
                    width="100%"
                  />
                </Link>
              </div>
              <div className="col-12" style={containerStyle}>
                <div style={childStyle}>
                  <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose={true}>
                    <button className="btn btn-primary" style={buttonStyle}>
                      Invite
                    </button>
                  </OverlayTrigger>
                </div>
                <div style={childStyle}>
                  <button className="btn btn-primary" style={buttonStyle} onClick={openModal}>
                    Add Organization Data
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Dialog open={isModalOpen} onClose={closeModal} fullWidth maxWidth="sm">
            <DialogTitle color={"#1976d2"}>Add Organization Data</DialogTitle>
            <DialogContent>
              <TextField
                label="Address"
                name="address"
                value={updateOrgSpecificFields.address}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="City"
                name="city"
                value={updateOrgSpecificFields.city}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <Box mt={2}>
                <Select
                  options={countriesData.map(country => ({ value: country.country, label: country.country }))}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  placeholder="Select Country"
                  isClearable
                  styles={{
                    container: (provided) => ({ ...provided, marginBottom: 16 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 })
                  }}
                />
              </Box>
              <Box mt={2}>
                <Select
                  options={statesData.map(state => ({ value: state.state, label: state.state }))}
                  value={selectedState}
                  onChange={handleStateChange}
                  placeholder="Select State"
                  isClearable
                  styles={{
                    container: (provided) => ({ ...provided, marginBottom: 16 }),
                    menu: (provided) => ({ ...provided, zIndex: 9999 })
                  }}
                  isDisabled={!selectedCountry} // Disable state dropdown if no country is selected
                />
              </Box>
              <TextField
                label="Postal Code"
                name="postalCode"
                value={updateOrgSpecificFields.postalCode}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Phone Number"
                name="phoneNumber"
                value={updateOrgSpecificFields.phoneNumber}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Fax"
                name="fax"
                value={updateOrgSpecificFields.fax}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Domain"
                name="domain"
                value={updateOrgSpecificFields.domain}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal}>
                Cancel
              </Button>
              <Button onClick={handleSubmit}>
                Save
              </Button>
            </DialogActions>
          </Dialog>

        </div>
        {/* {organizationData ? (
          <>
            <div
              style={{ width: "100%", display: "flex", marginTop: "15px", justifyContent: "center" }}
            >
              <div className="Buttonscontent">
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  style={{
                    width: "100px",
                    border: "1px solid #FF0000",
                    color: "#FF0000",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleUpdate}
                  style={{
                    width: "100px",
                    marginLeft: "30px",
                    backgroundColor: "#1976D2",
                    borderRadius: "10px",
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
           
          </>
        )} */}
      </div>
    </>
  );
};

export default Organization;
