import React, { useState, useEffect, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, IconButton } from '@material-ui/core';
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js';
import { checkPhrasing } from '../services/trackChanges';
import { toast, ToastContainer } from "react-toastify";
import Loader from '../utils/loader';
import SelectDropdownMui from './SelectDropdownMui';
import FeatureButton from './Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputBoxMui from './InputBoxMui';
import { useSelector } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import JoditEditorComp from './JoditEditorComp';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);


const CoAuther_ParaphraserPage = (props) => {
  // const { isFullSize } = useSelector((state) => state.manageFullSizeEditor);
  // const userDetails = useSelector((user) => user)
  const userID = props.userId
  const [secondaryeditorState, setSecondaryEditorState] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  // const [previousState, setPreviousState] = useState(props.callGrammerCheck);
  const [firstCall, setFirstCall] = useState(true);
  const [toneList, setToneList] = useState([
    {
      "value": "standard",
      "name": "Standard"
    },
    {
      "value": "formal",
      "name": "Formal"
    },
    {
      "value": "casual",
      "name": "Casual"
    },
    {
      "value": "positive",
      "name": "Positive"
    },
    {
      "value": "confident",
      "name": "Confident"
    },
    {
      "value": "friendly",
      "name": "Friendly"
    },
    {
      "value": "simple",
      "name": "Simple"
    },
    {
      "value": "shorten",
      "name": "Shorten"

    },
    {
      "value": "expand",
      "name": "Expand"

    },

  ]);
  const [selectedTone, setSelectedTone] = useState("")
  const [suggestionText, setSuggestionText] = useState("Custom")
  const [block, setBlock] = useState(null)
  const [isCustomSuggestion, setIsCustomSuggestion] = useState(true)
  const [customTextValue, setCustomTextValue] = useState("")
  const [isCustomOpen, setIsCustomOpen] = useState(false)
  const [customText, setCustomText] = useState("Custom")
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  //--------- jodit changes ----------
  const paraphraseeditor = useRef(null);
  const [editorContent, setEditorContent] = useState("")
  const [totalWords, setTotalWords] = useState(0)
  //--------- end -------------


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const { selectedCoauthorContent } = useSelector((state) => state.manageCoauthorSelectedContent);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize); 
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleTone = (e) => {
    console.log("selectedTone", selectedTone)
    let value = e.target.value
    setSelectedTone(value)
    console.log("value", value, e)
    setCustomText("Custom")
  }

  const handleCustomSelection = (type) => {
    let splitCustomText = type.split(' ');
    let suggestionType = splitCustomText[2];


    setCustomText(type);
    console.log(type, "type");
    setIsCustomOpen(false);
    setIsCustomSuggestion(true);
    setSuggestionText("Custom");
    setCustomTextValue("");

    grammarCheckForParaphrase(type);
  };



  const handleClose = () => {
    setIsCustomOpen(false)
    // setCustomText(customText)
  }

  const handleSuggestionChange = (e) => {
    console.log("Suggestion Text", e.target.value)
    let value = e.target.value
    console.log("value_", value)
    setCustomTextValue(value)

  }

  const handleCustomClick = () => {
    console.log("customText_", customTextValue)
    setIsCustomSuggestion(false)
    setSuggestionText(customTextValue)
  }



  const handleToneSelection = (type) => {
    //  setActiveEditor('paraphrase');
    grammarCheckForParaphrase(type)
  }


  useEffect(() => {
    if (selectedTone !== "" && selectedTone !== "custom") {
      handleToneSelection(selectedTone)
    }
  }, [selectedTone])




  useEffect(() => {
    console.log("selectedTone", selectedTone)
  }, [handleTone])


  // useEffect(() => {
  //   grammarCheckForParaphrase()
  // }, [props.callGrammerCheck])





  const handleOutputChange = (newEditorState) => {
    console.log("opChange_", newEditorState)
    setSecondaryEditorState(newEditorState)
  }

  useEffect(()=>{
    console.log(props.lowerModalToggle,"snoopdogg")
    if(props.paraphraserClicked){
    grammarCheckForParaphrase()
    }
  },[props.lowerModalToggle])


  const grammarCheckForParaphrase = async (type) => {


    

    console.log("typeeeee     ", type)

    let data = {}
    if (type !== undefined) {
      // Check for custom tones
      if (type == "Like a story" || type == "Like a poem" || type == "Like a letter" || type == "Like a salse executive" || type == "Like a news" || type == "Like a Doctor" || type == "Like a CEO") {
        data = {
          text:  props.editorState,
          tone: "custom",
          customTone: type,
          userId: userID,
          isCoAuthor: true
        }
      } else {
        // For other predefined tones
        data = {
          text:  props.editorState,
          tone: type,
          userId: userID,
          isCoAuthor: true
        }
      }
    } else {
      // If type is undefined, use default prompt
      data = {
        text:  props.editorState,
        tone: "defaultPrompt",
        userId: userID,
        isCoAuthor: true
      }
    }





    console.log("after   ", data.tone, "CustommTONE  ", data.customTone)



    setLoading(true)
    try {
      let paraphraseResponse = await checkPhrasing(data)
      console.log(data,"checkdataresponse")
      console.log("paraphraseResponse", paraphraseResponse)
      if (paraphraseResponse.data !== "") {
        let responseOutput = paraphraseResponse?.data?.output
        let concatenatedOutput = responseOutput.join(" ");
        // let wordLength = responseOutput.split(" ")
        console.log(data?.tone,"datatone")
        if(data?.tone=="shorten"){
          toast.success("Data shorten successfully!")
        }else if(data?.tone=="expand"){
          toast.success("Data expand successfully!")
        }
        console.log("paraphraseResponse_Pankaj", concatenatedOutput);
       
        let wordCount = concatenatedOutput.trim().split(/\s+/).length;
        setEditorContent(concatenatedOutput)
        setTotalWords(wordCount)
        // props.setSelectedContent(null)
      
      }
    }
    catch (e) {
      console.log("e", e)
    }
    //setCustomText("Custom")
    setLoading(false)

  }


  function handleModelClear() {
    Rephrase_grammarCheckForParaphrase()
    //setCustomText("Custom")
    setIsCustomOpen(false);
  }


  const Rephrase_grammarCheckForParaphrase = async (type) => {

    console.log("Secondary__editorState_", secondaryeditorState, secondaryeditorState.getCurrentContent().getPlainText());
    // if (secondaryeditorState.getCurrentContent().getPlainText() == "") {
    if (editorContent == "") {
      toast.warn("Nothing to rephrase");
      return;
    }
 
    const text = editorContent

    let data = {

      text:  text,
      tone: "defaultPrompt",
      userId: userID,
      isCoAuthor: true

    }
    











    setLoading(true)
    try {
      let paraphraseResponse = await checkPhrasing(data)
      console.log("paraphraseResponse", paraphraseResponse)
      if (paraphraseResponse.data !== "") {
        let responseOutput = paraphraseResponse?.data?.output
        // let wordLength = responseOutput.split(" ")
        console.log("paraphraseResponse", responseOutput);
        let parapharseText = paraphraseeditor.current
        parapharseText.setEditorValue(responseOutput[0]);
        let wordCount = responseOutput[0].trim().split(/\s+/).length;
        setEditorContent(responseOutput[0])
        setTotalWords(wordCount)
      }
    }
    catch (e) {
      console.log("e", e)
    }
    setLoading(false)

  }







  function handleDeletePara() {
    // setSecondaryEditorState(EditorState.createEmpty());
    setCustomText("Custom")
    setEditorContent("")
    let parapharseText = paraphraseeditor.current
    parapharseText.setEditorValue('');
  }

  const handleDownloadForParaphrase = () => {

    // if (!secondaryeditorState.getCurrentContent().getPlainText()) {
    if (editorContent == "") {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([editorContent], { type: 'text/plain' });
    console.log(blob, "blob");
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = '';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };



  const handleCopyEditorForParaphrase = () => {
    // let sentence = secondaryeditorState.getCurrentContent().getPlainText()
    let sentence = editorContent
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  // Jodit Editor

  const handleChangeEditor = () => {
    if (paraphraseeditor.current) {
      console.log("editor_", paraphraseeditor.current?.value);
      const htmlContent = paraphraseeditor.current.value; // Access the HTML content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = htmlContent;
      // Extract text content
      const textContent = tempElement.textContent || tempElement.innerText;
      console.log("textContent", textContent);
      let wordCount
      if (textContent !== 0) {
        wordCount = textContent.trim().split(/\s+/).length;
        setTotalWords(wordCount)
      } else {
        setTotalWords(0)
      }
      setEditorContent(textContent)
    }
  }







  return (
    <div style={{ height: "300px", width: "90%", marginTop: "100px" }}>
      <Loader loading={loading} />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginBottom: "10px", width: "38%" }}>
        <SelectDropdownMui
          optionsList={toneList}
          selectLabel={"Tone"}
          onChange={handleTone}
          value={selectedTone}
          customColor={'#3D83FA'}
        
        />
        </div>
        {/* <Button onChange={handleTone} value={"custom"} onClick={ setIsCustomOpen(true)}>Custom</Button> */}
        <Button variant="contained" size='medium'
          style={{
            color: isCustomSuggestion ? "white" : "black",
            backgroundColor: isCustomSuggestion ? '#3d83fa' : 'transparent',
            height:'33px',
            width:'150px'
          }}
          onClick={() => setIsCustomOpen(true)}>Custom</Button>
      </div>


      {customText !== "Custom" && <>
        <div>{customText}</div>
      </>}
      <Modal
        isOpen={isCustomOpen}
        // toggle={() => setIsCustomOpen(true)}
        size="lg"
        style={{
          // position: "absolute",
          // left: "43%",
          top: "33%",
        }}
      >



        <ModalHeader toggle={handleClose}>
          <div className="d-flex px-2 justify-content-between">
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
            >Make my text sound...</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
              className="col-md-10"
            >
              <InputBoxMui
                fullWidth
                placeholder={"E.g., “like a News”"}
                value={customTextValue}
                onChange={e => handleSuggestionChange(e)}

              />
            </p>
            <div className="ml-1" style={{ display: "flex", flexDirection: "row", width: "200px" }}>
              <button
                className="btn btn-primary ml-1"
                onClick={handleCustomClick}
                disabled={customTextValue !== "" ? false : true}

              >
                Done
              </button>
              {/* <FeatureButton text={"Clear Tone"} style={{ width: "120px" }} size={"md"} onClick={ handleModelClear} /> */}
              <button className="btn btn-primary ml-1" onClick={handleModelClear}

              > Clear Tone
              </button>
            </div>
          </div>
          <div className="px-2">
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#525252",
                fontSize: "14px",
              }}
            >Suggestions:</div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Story"} style={{ width: "120px",fontSize:windowWidth < 768 ? "10px" : "" }} size={"md"} onClick={() => handleCustomSelection("Like a story")} />
              <FeatureButton text={"Like a Poem"} divClassName={"ml-3"} style={{ width: "120px",fontSize:windowWidth < 768 ? "10px" : "" }} size={"md"} onClick={() => handleCustomSelection("Like a poem")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Ceo"} style={{ width: "120px",fontSize:windowWidth < 768 ? "10px" : "" }} size={"md"} onClick={() => handleCustomSelection("Like a CEO")} />
              <FeatureButton text={"Like a Doctor"} divClassName={"ml-3"} style={{ width: "120px",fontSize:windowWidth < 768 ? "10px" : "" }} size={"md"} onClick={() => handleCustomSelection("Like a Doctor")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a News"} style={{ width: "120px",fontSize:windowWidth < 768 ? "10px" : "" }} size={"md"} onClick={() => handleCustomSelection("Like a news")} />
              <FeatureButton text={"Like a Sales Executive"} divClassName={"ml-3"} style={{ width:windowWidth < 768 ? "120px" : "160px",fontSize:windowWidth < 768 ? "10px" : "" }} size={"md"} onClick={() => handleCustomSelection("Like a sales executive")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Letter"} style={{ width: "120px",fontSize:windowWidth < 768 ? "10px" : "" }} size={"md"} onClick={() => handleCustomSelection("Like a letter")} />
              <FeatureButton text={suggestionText} disable={isCustomSuggestion} divClassName={"ml-3"} style={{ width: "120px",fontSize:windowWidth < 768 ? "10px" : ""}} size={"md"} onClick={() => handleCustomSelection(suggestionText)} />
            </div>
          </div>
        </ModalBody>
      </Modal>



      <div className='mt-3' style={{ minWidth: '100%', maxWidth: '100%', boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)", borderRadius: '8px 8px 0px 0px'}}>
        

        <JoditEditorComp editorHeight={'55vh'} isMain={false} onChange={handleChangeEditor} editorContent={editorContent} editor={paraphraseeditor} isEdit={false} />
      </div>


<div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: '100%',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: '10px 5px',
            borderRadius: '0px 0px 8px 8px',
            background: 'white',
            minHeight: '70px',
            maxHeight: '70px',
        }}>
            <div style={{ display: "flex", alignItems: "center", marginLeft: '5px' }}>
                <CustomTooltip title="Delete">
                    <button onClick={handleDeletePara}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: '35px',
                            height: '35px',
                            borderRadius: '5px',
                            marginRight: '5px',
                            border: isHovered ? '1px solid grey' : '1px solid black',
                            color: isHovered ? 'grey' : 'red'
                        }}
                        onMouseOver={() => setIsHovered(true)}
                        onMouseOut={() => setIsHovered(false)}>
                        <DeleteIcon style={{ fontSize: '15px' }} />
                    </button>
                </CustomTooltip>
                <CustomTooltip title="Download">
                    <button onClick={handleDownloadForParaphrase}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: '35px',
                            height: '35px',
                            borderRadius: '5px',
                            marginRight: '5px',
                            border: isHovered2 ? '1px solid grey' : '1px solid black',
                            color: isHovered2 ? 'grey' : 'green'
                        }}
                        onMouseOver={() => setIsHovered2(true)}
                        onMouseOut={() => setIsHovered2(false)}>
                        <DownloadIcon style={{ fontSize: '15px' }} />
                    </button>
                </CustomTooltip>
                <CustomTooltip title="Copy">
                    <button onClick={handleCopyEditorForParaphrase}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: '35px',
                            height: '35px',
                            borderRadius: '5px',
                            marginRight: '5px',
                            border: isHovered3 ? '1px solid grey' : '1px solid black',
                            color: isHovered3 ? 'grey' : 'blue'
                        }}
                        onMouseOver={() => setIsHovered3(true)}
                        onMouseOut={() => setIsHovered3(false)}>
                        <ContentCopyIcon style={{ fontSize: '15px' }} />
                    </button>
                </CustomTooltip>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                color: '#1976D2',
                fontSize: '10px',
                textAlign: 'center',
                margin: '0 10px',
            }}>
                <FiberManualRecordIcon fontSize='small' />
                <b>{totalWords} words</b>
            </div>
            <div>
                <Button variant="contained"
                    style={{
                        color: "white",
                        backgroundColor: '#3d83fa',
                        fontSize:  'inherit',
                        width: 'auto'
                    }}
                    onClick={Rephrase_grammarCheckForParaphrase}>
                    Rephrase
                </Button>
            </div>
        </div>
    </div>


  );
}

export default CoAuther_ParaphraserPage;