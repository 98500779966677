import React, { useState } from "react";
import { Typography } from "@mui/material";

const TruncatedDescription = ({ description }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(prev => !prev);
  };

  const getTruncatedText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(" ")}...`;
    }
    return text;
  };

  return (
    <Typography>
      {description ? (
        <>
          {showFullText ? description : getTruncatedText(description, 20)}
          {description.split(" ").length > 20 && (
            <span
              style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
              onClick={toggleShowFullText}
            >
              {showFullText ? "Show less" : "Show more"}
            </span>
          )}
        </>
      ) : (
        <b>No description available.</b>
      )}
    </Typography>
  );
};

export default TruncatedDescription;