import React, { useEffect, useState } from 'react';
import './CoAuthor_M.css'
import Switch from '@mui/material/Switch';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, IconButton, Typography, Modal, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import { Modal as B_Modal, Form } from "react-bootstrap";
import LanguageIcon from '@mui/icons-material/Language';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AirplayIcon from '@mui/icons-material/Airplay';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputLabel from '@mui/material/InputLabel';
import FeedbackIcon from '@mui/icons-material/Feedback';
// import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuItem from '@mui/material/MenuItem';
import { TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SelectDropdownMui from "../../ReusableComponents/SelectDropdownMui";
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
// import citationService from '../../../services/citationService';
import { v4 as uuidv4 } from 'uuid';
import { CoAuthor_Searchcomponent } from './CoAuthor_Search';
import { CoAuthor_Aireviewcomponent } from './CoAuthor_Aireview';
import { Coauthor_Notescomponent } from './CoAuthor_Notes';
import { Coauthor_Citationcomponent } from './CoAuthor_Citation';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import CoAuther_PlagiarismChecker_Text from './CoAuther_PlagiarismChecker_Text';
import CoAuther_PlagiarismChecker_addWords from './CoAuther_PlagiarismChecker_addWords';
import CoAuther_PlagiarismChecker_Blank from './CoAuther_PlagiarismChecker_Blank';
import aireviewicon from "../../../static/AI_Review_color.png"
import aireviewiconwhite from "../../../static/AI_Review_white.png"

import plagiarismicon from "../../../static/Plagiarism_logo.png"
import plagiarismiconwhite from "../../../static/Plagiarism_white.png"

// import { getNotes } from "../../../services/notesService";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles({
  icon: {
    color: 'inherit',
    transition: 'color 0.3s',
  },
});


const CoauthorModal = (props) => {
  const classes = useStyles();
  const [sourceList, setsourceList] = useState([
    {
      "value": "Individual",
      "name": "Individual"
    },
    {
      "value": "Organization",
      "name": "Organization"
    }
  ]);
  const email = sessionStorage.getItem("currentUserEmail");
  console.log(email, "email from 1");
  // const [activeButton, setActiveButton] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showResearchContent, setShowResearchContent] = useState(true);
  const [showTextSnippetContent, setShowTextSnippetContent] = useState(false);
  const [showFormatQuoteContent, setShowFormatQuoteContent] = useState(false);
  const [showAirplayContent, setShowAirplayContent] = useState(false);
  const [showAireviewContent, setshowAireviewContent] = useState(false)
  const [noteInput, setNoteInput] = useState('');
  const [coAuther_PlagiarismChecker, setCoAuther_PlagiarismChecker] = useState(false)
  const [hoveredPC, setHoveredPC] = useState(false);
  const [hoveredAI, setHoveredAI] = useState(false);

  const dispatch = useDispatch();
  // const { rightModalOpen, leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);
  // const { isFullSize } = useSelector((state) => state.manageFullSizeEditor);


  const editorContent = props.content;

// Create a temporary DOM element to parse the HTML
const tempElement = document.createElement("div");
tempElement.innerHTML = editorContent;

const editableAreaDiv = tempElement.querySelector(".editable-area");

let textContent;

if (editableAreaDiv) {
  // If the div exists, get its text content
  textContent = editableAreaDiv.textContent || editableAreaDiv.innerText || "";
} else {
  // Otherwise, fall back to the entire content's text content
  textContent = tempElement.textContent || tempElement.innerText || "";
}



  const handleCloseModal = () => {
    props.setCoauthorModalOpen(false);
  };

  const handleTextInput = (text) => {
    setNoteInput(text);
  };
  const autherNameFromSearch = (name) => {
    // alert("name i modal", name)
    props.passNametoCoNewProject(name)
  }

  const handleMouseEnterPC = () => {
    setHoveredPC(true);
  };

  const handleMouseLeavePC = () => {
    setHoveredPC(false);
  };
  
  const handleMouseEnterAI = () => {
    setHoveredAI(true);
  };

  const handleMouseLeaveAI = () => {
    setHoveredAI(false);
  };

  return (
    <>
      <div>
        
        {props.coauthorModalOpen &&
          <div >
            <div className="Serive_div blackBg" style={{backgroundColor:'white',border:"10px solid yellow", width: props.mobileView ? '70%' : '468px', zIndex: '1000', overflowX:'hidden', overflowY: 'auto', height: props.mobileView ? '80vh' : '100vh', position: props.mobileView ? 'fixed' : "absolute", top: props.mobileView ? '90px' : "67px", right: props.mobileView ? '5px' : "0px", border: '1px solid  grey', padding: "none" }}>
              <div style={{display: 'flex', width:props.mobileView ? '100%' : '100%', justifyContent: "space-between", alignItems: "center", border: "1px solid grey ", marginTop: "0px" }}>
                <div style={{width:props.mobileView ? "85%" : "80%", display: 'flex',flexWrap:props.mobileView ? "wrap" : "", marginBottom: "0px" }}>
                  <div style={{ border: "1px solid grey", borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none', alignItems: "center" }}>
                    {props.searchClicked ? (
                      <IconButton
                        disableTouchRipple
                        style={{ backgroundColor: props.searchClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", marginBottom: "0px", marginTop: "0px" }}
                        onClick={() => props.handleSearchClick()}>
                        <div style={{ color: '#8A2BE2', display: 'flex', alignItems: "center" }}>< LanguageIcon />
                          {props.searchClicked && !props.mobileView &&
                            <h6 style={{ color: '#8A2BE2', marginLeft: '3px', marginBottom: "0px" }}>Search</h6>
                          }
                        </div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Search">
                        <IconButton
                          disableTouchRipple
                          style={{ backgroundColor: props.searchClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", marginBottom: "0px", marginTop: "0px" }}
                          onClick={() => props.handleSearchClick()}>
                          <div style={{ color: '#8A2BE2', display: 'flex', alignItems: "center" }}>< LanguageIcon />
                            {props.searchClicked &&
                              <h6 style={{ color: '#8A2BE2', marginLeft: '3px', marginBottom: "0px" }}>Search</h6>
                            }
                          </div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                  <div style={{ border: "1px solid grey", borderTop: 'none', borderRight: 'none', borderBottom: 'none', }}>
                    {props.notesClicked ? (
                      <IconButton
                        disableTouchRipple
                        style={{ backgroundColor: props.notesClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", }}
                        onClick={() => props.handleNotesClick()}>
                        <div style={{ color: '#FFA07A', display: 'flex', alignItems: "center" }}><TextSnippetIcon />
                          {props.notesClicked && !props.mobileView &&
                            <h6 style={{ color: '#FFA07A', marginLeft: '3px', marginBottom: "0px" }}>Notes</h6>
                          }
                        </div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Notes">
                        <IconButton
                          disableTouchRipple
                          style={{ backgroundColor: props.notesClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", }}
                          onClick={() => props.handleNotesClick()}>
                          <div style={{ color: '#FFA07A', display: 'flex', alignItems: "center" }}><TextSnippetIcon />
                            {props.notesClicked &&
                              <h6 style={{ color: '#FFA07A', marginLeft: '3px', marginBottom: "0px" }}>Notes</h6>
                            }
                          </div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                  <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none', borderRight: 'none' }}>
                    {props.citationClicked ? (
                      <IconButton
                        style={{ backgroundColor: props.citationClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", alignItems: "center", display: 'flex' }}
                        disableTouchRipple
                        onClick={() => props.handleCitationClick()}>
                        <div style={{ color: '#ff1694', display: 'flex', alignItems: "center" }}><FormatQuoteIcon />
                          {props.citationClicked && !props.mobileView &&
                            <h6 style={{ color: props.citationClicked ? '#ff1694' : 'black' , marginLeft: '2px', marginBottom: "0px" }}>Citation</h6>
                          }</div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Citation">
                        <IconButton
                          style={{ backgroundColor: props.citationClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", alignItems: "center", display: 'flex' }}
                          disableTouchRipple
                          onClick={() => props.handleCitationClick()}>
                          <div style={{ color: '#ff1694', display: 'flex', alignItems: "center" }}><FormatQuoteIcon />
                            {props.citationClicked &&
                              <h6 style={{ color: '#ff1694', marginLeft: '2px', marginBottom: "0px" }}>Citation</h6>
                            }</div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                  <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none' }}>
                    {props.aireviewClicked ? (
                      <IconButton
                        disableTouchRipple
                        style={{ backgroundColor: props.aireviewClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                        onClick={() => props.handleAireviewClick()}>
                        <div style={{ color: 'black', display: 'flex', alignItems: "center" }}>
                          {/* <AirplayIcon /> */}
                          <img
                              src={aireviewicon}
                              alt="AI Review"
                              style={{width:'21px'}}
                          /> 
                          {props.aireviewClicked && !props.mobileView &&
                            <h6 style={{ color: props.aireviewClicked ? 'green' : 'black', marginLeft: '4px', marginBottom: "0px" }}>AI Review</h6>
                          }</div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Ai Review">
                        <IconButton
                          disableTouchRipple
                          style={{ backgroundColor: props.aireviewClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                          onClick={() => props.handleAireviewClick()}>
                          <div style={{ color: 'black', display: 'flex', alignItems: "center" }}>
                            {/* <AirplayIcon /> */}
                            <img
                                src={aireviewicon}
                                alt="AI Review"
                                style={{width:'21px'}}
                            />
                            {props.aireviewClicked &&
                              <h6 style={{ color: 'black', marginLeft: '4px', marginBottom: "0px" }}>AI Review</h6>
                            }</div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                  <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none' }}>
                    {props.plagiarismClicked ? (
                      <IconButton
                        disableTouchRipple
                        style={{ backgroundColor: props.plagiarismClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                        onClick={() => props.handlePlagiarismClick()}>
                        <div style={{ color: 'black', display: 'flex', alignItems: "center" }}>
                        <img
                                src={plagiarismicon}
                                alt="Plagiarism Checker"
                                className={classes.icon}
                                style={{width:'21px'}}
                            />
                          {props.plagiarismClicked && !props.mobileView &&
                            <h6 style={{ color: props.plagiarismClicked ? 'red' : 'black', marginLeft: '4px', marginBottom: "0px" }}>Plagiarism Checker</h6>
                          }</div>
                      </IconButton>
                    ) : (
                      <LightTooltip title="Plagiarism Checker">
                        <IconButton
                          disableTouchRipple
                          style={{ backgroundColor: props.plagiarismClicked ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                          onClick={() => props.handlePlagiarismClick()}>
                          <div style={{ color: 'black', display: 'flex', alignItems: "center" }}>
                            {/* <ManageSearchIcon /> */}
                            <img
                                src={plagiarismicon}
                                alt="Plagiarism Checker"
                                className={classes.icon}
                                style={{width:'21px'}}
                            />
                            {props.plagiarismClicked &&
                              <h6 style={{ color: props.plagiarismClicked ? 'red' : 'black', marginLeft: '4px', marginBottom: "0px" }}>Plagiarism Checker</h6>
                            }</div>
                        </IconButton>
                      </LightTooltip>
                    )}
                  </div>

                </div>
                <div style={{width:props.mobileView ? "10%" : "15%"}} onClick={handleCloseModal}>
                  <CloseIcon className='whiteText' style={{ cursor: "pointer" }} />
                </div>
              </div>


              {props.searchClicked && (
                <CoAuthor_Searchcomponent userId={props.userId} />
              )}

              {props.notesClicked && (
                <Coauthor_Notescomponent handleTextInput={handleTextInput} />
              )}

              {props.aireviewClicked && (
                <>
                  < CoAuthor_Aireviewcomponent editorState={textContent} userId={props.userId} />
                </>
              )}

              {props.plagiarismClicked &&
                props.count == 0 && (
                  <CoAuther_PlagiarismChecker_Blank close={handleCloseModal} />
                )
              }

              {props.plagiarismClicked &&
                props.count == 1 && (
                  <CoAuther_PlagiarismChecker_addWords close={handleCloseModal} />
                )
              }

              {props.plagiarismClicked &&
                props.count > 1 && (
                  <CoAuther_PlagiarismChecker_Text editorState={textContent} setEditorState={props.setContent} userId={props.userId} />
                )
              }

              {props.citationClicked && (
                <Coauthor_Citationcomponent userId={props.userId} />

              )}

            </div>
          </div>

         } 
      </div>
    </>
  )
}

export default CoauthorModal;