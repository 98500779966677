
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import Switch from '@mui/material/Switch';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CachedIcon from '@mui/icons-material/Cached';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import SearchIcon from '@mui/icons-material/Search';
import { getAIReview } from "../../../services/trackChanges";
import { toast, ToastContainer } from "react-toastify";
import { Button, IconButton } from '@material-ui/core';
import { AtomicBlockUtils, BlockMapBuilder, ContentState, EditorState, Entity, Modifier, RichUtils, SelectionState } from 'draft-js';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoIcon from '@mui/icons-material/Info';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from '../../../utils/loader';
import { useSelector } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { json } from 'react-router-dom';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);



export const CoAuthor_Aireviewcomponent = (props) => {
  console.log("aireviewprops", props)
  
  const userID = props.userId
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [suggestedText, setSuggestedText] = useState([]);
  const [isContentExpanded, setIsContentExpanded] = useState(false);
  const [selectedSentence, setSelectedSentence] = useState("")
  const [showResolvedEnabled, setShowResolvedEnabled] = useState(false);
  const [resolvedAccordions, setResolvedAccordions] = useState([]);
  const [wordCount, setWordCount] = useState(0)
  const email = sessionStorage.getItem("currentUserEmail");
  console.log(email, "email from 1");
  const [customFeedback, setCustomFeedback] = useState('');
  const [saveToNotesHovered, setSaveToNotesHovered] = useState(false);
  const [copyIconHovered, setCopyIconHovered] = useState(false);
  const [infoIconHovered, setInfoIconHovered] = useState(false);
  const groupedArrays = [];
  let length_w = 0;

  const [accordionHoverStates, setAccordionHoverStates] = useState([]);
  const { selectedCoauthorContent } = useSelector((state) => state.manageCoauthorSelectedContent);

  const [isDarkMode, setIsDarkMode] = useState(false);
  useEffect(() => {
      const updateTheme = () => {
        const theme = localStorage.getItem('ThemeSwitch');
        setIsDarkMode(theme === 'true');
      };
      updateTheme();
      window.addEventListener('storage', updateTheme);
      const originalSetItem = localStorage.setItem;
      localStorage.setItem = function (key, value) {
        originalSetItem.apply(this, arguments);
        if (key === 'ThemeSwitch') {
          updateTheme(); 
        }
      };
      return () => {
        window.removeEventListener('storage', updateTheme);
        localStorage.setItem = originalSetItem; 
      };
    }, []);

  // const createAccordionHoverState = (index) => {
  //   setAccordionHoverStates((prevStates) => {
  //     const newStates = [...prevStates];
  //     newStates[index] = { saveToNotes: false, copyIcon: false, infoIcon: false };
  //     return newStates;
  //   });
  // };

  // const handleAccordionHover = (index, buttonType, isHovered) => {
  //   setAccordionHoverStates((prevStates) => {
  //     const newStates = [...prevStates];
  //     newStates[index][buttonType] = isHovered;
  //     return newStates;
  //   });
  // };




  // const handleSaveToNotesMouseEnter = () => {
  //   setSaveToNotesHovered(true);

  // };

  // const handleSaveToNotesMouseLeave = () => {
  //   setSaveToNotesHovered(false);

  // };

  // const handleCopyIconMouseEnter = () => {
  //   setCopyIconHovered(true);
  // };

  // const handleCopyIconMouseLeave = () => {
  //   setCopyIconHovered(false);
  // };

  // const handleInfoIconMouseEnter = () => {
  //   setInfoIconHovered(true);
  // };

  // const handleInfoIconMouseLeave = () => {
  //   setInfoIconHovered(false);
  // };
  const handlePromptSelection = (prompt) => {
    setSelectedPrompt(prompt);
    // handleAIReview()
  };
console.log(selectedCoauthorContent,"____selectedCoauthorContent")

  const handleAIReview = async () => {
    try {
      if (selectedPrompt === "Custom Feedback") {
        handleCustomFeedbackSubmit();
        return;
      }

      // let input = props.editorState.getCurrentContent().getPlainText();
      let input = selectedCoauthorContent ? selectedCoauthorContent : props.editorState
      console.log("input in AI before", input)
      input = input.replace(/\n/g, ' ');
      console.log("input in AI    AFTER", input)
      if (input === '') {
        toast.warn('Please enter text to get AI review');
        setLoading(false);
        return;
      }
      // alert(input,"input")

      let data;

      if (selectedPrompt === "Get general advice") {
        data = {

          // text: `Generate a rephrased response for the provided text to reduce ambiguity, rephrase technical info, clarify language and generate the output everytime in the following static JSON format  {AI generated text: <<Rephrased text>>", "Reasoning: <<Justification of rephrased text>>} text =${input}`
          // text: `Generate a rephrased response for the provided text to reduce ambiguity, rephrase technical info, clarify language, and generate the output every time in the following static JSON format: {"AI generated text": "<<Rephrased text>>", "Reasoning": "<<Justification of rephrased text>>"} text = ${input}`,
          text: `${input}`,
          type: "aireview",
          option: "generalAdvice",
          userId: userID,
          isCoAuthor: true
        };
      } else if (selectedPrompt === "Increase clarity & readability") {
        data = {
          // text: `Generate a rephrased response for the provided text to improve structure, argue effectively, increase engagement and generate the output every time in the  in the following  static JSON format:{"AI generated text": "<<Rephrased text>>", "Reasoning": "<<Justification of rephrased text>>"} text =${input}`
          text: `${input}`,
          type: "aireview",
          option: "clarityAndReadability",
          userId: userID,
          isCoAuthor: true
        };
      } else if (selectedPrompt === "Ensure content is complete") {
        data = {
          // text: `Generate a rephrased response for the provided text to identify missing sections or information and offer examples and generate the output every time in the following static JSON format:{"AI generated text": "<<Rephrased text>>", "Reasoning": "<<Justification of rephrased text>>"}  text = ${input}`
          text: `${input}`,
          type: "aireview",
          option: "ensureContentIsComplete",
          userId: userID,
          isCoAuthor: true
        };
      }



      setLoading(true);
      const responses = [];
      for (let i = 0; i < 3; i++) {
        const response = await getAIReview(data);
        console.log("Response", i + 1, ":", response);
        // responses.push(response);
        if (response && response.success && response.aiGeneratedText && response.Reasoning) {
          responses.push(response);
        }
        console.log(response.aiGeneratedText,"response.aiGeneratedText");
        console.log(response.Reasoning,"response.Reasoning");
        // Adding a timeout of 3 seconds before making the next call
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
      console.log("responses_", responses)
      setSuggestedText(responses);

      // new code to map the response 
      // const outputs = responses.map(response => {
      //   // Assuming each output contains only one element, if not, you might need to handle multiple elements here
      //   const output = response.output[0];
      //   // const parsedOutput = JSON.parse(output);
      //   return output;
      // });

      // // Now you have an array of parsed outputs
      // console.log(outputs);


      // if (outputs[0]) {
      //   const suggestedTextArray = outputs
      //   console.log("Suggested Text Array:", suggestedTextArray);
      //   setSuggestedText(suggestedTextArray);
      //   setLoading(false);
      // } else {
      //   console.error("Unexpected response structure:",);
        setLoading(false);
      // }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleCustomFeedbackSubmit = async () => {
    try {
      if (!customFeedback) {
        toast.warn('Please enter custom feedback before submitting.');
        return;
      }

      // setSelectedPrompt("Custom Feedback");
      // let input = props.editorState.getCurrentContent().getPlainText();
      let input = selectedCoauthorContent ? selectedCoauthorContent : props.editorState
      console.log("input in AI before     ", input)
      input = input.replace(/\n/g, ' ');
      if (input === '') {
        toast.warn('Please enter text to get AI review');
        setLoading(false);
        return;
      }
      setLoading(true);
      const responses = []; // Create an array to store responses

      for (let i = 0; i < 3; i++) {
        const response = await getAIReview({
          // text: `Generate a rephrased response for the provided text ${customFeedback} and generate the output every time in the following static JSON format: {"AI generated text": "<<Rephrased text>>", "Reasoning": "<<Justification of rephrased text>>"} text = ${input}`
          text: `${input}`,
          type: "aireview",
          option: "customFeedback",
          customQuery: customFeedback,
          userId: userID,
          isCoAuthor: true

        });
        console.log("Response", i + 1, ":", response);
        // responses.push(response); // Push each response into the array
        if (response && response.success && response.aiGeneratedText && response.Reasoning) {
          responses.push(response);
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
      console.log("responses_", responses)
      setSuggestedText(responses);

      // const output = responses.map(response => {
      //   const output = response.output[0];
      //   const parsedOutput = JSON.parse(output);
      //   return parsedOutput;
      // });

      // if (output) {
      //   console.log("Suggested Text Array:", output);
      //   setSuggestedText(output);
      // } else {
      //   console.error("Unexpected response structure:");
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (selectedPrompt !== null) {
      console.log(selectedPrompt, "selected prompt");
      handleAIReview();
    }
  }, [selectedPrompt]);

  const handleToggleContent = () => {
    setIsContentExpanded(!isContentExpanded);
  };


  const handleBack = () => {
    setSuggestedText(null)
    setSelectedPrompt(null);
  }

  //  ----- copy to clipboard ---

  const handleCopyText = () => {
    let sentence = groupedArrays
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const saveToNotes = async (aiGeneratedText, reasoning) => {
    console.log("AI generated text:", aiGeneratedText);
    console.log("Reasoning:", reasoning);
    try {
      let sessionData = sessionStorage.getItem('notesData') || "";

      // Concatenate AI generated text and reasoning with a newline separator
      const notesEntry = `Ai Generated Text: ${aiGeneratedText}\nReasoning: ${reasoning}`;

      // Add a new line before appending the text
      sessionData = sessionData === "" ? notesEntry : `${sessionData}\n_______________________________________________________\n\n${notesEntry}`;

      sessionStorage.setItem('notesData', sessionData);
      toast.info(`Notes saved successfully.`);
    } catch (error) {
      console.log(error);
    }
  }

  const handleCopyText1 = (text, reasoning) => {

    const TextToCopy = ` ${text}\nReasoning: ${reasoning}`;
    if (!TextToCopy || TextToCopy.trim() === "") {
      toast.warn(`You don't have text to copy`);
      return;
    }

    navigator.clipboard.writeText(TextToCopy).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        toast.info("Your text copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
        toast.error("Failed to copy text. Please try again.");
      }
    );
  };

console.log(suggestedText,"suggested text");

  return (
    <>
      <Loader loading={loading} />
      {Array.isArray(suggestedText) && suggestedText.length > 0 ? (


        <div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 0px", borderBottom: "1px solid gray" }}>
            <div style={{ marginLeft: "5px" }}>
              <p><b className='whiteText'>{selectedPrompt}</b></p>
            </div>
            <Button variant='contained' color='primary' style={{ fontSize: '13px', marginRight: '5px', textTransform: 'capitalize' }}
              onClick={handleBack}
            >Generate New Review</Button>
          </div>
          <div style={{ padding: "6px" }}>
            <div>
              <div>

                {suggestedText.map((item, index) => (
                  
                    <div style={{borderBottom:'1px solid black', padding:'15px', backgroundColor: isDarkMode ? "#2a2a2a" : "white",marginBottom: isDarkMode ? "10px" : "0px"}}>
                    <Typography>
                        <span  className='whiteText'>{item["aiGeneratedText"]}</span>
                        <div style={{ marginTop: "10px" }}>
                           <b  className='whiteText'>Reasoning: </b>
                           <span style={{color:"#007bff"}}>{item["Reasoning"]}</span>
                         </div>
                       </Typography>

                       <Typography>
                       <div>
                         <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px 0px 0px" }}>
                           {/* <b>Words: {item["AI generated text"].split(/\s+/).length}</b> */}
                           <b  className='whiteText'>Words: {`${item["aiGeneratedText"]} ${item["Reasoning"]}`.split(/\s+/).length}</b>
                           {!props.isGrammarCheck && <LightTooltip title="Save to notes">

                                
                                <IconButton onClick={() => saveToNotes(item["aiGeneratedText"], item["Reasoning"])}>
                                  <ArticleOutlinedIcon  className='whiteText'/>
                                  </IconButton>
                                
                              
                            </LightTooltip>}
                            <Button
                              style={{ fontWeight: "bold", backgroundColor: accordionHoverStates[index]?.copyIcon ? '#3DED97' : 'inherit', transition: "background-color 0.3s" }}
                              
                              onClick={() => handleCopyText1(item["aiGeneratedText"], item["Reasoning"])}
                            >
                              <LightTooltip title="Copy">
                                <ContentCopyIcon  className='whiteText' />
                              </LightTooltip>
                            </Button>
                            <Button
                              style={{ fontWeight: "bold", backgroundColor: accordionHoverStates[index]?.infoIcon ? '#3DED97' : 'inherit', transition: "background-color 0.3s" }}
                              
                            >
                              <LightTooltip title="Examples are used to illustrate suggestions. Check examples for factual errors if adding to your text.">
                                <InfoIcon color='primary'/>
                              </LightTooltip>
                            </Button>
                          </div>
                        </div>
                      </Typography>

                    </div>
                   
                ))}
              </div>

            </div>


          </div>
        </div>

      ) : (
        <div style={{padding:'20px 0px'}}>
          <div className='whiteText' style={{ width: "80%", margin: "auto", marginTop: "10px" , fontSize: props.mobileView ? '11px' : '' }}>
            Alright! Let's get started with a writing review. What type of pointer would you like
          </div>

          <div style={{ margin: "auto", marginTop: "10px" }}>
            

            <div style={{ position: "relative", width: props.mobileView ? '90%' : "430px", margin:  "auto" }}>
              <textarea
                style={{
                  borderRadius: "12px",
                  padding: "10px",
                  margin: "12px",
                  border: "1px solid #ccc",
                  boxSizing: "border-box",
                  width: "95%",
                  backgroundColor: isDarkMode ? "black" :  "white",
                  color: isDarkMode ? "white" : "black"
                }}
                className='custom-textarea'
                name=""
                placeholder='E.g., How can I make my writing sound more academic?'
                id=""
                rows="5"
                value={customFeedback}
                onChange={(e) => setCustomFeedback(e.target.value)}
              ></textarea>

              {/* Icon */}
              <span style={{ position: "absolute", top: "70%", right: "15px", transform: "translateY(-50%)", cursor: "pointer" }}>
                <ArrowForwardIosIcon  className='whiteText' onClick={handleCustomFeedbackSubmit} />
              </span>
            </div>


          </div>

          <div className='Service_bottom' style={{ width: props.mobileView ? '90%' : ''}}>
            <div className='Ai_Review_div' onClick={() => handlePromptSelection("Get general advice")} style={{width: props.mobileView ? '95%' : ''}}>
              <p className='whiteText' style={{ fontWeight: "600", fontFamily: "poppins", fontSize: props.mobileView ? '12px' : "14px" }}>Get general advice</p>
              <div style={{ display: 'flex', cursor: "pointer", justifyContent:'space-between', width: props.mobileView ? '100%' : '' }}>
                <div style={{width: props.mobileView ? '90%' : '', fontSize: props.mobileView ? '11px' : ""}}><p className='whiteText'>Improve structure, argue effectively, increase engagement</p></div><div style={{width: props.mobileView ? '10%' : ''}}><ArrowForwardIosIcon className='whiteText' /></div> 
              </div>
            </div>

            <div className='Ai_Review_div' onClick={() => handlePromptSelection("Increase clarity & readability")} style={{width: props.mobileView ? '95%' : ''}}>
              <p className='whiteText' style={{ fontWeight: "600", fontFamily: "poppins", fontSize:  props.mobileView ? '12px' : "14px" }}>Increase clarity & readability</p>
              <div style={{ display: 'flex', cursor: "pointer", justifyContent:'space-between', width: props.mobileView ? '100%' : '' }}>
                <div style={{width: props.mobileView ? '90%' : '' , fontSize: props.mobileView ? '11px' : ""}}><p className='whiteText'>Reduce ambiguity, rephrase technical info, clarify language</p></div> <div style={{width: props.mobileView ? '10%' : ''}}><ArrowForwardIosIcon className='whiteText' /></div>
              </div>
            </div>

            <div className='Ai_Review_div' onClick={() => handlePromptSelection("Ensure content is complete")} style={{width: props.mobileView ? '95%' : ''}}>
              <p className='whiteText' style={{ fontWeight: "600", fontFamily: "poppins", fontSize:  props.mobileView ? '12px' : "14px" }}>Ensure content is complete</p>
              <div style={{ display: 'flex', cursor: "pointer", justifyContent:'space-between',  width: props.mobileView ? '100%' : '' }}>
                <div style={{width: props.mobileView ? '90%' : '' , fontSize: props.mobileView ? '11px' : ""}}><p className='whiteText'>Identify missing sections or information & offer examples</p></div> <div style={{width: props.mobileView ? '10%' : ''}}><ArrowForwardIosIcon className='whiteText' /></div>
              </div>
            </div>
          </div>

        </div>
      )}
    </>
  )
}

