
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Dropdown, Button as B_Button, Modal } from 'react-bootstrap';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, IconButton, Typography, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import { Modal as B_Modal, Form } from "react-bootstrap";
// import { saveNotes, getNotes, saveWrittenNotes } from "../../../services/notesService";
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import { asBlob } from 'html-docx-js-typescript';
import { toast } from 'react-toastify';
import "./CoAuthor_Notes.css"
 
 
export const Coauthor_Notescomponent = ({ handleTextInput }) => {
  const email = sessionStorage.getItem("currentUserEmail");
  console.log(email, "email from 1");
 
  const [notesData, setNotesData] = useState(() => sessionStorage.getItem('notesData') || '');
  const [downloadModal, setDownloadModal] = useState(false)

  const openDownloadModal = () =>{
    if(!notesData){
      toast.error('Notes is empty')
      return
    }
    setDownloadModal(true)
  }

  const closeDownloadModal = () =>{
    setDownloadModal(false)
  }
 
  const handleTextChange = (e) => {
    const updatedNoteInput = e.target.value;
    handleTextInput(updatedNoteInput);
    setNotesData(updatedNoteInput);
    sessionStorage.setItem('notesData', updatedNoteInput);
  };
 
  useEffect(() => {
    const fetchData = () => {
      try {
        const sessionData = sessionStorage.getItem('notesData');
        if (sessionData) {
          setNotesData(sessionData);
        }
      } catch (error) {
        console.log(error);
      }
   
    };
 
    fetchData();
  }, [email]);


  const replaceUnderscoresWithHr = (text) => {
    return text.replace(/_{3,}/g, '<hr/>').replace(/\n/g, '<br/>'); 

  };

  const downloadNotesAsPDF = () => {
    const pdfContent = `
      <div>
        <h5>Notes</h5>
        <p>${replaceUnderscoresWithHr(notesData)}</p>
      </div>`;

    const element = document.createElement('div');
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: 'Notes.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }).then(() => {
      closeDownloadModal();
    });
  };

  const downloadNotesAsWord = () => {
    const htmlString = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <title>Notes</title>
    </head>
    <body>
        <h3>Notes</h3>
        <p>${replaceUnderscoresWithHr(notesData)}</p>
    </body>
    </html>`;

    asBlob(htmlString).then(docx => {
      saveAs(docx, 'Notes.docx');
      closeDownloadModal();
    });
  };
  
 
 
 
  return (
    <>
    <div style={{height:'45px', display:'flex', justifyContent:'space-between'}}>
      <div>
      <Button variant='contained' color='primary' style={{margin:'6px 10px'}} onClick={() => { openDownloadModal() }}>Download</Button>
      </div>
      <div className='whiteText'>
        <p style={{cursor:'pointer', margin:'13px 20px',fontSize:"12px"}} onClick={()=>{
          setNotesData('');
          sessionStorage.setItem('notesData', '');
          }}>Clear All</p>
      </div>
    </div>
     <Form.Group>
        <Form.Control
          as="textarea"
          placeholder="Please enter your text."
          cols="60"
          rows="50"
          value={notesData}
          onChange={handleTextChange}
           className="notesTextarea"
        />
      </Form.Group>

      <Modal show={downloadModal} onHide={closeDownloadModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Download Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <span>Download as...</span>
            <br /><br />
            <B_Button variant="primary" className="mr-3" onClick={downloadNotesAsPDF}>
              PDF
            </B_Button>
            <B_Button variant="primary" onClick={downloadNotesAsWord}>
              Word
            </B_Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}