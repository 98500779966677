import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = apiUrl + "/trackChanges";
} else {
  apiEndpoint = herokuUrl + "/trackChanges";
}

export async function saveTrackedChanges(  assetId, sectionId, assetType, createdBy, addedContent, removedContent, htmlContent, userId ) {
    let data = await httpService.post(`${apiEndpoint}/saveTrackedChanges`,
    {
        assetId, sectionId, assetType, createdBy, addedContent, removedContent, htmlContent, userId
    }
    );
    return data;
  }



export async function getTrackedChanges( assetId, sectionId, assetType ) {
    let data = await httpService.post(`${apiEndpoint}/getTrackedChanges`,
        {
            assetId, sectionId, assetType
        }
    );
    return data;
}

export async function sendRevertRequest(data) {
  let response = await httpService.post(`${apiEndpoint}/sendRevertRequest`,{data});
  return response;
}

export async function updateRevertRequest(data) {
  let response = await httpService.post(`${apiEndpoint}/updateRevertRequest`,{data});
  return response;
}



export async function saveContentLibrary(orgName, assetId, sectionId, createdBy, fieldValues) {
  let data = await httpService.post(`${apiEndpoint}/saveContentLibrary`, {
      orgName,
      assetId,
      sectionId,
      createdBy,
      fieldValues
  });
  return data;
}

export async function getContentLibrary( orgName, assetId, sectionId, createdBy ) {
  let data = await httpService.post(`${apiEndpoint}/getContentLibrary`,
      {
          orgName, assetId, sectionId, createdBy
      }
  );
  return data;
}

export async function getClausesList() {
  let data = await httpService.get(`${apiEndpoint}/getClausesList`);
  return data;
}

export async function checkPhrasing(data){
  const cleanedText = data.text.replace(/.*\t.*/g, '').trim();
  data.text = cleanedText
  const response = await httpService.post(`${apiEndpoint}/checkParaphrasing`,  data );
  return response;
}


export async function getCheckSummariser(input) {
  const cleanedText = input.text.replace(/.*\t.*/g, '').trim();
  input.text = cleanedText
  const data = await httpService.post(`${apiEndpoint}/checkSummarizer`, {
    input
  });
  return data;
}

export async function checkTranslator(text){
  const response = await httpService.post(`${apiEndpoint}/checkTranslator`, {text} );
  return response;
}

export async function checkOCR(data){
  const response = await httpService.post(`${apiEndpoint}/checkOCR`,  {data} );
  return response;
}


export async function getSearchHistory(data){
  const response = await httpService.post(`${apiEndpoint}/getSearchHistory`, data );
  return response;
}

export async function deleteSearchSuggestion(data){
  const response = await httpService.post(`${apiEndpoint}/deleteSearchSuggestion`, data );
  return response;
}

export async function getSearchApi(text){
  const response = await httpService.post(`${apiEndpoint}/coAuthorPrompts`, text );
  return response;
}


export async function getAIReview(text){
  const response = await httpService.post(`${apiEndpoint}/coAuthorPrompts`, text );
  return response;
}

export async function checkPlagiarism(data,userId,isCoAuthor){
  const response = await httpService.post(`${apiEndpoint}/checkPlagiarism`,{data,userId,isCoAuthor});
  return response;
}


export async function getCheckCitationWebpage(text, citationStyle, sourceitem,userId,isCoAuthor) {
  const allowedTextStyles = [
    "AMA 10th edition",
    "American Chemical Society",
    "APA 11th edition",
    "APA 6th edition",
    "APA 7th edition",
    "Chicago author"
];

const finalTextStyle = allowedTextStyles.includes(citationStyle) ? citationStyle : "AMA 10th edition";
  const data = await httpService.post(`${apiEndpoint}/checkCitationsWebpage`, {
    text,
    citationStyle: finalTextStyle,
    sourceItem: sourceitem,
    userId,isCoAuthor,
  });
  return data;
}

export async function getCheckCitationJournalArticle(text, citationStyle, sourceitem,userId,isCoAuthor) {
  const allowedTextStyles = [
    "AMA 10th edition",
    "American Chemical Society",
    "APA 11th edition",
    "APA 6th edition",
    "APA 7th edition",
    "Chicago author"
];

const finalTextStyle = allowedTextStyles.includes(citationStyle) ? citationStyle : "AMA 10th edition";
  const data = await httpService.post(`${apiEndpoint}/checkCitationsJournalArticle`, {
    text,
    citationStyle: finalTextStyle,
    sourceItem: sourceitem,
    userId,isCoAuthor
  });
  return data;
}

export async function getCheckCitationBook(text, citationStyle, sourceitem,userId,isCoAuthor) {
  const allowedTextStyles = [
    "AMA 10th edition",
    "American Chemical Society",
    "APA 11th edition",
    "APA 6th edition",
    "APA 7th edition",
    "Chicago author"
];

const finalTextStyle = allowedTextStyles.includes(citationStyle) ? citationStyle : "AMA 10th edition";
  const data = await httpService.post(`${apiEndpoint}/checkCitationsBook`, {
    text,
    citationStyle: finalTextStyle,
    sourceItem: sourceitem,
    userId,isCoAuthor
  });
  return data;
}

export async function getAddCitation2(websiteData, email) {
  
  const data = await httpService.post(`${apiEndpoint}/addCitation`, {
    title: websiteData.title,
    description: websiteData.description,
    websiteName: websiteData.websiteName,
    contributors:websiteData.contributors,
    publicationDate: websiteData.publicationDate,
    fullCitation: websiteData.fullCitation,
    textStyle: websiteData.citationStyle,
    url: websiteData.url,
    sourceType: websiteData.sourceType, 
    email: email,
    inTextCitation: websiteData.inTextCitation,
    doi: websiteData.doi,
    journalName: websiteData.journalName,
    edition: websiteData.edition,
    publisher: websiteData.publisher

  });
  return data;
}

export async function getCitationDetails(data) {
  const response = await httpService.post(`${apiEndpoint}/getCitationDetails`,{data});
  return response;
}

export async function getAddCitation(formData,citationStyle, selectedValue) {
  let sourceType = ""
  if(selectedValue === 0){sourceType="Webpage"}
        else if (selectedValue === 1){sourceType="Journal Article"}
        else if (selectedValue === 2){sourceType="Book"}
        else{sourceType = selectedValue}


const data = await httpService.post(`${apiEndpoint}/addCitation`, {
  title: formData.title,
  description: formData.description,
  contributors: formData.contributors,
  websiteName: formData.websiteName,
  url: formData.url,
  email:formData.email,
  publicationDate: formData.publicationDate,
  accessDate: formData.accessDate,
  textStyle: citationStyle,
  sourceType: sourceType,
  doi: formData.doi,
  journalName: formData.journalName,
  edition: formData.edition,
  publisher: formData.publisher,
  articleNumber: formData.articleNumber,
  volumeNumber: formData.volumeNumber,
  issueNumber: formData.issueNumber,
  page: formData.page,
  libraryDatabase: formData.libraryDatabase,
  ISBN: formData.ISBN,
  ISSN: formData.ISSN
 
});
return data;
}

export async function updateCitationDetails(citationId, updatedDetails) {
  const data = await httpService.post(`${apiEndpoint}/updateCitationDetails`, {
    citationId,
    updatedDetails
  });
  return data;
}


export async function Duplicate(id){
  const data = await httpService.post(`${apiEndpoint}/duplicateCitation`,{ citationId: id })
  return data
}

export async function GetOnecitation(id){
  const data = await httpService.post(`${apiEndpoint}/getoneCitationDetails`,{ citationId: id })
  return data
}

export async function deleteCitation(id) {
  const response = await httpService.post(`${apiEndpoint}/deleteCitation2`, {
    id,
  });
  return response;
}