import React, { useState, useCallback, useEffect, useMemo } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from "react-toastify";
import { IconButton, Modal, Button, TextField, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Background,
  useNodesState,
  useEdgesState,
  MiniMap,
  Controls,
  useOnSelectionChange,
  NodeResizer,
  MarkerType,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import "./reactflow.css";
import ResizableNode from "./ResizableNode";
import ResizableGroup from "./ResizableGroup";
import {
  addStages,
  addStatus,
  deleteStage,
  deletestatus,
  getSelectedStage,
  getStages,
  saveGraphToWorkflow,
  deleteTransition,
  updateStageName,
  updateStatusName,
  getWorkflowStages,
  mergedStages,
  splitedStages,
  getSpecificWorkFlow,
  completeWorkflows,
} from "../../services/workflowServices";
import { useSelector } from "react-redux";
import Loader from "../../utils/loader";
import {
  addnewtransition,
  updateTransitionName,
} from "../../services/transitionServices";
import TransitionWorkFlowModal from "./TransitionWorkFlowModal";
import {
  getappfeatureroles2,
  rolesbyids,
} from "../../services/transitionRoles";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const initialNodes = [];
const initialEdges = [];
const proOptions = { hideAttribution: true };

const NestedFlow = ({ workFlowDataDetails, workflowId,workflowStatus }) => {
  const userDetails = useSelector((user) => user);
const [workFlowData,setWorkFlowData]=useState(workFlowDataDetails);
  const orgVirtualId = userDetails.user.currentUser.organizationId;
  const [loading, setLoading] = useState(false);
  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedEdges, setSelectedEdges] = useState([]);

  const [statusModal, setStatusModal] = useState(false);
  const [status, setStatus] = useState("");
  const [statusDescription, setStatusDescription] = useState("");
  const [existingStages, setExistingStages] = useState([]);

  const [stageModal, setStageModal] = useState(false);
  const [mergeStageModal, setMergeStageModal] = useState(false);
  const [splitStageModal, setSplitStageModal] = useState(false);
  const [stage, setStage] = useState("");
  const [stageDescription, setStageDescription] = useState("");
  const [existingStatus, setExistingStatus] = useState([]);

  const reactFlowInstance = useReactFlow();
  const [flowObject, setFlowObject] = useState(null);

  const [isConnect, setIsConnect] = useState(false);
  const [connectedEdges, setConnectedEdges] = useState();
  const [parentId, setParentId] = useState("");
  const [tempEdges, setTempEdges] = useState([]);
  const [transitionModal, setTransitionModal] = useState(false);
  const [transition, setTransition] = useState("transition");

  const [editModal, setEditModal] = useState(false);
  const [newEdge, setNewEdge] = useState(null);
  const [stageOptions, setStageOptions] = useState([]);
  const [selectedStageId, setSelectedStageId] = useState("");
  const [selectedStages, setSelectedStages] = useState([]);
  const [mergedStageName, setMergedStageName] = useState('');
  const [newStageName1, setNewStageName1] = useState('');
  const [newStageName2, setNewStageName2] = useState('');
  const [stageOperations,setStageOperations]=useState(workflowStatus);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
 


  useEffect(() => {
    getFlowDetails();
  }, [reactFlowInstance, nodes, edges]);

  useEffect(() => {
    //console.log("workFlowData", workFlowData, workflowId);
    if (workFlowData.graphDetails.length > 0) {
      let graphData = workFlowData.graphDetails[0];
      setNodes(graphData.nodes);
      setEdges(graphData.edges);
    }
    getExistingStages();
  }, [workflowId,workFlowData]);

  const getFlowDetails = () => {
    if (reactFlowInstance) {
      const currentFlowObject = reactFlowInstance.toObject();
      setFlowObject(currentFlowObject);
      saveFlowDetails(currentFlowObject);
      setTempEdges(currentFlowObject.edges);
      console.log("currentFlowObject ", currentFlowObject);
    }
  };

  const getExistingStages = async () => {
    setLoading(true);
    try {
      const response = await getStages(workflowId);
      console.log("getExistingStages", response);
      setExistingStages(response.data);
    } catch (e) {
      console.log("error while fetching stages ");
    }
    setLoading(false);
  };

  useEffect(() => {
    getStatusforStages();
  }, [existingStages]);

  const nodeTypes = {
    resizable: ResizableNode,
    resizableGroup: ResizableGroup,
    // resizableGroup: (props) => <ResizableGroup {...props} addNode={addNode} />,
  };



  // const edgeTypes = {
  //     custom: CustomEdge,
  // };

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  // const onConnect = useCallback((connection) => {
  //     setEdges((eds) => addEdge(connection, eds));
  // }, []);

  const onConnect = useCallback((connection) => {
    console.log("onConnect", connection, nodes, edges, tempEdges);
    setConnectedEdges(connection);
    setIsConnect(true);
  }, []);

  useEffect(() => {
    console.log("onEdgesChange", nodes, edges);
    if (isConnect) {
      let { source, target } = connectedEdges;
      handleSaveTransistion(source, target);
      setIsConnect(false);
    }
  }, [isConnect]);

  const getOrgIdforStageAndStatus = (source, target) => {
    let orgStageIdForSource = nodes.filter((obj) => obj.id == source)[0]
      .parentNode;
    console.log("orgStageIdForSource", orgStageIdForSource);
    let orgStageIdForSourceFinal = existingStages.filter(
      (obj) => obj.vertexId == orgStageIdForSource
    )[0]._id;
    let orgStatusIdForSource = existingStatus.filter(
      (obj) => obj.stageId == orgStageIdForSourceFinal
    )[0].existingStatus;
    let orgStatusIdForSourceFinal = orgStatusIdForSource.filter(
      (obj) => obj.vertexId == source
    )[0]._id;
    let orgStageIdForTarget = nodes.filter((obj) => obj.id == target)[0]
      .parentNode;
    let orgStageIdForTargetFinal = existingStages.filter(
      (obj) => obj.vertexId == orgStageIdForTarget
    )[0]._id;
    let orgStatusIdForTarget = existingStatus.filter(
      (obj) => obj.stageId == orgStageIdForTargetFinal
    )[0].existingStatus;
    let orgStatusIdForTargetFinal = orgStatusIdForTarget.filter(
      (obj) => obj.vertexId == target
    )[0]._id;
    let transitionData = {
      fromstageid: orgStageIdForSourceFinal,
      fromstatusid: orgStatusIdForSourceFinal,
      tostageid: orgStageIdForTargetFinal,
      tostatusid: orgStatusIdForTargetFinal,
    };
    console.log("transitionData", transitionData);
    return transitionData;
  };

  const checkDuplicateTransition = (source, target) => {
    console.log("checkDuplicateTransition", edges, source, target);
    let isDuplicate = edges.some(
      (obj) => obj.source == source && obj.target == target
    );
    return isDuplicate;
  };

  const handleSaveTransistion = async (source, target) => {
    let isDuplicateTransition = checkDuplicateTransition(source, target);
    if (isDuplicateTransition) {
      toast.warn("Transition already exists.");
      return;
    }

    let param = getOrgIdforStageAndStatus(source, target);
    setLoading(true);
    let { fromstageid, fromstatusid, tostageid, tostatusid } = param;

    let sendData = {
      orgid: orgVirtualId,
      appname: workFlowData.appname,
      workflowid: workflowId,
      fromstageid: fromstageid,
      fromstatusid: fromstatusid,
      tostageid: tostageid,
      tostatusid: tostatusid,
      sourceNode: source,
      targetNode: target,
      transitionName: transition, // Assuming transition is defined elsewhere
      createdby: workFlowData.createdby,
      isactive: 1,
      roles: ["T1", "T2"],
    };

    console.log("handleSaveTransistionsendData", sendData);

    try {
      let response = await addnewtransition(sendData);
      if (response.status === 200) {
        console.log("addnewtransitionresponse", response);

        // Add the newEdge to edges state after successful API call
        const newEdge = {
          ...connectedEdges,
          animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
            color: "#FF0072",
          },
          label: transition, // Assuming transition is defined elsewhere
          style: {
            strokeWidth: 2,
            stroke: "#FF0072",
          },
        };
        setEdges((eds) => addEdge(newEdge, eds));

        getFlowDetails();
        openTransitionModal();
      } else if (response.status === 400) {
        toast.warn(response.message);
        // Optionally, reset visual connection if needed
        setIsConnect(false); // Reset isConnect state if needed
      }
    } catch (error) {
      console.log("Error saving transition:", error);
      toast.error("Failed to save transition.");
    }

    setLoading(false);
  };

  const addTransitionName = async () => {
    let sendData = {
      orgid: orgVirtualId,
      workflowid: workflowId,
      sourceNode: connectedEdges.source,
      targetNode: connectedEdges.target,
      transitionName: transition,
    };
    setLoading(true);
    try {
      let response = await updateTransitionName(sendData);
      console.log("addTransitionName", response);
      toast.success(response.message);
      setEdges((prevEdges) =>
        prevEdges.map((obj) => {
          if (
            obj.source == connectedEdges.source &&
            obj.target == connectedEdges.target
          ) {
            return {
              ...obj,
              ["label"]: transition,
            };
          }
          return obj;
        })
      );
      closeTransitionModal();
    } catch (e) {
      console.log("e", e);
    }
    setLoading(false);
  };

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      setSelectedNodes(nodes.map((node) => node.id));
      setSelectedEdges(edges.map((edge) => edge.id));
    },
  });

  useEffect(() => {
    console.log("selectedNodes", selectedNodes);
  }, [selectedNodes]);

  function generateUniqueID(arr1, arr2) {
    const generateID = () => {
      return Math.floor(1000 + Math.random() * 9000);
    };

    let id = generateID();

    if (undefined !== arr1 && undefined !== arr2) {
      while (
        (arr1.length > 0 && arr1.some((obj) => obj.id == id)) ||
        (arr2.length > 0 && arr2.some((obj) => obj.id == id))
      ) {
        id = generateID();
      }
    }

    return id.toString();
  }

  const checkDuplicateStatus = () => {
    let isDuplicate = nodes.some(
      (obj) => obj.parentNode == parentId && obj.data.label == status
    );
    return isDuplicate;
  };

  const addNodeInsideStage = () => {
    let isDuplicateStatus = checkDuplicateStatus();
    if (isDuplicateStatus) {
      toast.warn("Status name already exists. Provide a different name");
      return;
    }

    let nodeData = {
      id: `status_${generateUniqueID(nodes, edges)}`,
      type: "resizable",
      width: 80,
      data: { label: status },
      position: { x: 10, y: 30 },
      parentNode: parentId,
      extent: "parent",
      style: {
        background: "#fff",
        border: "1px solid black",
        borderRadius: 10,
        fontSize: 10,
      },
    };

    console.log("addNodeInsideStage", nodes, nodeData);
    handleAddStatus(nodeData); // Attempt to add the status
    closeStatusModal();
  };

  const addNode = () => {
    console.log("addNode", selectedNodes);

    let parentNodeId = selectedNodes[0];
    if (selectedNodes.length == 1) {
      let notAParent = nodes.some(
        (obj) => obj.id == parentNodeId && obj.type !== "resizableGroup"
      );
      console.log("notAParent", notAParent);
      if (notAParent) {
        toast.warn("Please select a stage");
        return;
      }
    }
    if (selectedNodes.length > 1 || selectedNodes.length == 0) {
      toast.warn("Select any one stage node");
      return;
    }

    let nodeData = {
      id: `status_${generateUniqueID(nodes, edges)}`,
      type: "resizable",
      width: 80,
      data: { label: status },
      position: { x: 10, y: 30 },
      parentNode: parentNodeId,
      extent: "parent",
      style: {
        background: "#fff",
        border: "1px solid black",
        borderRadius: 10,
        fontSize: 10,
      },
    };

    console.log("addNode", nodes, nodeData);
    // setNodes([...nodes, nodeData]);

    // setNodes((prevNodes) => {
    //     console.log("addNode", prevNodes, nodeId, nodeData);
    //     return [...prevNodes, nodeData];
    // });

    setNodes((prev) => prev.concat(nodeData));
    handleAddStatus(nodeData);
    closeStatusModal();
  };

  const getStageOrgId = (param) => {
    let stageOrgId = existingStages.filter((obj) => obj.vertexId == param)[0]
      ._id;
    console.log("stageOrgId", stageOrgId);
    return stageOrgId;
  };

  const handleAddStatus = async (nodeData) => {
    setLoading(true);
    const vertexId = nodeData.id;
    const selectedStageId = getStageOrgId(nodeData.parentNode);
    try {
      const response = await addStatus(
        workflowId,
        selectedStageId,
        status,
        statusDescription,
        vertexId
      );
      console.log("response__", response);

      if (response.status === 201) {
        toast.success(`Status added successfully`);
        setNodes((prev) => prev.concat(nodeData)); // Add the status node only if the backend confirms the addition
        getStatusforStages();
      } else if (response.status == 400) {
        toast.warn(response.message);
        getStatusforStages();
      }
    } catch (e) {
      console.log("Error while Adding:", e);
      toast.error("An error occurred while adding the status.");
    } finally {
      setLoading(false);
    }
  };

  const getStatusforStages = async () => {
    setLoading(true);
    try {
      let finalArr = [];
      let finalArr2 = [];
      let finalArr3 = [];
      let dataStatus = [];
      for (let i = 0; i < existingStages.length; i++) {
        let stageId = existingStages[i]._id;
        const response = await getSelectedStage(stageId);
        console.log("getSelectedStageresponse", response);
        if (response.status === 201) {
          dataStatus = response.existingStatus;
          finalArr3.push({
            stageId: stageId,
            existingStatus: dataStatus,
          });
        } else if (response.status == 400) {
          console.log("error in getSelectedStageresponse");
        }
      }
      console.log("finalArr2", finalArr, finalArr2);
      console.log("finalArr3", finalArr3);
      setExistingStatus(finalArr3);
    } catch (e) {
      console.log("Error while Adding:", e);
    }
    setLoading(false);
  };

  const checkDuplicateStage = () => {
    let isDuplicate = false;
    if (nodes.length > 0) {
      isDuplicate = nodes.some(
        (obj) => obj.type == "resizableGroup" && obj.data.label == stage
      );
    }
    return isDuplicate;
  };

  const addStage = () => {
    // console.log("addStage", selectedNodes, selectedEdges)
    let isDuplicateStage = checkDuplicateStage();
    if (nodes.length > 0 && isDuplicateStage) {
      toast.warn("Stage name already exists. Provide a different name");
      return;
    }

    let stageData = {
      id: `stage_${generateUniqueID(nodes, edges)}`,
      // type: 'group',
      type: "resizableGroup",
      data: { label: stage },
      position: { x: 0, y: 0 },
      style: {
        width: 170,
        height: 140,
        background: "transparent",
        border: "1px solid black",
        borderRadius: 15,
        fontSize: 12,
      },
    };

    console.log("addStage", nodes, stageData);

    handleAddStage(stageData);
    closeStageModal();
  };
 // console.log(workflowId, stage, stageDescription, "alldatadatadatadat");
  const handleAddStage = async (stageData) => {
    console.log(stageData, "stageDatastageData");
    setLoading(true);
    try {
      let vertexId = stageData.id;
      console.log(
        workflowId,
        stage,
        stageDescription,
        vertexId,
        "alldatadatadatadat"
      );
      const response = await addStages(
        workflowId,
        stage,
        stageDescription,
        vertexId
      );
      console.log("response_", response);
      getExistingStages();
      if (response.status == 201) {
        if (nodes.length == 0) {
          let arr = [];
          arr.push(stageData);
          console.log("arr", arr);
          setNodes(arr);
        } else {
          setNodes((prev) => prev.concat(stageData));
        }
        toast.success(` Stage Added successfully`);
        console.log("response__", response);
      } else if (response.status == 400) {
        toast.warn("This workflow is in use ");
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false);
  };

  const deleteSelectedNode = async () => {
    // alert("first");
    console.log("deleteSelectedNode", selectedNodes, edges);

    if (selectedNodes.length > 1 || selectedNodes.length == 0) {
      toast.warn("Select any one node");
      return;
    }

    let selectedItemId = selectedNodes[0];
    let ifNodesHaveEdges = edges.some(
      (obj) => obj.source == selectedItemId || obj.target == selectedItemId
    );
    if (ifNodesHaveEdges) {
      toast.warn("Delete edges connected to status");
      return;
    }

    let isParent = nodes.some(
      (obj) => obj.id == selectedItemId && obj.type == "resizableGroup"
    );
    if (isParent) {
      let nodesInParent = nodes.filter(
        (obj) => obj.parentNode == selectedItemId
      );
      if (nodesInParent.length > 0) {
        // toast.warn("Delete the child nodes inside the parent");
        toast.warn("Delete the statuses inside the stage");
        return;
      } else {
        let stageName = existingStages.filter(
          (obj) => obj.vertexId == selectedItemId
        )[0].stageName;
        deleteSelectedStage(stageName);
      }
    } else {
      alert("funcall");
      let orgStageIdForStatus = nodes.filter(
        (obj) => obj.id == selectedItemId
      )[0].parentNode;
      let stageIdOrg = getStageOrgId(orgStageIdForStatus);
      let statusId = existingStatus.filter(
        (obj) => obj.stageId == stageIdOrg
      )[0].existingStatus;
      let statusIdOrg = statusId.filter(
        (obj) => obj.vertexId == selectedItemId
      )[0]._id;

      // Attempt to delete the status
      const deletionSuccess = await deleteSelectedStatus(
        stageIdOrg,
        statusIdOrg
      );

      if (deletionSuccess) {
        // Only update the state if the deletion was successful
        setNodes((prevNodes) =>
          prevNodes.filter((node) => node.id !== selectedItemId)
        );
        setEdges((prevEdges) =>
          prevEdges.filter(
            (edge) =>
              edge.source !== selectedItemId && edge.target !== selectedItemId
          )
        );
      }
    }
    setNodes((prevNodes) =>
      prevNodes.filter((node) => node.id !== selectedItemId)
    );
    setEdges((prevEdges) =>
      prevEdges.filter(
        (edge) =>
          edge.source !== selectedItemId && edge.target !== selectedItemId
      )
    );
  };

  const deleteSelectedStage = async (stageName) => {
    setLoading(true);
    try {
      let response = await deleteStage(workflowId, stageName);
      console.log("deleteStageresponse", response);
      // getFlowDetails()
      toast.success(response.message);
    } catch (e) {
      console.log("deleteStageerror", e);
    }
    setLoading(false);
  };

  const deleteSelectedStatus = async (stageIdOrg, statusIdOrg) => {
    alert("second");
    setLoading(true);
    try {
      let response = await deletestatus(workflowId, stageIdOrg, statusIdOrg);
      console.log("deleteVertexresponse", response);

      if (response.success) {
        // If deletion is successful, return true
        toast.success(response.message);
        return true;
      } else {
        // If deletion is not successful, show error message
        toast.warn(response.message);
        return false;
      }
    } catch (e) {
      console.log("deletevertexerror", e);
      toast.error("An error occurred while deleting the status.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const deleteSelectedEdge = async () => {
    console.log("deleteSelectedEdge", selectedEdges);

    if (selectedEdges.length > 1 || selectedEdges.length === 0) {
      toast.warn("Select any one edge");
      return;
    }

    let selectedItem = selectedEdges[0];
    let edgeDetail = edges.filter((obj) => obj.id === selectedItem);

    if (edgeDetail.length === 0) {
      toast.error("Selected edge not found.");
      return;
    }

    let source = edgeDetail[0].source;
    let target = edgeDetail[0].target;

    const isDeleted = await deleteTransitionData(source, target);

    if (isDeleted) {
      setEdges((prevEdges) =>
        prevEdges.filter((edge) => edge.id !== selectedItem)
      );
    }
  };

  const [entityName, setEntityName] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");

  const handleEntityName = (e) => {
    console.log("handleEntityName", nodes, edges, e);
    let value = e.target.value;
    setEntityName(value);
  };

  const updateStage = async (stageOrgId, selectedItemId) => {
    setLoading(true);
    try {
      let response = await updateStageName(workflowId, stageOrgId, entityName);
      if (response.status == 200) {
        toast.success(response.message);
        setNodes((prev) =>
          prev.map((obj) => {
            if (obj.id == selectedItemId) {
              return {
                ...obj,
                data: {
                  ...obj.data,
                  ["label"]: entityName,
                },
              };
            }
            return obj;
          })
        );
      } else if (response.status == 400) {
        toast.warn(response.message);
      }
    } catch (e) {
      console.log("e", e);
    }
    setLoading(false);
  };

  const updateStatus = async (stageOrgId, statusId, selectedItemId) => {
    setLoading(true);
    try {
      let response = await updateStatusName(
        workflowId,
        stageOrgId,
        statusId,
        entityName
      );
      if (response.status === 200) {
        toast.success(response.message);
        setNodes((prev) =>
          prev.map((obj) => {
            if (obj.id === selectedItemId) {
              return {
                ...obj,
                data: {
                  ...obj.data,
                  label: entityName,
                },
              };
            }
            return obj;
          })
        );
      } else if (response.status === 400) {
        toast.warn(response.message);
      } else if (response.status === 404) {
        toast.warn(response.message);
      }
    } catch (e) {
      console.log("Error while updating status:", e);
      toast.error("An error occurred while updating the status.");
    } finally {
      setLoading(false);
    }
  };

  const updateTransition = async (selectedEdgeDetails) => {
    let sendData = {
      orgid: orgVirtualId,
      workflowid: workflowId,
      sourceNode: selectedEdgeDetails.source,
      targetNode: selectedEdgeDetails.target,
      transitionName: entityName,
    };
    setLoading(true);
    try {
      let response = await updateTransitionName(sendData);
      console.log("updateTransitionName", response);

      if (response.status === 200) {
        toast.success(response.message);
        setEdges((prevEdges) =>
          prevEdges.map((obj) => {
            if (
              obj.source === selectedEdgeDetails.source &&
              obj.target === selectedEdgeDetails.target
            ) {
              return {
                ...obj,
                label: entityName,
              };
            }
            return obj;
          })
        );
      } else if (response.status === 400) {
        toast.warn(response.message);
      } else if (response.status === 404) {
        toast.warn(response.message);
      }
    } catch (e) {
      console.log("e", e);
      toast.error("An error occurred while updating the transition.");
    } finally {
      setLoading(false);
    }
  };

  const updateSelectedEntity = () => {
    console.log("updateSelectedEntity", selectedNodes, selectedEdges);
    let selectedItemId;
    if (selectedNodes.length === 1) {
      selectedItemId = selectedNodes[0];
      let isParent = nodes.some(
        (obj) => obj.id === selectedItemId && obj.type === "resizableGroup"
      );
      console.log("isParent", isParent);
      if (isParent) {
        let stageOrgId = getStageOrgId(selectedItemId);
        updateStage(stageOrgId, selectedItemId);
      } else {
        let stageVertexId = nodes.filter((obj) => obj.id === selectedItemId)[0]
          .parentNode;
        let stageOrgId = existingStages.filter(
          (obj) => obj.vertexId === stageVertexId
        )[0]._id;
        let statusList = existingStatus.filter(
          (obj) => obj.stageId === stageOrgId
        )[0].existingStatus;
        let statusId = statusList.filter(
          (obj) => obj.vertexId === selectedItemId
        )[0]._id;
        updateStatus(stageOrgId, statusId, selectedItemId);
      }
    } else if (selectedEdges.length === 1) {
      selectedItemId = selectedEdges[0];
      let selectedEdgeDetails = edges.filter(
        (obj) => obj.id === selectedItemId
      )[0];
      updateTransition(selectedEdgeDetails);
    }
    closeEditModal();
  };

  const openEditModal = () => {
    console.log("updateSelectedEntity", selectedNodes, selectedEdges);
    let selectedItemId;
    if (selectedNodes.length == 1) {
      selectedItemId = selectedNodes[0];
      let isParent = nodes.some(
        (obj) => obj.id == selectedItemId && obj.type == "resizableGroup"
      );
      console.log("isParent", isParent);
      let selectedNodeName = nodes.filter((obj) => obj.id == selectedItemId)[0]
        .data.label;
      setSelectedEntity(isParent ? "stage" : "status");
      setEntityName(selectedNodeName);
    } else if (selectedEdges.length == 1) {
      selectedItemId = selectedEdges[0];
      let selectedEdgeName = edges.filter((obj) => obj.id == selectedItemId)[0]
        .label;
      setSelectedEntity("transition");
      setEntityName(selectedEdgeName);
    }
    setEditModal(true);
  };

  const closeEditModal = () => {
    setEditModal(false);
    setEntityName("");
    setSelectedEntity("");
  };

  const deleteTransitionData = async (source, target) => {
    setLoading(true);
    try {
      let response = await deleteTransition(
        workflowId,
        getOrgIdforStageAndStatus(source, target)
      );
      console.log("addnewtransitionresponse", response);

      if (response.status === 200) {
        // Successfully deleted the transition
        getSelectedStage();
        toast.success(response.message);
        return true;
      } else if (response.status === 400) {
        // Workflow is in use, cannot delete the transition
        alert("trigger");
        getSelectedStage();
        toast.warn(response.message);
        return false;
      }
    } catch (e) {
      console.log("e", e);
      toast.error("An error occurred while deleting the transition.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const saveFlowDetails = async (graphDetails) => {
    try {
      let email = sessionStorage.getItem("currentUserEmail");
      let response = await saveGraphToWorkflow(
        workflowId,
        email,
        graphDetails,
        stage
      );
      console.log("saveFlowDetails", response);
    } catch (e) {
      console.log("e", e);
    }
  };

 

  useEffect(() => {
    console.log("edges", edges);
  }, [edges]);

  const handleStatus = (e) => {
    console.log("handleStatus", nodes, e);
    let value = e.target.value;
    setStatus(value);
  };

  const handleStatusDescription = (e) => {
    console.log("handleStatusDescription", nodes, e);
    let value = e.target.value;
    setStatusDescription(value);
  };

  const handleStage = (e) => {
    console.log("handleStage", nodes, e);
    let value = e.target.value;
    setStage(value);
  };

  const handleStageDescription = (e) => {
    console.log("handleStageDescription", nodes, e);
    let value = e.target.value;
    setStageDescription(value);
  };

  const openStageModal = () => {
    setStageModal(true);
    setStage("");
    setStageDescription("");
  };

  const openMergemodal = async () => {
    setMergeStageModal(true);
    const data = workflowId;
    const stages = await getWorkflowStages({ data });
    if (stages.status == 200) {
      console.log(stages.data, "checkdatatata"); // Display stages in dropdown
      setStageOptions(stages.data);
    } else {
      toast.error(stages.message);
    }
  };


  const closeMergeStageModal = () => {
    setMergeStageModal(false);
    setSelectedStages([]);
  };
  const handleSelectStage = (event) => {
    const selectedStageId = event.target.value;
    if (selectedStageId) {
      if (selectedStages.length > 0) {
        toast.error("You can select only one stage");
      } else {
        const selectedStage = stageOptions.find(stage => stage._id === selectedStageId);
        setSelectedStages([selectedStage]); // Replace the entire array with the newly selected stage
      }
    } else {
      setSelectedStages([]); // Clear the selection if no stage is selected
    }
  };


  // const handleSelectStageformerge = (event) => {
  //   const selectedStageId = event.target.value;
  //   const selectedStage = stageOptions.find(stage => stage._id === selectedStageId);

  //   // Check if selected stage is already in selectedStages
  //   if (selectedStage && !selectedStages.find(stage => stage._id === selectedStageId)) {
  //       // Check sequence validation
  //       const maxSequence = Math.max(...selectedStages.map(stage => stage.sequence), 0); // Get maximum sequence from selected stages

  //       if (selectedStages.length > 0 && selectedStage.sequence !== maxSequence + 1) {
  //           toast.error("You can only merge stages that are in sequence.");
  //           return; // Exit function if sequence is not valid
  //       }

  //       // Add selected stage to selectedStages
  //       setSelectedStages([...selectedStages, selectedStage]);
  //   }
  // };

  const handleSelectStageformerge = (event, selectedStage) => {
    // Check if the stage is already selected
    if (selectedStages.some(stage => stage._id === selectedStage._id)) {
      // Remove the stage if it's already selected
      setSelectedStages(selectedStages.filter(stage => stage._id !== selectedStage._id));
    } else {
      // Check sequence validation
      const maxSequence = Math.max(...selectedStages.map(stage => stage.sequence), 0); // Get maximum sequence from selected stages

      if (selectedStages.length > 0 && selectedStage.sequence !== maxSequence + 1) {
        toast.error("You can only merge stages that are in sequence.");
        return; // Exit function if sequence is not valid
      }

      // Add selected stage to selectedStages
      setSelectedStages([...selectedStages, selectedStage]);
    }
  };
  //console.log(selectedStages, "selectedStages")


  const handleRemoveStage = (id) => {
    setSelectedStages(selectedStages.filter(stage => stage._id !== id));
  };


  const handleGetworkflow = async (id) => {
    setLoading(true)

    try {
      const response = await getSpecificWorkFlow(id);
      if (response.status === 200) {
        // setSpecificWorkflowData(response.data)
        // setspecificWorkflowDescription(response.data.description)
        setWorkFlowData(response.data)
        if(response?.data?.mergeStatus==true){
          setStageOperations(true)
        }else{
          setStageOperations(false)
        }
        console.log("Selected Workflow response__", response.data);

      } else {
        console.error("Error while finding Selected Workflow :", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
    setLoading(false)
  }


  const mergeStages = async () => {
    try {
      setLoading(true);
      const stageIds = selectedStages.map(stage => stage._id);
      const workflowid = workflowId;
      const data = { stageIds, workflowid, mergedStageName }
      console.log(stageIds, data, "stageIdsstageIds");
      const response = await mergedStages(data);
      if (response.status == 200) {
        toast.success("stages merged successfully.")
        setSelectedStages('');
        closeMergeStageModal();
        handleGetworkflow(workflowid)
        // setMergeStageModal(false);
        // setStageOptions('');
      } else {
        toast.error(response.message);
      }

    } catch (error) {
      console.log(error)
    }
    finally {
      setLoading(false);
    }
  }


const handleCompleteWorkflow = async ()=>{
  try {
    setLoading(true);
    const id=workflowId;
    const data={id}
    const response=await completeWorkflows(data);
    if(response?.status==200){
      toast.success("Changes saved successfully!")
      handleGetworkflow(id)
    }else{
      toast.error(response?.message);
    }
  } catch (error) {
    console.log(error)
  }finally{
    setLoading(false);
  }
}


  const splitStage = async () => {
    try {
      setLoading(true);
      const [stageToSplit] = selectedStages.map(stage => stage._id);
      const workflowid = workflowId;
      const data = { stageToSplit, workflowid, newStageName1, newStageName2 }
      console.log(data, "splitStageData")
      const response = await splitedStages(data);
      if (response.status == 200) {
        toast.success("Stages have been split successfully.")
        setSelectedStages('');
        setSplitStageModal(false)
        handleGetworkflow(workflowid)
        // setStageOptions('');
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  }


  const openSplitmodal = async () => {
    setSplitStageModal(true);
    const data = workflowId;
    const stages = await getWorkflowStages({ data });
    if (stages.status == 200) {
      console.log(stages.data, "checkdatatata"); // Display stages in dropdown
      setStageOptions(stages.data);
    } else {
      toast.error(stages.message);
    }
  };

  const closeStageModal = () => {
    setStageModal(false);
  };

  const closeSplitStageModal = () => {
    setSplitStageModal(false);
  };

  const openStatusModal = () => {
    console.log("addNode", selectedNodes, flowObject);
    let isNodesCreated = flowObject.nodes.length == 0 ? false : true;
    if (!isNodesCreated) {
      toast.warn("Please create stage first");
      return;
    }

    let parentNodeId = selectedNodes[0];
    if (selectedNodes.length == 1) {
      let notAParent = nodes.some(
        (obj) => obj.id == parentNodeId && obj.type !== "resizableGroup"
      );
      console.log("notAParent", notAParent);
      if (notAParent) {
        toast.warn("Please select a stage");
        return;
      }
    }
    if (selectedNodes.length > 1 || selectedNodes.length == 0) {
      toast.warn("Select any one stage node");
      return;
    }
    setStatusModal(true);
    setStatus("");
    setStatusDescription("");
  };

  const handleTransition = (e) => {
    console.log("handleTransition", edges, e);
    let value = e.target.value;
    setTransition(value);
  };

  const openStatusModalInsideStage = useCallback((id, data) => {
    console.log("id", id, "adddata", data);
    setStatusModal(true);
    setStatus("");
    setStatusDescription("");
    setParentId(id);
  }, []);

  const memoizedNodeTypes = useMemo(
    () => ({
      // ...nodeTypes
      resizable: ResizableNode,
      resizableGroup: (props) => (
        <ResizableGroup {...props} addStatus={openStatusModalInsideStage} />
      ),
    }),
    [openStatusModalInsideStage]
  );

  const closeStatusModal = () => {
    setStatusModal(false);
  };

  const openTransitionModal = () => {
    setTransitionModal(true);
    setTransition("transition");
  };

  const closeTransitionModal = () => {
    setTransitionModal(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
    fetchDropdownValues();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [dropdownValues, setDropdownValues] = useState([]);
  const fetchDropdownValues = async () => {
    try {
      const data = sessionStorage.getItem("organizationId");
      const response = await getappfeatureroles2({ data });
      console.log("Dropdown data rolesbyids", response);
      if (response.status === 200) {
        console.log(response);
        setDropdownValues(response.data);
      } else {
        console.error("Failed to fetch dropdown values:", response.message);
      }
    } catch (error) {
      console.log("Error fetching dropdown values:", error.message);
    }
  };
  //console.log("Updated Dropdown Values:", dropdownValues);

  return (
    <div style={{marginLeft:"-20px" }}>
      <Loader loading={loading} />
      <div className="" >
      <div className="d-flex flex-wrap p-2 mt-2" style={{ backgroundColor: "white"}}>
      <button className="btn btn-primary ml-2 mb-2" onClick={openStageModal}>
       Add Stage
      </button>
        <button
        className="btn btn-primary ml-2 mb-2"
        onClick={deleteSelectedNode}
        disabled={selectedNodes.length > 0 ? false : true}
          >    
           Delete Node
         </button>
       <button
         className="btn btn-primary ml-2 mb-2"
        onClick={deleteSelectedEdge}
          disabled={selectedEdges.length > 0 ? false : true}
        >
       Delete Transition
       </button>
          <button
          className="btn btn-primary ml-2 mb-2"
          onClick={openEditModal}
         disabled={
           selectedEdges.length === 1 || selectedNodes.length === 1 ? false : true
         }
          >
           Edit
           </button>
           <button
    className="btn btn-primary ml-2 mb-2"
    onClick={openModal}
  >
    Transition Table
           </button>
         {workFlowData.isDuplicate && (
    <>
      <button
        className="btn btn-primary ml-2 mb-2"
        onClick={openMergemodal}
      >
        Merge Stages
      </button>
      <button
        className="btn btn-primary ml-2 mb-2"
        onClick={openSplitmodal}
      >
        Split Stages
      </button>
      {stageOperations && <>
        <button
        className="btn btn-primary ml-2 mb-2"
        onClick={handleCompleteWorkflow}
      >
        Complete Workflow
      </button>
      </>}
    </>
          )}
          {stageOperations && <>
          <div><p>You have to click on complete workflow to save the chnages.</p></div>
          </>}

          <TransitionWorkFlowModal
            isOpen={isModalOpen}
            onClose={closeModal}
            data={dropdownValues}
            workflowid={workflowId}
          />
</div>



        {/* <div className="p-2 mt-2">
                    <p>Selected nodes: {selectedNodes.join(', ')}</p>
                    <p>Selected edges: {selectedEdges.join(', ')}</p>
                </div> */}

        {/* <ReactFlowProvider> */}
        <div className="viewport p-2" >
          <ReactFlow
            // edgeTypes={edgeTypes}
            // nodeTypes={nodeTypes}
            nodeTypes={memoizedNodeTypes}
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            className="react-flow-subflows-example"
            fitView
            proOptions={proOptions}
          >
            {/* <MiniMap /> */}
            <Controls />
            <Background />
            <NodeResizer />
          </ReactFlow>
        </div>
        {/* </ReactFlowProvider> */}
      </div>

      {/* stage modal start */}
      <Modal
        open={stageModal}
        onClose={closeStageModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px"
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            width:windowWidth < 500 ? "80%" : "30%"
          }}
        >
          <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div >
              <h3  style={{ color: "#3d83fa" }}>Add New Stage</h3>
            </div>
            <div >
              <IconButton onClick={closeStageModal}>
              <HighlightOffIcon style={{color: "red"}} />
              </IconButton>
            </div>
          </div>


          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <TextField
              style={{ borderRadius: "15px"}}
              label="Stage"
              value={stage}
              onChange={handleStage}
              variant="outlined"
              fullWidth
            />

            <TextField
              style={{ marginBottom: "10px" }}
              label="Description"
              placeholder="Enter Description"
              value={stageDescription}
              onChange={handleStageDescription}
              multiline
              rows={4}
              variant="outlined"
              InputProps={{
                sx: {
                  width: "400px",
                },
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px" }}>
            <Button
              variant="outlined"
              style={{
                fontSize: '12px',
                marginRight: '10px',
                borderRadius: '10px',
                color: 'white',
                borderColor: '#1976D2',
                textTransform: 'capitalize',
                boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)',
                backgroundColor: "#1976D2",
                marginRight: "15px"
              }}
              onClick={addStage}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "red",
                borderColor: "red",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={closeStageModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* merge stages modal */}
      <Modal
        open={mergeStageModal}
        onClose={closeMergeStageModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            width:windowWidth < 500 ? "80%" : "350px",
          }}
        >
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "-10px", right: "-5px" }}>
              <IconButton onClick={closeMergeStageModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              margin: "10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            <h3>Merge Stage</h3>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            {/* <FormControl variant="outlined" fullWidth>
                            <InputLabel>Select a stage</InputLabel>
                            <Select
                                value=""
                                onChange={handleSelectStageformerge}
                                label="Select a stage"
                                style={{ borderColor: "#1976D2" }}
                                inputProps={{
                                    style: {
                                        borderColor: '#1976D2',
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderRadius: '4px',
                                        padding: '10px',
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>Select a stage</em>
                                </MenuItem>
                                {stageOptions.map((stage) => (
                                    <MenuItem key={stage._id} value={stage._id}>
                                        {stage.stageName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}
            <FormControl component="fieldset" fullWidth>
              <FormGroup>
                {stageOptions.map((stage) => (
                  <FormControlLabel
                    key={stage._id}
                    control={
                      <Checkbox
                       checked={selectedStages && selectedStages.some(selectedStage => selectedStage._id === stage._id)}
                        onChange={(event) => handleSelectStageformerge(event, stage)}
                        name={stage.stageName}
                        style={{ color: "#1976D2" }}
                      />
                    }
                    label={stage.stageName}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          {selectedStages.length > 0 && (
            <>
              {/* <div style={{ marginTop: "20px" }}>
                            <h4>Selected Stages:</h4>
                            {selectedStages.map(stage => (
                                <div key={stage._id} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span>{stage.stageName}</span>
                                    <IconButton onClick={() => handleRemoveStage(stage._id)}>
                                        <CloseIcon style={{ color: 'red' }} />
                                    </IconButton>
                                </div>
                            ))}
                        </div> */}
              <div style={{ marginTop: "20px" }}>
                <TextField
                  label="Merged Stage Name"
                  variant="outlined"
                  fullWidth
                  value={mergedStageName}
                  onChange={(e) => setMergedStageName(e.target.value)}
                />
              </div>
            </>
          )}

          <div
            className="mt-2"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#1976D2",
                borderColor: "#1976D2",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={mergeStages}
            >
              Merge
            </Button>

            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#1976D2",
                borderColor: "#1976D2",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={closeMergeStageModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      {/* stage modal end */}

      {/* split modal starts */}
      <Modal
        open={splitStageModal}
        onClose={closeSplitStageModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            width:windowWidth < 500 ? "80%" : "350px",
          }}
        >
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "-10px", right: "-5px" }}>
              <IconButton onClick={closeSplitStageModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              margin: "10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            <h3>Split Stage</h3>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Select a stage</InputLabel>
              <Select
                value=""
                onChange={handleSelectStage}
                label="Select a stage"
                style={{ borderColor: "#1976D2" }}
                inputProps={{
                  style: {
                    borderColor: '#1976D2',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderRadius: '4px',
                    padding: '10px',
                  }
                }}
              >
                <MenuItem value="">
                  <em>Select a stage</em>
                </MenuItem>
                {stageOptions.map((stage) => (
                  <MenuItem key={stage._id} value={stage._id}>
                    {stage.stageName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {selectedStages.length > 0 && <>
            {selectedStages && (
              <>
                <div style={{ marginTop: "20px" }}>
                  <h4>Selected Stage:</h4>
                  {selectedStages.map(stage => (
                    <div key={stage._id} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <span>{stage?.stageName}</span>
                      <IconButton onClick={() => handleRemoveStage(stage._id)}>
                        <CloseIcon style={{ color: 'red' }} />
                      </IconButton>
                    </div>
                  ))}
                </div>
                <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                  <TextField
                    label="New Stage Name 1"
                    variant="outlined"
                    fullWidth
                    value={newStageName1}
                    onChange={(e) => setNewStageName1(e.target.value)}
                  />
                  <TextField
                    label="New Stage Name 2"
                    variant="outlined"
                    fullWidth
                    value={newStageName2}
                    onChange={(e) => setNewStageName2(e.target.value)}
                  />
                </div>
              </>
            )}
          </>}
          <div
            className="mt-2"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#1976D2",
                borderColor: "#1976D2",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={splitStage}
            >
              Split
            </Button>

            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#1976D2",
                borderColor: "#1976D2",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={closeSplitStageModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* status modal start */}
      <Modal
        open={statusModal}
        onClose={closeStatusModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            minWidth: "350px",
          }}
        >
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "-10px", right: "-5px" }}>
              <IconButton onClick={closeStatusModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              margin: "10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            <h3>Add New Status</h3>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <TextField
              style={{ borderRadius: "15px", width: "400px" }}
              label="Status"
              value={status}
              onChange={handleStatus}
              variant="outlined"
              fullWidth
            />

            <TextField
              style={{ marginBottom: "10px" }}
              label="Description"
              placeholder="Enter Description"
              value={statusDescription}
              onChange={handleStatusDescription}
              multiline
              rows={4}
              variant="outlined"
              InputProps={{
                sx: {
                  width: "400px",
                },
              }}
            />
          </div>
          <div
            className="mt-2"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#1976D2",
                borderColor: "#1976D2",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              // onClick={addNode}
              onClick={addNodeInsideStage}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#1976D2",
                borderColor: "#1976D2",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={closeStatusModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>

      {/* transition modal start */}
      <Modal
        open={transitionModal}
        onClose={closeTransitionModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            minWidth: "350px",
          }}
        >
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", top: "-10px", right: "-5px" }}>
              <IconButton onClick={closeTransitionModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              margin: "10px",
              marginBottom: "20px",
              justifyContent: "center",
            }}
          >
            <h3>Add Transition</h3>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <TextField
              style={{ borderRadius: "15px", width: "400px" }}
              label="Transition"
              value={transition}
              onChange={handleTransition}
              variant="outlined"
              fullWidth
            />

            {/* <TextField
                            style={{ marginBottom: '10px' }}
                            label="Description"
                            placeholder="Enter Description"
                            value={statusDescription}
                            onChange={handleStatusDescription}
                            multiline
                            rows={4}
                            variant="outlined"
                            InputProps={{
                                sx: {
                                    width: "400px"
                                }
                            }}
                        /> */}
          </div>
          <div
            className="mt-2"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#1976D2",
                borderColor: "#1976D2",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={addTransitionName}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "#1976D2",
                borderColor: "#1976D2",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={closeTransitionModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      {/* transition modal end */}

      {/* edit modal start */}
      <Modal
        open={editModal}
        onClose={closeEditModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            width:windowWidth < 500 ? "80%" : "30%",
          }}
        >
            <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div >
                  <h3  style={{ color: "#3d83fa" }}>Update selected {selectedEntity}</h3>
          </div>
            <div >
              <IconButton onClick={closeEditModal}>
              <HighlightOffIcon style={{color: "red"}} />
              </IconButton>
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <TextField
              style={{ borderRadius: "15px", width:windowWidth < 500 ? "" : "400px" }}
              label={selectedEntity}
              value={entityName}
              onChange={handleEntityName}
              variant="outlined"
              fullWidth
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "15px" }}>
              <Button
                variant='outlined'
                style={{
                  fontSize: '12px',
                    marginRight: '10px',
                    borderRadius: '10px',
                    color: 'white',
                    borderColor: '#1976D2',
                    textTransform: 'capitalize',
                    boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)',
                    backgroundColor: "#1976D2",
                    marginRight: "15px"
                }}
              onClick={updateSelectedEntity}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              style={{
                fontSize: "12px",
                marginRight: "10px",
                borderRadius: "10px",
                color: "red",
                borderColor: "red",
                textTransform: "capitalize",
                boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={closeEditModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      {/* edit modal end */}
    </div>
  );
};

export default NestedFlow;
