import ProposalWriting from "./ProposalWriting";
import { LinearProgress } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import OppourtunityManagementTemplate from "./OppourtunityManagementTemplate";
import { gettemplates } from "../services/templatesService";
import Loader from "../utils/loader";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ReactTooltip from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { showToast } from "../messages";

import {
  Container,
  TextField,
  Button,
  IconButton,
  Modal,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Menu,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import WindowIcon from "@mui/icons-material/Window";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DownloadIcon from "@mui/icons-material/Download";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { DocumentGridCard, DocumentListCard } from "./MyDocumentsCards";
import InputLabel from "@mui/material/InputLabel";
import { getopportunities } from "../services/opportunityService";
import { getopportunitymatch } from "../services/opportunityMatchService";
import assetService, { checkMember, validateAccess } from "../services/assetService";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  farClause,
  myprojectScreen,
  proposalWritingData,
  rfmManagmentData,
} from "../redux/opportunity/opportunity.action";
import { setProjectType } from '../redux/manageOpportunity/manageOpportunity.action';
import "react-datepicker/dist/react-datepicker.css";
import assetSharingServices from "../services/assetSharingServices";
import {
  approvalForAssetPerformance,
  assetRequestedList,
  assetRequestedListbyAssetid,
  getAssetRequestStatus,
  sendAssetRequest,
} from "../services/assetRequest";
import { toast } from "react-toastify";
import {
  addClauses,
  getResourceLinks,
  getClauses,
  deleteClause,
  addClauseProject,
  getClauseProject,
} from "../services/clauseExtractionService";
import { Icon } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import { asBlob } from "html-docx-js-typescript";
import html2pdf from "html2pdf.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { getUserInfo } from "../services/opportunityService";
import { deletePerfomanceMatrics, getPerfomanceMatrics, getPerfomanceMatricsByAssetid, savePerfomanceMatrics, updatePerformanceMetrics } from "../services/performanceMetrics";
import { MetricsModal } from "./pages/Modals/metricsModal";
import SubmissionHistoryModal from "./SubmissionHistoryModal";
import { getSubmissionHistory,unArchiveProject } from "../services/assetService";
import RefreshIcon from "@mui/icons-material/Refresh";


let docList = [
  {
    _id: {
      $oid: "65cf49fdfbd8206f10612ee0",
    },
    typeOfSetAsideDescription: null,
    typeOfSetAside: null,
    naicsCodes: ["336413"],
    award: null,
    pointOfContact: [
      {
        fax: null,
        type: "primary",
        email: "DibbsBSM@dla.mil",
        phone: null,
        title: null,
        fullName:
          "Questions regarding this solicitation should be emailed to the buyer listed in block 5 of the solicitation document which can be found under the Additional Information link.\nIf the Additional Information link does not work, please go to https://www.dibbs.bsm.dla.mil/Solicitations/ and type the solicitation number in the Global Search box.\n",
      },
    ],
    additionalInfoLink: null,
    links: [
      {
        rel: "self",
        href: "https://api.sam.gov/prod/opportunities/v2/search?noticeid=ff57203a4e244462a32974c902fc07fe&limit=1",
      },
    ],
    resourceLinks: null,
    noticeId: "ff57203a4e244462a32974c902fc07fe",
    title: "15--COVER,ACCESS,AIRCRA",
    solicitationNumber: "SPE4A524T016H",
    fullParentPathName:
      "DEPT OF DEFENSE.DEFENSE LOGISTICS AGENCY.DLA AVIATION.DLA AV RICHMOND.DLA AVIATION",
    fullParentPathCode: "097.97AS.DLA AVIATION.DLA AV RICHMOND.SPE4A5",
    postedDate: "2024-02-14",
    type: "Combined Synopsis/Solicitation",
    baseType: "Combined Synopsis/Solicitation",
    archiveType: "autocustom",
    archiveDate: "2024-03-23",
    responseDeadLine: "2024-02-22",
    naicsCode: "336413",
    classificationCode: "15",
    active: "Yes",
    description:
      "https://api.sam.gov/prod/opportunities/v1/noticedesc?noticeid=ff57203a4e244462a32974c902fc07fe",
    organizationType: "OFFICE",
    officeAddress: {
      zipcode: "23237",
      city: "RICHMOND",
      countryCode: "USA",
      state: "VA",
    },
    placeOfPerformance: null,
    uiLink: "https://sam.gov/opp/ff57203a4e244462a32974c902fc07fe/view",
    __v: 0,
  },
  {
    _id: {
      $oid: "65cf49fdfbd8206f10612ee3",
    },
    typeOfSetAsideDescription: null,
    typeOfSetAside: [""],
    naicsCodes: ["325520"],
    award: null,
    pointOfContact: [
      {
        fax: null,
        type: "primary",
        email: "STEVE.SMITHEY@NAVY.MIL",
        phone: null,
        title: null,
        fullName: "Telephone: 7176057751",
      },
    ],
    additionalInfoLink: null,
    links: [
      {
        rel: "self",
        href: "https://api.sam.gov/prod/opportunities/v2/search?noticeid=ff2d63a0b7f54ed0ba240abd02484898&limit=1",
      },
    ],
    resourceLinks: null,
    noticeId: "ff2d63a0b7f54ed0ba240abd02484898",
    title: "80--SEALING COMPOUND",
    solicitationNumber: "N0010424QNB81",
    fullParentPathName:
      "DEPT OF DEFENSE.DEPT OF THE NAVY.NAVSUP.NAVSUP WEAPON SYSTEMS SUPPORT.NAVSUP WSS MECHANICSBURG.NAVSUP WEAPON SYSTEMS SUPPORT MECH",
    fullParentPathCode: "017.1700.NAVSUP.NAVSUP WSS.NAVSUP WSS MECH.N00104",
    postedDate: "2024-02-14",
    type: "Solicitation",
    baseType: "Solicitation",
    archiveType: "auto15",
    archiveDate: "2024-03-30",
    responseDeadLine: "2024-03-15T16:30:00-04:00",
    naicsCode: "325520",
    classificationCode: "8030",
    active: "Yes",
    description:
      "https://api.sam.gov/prod/opportunities/v1/noticedesc?noticeid=ff2d63a0b7f54ed0ba240abd02484898",
    organizationType: "OFFICE",
    officeAddress: {
      zipcode: "17050-0788",
      city: "MECHANICSBURG",
      countryCode: "USA",
      state: "PA",
    },
    placeOfPerformance: null,
    uiLink: "https://sam.gov/opp/ff2d63a0b7f54ed0ba240abd02484898/view",
    __v: 0,
  },
];

const OpportunityManagement = () => {
  const { id } = useParams();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [listView, setListView] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [myDocs, setMyDocs] = useState([]);
  const [project, setProject] = useState([]);
  const [sharedProject, setSharedProject] = useState([]);
  const [onlyId, setOnlyId] = useState([]);
  const [assetType, setassetType] = useState([]);
  const [Archive, setArchive] = useState([]);
  const [initialMyDocs, setInitialMyDocs] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openSummaryModal, setOpenSummaryModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [ishandleNewPage, setisHandleNewPage] = useState(false);
  const [noticeId, setNoticId] = useState("");
  const [title, setTitle] = useState("");
  const [docPreview, setDocPreview] = useState(false);
  const [showList, setShowList] = useState(false);
  const [descriptionData, setdescriptionData] = useState("");
  const [opportunityId, setOpportunityId] = useState("");
  const [deadLine, setDeadline] = useState("");
  const [type, setType] = useState("");
  const [getassetId, setgetAssetId] = useState("");
  const [ToolTipName, setToolTipName] = useState("");
  const [showProposal, setShowProposal] = useState("");
  const [assetRequestStatusData, setAssetRequestStatusData] = useState([]);
  const [assetSharedinfo, setAssetSharedInfo] = useState([]);
  const [selectedAssetId, setselectedAssetId] = useState("");
  const [resourceLinks, setResourceLinks] = useState([]);
  const [extractedClause, setExtractedClause] = useState([]);
  const [isOpenClauseModal, setIsOpenClauseModal] = useState(false);
  const [initialShareProject, setInitialShareProject] = useState([]);
  const [initialArchived, setInitialArchived] = useState([]);
  const [cancelledProjects, setCancelledProjects] = useState([]);
  const [completeProjects, setcompleteProjects] = useState([]);
  const [assetIdForClauses, setAssetIdForClauses] = useState("");
  const [downloadFileName, setDownloadFileName] = useState("");
  const [addManual, setAddManual] = useState(false);
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);
  const RFP = "RFP";
  const proposalWriting = "Proposal Writing";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isCollaps, setIsCollaps] = useState({
    isMyProject: false,
    isSharedProject: false,
    isArchivedProject: false,
    isProjectList: false,
    isCancelledProject:false,
    isCompletedProject:false
  });
  const [showDownloadModal, setshowDownloadModal] = useState("");
  const [sortedProjects, setSortedProjects] = useState([]);
  const [sortSharedProjects, setSortSharedProjects] = useState([]);
  const [assetRequestedMemberList, setAssetRequestedMemberList] = useState();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [assetRequestData, setAssetRequestData] = useState({
    assetid: "",
    requestFrom: "",
    requestTo: "",
    comment: "",
    assetType: "rfp",
  });
  const [openMemberListModal, setOpenMemberListModal] = useState(false);
  const [submissionHistoryModal, setSubmissionHistoryModal] = useState(false)
  const [submissionHistory, setSubmissionHistory] = useState([])
  const [mainOption, setMainOption] = useState('');
  const [subOption, setSubOption] = useState('');
  const [validationData,setValidationData]=useState([])
  const [openValidationModal,setValidationModal]=useState(false)
  const [metricAssetId,setMetricsAssetId] = useState('')
  const [metricsTital,setMetricsTital] = useState('')
  const [emptyState , setEmptyState]=useState(false)
  const [isMetricsModal,setisMetricsModal]=useState(false)
  const [existingData , setExistingData]=useState()
  const [rows, setRows] = useState([]);
  const [userInfo, setUserInfo] = useState("");
  const [ismetricEdit , setIsmetricEdit]=useState(false)

  const projectType = useSelector((state) => state.opportunity.projectType);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [initialMyProject, setInitialMyProject] = useState([]);
  const [assetRequestedListData, setAssetRequestedListData] = useState();
  const [data, setData] = useState([]);
  const [agancy, setAgancy] = useState("");



  const [sortBy, setSortBy] = useState(''); 
  const dropdownRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [initialCompletedProjects, setInitialCompletedProjects] = useState([]);
  const [initialCancelledProjects, setInitialCancelledProjects] = useState([]);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false)
  const [archiveId, setArchiveId] = useState("")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 


  const handleSelectAllChange = () => {};
  const handleDownloadDropdownClick = () => {};
  const handleSortClick = () => {};

console.log(data,"currentdata")
  useEffect(() => {
    if (sortBy) {
      updateCategoryStates(data);
    }
  }, [sortBy]);


  const handleSortChange = (event) => {
  
    
    const criterion = event.target.value;
    
    setSortBy(criterion);
    updateCategoryStates(data); // Update category states based on current data and selected sort criterion
    // setDropdownVisible(false);
  };
  
  const updateCategoryStates = (opportunities) => {
    let sortedOpportunities = opportunities;
    if (sortBy === 'title') {
      sortedOpportunities = sortOpportunitiesByTitle(opportunities);
    } 
    else if (sortBy === 'publishDate') {
      sortedOpportunities = sortOpportunitiesByPublishDate(opportunities);
    }

    else if (sortBy === 'responseDate') {
      sortedOpportunities = sortOpportunitiesByResponseDate(opportunities);
    }
    
    else if (sortBy === 'department') {
      sortedOpportunities = sortOpportunitiesByDepartment(opportunities);
    }

    else if (sortBy === 'subtier') {
      sortedOpportunities = sortOpportunitiesBySubtier(opportunities);
    }


    else if (sortBy === 'office') {
      sortedOpportunities = sortOpportunitiesByOffice(opportunities);
    }


    else if (sortBy === 'noticeType') {
      sortedOpportunities = sortOpportunitiesBynoticeType(opportunities);
    }

    
    setProject(sortedOpportunities.filter(
      (item) => item.type === "Project" && item.status !== "Cancel" && item.status !== "Complete"
    ));
    setArchive(sortedOpportunities.filter(
      (item) => item.type === "Archive" && item.status !== "Cancel" && item.status !== "Complete"
    ));
    setCancelledProjects(sortedOpportunities.filter(
      (item) => item.status === "Cancel"
    ));
    setcompleteProjects(sortedOpportunities.filter(
      (item) => item.status === "Complete"
    ));
  };
  

  const handleIconClick = () => {
    setDropdownVisible(!dropdownVisible);
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);


  const sortOpportunitiesByTitle = (opportunities) => {
    console.log("sortOpportunitiesByTitle called with opportunities:", opportunities);
    return opportunities.sort((a, b) => {
      const titleA = a.opportunity.title || ''; // Default to empty string if title is not available
      const titleB = b.opportunity.title || ''; // Default to empty string if title is not available
      return titleA.localeCompare(titleB);
    });
  };
  
  const sortOpportunitiesByPublishDate = (opportunities) => {
    console.log("sortOpportunitiesByPublishDate called with opportunities:", opportunities);
    return opportunities.sort((a, b) => {
      const dateA = new Date(a.opportunityid?.postedDate);
      const dateB = new Date(b.opportunityid?.postedDate);
      return dateA - dateB;
    });
  };


  const sortOpportunitiesByResponseDate = (opportunities) => {
    console.log("sortOpportunitiesByResponseDate called with opportunities:", opportunities);
    return opportunities.sort((a, b) => {
      const dateA = new Date(a.opportunityid?.responseDeadLine);
      const dateB = new Date(b.opportunityid?.responseDeadLine);
      return dateA - dateB;
    });
  };


  const sortOpportunitiesByDepartment = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities.sort((a, b) => {
      const deptA = (a.opportunityid?.fullParentPathName?.split(".")[0]);
      const deptB = (b.opportunityid?.fullParentPathName?.split(".")[0]);
      // console.log(dateA,dateB,"dateBdateB")
      if(deptA && deptB){
      return deptA.localeCompare(deptB)
      // return dateA - dateB;
      }
    });
  };


  const sortOpportunitiesBySubtier = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities.sort((a, b) => {
      const subtierA = (a.opportunityid?.fullParentPathName?.split(".")[1]);
      const subtierB = (b.opportunityid?.fullParentPathName?.split(".")[1]);
      // console.log(dateA,dateB,"dateBdateB")
      if(subtierA && subtierB){
      return subtierA.localeCompare(subtierB)
      }
      // return dateA - dateB;
    });
  };


  const sortOpportunitiesByOffice = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities.sort((a, b) => {
      const officeA = (a.opportunityid?.fullParentPathName?.split("."))[(a.opportunityid?.fullParentPathName?.split(".")).length-1];
      const officeB = (b.opportunityid?.fullParentPathName?.split("."))[(b.opportunityid?.fullParentPathName?.split(".")).length-1]
      console.log(officeA,officeB,"dateBdateB")
      if(officeA && officeB){
      return officeA.localeCompare(officeB)
      // return dateA - dateB;
      }
    });
  };


  const sortOpportunitiesBynoticeType = (opportunities) => {
    console.log("sortOpportunitiesByNoticeId called with opportunities:", opportunities);
    return opportunities.sort((a, b) => {
      const noticeTypeA = (a.opportunityid?.type);
      const noticeTypeB = (b.opportunityid?.type);
      // console.log(dateA,dateB,"dateBdateB")
      if(noticeTypeA && noticeTypeB){
      return noticeTypeA.localeCompare(noticeTypeB)
      }
      // return dateA - dateB;
    });
  };




  const email = sessionStorage.getItem("currentUserEmail");
  let screenName = "Opportunity Management";

  const features = JSON.parse(sessionStorage.getItem("FeatureRoleAccess"));
  const featureArray = features?.featuresAccess;
  const hierarchy=features?.hierarchy;
  console.log(typeof(hierarchy),"hierarchydata")
  // console.log(featureArray,"featureArray");
  let accessFeatures;
  if (featureArray) {
    const matchingFeature = featureArray.filter(
      (feature) => feature.ScreenName == screenName
    );

    if (matchingFeature) {
      accessFeatures = matchingFeature[0].features;
      // setAccessTofeature(accessFeatures)
      // console.log("accessFeatures", accessFeatures);
    } else {
      console.log("No feature found with screen name:", screenName);
    }
  } else {
    console.log("No features found in the storage.");
  }

 
  const simulateUploadProgress = () => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += Math.random() * 10; // Simulate progress increase
      if (progress >= 100) {
        clearInterval(interval); // Stop interval when progress reaches 100
      }
      setUploadProgress(progress);
    }, 500); // Update progress every 500 milliseconds
  };
  // const [reminderAssetId,setRemiderAssetid]=useState('')
  const reminderAssetId = useSelector((state) => state.rfp.reminderAssetId);

  const getDocs = async () => {
    const Username = sessionStorage.getItem("currentUserEmail");
    // getOppo1()
    // setMyDocs(docList)
    setInitialMyDocs(docList);
  };


  const getCurrentUser = async (Username) => {
    try {
      const response = await getUserInfo(Username);
      setUserInfo(response.data);
      return true;

    } catch (error) {
      console.log(error);
    }
  };

  const closeSubmissionHistory = () => {
    setSubmissionHistoryModal(false)
  }

  const openSubmissionHistory = () => {
    fetchSubmissionHistory()
   
  }


  const fetchSubmissionHistory =async ()=>{

    setLoading(true)
   
    let data ={
      orgId:sessionStorage.getItem("organizationId")
    }
    const response = await getSubmissionHistory(data)
    if(response.status === 200){
      setSubmissionHistory(response.submissionHistory)
      setSubmissionHistoryModal(true)
      setLoading(false)

    }else{
      console.log("Error in fetching submission history")
      setLoading(false)

    }

   
  }


  const handleOpenSummary = (document) => {
    // alert("clicked")
    // setSelectedDocument(document);
    // setOpenSummaryModal(true);
    // setShowList(false);
  };

  const handleClose = () => {
    setisHandleNewPage(true);
    setShowList(false);
  };

  const getsharedProjects = async () => {
    setLoading(true)

    try {
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      const response = await assetSharingServices.getassetsharingbyid(data);
      if (response) {
        console.log("getopportunitymatch ", response);
        setAssetSharedInfo(response?.sharedInfo);
        setSharedProject(response?.data);
        setInitialShareProject(response?.data);
        setOnlyId(response?.data?.map((k) => k.count));
        setassetType(response?.data?.map((k) => k.assetType));
        console.log(response?.abhayFaktTuzyasathi);
      }
    } catch (error) {
      console.log(error, "errrororrooror");
    }
    setLoading(false)

  };

  console.log(onlyId, "onlyIdonlyId");
  console.log(cancelledProjects, "cancelledProjects");

  const getOppo = async () => {
    try {
      setLoading(true);
      const email = sessionStorage.getItem("currentUserEmail");
      const data = { email };
      console.log(email, "email");
      const response = await assetService.getassetsbyid(data);
      if (response) {
        console.log("response of getopportunitymatch ", response);
        setData(response?.data);
        const projectOpportunities = response.data.filter(
          (item) => item.type === "Project" && item.status !== "Cancel" && item.status !== "Complete"
        );
        const archiveOpportunities = response.data.filter(
          (item) => item.type === "Archive" && item.status!=="Cancel" && item.status !== "Complete"
        );
        const cancelledProjects = response.data.filter(
          (item) => item.status === "Cancel"
        );
        const completeProjects = response.data.filter(
          (item) => item.status === "Complete"
        );
        const p1 = projectOpportunities.map((k) => k.opportunityid);
        setProject(projectOpportunities);
        setInitialMyProject(projectOpportunities);
        setArchive(archiveOpportunities);
        setInitialArchived(archiveOpportunities);
        setCancelledProjects(cancelledProjects);
      
        setcompleteProjects(completeProjects);
        setInitialCancelledProjects(cancelledProjects);
        setInitialCompletedProjects(completeProjects);
      } else {
        console.log("error in response");
      }
    } catch (e) {
      console.log(e, "errrororrooror");
    } finally {
      setLoading(false);
    }
  };
  console.log(project, "project");
  useEffect(() => {
    getOppo();
    getsharedProjects();
  }, [isEdit]);

  useEffect(() => {
    handleAssetRequestStatus();
    handleAssetRequestedList();
    getCurrentUser(email)
    getAllExistingData()
  }, []);

  const handleNewPage = (
    title,
    noticeId,
    descriptionData,
    id,
    type,
    date1,
    issuingagancy,
    assetid
  ) => {
    setisHandleNewPage(true);
    setShowList(false);
    setTitle(title);
    setNoticId(noticeId);
    setdescriptionData(descriptionData);
    setOpportunityId(id);
    setType(type);
    setDeadline(date1);
    setAgancy(issuingagancy);
    setgetAssetId(assetid);
    console.log("descriptionData__", descriptionData);
  };

  const HandleProposalWriting = (
    title,
    noticeId,
    descriptionData,
    id,
    type,
    date1,
    issuingagancy,
    assetid
  ) => {
    setShowList(true);
    setisHandleNewPage(false);
    setTitle(title);
    setNoticId(noticeId);
    setdescriptionData(descriptionData);
    setOpportunityId(id);
    setType(type);
    setDeadline(date1);
    setAgancy(issuingagancy);
    setgetAssetId(assetid);
    console.log("descriptionData__1", descriptionData);
  };

  const templatePage = (value) => {
    if (value == true) {
      setisHandleNewPage(false);
      setShowList(false);
    } else {
      setisHandleNewPage(false);
      setShowList(true);
    }
  };

  const openManualOpportunity = () => {
    history.push("/manualOpportunity");
  };

  const handleRedirectToRfp = async (
    title,
    noticeId,
    descriptionData,
    id,
    type,
    date1,
    agancy,
    assetid,
    resourceLinksPath,
    viewType,
    oppList
  ) => {
    // setLoading(true)
    let featurename = "RFP";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast('Restriction.openRfp')
    } else {
      console.log(resourceLinksPath, "resourceLinksPath");
      const canRedirect = await handleValidation(assetid)
      console.log("canRedirect",canRedirect)
      if(canRedirect == true){
        // alert(oppList)
        if(oppList=="Project"){
        setValidationModal(true)
        }else{
          showToast('Error.access')
        }
        // setLoading(false)

        return
      }
      const userEmail = sessionStorage.getItem("currentUserEmail")
      const data = { assetId: assetid, userEmail: userEmail, assetType: "rfp" };
      const response = await validateAccess(data);
     
      if (response.status !== 200) {
          showToast('Restriction.openRfp');
          // setLoading(false)

          return;
      }
      dispatch(
        rfmManagmentData(
          title,
          noticeId,
          descriptionData,
          id,
          type,
          date1,
          agancy,
          assetid,
          resourceLinksPath
        )
      );
      dispatch(myprojectScreen(viewType));
      console.log("viewType", viewType);

      // console.log("Working redirect ")
      history.push("/rfpManagement");
    }
    // setLoading(false)

  };

  const handleRedirectToPropsal = async (
    title,
    noticeId,
    descriptionData,
    id,
    type,
    date1,
    agancy,
    assetid,
    oppList
  ) => {
    // setLoading(true)

    let featurename = "Proposal";
    const checkfeatureName = accessFeatures?.filter(
      (k) => k.name == featurename
    );
    console.log(checkfeatureName[0].access, "checkfeatureName");
    if (!checkfeatureName[0].access) {
      showToast('Restriction.openProposal')
    } else {
      const canRedirect = await handleValidation(assetid)
      if(canRedirect == true){
        if(oppList=="Project"){
        setValidationModal(true)
        }else{
          showToast('Error.access')
        }
        // setLoading(false)

        return
      }
      const userEmail = sessionStorage.getItem("currentUserEmail")
      const data = { assetId: assetid, userEmail: userEmail, assetType: "proposal" };
      const response = await validateAccess(data);
     
      if (response.status !== 200) {
          showToast('Restriction.openProposal');
          setLoading(false)

          return;
      }
      dispatch(
        proposalWritingData(
          title,
          noticeId,
          descriptionData,
          id,
          type,
          date1,
          agancy,
          assetid
        )
      );

      history.push("/proposalWriting");
    }
    // setLoading(false)

  };

  function handleCollaps(value) {
    // alert(value)
    if (value == "myproject") {
      setIsCollaps((prevState) => ({
        ...prevState,
        isMyProject: !prevState.isMyProject,
      }));
    } else if (value == "sharedproject") {
      setIsCollaps((prevState) => ({
        ...prevState,
        isSharedProject: !prevState.isSharedProject,
      }));
    } else if (value == "archivedproject") {
      setIsCollaps((prevState) => ({
        ...prevState,
        isArchivedProject: !prevState.isArchivedProject,
      }));
    } else if (value == "ProjectList") {
      setIsCollaps((prevState) => ({
        ...prevState,
        isProjectList: !prevState.isProjectList,
      }));
    }else if (value == "cancelledProjects") {
      setIsCollaps((prevState) => ({
        ...prevState,
        isCancelledProject: !prevState.isCancelledProject,
      }));
    }else if (value == "completeProjects") {
      setIsCollaps((prevState) => ({
        ...prevState,
        isCompletedProject: !prevState.isCompletedProject,
      }));
    }
  }

  const handleCommentModal = (createdBy, assetid, email) => {
    handleOpenCommentModal();
    console.log(
      "Workking ",
      "assetid",
      assetid,
      "createdBy",
      createdBy,
      "email",
      email
    );
    setAssetRequestData((prevState) => ({
      ...prevState,
      assetid: assetid,
      requestFrom: email,
      requestTo: createdBy,
    }));
  };

  const handleSendClick = async () => {
    setLoading(true)

    try {
      if(!assetRequestData?.comment){
        showToast('Warning.enterMessage')
      }else{
      const response = await sendAssetRequest(assetRequestData);
      if (response.status == 200) {
        setAssetRequestStatusData("");
        toast.success(response.message);
        setProject("");
        handleCloseCommentModal();
        handleAssetRequestStatus();
        getOppo();
      }
      if (response.status == 400) {
        toast.warn(response.message);
      }
      console.log(response, "response of asset request ");
      }
    } catch (e) {
      console.log(e, "error in sending asset request");
    }
    setLoading(false)

  };

  const handleCloseCommentModal = () => {
    setIsCommentModalOpen(false);
  };

  const handleOpenCommentModal = (assetid, createdBy, email, assetType) => {
    if ((assetid, createdBy, email, assetType)) {
      setAssetRequestData((prevState) => ({
        ...prevState,
        assetid: assetid,
        requestFrom: email,
        requestTo: createdBy,
        assetType: assetType,
      }));
    }
    setAssetRequestData((prevState) => ({
      ...prevState,
      comment: "",
    }));
    setIsCommentModalOpen(true);
  };

  const handleCommentTextareaChange = (value) => {
    console.log("value of comment ", value);
    setAssetRequestData((prevState) => ({
      ...prevState,
      comment: value,
    }));
  };

  const handleAssetRequestStatus = async () => {
    let data = {
      email: email,
    };
    setLoading(true)

    try {
      const response = await getAssetRequestStatus(data);
      console.log(response, "response of asset status");
      if (response) {
        setAssetRequestStatusData(response.data);
        console.log(response.data, "response of asset status");
      } else {
        console.log("error in getting asset request ");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)

  };

  const handleAssetRequestedList = async () => {
    let data = {
      email: email,
    };
    setLoading(true)

    try {
      const response = await assetRequestedList(data);
      console.log(response, "response of asset status");
      if (response) {
        setAssetRequestedListData(response.data);
        console.log(response.data, "respons request  asset list ");
      } else {
        console.log("error in getting asset request ");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)

  };

  const handleGetRequestedMembers = async (assetId) => {
    let data = {
      assetId,
    };
    setselectedAssetId(assetId);
    setLoading(true)

    try {
      const response = await assetRequestedListbyAssetid(data);
      if (response) {
        setAssetRequestedMemberList(response.data);
        handleOpenMemberListModal();
        console.log(response.data, "response of asset member list");
      } else {
        console.log("error in getting asset request ");
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)

  };

  const handleOpenMemberListModal = () => {
    setOpenMemberListModal(true);
  };

  const grantAccessForSelectedEmails = async () => {
    const emailMap = new Map();

    // Iterate over selectedEmails array
    selectedEmails.forEach((obj) => {
      // Check if the email already exists in the map
      if (emailMap.has(obj.email)) {
        // If yes, check if the assetType is different
        const existingAssetType = emailMap.get(obj.email).assetType;
        if (existingAssetType !== obj.assetType) {
          // If different, set assetType to "both"
          emailMap.set(obj.email, { email: obj.email, assetType: "both" });
        }
      } else {
        // If email doesn't exist, add it to the map
        emailMap.set(obj.email, { email: obj.email, assetType: obj.assetType });
      }
    });

    // Convert map values to array
    const updatedSelectedEmails = Array.from(emailMap.values());

    // Prepare data object with updatedSelectedEmails
    let data = {
      emailreuested: updatedSelectedEmails,
      loggedinEmail: email,
      access: "accept",
      assetid: selectedAssetId,
    };
    try {
      setLoading(true)
      console.log(data,"emaildata")
      if(data?.emailreuested?.length==0){
       showToast('Warning.selectEmail')
      }else{
      const response = await approvalForAssetPerformance(data);

      if (response.status == 201) {
        console.log("Selected Emails:", selectedEmails);
        setSelectedEmails([]);
        toast.success(response.message);
        handleAssetRequestStatus();
        handleAssetRequestedList();
        setProject("");
        setOpenMemberListModal(false);
        getOppo();
        console.log(response, "response of appoverd asset  status");
      } else {
        console.log("error in getting asset request ");
      }
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)

  };

  const toggleEmailSelection = (email, assetType) => {
    setSelectedEmails((prevSelected) => {
      const existingIndex = prevSelected.findIndex(
        (obj) => obj.email == email && obj.assetType == assetType
      );
      if (existingIndex !== -1) {
        // If the email is already selected, remove it from the selection
        const updatedSelection = [...prevSelected];
        updatedSelection.splice(existingIndex, 1);
        return updatedSelection;
      } else {
        // If the email is not selected, add it to the selection along with its asset ID
        return [...prevSelected, { email, assetType }];
      }
    });
  };

  const handleAssetTypeChange = (event) => {
    let value = event.target.value;
    setAssetRequestData((prevState) => ({
      ...prevState,
      assetType: value,
    }));
  };

  useEffect(() => {
    console.log("selected Emails", selectedEmails);
  }, [selectedEmails]);

  const toggleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedEmails([]); // Clear selection if Select All is unchecked
    } else {
      const pendingEmails = assetRequestedMemberList
        .filter((data) => data.requestedStatus === "pending")
        .map((entry) => ({
          email: entry.requestFrom,
          assetType: entry.assetType,
        }));
      setSelectedEmails(pendingEmails);
    }
    setSelectAllChecked((prev) => !prev);
  };

  const handleClauseExtraction = async (resourceLinkspaths, assetId, title) => {
    setExtractedClause("");
    // if (resourceLinkspaths?.length === 0 || resourceLinkspaths === null ) {
    //   toast.warn("No resource links found.");
    //   return;
    // }
    setDownloadFileName(title);
    setAssetIdForClauses(assetId);
    setResourceLinks(resourceLinkspaths);
    let data = {
      resourceLinks: resourceLinkspaths,
      assetId,
      email:sessionStorage.getItem("currentUserEmail")
    };
    try {
      setLoading(true);
      const response = await getResourceLinks(data);

      if (response.status == 201) {
        console.log("extracted clauses:", response);
        setExtractedClause(response.data);
        handleOpenClauseModal();
      } else if (response.status == 400) {
        getExtractedClause(assetId);
        handleOpenClauseModal();
      } else {
        console.log("error in getting xtracted clauses ");
        handleOpenClauseModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenClauseModal = () => {
    setIsOpenClauseModal(true);
  };
  const formatDate = (dateString) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date(dateString);
    const monthIndex = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${
      months[monthIndex]
    } ${day}, ${year} at ${formatTime(hours, minutes)}`;

    return formattedDate;
  };
  const formatTime = (hours, minutes) => {
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm} GMT+5:30`;
  };
  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value.toLowerCase().trim();
  
    const filterProjects = (projects) => {
      return projects?.filter((doc) => {
        const titleMatched = doc.opportunityid?.title?.toLowerCase().includes(newSearchTerm);
        const descriptionMatched = doc.opportunityid?.descriptionData?.toLowerCase().includes(newSearchTerm);
        const noMatched = doc.opportunityid?.solicitationNumber?.toLowerCase().includes(newSearchTerm);
        const department = doc.opportunityid?.fullParentPathName?.toLowerCase().includes(newSearchTerm);
        const postedDate = formatDate(doc.opportunityid?.postedDate)?.toLowerCase().includes(newSearchTerm);
        const responseDeadLine = formatDate(doc.opportunityid?.responseDeadLine)?.toLowerCase().includes(newSearchTerm);
        return (
          titleMatched ||
          descriptionMatched ||
          noMatched ||
          department ||
          postedDate ||
          responseDeadLine
        );
      });
    };
  
    const filterdMyProject = filterProjects(initialMyProject);
    const filterdCompletedProject = filterProjects(initialCompletedProjects);
    const filterdCancelledProject = filterProjects(initialCancelledProjects);
    const filterdSharedProject = filterProjects(initialShareProject);
    const filterdArchivedProject = filterProjects(initialArchived);
  
    setProject(() => (newSearchTerm ? filterdMyProject : initialMyProject));
    setcompleteProjects(() => (newSearchTerm ? filterdCompletedProject : initialCompletedProjects));
    setCancelledProjects(() => (newSearchTerm ? filterdCancelledProject : initialCancelledProjects));
    setSharedProject(() => (newSearchTerm ? filterdSharedProject : initialShareProject));
    setArchive(() => (newSearchTerm ? filterdArchivedProject : initialArchived));
  };
  




  const getExtractedClause = async (assetId) => {
    try {
      setLoading(true)
      let data = {
        assetid: assetId ? assetId : assetIdForClauses,
      };
      const response = await getClauses(data);
      console.log("response", response);
      setExtractedClause(response?.data);
    } catch (e) {
      console.log(e, "erro in getExtractedClause");
    }
    setLoading(false)

  };

  const handleAddMaual = async () => {
    try {
      let featurename = "Add Manual Opportunity";
      const checkfeatureName = accessFeatures?.filter(
        (k) => k.name == featurename
      );
      console.log(checkfeatureName[0].access, "checkfeatureName");
      if (!checkfeatureName[0].access) {
        showToast('Restriction.addManualOpportunity')
      } else {
        setAddManual(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("mmmm", id, reminderAssetId);
    if (id && initialMyProject) {
      const matchedProject = initialMyProject
        .slice()
        .filter((project) => project._id === id);
      const unmatchedProject = initialMyProject
        .slice()
        .filter((project) => project._id !== id);

      const sortedProjects = matchedProject.concat(unmatchedProject);
      setProject(sortedProjects);
      setSortedProjects(sortedProjects);
    }
    if (id && initialShareProject) {
      const matchedProject = initialShareProject
        .slice()
        .filter((project) => project._id === id);
      const unmatchedProject = initialShareProject
        .slice()
        .filter((project) => project._id !== id);

      const sortedProjects = matchedProject.concat(unmatchedProject);
      setSharedProject(sortedProjects);
      setSortSharedProjects(sortedProjects);
    }

    if (id && initialArchived) {
      const matchedProject = initialArchived
        .slice()
        .filter((project) => project._id === id);
      const unmatchedProject = initialArchived
        .slice()
        .filter((project) => project._id !== id);

      const sortedProjects = matchedProject.concat(unmatchedProject);
      setArchive(sortedProjects);
    }
    if (id && initialCompletedProjects) {
      const matchedProject = initialCompletedProjects
        .slice()
        .filter((project) => project._id === id);
      const unmatchedProject = initialCompletedProjects
        .slice()
        .filter((project) => project._id !== id);

      const sortedProjects = matchedProject.concat(unmatchedProject);
      setcompleteProjects(sortedProjects);
    }
  }, [id, initialMyProject, initialShareProject,initialCompletedProjects]);

  const handleDownloadClause = () => {
    setshowDownloadModal(true);
  };

  const downloadSinglePDF = () => {
    if(!extractedClause?.clauses){
      return showToast('Proposal.noClauseDownload')
    }
    let pdfContent = "";
    console.log(extractedClause, downloadFileName, "aaaa");
    extractedClause.clauses.forEach((item) => {
      const responseData = item.responseData
        .split("\n")
        .map((clause) => `<p>${clause}</p>`)
        .join("");
      pdfContent += `<div><h5>${item.filename}</h5>${responseData}</div><br>`;
    });
    console.log("pdfContent", pdfContent);
    const element = document.createElement("div");
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: `${downloadFileName}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    }).then((pdf) => {
      saveAs(pdf, `${downloadFileName}.pdf`);
    });
    setshowDownloadModal(false);
  };

  function updateImgWidth(match, p1, p2, p3) {
    // p2 is the current width value
    const currentWidth = parseInt(p2, 10);
    // Check if the current width is less than 600
    if (currentWidth < 600) {
        return `<img${p1}width="${currentWidth}"${p3}>`;
    }
    // If the current width is 600 or more, set it to 600
    return `<img${p1}width="600"${p3}>`;
}

  const handleDocxDownload = async () => {

    let pdfContent = "";
    console.log(extractedClause, downloadFileName, "aaaa");
    extractedClause.clauses.forEach((item) => {
      const responseData = item.responseData
        .split("\n")
        .map((clause) => `<p>${clause}</p>`)
        .join("");
      pdfContent += `<div><h5>${item.filename}</h5>${responseData}</div><br>`;
    });

    let htmlString = `<!DOCTYPE html>
      <html lang="en">
      <head>
      <meta charset="UTF-8">
      <title>${downloadFileName}</title>
      </head>
      <body>
      <h3>${downloadFileName}</h3>
      <hr/>
      ${pdfContent}
      </body>
      </html>`;

      htmlString = htmlString.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);
  
  
  htmlString = htmlString.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');

    asBlob(htmlString).then((docx) => {
      saveAs(docx, `${downloadFileName}.docx`);
      setshowDownloadModal(false); // Close the modal after the download
    });
  };

  useEffect(()=>{
    console.log("Extartced clause",extractedClause)
  },[extractedClause])

  const handleDocumentClick = (document, type) => {
    dispatch(setProjectType(type));
    handleOpenSummary(document);
  };


  const handleValidation =async (assetId)=>{
    setLoading(true)

   
    let data ={
      assetId
      ,orgId:sessionStorage.getItem("organizationId")
    }
    const response = await checkMember(data)
    if(response.status === 200){
      console.log("handleValidation",response)
      setLoading(false)

      return false
    }else if(response.status === 201){
      console.log("handleValidationElse",response)
      setValidationData(response.data)
      setLoading(false)

      return true
    }

  }

  const redirectToWorkflow = () => {
    setValidationModal(false);
    history.push('/subAdminManageUser');
  };
 

  const handleOpenMetricsModal = async (assetId, title) => {
    setLoading(true)

    const dataLoaded = await getParticularData(assetId);
    if (dataLoaded) {
      setIsmetricEdit(false)
      setMetricsTital(title);
      setMetricsAssetId(assetId);
      setisMetricsModal(true);
      console.log("assetId", assetId);
    }
    setLoading(false)

  };

  const getParticularData =async (assetId)=>{
    setLoading(true)

    try{
      let data = {
        assetId,
        orgId:sessionStorage.getItem("organizationId")
      }
      console.log("CHECK@",assetId)
      const res = await getPerfomanceMatricsByAssetid(data);
      if(res.status == 200){
        console.log("rows1",res)
        setExistingData(res.data)
      }
      else{
        setExistingData("")
      }
    }catch(error){
      console.log("error",error)
    }finally{
      setLoading(false)

      return true
    }
  }
  const handleCloseMetricsModal = ()=>{
    setisMetricsModal(false)
  }





  const handleAddMetricsData =async (value)=>{
    try{
 
      if(!value.winningPercentage || !value.lostPercentage || !value.submissionDate || !value.submissionMethod ){
       return showToast('Warning.allFields')
      }
      if(value.winningPercentage < 0 || value.lostPercentage < 0){
       return showToast('Warning.minNumber')
      }
      if(value.winningPercentage > 100 || value.lostPercentage > 100){
       return showToast('Warning.maxNumber')
      }
      let data = {
        proposalName:metricsTital,
        assetId:metricAssetId,
        winningPercentage:value.winningPercentage,
        lostPercentage:value.lostPercentage,
        orgId:sessionStorage.getItem("organizationId"),
        submissionDate:value.submissionDate,
        submissionMethod: value.submissionMethod
      }
      setLoading(true)

      const response =await savePerfomanceMatrics(data)
      console.log(response ,"savePerfomanceMatrics")
      if(response.status === 200){
        showToast('Success.dataAdded')
        getAllExistingData()
        getParticularData(metricAssetId)
        setEmptyState(true)
      }
     
    }catch(error){
      console.log(error)
    }
    setLoading(false)

  }

  const handleDeletemetrics = async (id) => {
    try{
      setLoading(true)

      setIsmetricEdit(false)
      let data = {
        orgId:sessionStorage.getItem("organizationId"),
        assetId:id
      }
      const response  = await deletePerfomanceMatrics(data)
      if(response.status == 200){
        showToast('Success.dataDeleted')
        if(id == metricAssetId){
          setMetricsTital('')
          setExistingData('')
          handleCloseMetricsModal()
        }
        const data = await getAllExistingData()
        // const data1 =await getParticularData(id)
        console.log("data1",data ,rows,existingData)
        if(data ){
          if(rows?.lenght == 0){
            handleCloseMetricsModal()
          }
         
        }
      }

    }catch(error){
      console.log(error)
    }
    setLoading(false)

  };

  const getAllExistingData =async ()=>{
    try{
      setLoading(true)

      let data = {
        orgId:sessionStorage.getItem("organizationId")
      }
      const res = await getPerfomanceMatrics(data);
      if(res.status == 200){
        console.log("rows",res)
        setRows(res.data)
      }
    }catch(error){
      console.log("error",error)
    }finally{
      setLoading(false)

      return true
    }
  }

  const handleUpdateMetrics =async (value)=>{
    try{
      if(!value.winningPercentage || !value.lostPercentage || !value.submissionDate || !value.submissionMethod ){
        return showToast('Warning.allFields')
       }
      if(value.winningPercentage < 0 || value.lostPercentage < 0){
        return showToast('Warning.minNumber')
       }
       if(value.winningPercentage > 100 || value.lostPercentage > 100){
        return showToast('Warning.maxNumber')
       }

      let data = {
        orgId:sessionStorage.getItem("organizationId"),
        assetId:metricAssetId,
        winningPercentage:value.winningPercentage,
        lostPercentage:value.lostPercentage,
        submissionDate:value.submissionDate,
        submissionMethod: value.submissionMethod
      }
 
 
      setLoading(true)

 
      const response = await updatePerformanceMetrics(data)
      if(response.status == 200){
        showToast('Success.dataUpdated')
        getAllExistingData()
        getParticularData(metricAssetId)
        setIsmetricEdit(false)
      }
    }catch(error){
      console.log("error",error)
    }
    setLoading(false)

  }
 

  const handleEnabelUpdateMetrics =async (value)=>{
    try{
      console.log("handleEnabelUpdateMetrics",value)
      setExistingData(value)
      setIsmetricEdit(true)
      setMetricsAssetId(value.assetId)
    }catch(error){
      console.log("error",error)
    }
  }

 

  const handleMainOptionChange = (event) => {
    setMainOption(event.target.value);
    setSubOption(''); // Reset subOption when mainOption changes
  };

  const handleSubOptionChange = (event) => {
    setSubOption(event.target.value);
    // alert(`Selected ${mainOption} - ${event.target.value}`);
  };
  const handleRefresh = async () => {
    window.location.reload();
  };

  const UnArchiveModalOpen = async (id) => {
    console.log(id, "++id")
    setArchiveId(id)
    setArchiveModalOpen(true)
  };

  const UnArchiveModalClose = async () => {
    setArchiveModalOpen(false)
  };
  console.log(archiveId, "archiveId")
  const handleUnArchive = async () => {
    try {
      const data = {
        id: archiveId,
        orgId: sessionStorage.getItem("organizationId")
      }
      console.log(data, "((data")
      const response = await unArchiveProject(data)
      console.log(response, "!!ress")
      if (response.status == 200) {
        // setInitialArchived(response.data)
        // setArchive(response.data)
        // await setData(response.data)
        showToast('Success.projectUnarchived')
        setArchiveModalOpen(false)
        handleRefresh()
      } else {
        showToast('Error.projectUnarchived')
      }
    } catch (error) {
      console.log("Error in unarchiving project:", error)
      showToast('Error.errorOccured')
    }
  };
  console.log(Archive, "__aaaa")
  console.log(completeProjects, "_completeProjects")
  console.log(initialArchived, "__initialArchived")
  // useEffect(()=>{
  //  updateCategoryStates(data)
  // },[Archive])
  console.log(data,"BBBBBB")

  return (
    <>
      <Loader loading={loading} />
      <div>
        <div>
          <div className="container-fluid" style={{border:"1px solid #ccc",borderRadius:"10px"}}>
            {!ishandleNewPage && !showList && (
              <>
                <div className="row d-flex p-3" style={{ borderBottom: "1px solid #ccc" }}>
                  <div className={`${windowWidth < 768 ? 'col-12' : 'col-md-9'}`}>
                    <TextField
                      label={
                        <div>
                          <SearchIcon /> Search
                        </div>
                      }
                      variant="outlined"
                      size="small"
                      style={{ width: "100%", borderRadius: "30px" }}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className={`${windowWidth < 768 ? 'col-12 d-flex justify-content-center' : 'col-md-3 d-flex justify-content-end'} mt-3 mt-md-0`}
                  style={{paddingLeft: windowWidth < 350 ? "0px" : ""}}
                  >
                    <IconButton style={{ outline: "none" }} title="Refresh" onClick={handleRefresh}>
                      <RefreshIcon style={{ color: "rgb(61, 131, 250)" }} />
                    </IconButton>

                    <IconButton onClick={handleAddMaual}>
                      {addManual ? (
                        <Link to="/manualOpportunity">
                          <CreateNewFolderIcon style={{ color: "rgb(61, 131, 250)" }} />
                        </Link>
                      ) : (
                        <CreateNewFolderIcon style={{ color: "rgb(61, 131, 250)" }} />
                      )}
                    </IconButton>
                    {windowWidth >= 768 && (
                    <IconButton>
                      {listView ? (
                        <div data-tip data-for="GridView">
                          <ReactTooltip id="GridView" place="bottom" effect="solid">
                            Grid View
                          </ReactTooltip>
                          <WindowIcon style={{ color: "rgb(61, 131, 250)" }} onClick={() => setListView(false)} />
                        </div>
                      ) : (
                        <div data-tip data-for="ListView">
                          <ReactTooltip id="ListView" place="bottom" effect="solid">
                            List View
                          </ReactTooltip>
                          <TableRowsIcon style={{ color: "rgb(61, 131, 250)" }} onClick={() => setListView(true)} />
                        </div>
                      )}
                    </IconButton>
                    )}
                    <IconButton style={{ border: "none", outline: "none" }}>
                      <div title="Sort">
                        {!dropdownVisible && (
                          <SortByAlphaIcon onClick={handleIconClick} style={{ cursor: 'pointer', border: "none", outline: "none" }} />
                        )}
                        {dropdownVisible && (
                          <div style={{ width: "150px", height: "25px", fontSize: "12px", outline: "none" }}>
                            <select
                              style={{ width: "100%", height: "100%", outline: "none", border: "none" }}
                              id="sortSelect"
                              ref={dropdownRef}
                              value={sortBy}
                              onChange={handleSortChange}
                            >
                              <option value="">Select sorting criteria</option>
                              <option value="title">Title</option>
                              <option value="publishDate">Publish Date</option>
                              <option value="responseDate">Response Date</option>
                              <option value="department">Department</option>
                              <option value="subtier">Subtier</option>
                              <option value="office">Office</option>
                              <option value="noticeType">Notice Type</option>
                            </select>
                          </div>
                        )}
                      </div>
                    </IconButton>
                  </div>
                </div>


           
             
               

                {/* {sharedProject?.length > 0 && (
                  <div className="row">
                    <div className=" projectTitle">
                      <hr />
                      <h3 className="title_headTag">Shared Projects ({sharedProject.length})</h3>
                      <IconButton
                        onClick={() => {
                          handleCollaps("sharedproject");
                        }}
                      >
                        {isCollaps.isSharedProject === false ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </IconButton>
                    </div>
                    {isCollaps.isSharedProject === false && (
                      <>
                        {sharedProject?.map((document, index) => {
                          const parsedJson = document.onlyDocumentContent
                            ? JSON.parse(document.onlyDocumentContent)
                            : { blocks: [{ text: "" }] };
                          let description = parsedJson.blocks[0].text;

                          return (
                            <div
                              className={
                                listView ? "col-md-12" : "col-md-6 mt-3"
                              }
                              key={index}
                              // onClick={() => handleOpenSummary(document)}
                              onClick={() => handleDocumentClick(document, 'sharedProject')}
                            >
                              {listView ? (
                                <DocumentListCard
                                highlightClor="highlight"
                                  reminderAssetId={id}
                                  assetid={document._id}
                                  assetNoteComment={document.assetNoteComment}
                                  agancy={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  id={document?.opportunityid?._id}
                                  documentId={document?.opportunityid?._id}
                                  type={document?.opportunityid?.type}
                                  title={document?.opportunityid?.title}
                                  description={
                                    document?.opportunityid?.descriptionData
                                  }
                                  noticeId={
                                    document?.opportunityid?.solicitationNumber
                                  }
                                  department={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  descriptionData={
                                    document?.opportunityid?.descriptionData
                                  }
                                  subtier={document?.opportunityid?.type}
                                  office={
                                    document?.opportunityid?.officeAddress
                                  }
                                  date={document?.opportunityid?.postedDate}
                                  date1={
                                    document?.opportunityid?.responseDeadLine
                                  }
                                  status={document?.opportunityid?.active}
                                  orgId={
                                    document?.opportunityid?.organizationId
                                  }
                                  contact={
                                    document?.opportunityid?.pointOfContact[0]
                                  }
                                  place={
                                    document?.opportunityid?.placeOfPerformance
                                  }
                                  handleNewPage={handleNewPage}
                                  HandleProposalWriting={HandleProposalWriting}
                                  viewType={"Shared Project"}
                                  proposalWriting={proposalWriting}
                                  RFP={RFP}
                                  redirectToRfp={handleRedirectToRfp}
                                  redirectToPropsal={handleRedirectToPropsal}
                                  renderIcons={document?.count}
                                  assetTypes={document?.assetType}
                                  requestAccess={handleOpenCommentModal}
                                  createdBy={document?.createdby?.email}
                                  currentlyAssignedTeam = {document.currentassignedTeam}
                                  userRole = {userInfo.userRole}
                                  proposalAccess = {document.proposalAccess}
                                />
                              ) : (
                                <DocumentGridCard
                                  assetid={document._id}
                                  documentId={document?.opportunityid?._id}
                                  assetNoteComment={document.assetNoteComment}
                                  agancy={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  id={document?.opportunityid?._id}
                                  type={document?.opportunityid?.type}
                                  description={
                                    document?.opportunityid?.descriptionData
                                  }
                                  title={document?.opportunityid?.title}
                                 
                                  // noticeId={document?.opportunityid?.noticeId}
                                  noticeId={
                                    document?.opportunityid
                                      ?.solicitationNumber
                                  }
                                  department={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  descriptionData={
                                    document?.opportunityid?.descriptionData
                                  }
                                  subtier={document?.opportunityid?.type}
                                  office={
                                    document?.opportunityid?.officeAddress
                                  }
                                  date={document?.opportunityid?.postedDate}
                                  date1={
                                    document?.opportunityid?.responseDeadLine
                                  }
                                  status={document?.opportunityid?.active}
                                  orgId={
                                    document?.opportunityId?.organizationId
                                  }
                                  handleNewPage={handleNewPage}
                                  HandleProposalWriting={HandleProposalWriting}
                                  //  templatePage={templatePage}
                                  viewType={"Oppoupload"}
                                  redirectToRfp={handleRedirectToRfp}
                                  redirectToPropsal={handleRedirectToPropsal}
                                  currentlyAssignedTeam = {document.currentassignedTeam}
                                  userRole = {userInfo.userRole}
                                  proposalAccess = {document.proposalAccess}
                                  requestAccess={handleOpenCommentModal}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )} */}



                {project?.length > 0 && (
                  <div className="row">
                    <div className="projectTitle">
                      <hr />
                      <p style={{fontSize: windowWidth<768?'15px':'18px', fontWeight:'bold'}} className="title_headTag">My Projects ({project.filter(document => document?.createdby?.email == email).length})</p>
                      <IconButton
                        onClick={() => {
                          handleCollaps("myproject");
                        }}
                      >
                        {isCollaps.isMyProject === false ? (
                          <ArrowDropUpIcon className="title_arrowTag" />
                        ) : (
                          <ArrowDropDownIcon className="title_arrowTag" />
                        )}
                      </IconButton>
                    </div>
                    {isCollaps.isMyProject === false && (
                      <>
                        {project
                          ?.filter(document =>
                            document?.createdby?.email == email
                          )
                          .map((document, index) => {
                            const parsedJson = document.onlyDocumentContent
                              ? JSON.parse(document.onlyDocumentContent)
                              : { blocks: [{ text: "" }] };
                            let description = parsedJson.blocks[0].text;

                            return (
                              <div
                                className={
                                  listView ? "col-md-12" : "col-md-6"
                                }
                                key={index}
                                // onClick={() => handleOpenSummary(document)}
                              onClick={() => handleDocumentClick(document, 'myProject')}
                              >
                                {listView ? (
                                  <DocumentListCard
                                    highlightClor="highlight"
                                    reminderAssetId={id}
                                    clauseExtraction={handleClauseExtraction}
                                    assetid={document._id}
                                    assetNoteComment={document.assetNoteComment}
                                    agancy={
                                      document?.opportunityid
                                        ?.fullParentPathName
                                    }
                                    id={document?.opportunityid?._id}
                                    documentId={document?.opportunityid?._id}
                                    type={document?.opportunityid?.type}
                                    title={document?.opportunityid?.title}
                                    description={
                                      document?.opportunityid?.descriptionData
                                    }
                                    noticeId={
                                      document?.opportunityid
                                        ?.solicitationNumber
                                    }
                                    department={
                                      document?.opportunityid
                                        ?.fullParentPathName
                                    }
                                    descriptionData={
                                      document?.opportunityid?.descriptionData
                                    }
                                    subtier={document?.opportunityid?.type}
                                    office={
                                      document?.opportunityid?.officeAddress
                                    }
                                    date={document?.opportunityid?.postedDate}
                                    date1={
                                      document?.opportunityid?.responseDeadLine
                                    }
                                    status={document?.opportunityid?.active}
                                    orgId={
                                      document?.opportunityid?.organizationId
                                    }
                                    // contact={document?.opportunityid?.pointOfContact[0]}
                                    contact={
                                      document?.opportunityid
                                        ?.pointOfContact !== null
                                        ? document?.opportunityid
                                            ?.pointOfContact[0]
                                        : []
                                    }
                                    place={
                                      document?.opportunityid
                                        ?.placeOfPerformance
                                    }
                                    resourceLinksPath={
                                      document?.opportunityid?.resourceLinksPath
                                    }
                                    resourceLinksData={
                                      document?.opportunityid?.resourceLinks
                                    }
                                    handleNewPage={handleNewPage}
                                    HandleProposalWriting={
                                      HandleProposalWriting
                                    }
                                    viewType={"Oppoupload"}
                                    oppList={"Project"}
                                    proposalWriting={proposalWriting}
                                    RFP={RFP}
                                    redirectToRfp={handleRedirectToRfp}
                                    redirectToPropsal={handleRedirectToPropsal}
                                    createdBy={document?.createdby?.email}
                                    commentModal={handleCommentModal}
                                    requestListData={assetRequestedListData}
                                    getRequestedlistMember={
                                      handleGetRequestedMembers
                                    }
                                    opportunityUpdate={
                                      document?.opportunityUpdate
                                    }
                                    hierarchy={document?.createdby?.hierarchy}
                                  />
                                ) : (
                                  <DocumentGridCard
                                    assetid={document._id}
                                    assetNoteComment={document.assetNoteComment}
                                    agancy={
                                      document?.opportunityid
                                        ?.fullParentPathName
                                    }
                                    id={document?.opportunityid?._id}
                                    documentId={document?.opportunityid?._id}
                                    clauseExtraction={handleClauseExtraction}
                                    resourceLinksData={
                                      document?.opportunityid?.resourceLinks
                                    }
                                    type={document?.opportunityid?.type}
                                    title={document?.opportunityid?.title}
                                    description={description}
                                    noticeId={document?.opportunityid?.solicitationNumber}
                                    department={
                                      document?.opportunityid
                                        ?.fullParentPathName
                                    }
                                    descriptionData={
                                      document?.opportunityid?.descriptionData
                                    }
                                    subtier={document?.opportunityid?.type}
                                    office={
                                      document?.opportunityid?.officeAddress
                                    }
                                    date={document?.opportunityid?.postedDate}
                                    date1={
                                      document?.opportunityid?.responseDeadLine
                                    }
                                    status={document?.opportunityid?.active}
                                    orgId={
                                      document?.opportunityId?.organizationId
                                    }
                                    resourceLinksPath={
                                      document?.opportunityId?.resourceLinksPath
                                    }
                                    handleNewPage={handleNewPage}
                                    HandleProposalWriting={
                                      HandleProposalWriting
                                    }
                                    //  templatePage={templatePage}
                                    viewType={"Oppoupload"}
                                    oppList={"Project"}
                                    redirectToRfp={handleRedirectToRfp}
                                    redirectToPropsal={handleRedirectToPropsal}
                                    requestListData={assetRequestedListData}
                                    opportunityUpdate={
                                      document?.opportunityUpdate
                                    }
                                    noticeId2={document?.opportunityid?.noticeId}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                )}

           

                {project?.length > 0 && (
                  <div className="row">
                    <div className="projectTitle">
                      <hr />
                      <p style={{fontSize: windowWidth<768?'15px':'18px', fontWeight:'bold'}} className="title_headTag">Project List ({project.filter(document =>
                          document?.createdby?.email !== email 
                        )
                          // .filter((document) => {
                          //   return !assetSharedinfo?.some(
                          //     (info) =>
                          //       info.assetid == document._id &&
                          //       info.sharedwith.some(
                          //         (shared) => shared.email == email
                          //       )
                          //   );
                          // })
                          .length})</p>
                      <IconButton
                        onClick={() => {
                          handleCollaps("ProjectList");
                        }}
                      >
                        {isCollaps.isProjectList == false ? (
                          <ArrowDropUpIcon className="title_arrowTag"/>
                        ) : (
                          <ArrowDropDownIcon className="title_arrowTag" />
                        )}
                      </IconButton>
                    </div>
                    {isCollaps.isProjectList == false && (
                      <>
                        {project
                        ?.filter(document =>
                          document?.createdby?.email !== email 
                        )
                          // .filter((document) => {
                          //   // Check if the assetid matches and the email exists in sharedwith array
                          //   return !assetSharedinfo?.some(
                          //     (info) =>
                          //       info.assetid == document._id &&
                          //       info.sharedwith.some(
                          //         (shared) => shared.email == email
                          //       )
                          //   );
                          // })
                          .map((document, index) => {
                            const parsedJson = document.onlyDocumentContent
                              ? JSON.parse(document.onlyDocumentContent)
                              : { blocks: [{ text: "" }] };
                            let description = parsedJson.blocks[0].text;

                            return (
                              <div
                                className={
                                  listView ? "col-md-12" : "col-md-6"
                                }
                                key={index}
                                onClick={() => handleOpenSummary(document)}
                              >
                                    {listView ? (
                                  <DocumentListCard
                                    highlightClor="highlight"
                                    reminderAssetId={id}
                                    clauseExtraction={handleClauseExtraction}
                                    assetid={document._id}
                                    assetNoteComment={document.assetNoteComment}
                                    agancy={
                                      document?.opportunityid
                                        ?.fullParentPathName
                                    }
                                    id={document?.opportunityid?._id}
                                    documentId={document?.opportunityid?._id}
                                    type={document?.opportunityid?.type}
                                    title={document?.opportunityid?.title}
                                    description={
                                      document?.opportunityid?.descriptionData
                                    }
                                    noticeId={
                                      document?.opportunityid
                                        ?.solicitationNumber
                                    }
                                    department={
                                      document?.opportunityid
                                        ?.fullParentPathName
                                    }
                                    descriptionData={
                                      document?.opportunityid?.descriptionData
                                    }
                                    subtier={document?.opportunityid?.type}
                                    office={
                                      document?.opportunityid?.officeAddress
                                    }
                                    date={document?.opportunityid?.postedDate}
                                    date1={
                                      document?.opportunityid?.responseDeadLine
                                    }
                                    status={document?.opportunityid?.active}
                                    orgId={
                                      document?.opportunityid?.organizationId
                                    }
                                    // contact={document?.opportunityid?.pointOfContact[0]}
                                    contact={
                                      document?.opportunityid
                                        ?.pointOfContact !== null
                                        ? document?.opportunityid
                                            ?.pointOfContact[0]
                                        : []
                                    }
                                    place={
                                      document?.opportunityid
                                        ?.placeOfPerformance
                                    }
                                    resourceLinksPath={
                                      document?.opportunityid?.resourceLinksPath
                                    }
                                    resourceLinksData={
                                      document?.opportunityid?.resourceLinks
                                    }
                                    handleNewPage={handleNewPage}
                                    HandleProposalWriting={
                                      HandleProposalWriting
                                    }
                                    viewType={"Oppoupload"}
                                    oppList={"ProjectList"}
                                    proposalWriting={proposalWriting}
                                    RFP={RFP}
                                    redirectToRfp={handleRedirectToRfp}
                                    redirectToPropsal={handleRedirectToPropsal}
                                    createdBy={document?.createdby?.email}
                                    commentModal={handleCommentModal}
                                    requestListData={assetRequestedListData}
                                    getRequestedlistMember={
                                      handleGetRequestedMembers
                                    }
                                    opportunityUpdate={
                                      document?.opportunityUpdate
                                    }
                                    hierarchy={document?.createdby?.hierarchy}
                                  />
                                ) : (
                                  <DocumentGridCard
                                    assetid={document._id}
                                    assetNoteComment={document.assetNoteComment}
                                    agancy={
                                      document?.opportunityid
                                        ?.fullParentPathName
                                    }
                                    id={document?.opportunityid?._id}
                                    documentId={document?.opportunityid?._id}
                                    clauseExtraction={handleClauseExtraction}
                                    resourceLinksData={
                                      document?.opportunityid?.resourceLinks
                                    }
                                    type={document?.opportunityid?.type}
                                    title={document?.opportunityid?.title}
                                    description={description}
                                    // noticeId={document?.opportunityid?.noticeId}
                                    noticeId={
                                      document?.opportunityid
                                        ?.solicitationNumber
                                    }
                                    department={
                                      document?.opportunityid
                                        ?.fullParentPathName
                                    }
                                    descriptionData={
                                      document?.opportunityid?.descriptionData
                                    }
                                    subtier={document?.opportunityid?.type}
                                    office={
                                      document?.opportunityid?.officeAddress
                                    }
                                    date={document?.opportunityid?.postedDate}
                                    date1={
                                      document?.opportunityid?.responseDeadLine
                                    }
                                    status={document?.opportunityid?.active}
                                    orgId={
                                      document?.opportunityId?.organizationId
                                    }
                                    resourceLinksPath={
                                      document?.opportunityId?.resourceLinksPath
                                    }
                                    handleNewPage={handleNewPage}
                                    HandleProposalWriting={
                                      HandleProposalWriting
                                    }
                                    //  templatePage={templatePage}
                                    viewType={"Oppoupload"}
                                    oppList={"ProjectList"}
                                    redirectToRfp={handleRedirectToRfp}
                                    redirectToPropsal={handleRedirectToPropsal}
                                    requestListData={assetRequestedListData}
                                    opportunityUpdate={
                                      document?.opportunityUpdate
                                    }
                                  />
                                )}
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                )}


                {Archive.length > 0 && (
                  <div className="row">
                    <div className="projectTitle">
                      <hr />
                      <p style={{fontSize: windowWidth<768?'15px':'18px', fontWeight:'bold'}} className="title_headTag">Archived Projects ({Archive.length})</p>
                      <IconButton
                        onClick={() => {
                          handleCollaps("archivedproject");
                        }}
                      >
                        {isCollaps.isArchivedProject === false ? (
                          <ArrowDropUpIcon className="title_arrowTag"/>
                        ) : (
                          <ArrowDropDownIcon className="title_arrowTag"/>
                        )}
                      </IconButton>
                    </div>
                    {isCollaps.isArchivedProject === false && (
                      <>
                        {Archive.map((document, index) => {
                          const parsedJson = document.onlyDocumentContent
                            ? JSON.parse(document.onlyDocumentContent)
                            : { blocks: [{ text: "" }] };
                          let description = parsedJson.blocks[0].text;

                          return (
                            <div
                              className={
                                listView ? "col-md-12" : "col-md-6"
                              }
                              key={index}
                            >
                              {listView ? (
                                <DocumentListCard
                                // highlightClor="highlight"
                                  id={document._id}
                                  documentId={document?.opportunityid?._id}
                                  reminderAssetId={id}
                                  agancy={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  type={document?.opportunityid?.type}
                                  title={document?.opportunityid?.title}
                                  description={description}
                                  // noticeId={document?.opportunityid?.noticeId}
                                  noticeId={
                                    document?.opportunityid
                                      ?.solicitationNumber
                                  }
                                  department={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  descriptionData={
                                    document?.opportunityid?.descriptionData
                                  }
                                  subtier={document?.opportunityid?.type}
                                  office={
                                    document?.opportunityid?.officeAddress
                                  }
                                  date={document?.opportunityid?.postedDate}
                                  date1={
                                    document?.opportunityid?.responseDeadLine
                                  }
                                  status={document?.opportunityid?.active}
                                  viewType={"Archived"}
                                  unArchiveOpen={UnArchiveModalOpen}
                                  viewType3={"Archived"}

                                />
                              ) : (
                                <DocumentGridCard
                                  id={document._id}
                                  documentId={document?.opportunityid?._id}
                                  agancy={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  type={document?.opportunityid?.type}
                                  title={document?.opportunityid?.title}
                                  description={description}
                                  // noticeId={document?.opportunityid?.noticeId}
                                  noticeId={
                                    document?.opportunityid
                                      ?.solicitationNumber
                                  }
                                  department={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  descriptionData={
                                    document?.opportunityid?.descriptionData
                                  }
                                  subtier={document?.opportunityid?.type}
                                  office={
                                    document?.opportunityid?.officeAddress
                                  }
                                  date={document?.opportunityid?.postedDate}
                                  date1={
                                    document?.opportunityid?.responseDeadLine
                                  }
                                  status={document?.opportunityid?.active}
                                  viewType={"Archived"}
                                  unArchiveOpen={UnArchiveModalOpen}
                                  viewType3={"Archived"}

                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
                {/* completed projects */}
                {completeProjects.length > 0 && (
                  <div className="row">
                    <div className="projectTitle" style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                     
                      <div style={{display:'flex'}}>
                      <hr />
                      <p  style={{fontSize: windowWidth<768?'14px':'18px', fontWeight:'bold'}} className="title_headTag">Complete Projects ({completeProjects.length})</p>

                      <IconButton
                        onClick={() => {
                          handleCollaps("completeProjects");
                        }}
                      >
                        {isCollaps.isCompletedProject === false ? (
                          <ArrowDropUpIcon  className="title_arrowTag"/>
                        ) : (
                          <ArrowDropDownIcon className="title_arrowTag"/>
                        )}
                      </IconButton>
                      </div>
                      <div style={{marginRight:'20px'}}>
                        <IconButton onClick={()=>openSubmissionHistory()}>
                          <HistoryEduIcon/>
                        </IconButton>

                        <SubmissionHistoryModal
                            open={submissionHistoryModal}
                            handleClose={closeSubmissionHistory}
                            submissionHistory={submissionHistory}
                        />

                      </div>
                    </div>

                   

                   

                    {isCollaps.isCompletedProject === false && (
                      <>
                        {completeProjects.map((document, index) => {
                          const parsedJson = document.onlyDocumentContent
                            ? JSON.parse(document.onlyDocumentContent)
                            : { blocks: [{ text: "" }] };
                          let description = parsedJson.blocks[0].text;

                          return (
                            <div
                              className={
                                listView ? "col-md-12" : "col-md-6"
                              }
                              key={index}
                            >
                              {listView ? (
                                <DocumentListCard
                                  id={document._id}
                                  documentId={document?.opportunityid?._id}
                                  reminderAssetId={id}
                                  assetid={document._id}
                                  agancy={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  type={document?.opportunityid?.type}
                                  title={document?.opportunityid?.title}
                                  description={description}
                                  // noticeId={document?.opportunityid?.noticeId}
                                  noticeId={
                                    document?.opportunityid
                                      ?.solicitationNumber
                                  }
                                  department={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  descriptionData={
                                    document?.opportunityid?.descriptionData
                                  }
                                  subtier={document?.opportunityid?.type}
                                  office={
                                    document?.opportunityid?.officeAddress
                                  }
                                  date={document?.opportunityid?.postedDate}
                                  date1={
                                    document?.opportunityid?.responseDeadLine
                                  }
                                  status={document?.opportunityid?.active}
                                  viewType={"cancelled_project"}
                                  viewType1={"completed_project"}
                                  openMetrics={handleOpenMetricsModal}
                                  highlightClor="highlight"    
                                />
                              ) : (
                                <DocumentGridCard
                                  id={document._id}
                                  documentId={document?.opportunityid?._id}
                                  agancy={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  type={document?.opportunityid?.type}
                                  title={document?.opportunityid?.title}
                                  description={description}
                                  // noticeId={document?.opportunityid?.noticeId}
                                  noticeId={
                                    document?.opportunityid
                                      ?.solicitationNumber
                                  }
                                  department={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  descriptionData={
                                    document?.opportunityid?.descriptionData
                                  }
                                  subtier={document?.opportunityid?.type}
                                  office={
                                    document?.opportunityid?.officeAddress
                                  }
                                  date={document?.opportunityid?.postedDate}
                                  date1={
                                    document?.opportunityid?.responseDeadLine
                                  }
                                  status={document?.opportunityid?.active}
                                  viewType={"cancelled_project"}
                                  viewType1={"completed_project"}
                                  openMetrics={handleOpenMetricsModal}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
                {/* //cancelled projects */}
                {cancelledProjects.length > 0 && (
                  <div className="row">
                    <div className="projectTitle">
                      <hr />
                      <p style={{fontSize: windowWidth<768?'15px':'18px', fontWeight:'bold'}} className="title_headTag">Cancelled Projects ({cancelledProjects.length})</p>
                      <IconButton
                        onClick={() => {
                          handleCollaps("cancelledProjects");
                        }}
                      >
                        {isCollaps.isCancelledProject === false ? (
                          <ArrowDropUpIcon className="title_arrowTag"/>
                        ) : (
                          <ArrowDropDownIcon className="title_arrowTag" />
                        )}
                      </IconButton>
                    </div>
                    {isCollaps.isCancelledProject === false && (
                      <>
                        {cancelledProjects.map((document, index) => {
                          const parsedJson = document.onlyDocumentContent
                            ? JSON.parse(document.onlyDocumentContent)
                            : { blocks: [{ text: "" }] };
                          let description = parsedJson.blocks[0].text;

                          return (
                            <div
                              className={
                                listView ? "col-md-12" : "col-md-6"
                              }
                              key={index}
                            >
                              {listView ? (
                                <DocumentListCard
                                  id={document._id}
                                  documentId={document?.opportunityid?._id}
                                  reminderAssetId={id}
                                  agancy={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  type={document?.opportunityid?.type}
                                  title={document?.opportunityid?.title}
                                  description={description}
                                  // noticeId={document?.opportunityid?.noticeId}
                                  noticeId={
                                    document?.opportunityid
                                      ?.solicitationNumber
                                  }
                                  department={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  descriptionData={
                                    document?.opportunityid?.descriptionData
                                  }
                                  subtier={document?.opportunityid?.type}
                                  office={
                                    document?.opportunityid?.officeAddress
                                  }
                                  date={document?.opportunityid?.postedDate}
                                  date1={
                                    document?.opportunityid?.responseDeadLine
                                  }
                                  status={document?.opportunityid?.active}
                                  viewType={"cancelled_project"}
                                />
                              ) : (
                                <DocumentGridCard
                                  id={document._id}
                                  documentId={document?.opportunityid?._id}
                                  agancy={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  type={document?.opportunityid?.type}
                                  title={document?.opportunityid?.title}
                                  description={description}
                                  // noticeId={document?.opportunityid?.noticeId}
                                  noticeId={
                                    document?.opportunityid
                                      ?.solicitationNumber
                                  }
                                  department={
                                    document?.opportunityid?.fullParentPathName
                                  }
                                  descriptionData={
                                    document?.opportunityid?.descriptionData
                                  }
                                  subtier={document?.opportunityid?.type}
                                  office={
                                    document?.opportunityid?.officeAddress
                                  }
                                  date={document?.opportunityid?.postedDate}
                                  date1={
                                    document?.opportunityid?.responseDeadLine
                                  }
                                  status={document?.opportunityid?.active}
                                  viewType={"cancelled_project"}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
              </>
            )}

            <Modal open={isCommentModalOpen} onClose={handleCloseCommentModal}>
              <div className="commentModal">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <h4 variant="h5" className="Request_Access">
                    Request Access
                  </h4>
                  <IconButton onClick={handleCloseCommentModal}>
                    <CloseIcon />
                  </IconButton>
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <FormControl fullWidth>
                    <Select
                      value={assetRequestData.assetType}
                      onChange={handleAssetTypeChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="rfp">
                        <em>rfp</em>
                      </MenuItem>
                      <MenuItem value={"proposal"}>propsal</MenuItem>
                      <MenuItem value={"both"}>both</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <TextField
                  label="Enter your message"
                  multiline
                  rows={5}
                  fullWidth
                  required
                  value={assetRequestData?.comment}
                  onChange={(e) => handleCommentTextareaChange(e.target.value)}
                  variant="outlined"
                />

                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginRight: "10px" }}
                    onClick={handleCloseCommentModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSendClick()}
                  >
                    Send
                  </Button>
                </div>
              </div>
            </Modal>
          </div>

          <Modal open={archiveModalOpen} onClose={UnArchiveModalClose}>
            <div className="commentModal">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <div>Are you sure you want to unarchive this project?</div>
                <IconButton onClick={UnArchiveModalClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "10px" }}
                  onClick={UnArchiveModalClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleUnArchive()}
                >
                  Unarchive
                </Button>
              </div>
            </div>
          </Modal>


          {!ishandleNewPage && !showList && (
            <>
              <Modal
                open={openUploadModal}
                onClose={() => setOpenUploadModal(false)} // Close the modal
                aria-labelledby="upload-modal-title"
                aria-describedby="upload-modal-description"
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    outline: "none",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    minWidth: "300px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Center items horizontally
                  }}
                >
                  <Typography variant="h5" id="upload-modal-title">
                    Uploading Notice
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                    }} // Ensure the progress bar spans the entire width
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenUploadModal(false)} // Close the modal on cancel
                    style={{ outline: "none" }}
                  >
                    Cancel
                  </Button>
                </div>
              </Modal>

              <Modal
                open={openSummaryModal}
                onClose={() => setOpenSummaryModal(false)}
                aria-labelledby="summary-modal-title"
                aria-describedby="summary-modal-description"
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    outline: "none",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    minWidth: "300px",
                    maxWidth: "800px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography variant="h5" id="summary-modal-title">
                      Notice Summary
                    </Typography>
                    <IconButton onClick={() => setOpenSummaryModal(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Document Name"
                        defaultValue={selectedDocument.title}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Description"
                        defaultValue={selectedDocument.description}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Notice ID"
                        defaultValue={selectedDocument.noticeId}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Department"
                        defaultValue={selectedDocument.department}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Subtier"
                        defaultValue={selectedDocument.subtier}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Office"
                        defaultValue={selectedDocument.office}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Date"
                        defaultValue={selectedDocument.date}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Response Deadline"
                        defaultValue={selectedDocument.date1}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        label="Status"
                        defaultValue={selectedDocument.status}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => console.log("Save")}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setOpenSummaryModal(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Modal>

              <Modal
                open={openMemberListModal}
                onClose={() => setOpenMemberListModal(false)}
              >
                <div className="requestModal">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h5" id="summary-modal-title">
                      Request List
                    </Typography>
                    <IconButton onClick={() => setOpenMemberListModal(false)}>
                      <CloseIcon />
                    </IconButton>
                  </div>

                  <div className="selectAll_checkbox">
                    <div>
                      <label className="selectAll_checkbox_label">
                        Select All
                      </label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        checked={selectAllChecked}
                        onChange={toggleSelectAll}
                      />
                    </div>
                  </div>

                  <div className="memberParent_div">
                    {assetRequestedMemberList
                      ?.filter((data) => data?.requestedStatus == "pending")
                      .map((entry, index) => (
                        <div className="member_info_div" key={index}>
                          <div>
                            <p className="member_info_ptag">
                              Email: {entry.requestFrom}
                            </p>
                            <p className="member_info_ptag">
                              Comment: {entry.comment}
                            </p>
                            <p className="member_info_ptag">
                              type:{entry.assetType}
                            </p>
                          </div>

                          <div>
                            <input
                              type="checkbox"
                              checked={selectedEmails.some(
                                (obj) =>
                                  obj.email === entry.requestFrom &&
                                  obj.assetType === entry.assetType
                              )}
                              onChange={() =>
                                toggleEmailSelection(
                                  entry.requestFrom,
                                  entry.assetType
                                )
                              }
                            />
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="grant_access-div">
                    <div className="grantAccess_butoon_div">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={grantAccessForSelectedEmails}
                      >
                        Grant Access
                      </Button>
                    </div>

                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setOpenMemberListModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>

              <Modal
                open={isOpenClauseModal}
                onClose={() => setIsOpenClauseModal(false)}
              >
                <div className="requestModal">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" id="Clause-modal-title">
                      Clauses
                    </Typography>
                    <IconButton onClick={() => setIsOpenClauseModal(false)}>
                      <CloseIcon id="closeIcon" />
                    </IconButton>
                  </div>

                  <div id="clauses_textfeild_flex">
                    {/* <div id="clauses_textfeild_main">
                      <TextField
                        label={<div>Add Clause</div>}
                        variant="outlined"
                        size="small"
                        style={{ width: "100%", borderRadius: "20px" }}
                        onChange={handleAddClauses}
                      />
                    </div>
                    <div id="clauses_addButton_main">
                      <Button
                        title="Upload"
                        variant="outlined"
                        onClick={handleAddManualClause}
                        id="addclauseButton"
                      >
                        Add
                       
                      </Button>
                    </div> */}
                     {extractedClause && extractedClause?.clauses?.length > 0 &&
                    <div>
                      <IconButton>
                        <div title="Download" onClick={handleDownloadClause}>
                          <DownloadIcon />
                        </div>
                      </IconButton>
                    </div>
}
                  </div>

                  {!extractedClause || extractedClause?.clauses?.length == 0 &&
                  <div style={{display:"flex",justifyContent:"center",}}>
                    <h4>No clauses found </h4>
                  </div>
                  }

                     
                  <div id="extractedClause_div">
                    {extractedClause && (
                      <>
                        <div>
                          {extractedClause?.clauses?.map((clauseData, i) => (
                            <div key={i}>
                              {clauseData.filename ==
                              "Manually Added Clauses" ? (
                                <h5 id="filename_ptag">
                                  {clauseData.filename}
                                </h5>
                              ) : (
                                <h5 id="filename_ptag">
                                  Filename: <span
                                  className="filname_Style"
                                  >
                                  {clauseData.filename}
                                  </span>
                                </h5>
                              )}
                              {clauseData?.responseData
                                ?.split("\n")
                                .map((clause, j) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                    key={j}
                                  >
                                    <p
                                      id="clause_ptag1"
                                    >
                                      {clause}
                                    </p>
                               
                                  </div>
                                ))}
                            </div>
                          ))}
                        </div>
                        {/* </div> */}
                      </>
                    )}
                  </div>

                  <div id="clause_footer_div">
                    <div>
                      <Button onClick={() => setIsOpenClauseModal(false)} variant="contained" color="secondary">
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>


              <Modal
                open={showDownloadModal}
                onHide={() => setshowDownloadModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%", // Adjusted width
                    maxWidth: 400,
                    bgcolor: "white",
                    borderRadius: 8,
                    boxShadow: 24,
                    p: 2,
                    textAlign: "center",
                    overflowY: "auto", // Enable scrolling if content exceeds box height
                  }}
                >
                  <div className="modal-header">
                    <h3 id="modal-modal-title" className="modal-title">
                      Download Options
                    </h3>
                    <CloseIcon
                      onClick={() => setshowDownloadModal(false)}
                      className="close-icon"
                      style={{
                        color: "red",
                        cursor: "pointer",
                        position: "absolute",
                        top: 10,
                        right: 20,
                      }}
                    />
                  </div>

                  <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <span>Download as...</span>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button
                      onClick={downloadSinglePDF}
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "30px", marginBottom: "10px" }}
                    >
                      PDF
                    </Button>
                    <Button
                      onClick={handleDocxDownload}
                      variant="contained"
                      color="primary"
                      style={{ marginBottom: "10px" }}
                    >
                      Word
                    </Button>
                  </div>
                </Box>
              </Modal>


          <Modal open={openValidationModal} onClose={() => setValidationModal(false)}>
          <div
            style={{
              position: "absolute",
              height:"160px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              // padding: "20px",
              borderRadius: "15px",
              outline: "none",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              Width: "400px",
              // border:"2px solid red",
              display:"flex",
              justifyContent:"space-between",
              borderLeft:"16px solid #1976D2"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "10%",
                // border:"1px solid green",
                color:"#1976D2",
                
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
              
              }}
            >
              {/* <Typography variant="h5" id="summary-modal-title">

              </Typography> */}
             <InfoIcon style={{fontSize:"60px"}} />
            </div>

            <h4 style={{width:"67%",
              // border:"1px solid blue",
              display:"flex",justifyContent:"center",marginTop:"7.5%"}}>
            {validationData && validationData.length > 0 ? (
              `Currently, the workflow for the ${validationData.map((data) => data.teamName).join(', ')}. Please assign the respective team members`
            ) : (
              'You dont have team members assigned to workflow.'
            )}
          </h4>
           

            <div
              style={{
                width:"15%",
                height:"100%",
                paddingRight:"12px",
                display: "flex",
                flexDirection:"column",
                justifyContent: "right",
                // border:"2px solid orange",
                
              }}
            >
          <IconButton style={{marginLeft:"80px",color:"orangered"}} onClick={() => setValidationModal(false)}>
                <CloseIcon style={{outline:"none",border:"none"}} />
              </IconButton>
              <Button
                variant="contained"
                // color="#1976D2"
                style={{marginTop:"17%",width:"95%",backgroundColor:"#1976D2",color:"white"}}
                onClick={() => {
                  redirectToWorkflow();
                }}
              >
                redirect
              </Button>
            </div>
          </div>
        </Modal>
            </>
          )}
        </div>

        <MetricsModal
          open={isMetricsModal}
          close={handleCloseMetricsModal}
          assetId = {metricAssetId}
          title={metricsTital}
          existingData={existingData}
          allExistingData={rows}
          handleAdd={handleAddMetricsData}
          handleDelete={handleDeletemetrics}
          enableUpdateMetric={handleEnabelUpdateMetrics}
          canEdit = {ismetricEdit}
          updateMetrics={handleUpdateMetrics}
        />
      </div>


    </>
  );
};

export default OpportunityManagement;