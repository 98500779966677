import React, { useEffect, useState } from 'react';

const ProjectCard = ({ projectName, date, status, lastEdited, team, cardWidth, cardHeight, assetid, redirect }) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{
      // width: cardWidth || '650px',
      // height: '100%',
      border: '1px solid #1976D2',
      boxShadow: '2px 2px 5px -2px #000000',
      padding: '10px',
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: '10px',
      flexDirection: windowWidth < 350 ? "column" : 'row',
      marginBottom: '10px',
      cursor: 'pointer',
    }}
      onClick={() => { redirect(assetid) }}>
      <div style={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
        <strong>{projectName}</strong>
        <span style={{ fontSize: '12px', color: 'red', marginTop: '10px' }}>
          {date}
        </span>
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', textAlign: windowWidth < 350 ? "left" : 'right' }}>
        Status:{status}
        {/* <span style={{ fontSize: '12px', marginTop:'10px' }}>{`Last edited ${lastEdited} ago`}</span> */}
      </div>
    </div>
  );
};

export default ProjectCard;
