import React, { useState } from 'react';
import './ReorderSequence.css';
import alertCircle from '../Images/alertCircle.png';
import xCircle from '../Images/xCircle.png'
import { getorderedsection } from '../../services/assetSectionServices';
import ReorderPopup from './ReorderPopup';

const ReorderSequence = ({ items, setItems, toggleModal }) => {
  const [reorderedItems, setReorderedItems] = useState([...items]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  const handleReorder = async(startIndex, endIndex) => {
    const newItems = [...reorderedItems];
    const [removed] = newItems.splice(startIndex, 1);
    newItems.splice(endIndex, 0, removed);
    setReorderedItems(newItems);
    let id = []
    let payload = {
      "order":[],
      "assetType":"",
      "assetId":""
    }
     newItems.map((item, index) => {
      console.log("item__GG", item)
      id.push({_id:item._id})
      console.log("_id, id",id)
     console.log("length_", id.length, index, newItems.length)
           if (id.length == newItems.length) {
        console.log("inside if")
        payload = {
          "order": id,
          "assetType": item.assetType,
          "assetId": item.assetId
        } } }    );
  
        setSelectedItem()
        try {
            const response = await getorderedsection(payload)
            console.log(response);
            if (response) {
              console.log('Sequence updated successfully');
            } else {
              console.error('Failed to update sequence');
            }        
          } catch (error) {
            console.error('Error saving data:', error);
          } 
  };

  const handleSave = () => {
    setItems([...reorderedItems]);
    toggleModal();
  };

  const handleCancel = () => {
    setReorderedItems([...items]);
  };
// popup functions
 

  const handleClosePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(false);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };


  return (
    <div className="modal-overlayR">
      <div className="modal-contentR">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div><h2 style={{ color: 'rgb(61, 131, 250)',marginBottom:'15px'}}>Reorder Sections</h2></div>
          <div>
            <span><img src={alertCircle} style={{ height: '20px', marginRight: '15px',cursor: "pointer" }} alt="Description of the image" onClick={handleOpenPopup}/>
            
            </span>
            <span><img src={xCircle} style={{ height: '20px',cursor: "pointer" }} alt="Description of the image" onClick={toggleModal} /></span>
          </div>
        </div>
        <ul className="item-listR">
          {reorderedItems.map((item, index) => (
            <li
              key={item._id}
              draggable
              onDragOver={(e) => e.preventDefault()}
              onDragStart={(e) => e.dataTransfer.setData("text/plain", index)}
              onDrop={(e) => {
                e.preventDefault();
                const startIndex = Number(e.dataTransfer.getData("text/plain"));
                const endIndex = index;
                handleReorder(startIndex, endIndex);
              }}
              onMouseEnter={() => setSelectedItem(item._id)}
              onMouseLeave={() => setSelectedItem(null)}
              className={`list-itemR ${selectedItem === item._id ? 'selected' : ''}`}
            >
              {item.title}
            </li>
          ))}
        </ul>
        <div className="button-groupR">
          <button className="cancel-buttonR" onClick={handleCancel}>Cancel</button>
          <button className="save-buttonR" onClick={handleSave}>Save New Order</button>

        </div>
        <div>
      {isPopupOpen && <ReorderPopup open={isPopupOpen} message="Drag the section to the desired position." onClose={handleClosePopup} />}
    </div>
      </div>
     
    </div>
   
  );
};

export default ReorderSequence;
